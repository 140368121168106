import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChartModule} from 'primeng/chart';
import 'chartjs-adapter-date-fns';
import {fr} from 'date-fns/locale';

export interface LineChartDataset {
  label: string;
  data: Array<{ x: Date; y: number }>;
  color?: string;
}

@Component({
  selector: 'line-chart',
  standalone: true,
  imports: [ChartModule],
  template: `
    <div class="flex justify-content-between">
      <span class="text-2xl font-bold">{{ title }}</span>
    </div>
    <div class="px-4">
      <p-chart [type]="'line'" [data]="chartData" [options]="chartOptions" [responsive]="true" />
    </div>
  `
})
export class GenericLineChartComponent implements OnChanges {

  @Input({required: true}) title!: string;
  @Input() datasets: LineChartDataset[] = [];
  @Input() xAxisType: 'time' | 'category' = 'time';
  @Input() xAxisTimeUnit?: 'day' | 'week' | 'biweekly' | 'month' | 'quarter' | 'year';
  @Input() yAxisLabel?: string;
  @Input() currency?: string;

  chartData: any;
  chartOptions: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['datasets'] || changes['xAxisType'] || changes['xAxisTimeUnit']) {
      this.prepareChartData();
    }
  }

  prepareChartData() {
    const labels = this.xAxisType === 'time'
      ? [...new Set(this.datasets.flatMap(ds => ds.data.map(d => d.x)))].sort((a, b) => a.getTime() - b.getTime())
      : [...new Set(this.datasets.flatMap(ds => ds.data.map(d => d.x)))];

    this.chartData = {
      labels: labels,
      datasets: this.datasets.map(dataset => ({
        label: dataset.label,
        data: dataset.data.map(d => ({ x: d.x, y: d.y })),
        borderColor: dataset.color ?? this.getRandomColor(),
        fill: false,
        tension: 0.4
      }))
    };

    this.setChartOptions();
  }

  setChartOptions() {
    this.chartOptions = {
      responsive: true,
      scales: {
        x: {
          type: this.xAxisType,
          time: this.xAxisType === 'time' ? {
            unit: this.xAxisTimeUnit ?? 'week',
            displayFormats: this.getDisplayFormat(this.xAxisTimeUnit)
          } : undefined,
          adapters: {
            date: { locale: fr }
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: !!this.yAxisLabel,
            text: this.yAxisLabel
          },
          ticks: {
            callback: (value: number) => {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                notation: 'compact'
              }).format(value);
            }
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              let label = context.dataset.label || '';
              if (label) label += ': ';
              if (context.parsed.y !== null) {
                label += this.currency
                  ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: this.currency }).format(context.parsed.y)
                  : context.parsed.y.toFixed(2);
              }
              return label;
            }
          }
        },
        legend: {
          position: 'top',
        }
      }
    };
  }

  getDisplayFormat(timeUnit?: string) {
    switch (timeUnit) {
      case 'week':
        return { week: 'dd/MM/yyyy' };
      case 'month':
        return { month: 'MM/yyyy' };
      case 'quarter':
        return { quarter: "QQQ yyyy" };
      case 'year':
        return { year: 'yyyy' };
      default:
        return { day: 'dd/MM/yyyy' };
    }
  }

  getRandomColor() {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
  }
}
