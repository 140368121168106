import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AccountType, IAccount} from "../../../../../core/models/account.model";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {VirementService} from "../../../../../core/services/virement.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {AccountService} from "../../../../../core/services/account.service";
import {getUnpagedPageable} from "../../../../../core/models/page.model";
import {IVirementRequest} from "../../../../../core/models/virement.model";
import {DropdownModule} from "primeng/dropdown";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {NgIf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {CalendarModule} from "primeng/calendar";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ICurrency} from "../../../../../core/models/devise.model";
import {CurrencyService} from "../../../../../core/services/currency.service";
import {BeneficiaryService} from "../../../../../core/services/beneficiary.service";
import {StorageService} from "../../../../../core/services/storage.service";
import {IBeneficiary} from "../../../../../core/models/beneficiary.model";

@Component({
  selector: 'app-edit-virement',
  standalone: true,
  imports: [
    // Modules
    ReactiveFormsModule, DropdownModule, InputTextModule, CalendarModule, InputNumberModule, InputTextareaModule,
    // Pipes & Directives
    Button, Ripple, NgIf
  ],
  templateUrl: './edit-virement.component.html',
  styleUrl: './edit-virement.component.scss'
})
export class EditVirementComponent implements OnInit {

  formGroup = new FormGroup({
    libelle: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    currency: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl<string>(''),
    amount: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
    operationDate: new FormControl<Date | null>(null, { nonNullable: true, validators: [Validators.required] }),
    valueDate: new FormControl<Date | null>(null, { nonNullable: true, validators: [Validators.required] }),
    account: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    beneficiary: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
  });
  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  accounts: IAccount[] = [];
  beneficiaries: IBeneficiary[] = [];
  currencies: ICurrency[] = [];
  today: Date | undefined = new Date();

  constructor(
    private readonly virementService: VirementService,
    private readonly dialogService: DialogService,
    private readonly deviseService: CurrencyService,
    private readonly beneficiaryService: BeneficiaryService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef,
    private readonly accountService: AccountService,
    private readonly tokenManager: StorageService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.getCurrencies();
    this.getAccounts();
    this.getBeneficiaries();
    this.boundData = this.instance?.data;
    if (!this.boundData?.isCreate) {
      this.today = undefined;
      const formData = {
        ...this.boundData?.data,
        operationDate: new Date(this.boundData?.data.operationDate),
        valueDate: new Date(this.boundData?.data.valueDate),
        account: this.boundData?.data.account.id,
        beneficiary: this.boundData?.data.beneficiary.id,
        currency: this.boundData?.data.currency.id
      };
      this.formGroup.patchValue(formData);
      if (this.boundData?.isView) this.formGroup.disable();
    }
  }

  getBeneficiaries() {
    const jwt = this.tokenManager.getToken();
    this.beneficiaryService.getBeneficiaryByInstitutionId(jwt.institution_id, getUnpagedPageable())
      .subscribe({
        next: (page) => this.beneficiaries = page.content,
        error: (err) => this.toastService.showToast('Récupération des bénéficiaires', err.error, 'error')
      });
  }

  getCurrencies() {
    this.deviseService.getAllDevises(getUnpagedPageable()).subscribe({
      next: (page) => this.currencies = page.content,
      error: (err) => this.toastService.showToast('Récupération des devises', err.error , 'error')
    });
  }

  getAccounts() {
    this.accountService.getAccountsByType(AccountType.NOSTRO, getUnpagedPageable()).subscribe({
      next: (page) => this.accounts = page.content,
      error: (err) => this.toastService.showToast('Chargement des comptes', err.error, 'error')
    });
  }

  create() {
    this.isLoading = true;
    const virement = this.getFormValue();
    this.virementService.createVirement(virement).subscribe({
      next: (virement) => {
        this.toastService.showToast('Virement créé', `Le virement ${virement.libelle} a été créé avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Création du virement', error.error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const virement = this.getFormValue();
    this.virementService.updateVirement(this.boundData?.data.id, virement).subscribe({
      next: (virement) => {
        this.toastService.showToast('Virement modifié', `Le virement ${virement.libelle} a été modifié avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification du virement', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  getFormValue(): IVirementRequest {
    const virement = this.formGroup.value as unknown as IVirementRequest;
    virement.beneficiary = <IBeneficiary>this.beneficiaries.find(b => b.id == this.formGroup.value.beneficiary);
    virement.account = <IAccount>this.accounts.find(d => d.id == this.formGroup.value.account);
    virement.currency = <ICurrency>this.currencies.find(d => d.id == this.formGroup.value.currency);
    console.log('Virement: ', virement);
    return virement;
  }

}
