import { Component, Input, OnInit } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DateRangeComponent } from "../../../date-range/date-range.component";
import { RepaymentService } from "../../../../core/services/repayment-entry.service";
import { ToastService } from "../../../../core/services/toast.service";
import { IRepaymentEntrySummary } from "../../../../core/models/repayment-entry.model";
import { startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { fr } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import { LoanBorrowingType } from "../../../../core/models/loan-borrowing.model";
import { Router } from "@angular/router";

interface IAggregatedData {
  date: Date;
  amount: number;
}

interface IDatasetsByType {
  [key: string]: IAggregatedData[];  // key is the type (Prêts or Emprunts)
}

interface IDatasetsByCurrency {
  [key: string]: IDatasetsByType;    // key is the currency code
}

@Component({
  selector: 'repayment-volume-bar-chart',
  standalone: true,
  imports: [
    ChartModule, DropdownModule, FormsModule,
    DateRangeComponent
  ],
  template: `
    <div class="flex flex-column gap-2 align-items-center w-full">
      <div class="w-full flex justify-content-between">
        <span class="font-semibold text-xl">Volume de remboursements</span>
        <div class="flex gap-2">
          <date-range [maxDate]="maxDate" (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)"/>
          <p-dropdown [options]="timeGroupings" [(ngModel)]="selectedTimeGrouping" (onChange)="onTimeGroupingChange()" />
        </div>
      </div>
      <div class="w-9">
        <p-chart
          class="m-auto"
          style="max-width: 756px; max-height: 512px"
          type="bar"
          [data]="chartData"
          [options]="chartOptions"
          [responsive]="true"
        />
      </div>
    </div>
  `
})
export class RepaymentVolumeChartComponent implements OnInit {

  @Input() types: LoanBorrowingType[] = [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING];
  isLocal: boolean = true;

  maxDate: Date = new Date();
  rangeDates: Date[] = [];
  repaymentSummary: IRepaymentEntrySummary[] = [];
  chartData: any;
  chartOptions: any;

  timeGroupings = [
    { label: 'Mensuel', value: 'month' },
    { label: 'Trimestriel', value: 'quarter' },
    { label: 'Annuel', value: 'year' }
  ];
  selectedTimeGrouping: 'month' | 'quarter' | 'year' = 'month';

  private readonly colorMap: Map<string, string> = new Map();
  private usedHues: number[] = [];

  constructor(
    private readonly repaymentService: RepaymentService,
    private readonly toastService: ToastService,
    private readonly router: Router
  ) {
    this.isLocal = this.router.url.includes('/tresorerie/locale/');
  }

  ngOnInit() {
    const yearLater = new Date();
    yearLater.setFullYear(yearLater.getFullYear() + 1);
    this.rangeDates = [new Date(), yearLater];
    this.loadRepaymentSummary();
  }

  onDateRangeSelected(dateRange: Date[]) {
    this.rangeDates = dateRange;
    this.loadRepaymentSummary();
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Sélection de date invalide', message, 'error');
  }

  onTimeGroupingChange() {
    this.prepareChartData();
  }

  loadRepaymentSummary() {
    this.repaymentService.getRepaymentSummary(
      this.rangeDates[0],
      this.rangeDates[1],
      this.isLocal,
      this.types
    ).subscribe({
      next: (data: IRepaymentEntrySummary[]) => {
        this.repaymentSummary = data;
        this.prepareChartData();
      },
      error: (err) => this.toastService.showToast('Chargement des données échoué', err.error, 'error')
    });
  }

  private organizeDataByCurrency(data: IRepaymentEntrySummary[]): IDatasetsByCurrency {
    const organizedData: IDatasetsByCurrency = {};

    // First pass: organize data by currency and type
    data.forEach(item => {
      const currencyCode = item.currency.code;
      const date = this.getGroupDate(new Date(item.paymentDate));

      if (!organizedData[currencyCode]) {
        organizedData[currencyCode] = {
          'Prêts': [],
          'Emprunts': []
        };
      }

      organizedData[currencyCode][item.type].push({
        date,
        amount: item.amount
      });
    });

    // Second pass: aggregate amounts for same dates
    Object.keys(organizedData).forEach(currency => {
      Object.keys(organizedData[currency]).forEach(type => {
        const aggregated = new Map<number, number>();

        organizedData[currency][type].forEach(item => {
          const timestamp = item.date.getTime();
          aggregated.set(timestamp, (aggregated.get(timestamp) ?? 0) + item.amount);
        });

        organizedData[currency][type] = Array.from(aggregated.entries())
          .map(([timestamp, amount]) => ({
            date: new Date(timestamp),
            amount
          }))
          .sort((a, b) => a.date.getTime() - b.date.getTime());
      });
    });

    return organizedData;
  }

  prepareChartData() {
    const organizedData = this.organizeDataByCurrency(this.repaymentSummary);
    const currencies = Object.keys(organizedData).sort((a, b) => a.localeCompare(b));

    // Get all unique dates across all data
    const allDates = new Set<number>();
    currencies.forEach(currency => {
      Object.values(organizedData[currency]).forEach(typeData => {
        typeData.forEach(entry => allDates.add(entry.date.getTime()));
      });
    });

    const labels = Array.from(allDates)
      .sort((a, b) => a - b)
      .map(timestamp => new Date(timestamp));

    // Generate colors for currencies
    this.generateColorsForCurrencies(currencies);

    // Create datasets
    const datasets = currencies.flatMap(currency =>
      this.types.map(type => {
        const typeData = organizedData[currency][type];
        const baseHue = this.colorMap.get(currency) ?? '0';
        const color = this.getColorForTypeAndCurrency(type, baseHue);

        return {
          label: `${type} (${currency})`,
          data: labels.map(date => {
            const entry = typeData.find(item =>
              item.date.getTime() === date.getTime()
            );
            return entry?.amount ?? 0;
          }),
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          barPercentage: 0.8,
          categoryPercentage: 0.9
        };
      })
    );

    this.chartData = { labels, datasets };
    this.setChartOptions();
  }

  private generateColorsForCurrencies(currencies: string[]) {
    this.colorMap.clear();
    this.usedHues = [];

    currencies.forEach(currency => {
      if (!this.colorMap.has(currency)) {
        const hue = this.generateUniqueHue();
        this.colorMap.set(currency, hue.toString());
      }
    });
  }

  private generateUniqueHue(): number {
    const minHueDistance = 30;
    let hue: number;

    do {
      hue = Math.floor(Math.random() * 360);
    } while (
      this.usedHues.some(
        usedHue =>
          Math.abs(usedHue - hue) < minHueDistance ||
          Math.abs(usedHue - hue - 360) < minHueDistance
      )
      );

    this.usedHues.push(hue);
    return hue;
  }

  private getColorForTypeAndCurrency(type: string, baseHue: string): string {
    const saturation = type === 'Prêts' ? '80%' : '60%';
    const lightness = type === 'Prêts' ? '60%' : '30%';
    const opacity = type === 'Prêts' ? '1' : '0.75';

    return `hsla(${baseHue}, ${saturation}, ${lightness}, ${opacity})`;
  }

  private getGroupDate(date: Date): Date {
    switch (this.selectedTimeGrouping) {
      case 'month': return startOfMonth(date);
      case 'quarter': return startOfQuarter(date);
      case 'year': return startOfYear(date);
    }
  }

  private setChartOptions() {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: this.selectedTimeGrouping,
            displayFormats: {
              month: 'MM/yyyy',
              quarter: 'QQQ yyyy',
              year: 'yyyy'
            }
          },
          adapters: {
            date: { locale: fr }
          },
          grid: {
            display: false
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: 'rgba(0, 0, 0, 0.1)'
          },
          ticks: {
            callback: (value: number) => {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                notation: 'compact'
              }).format(value);
            }
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const label = context.dataset.label || '';
              if (label) {
                const currency = label.match(/\((.*?)\)/)[1];
                return `${label}: ${new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: currency,
                  maximumFractionDigits: 0
                }).format(context.parsed.y)}`;
              }
              return label;
            }
          }
        },
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 20,
            font: {
              size: 11
            }
          }
        }
      },
      interaction: {
        intersect: false,
        mode: 'index'
      },
      animation: {
        duration: 500
      }
    };
  }
}
