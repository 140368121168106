<div class="flex flex-column gap-4 w-full h-full align-content-between">
  <div class="flex w-full justify-content-between mt-2">
    <search (search)="onSearch($event)" [placeholder]="'Recherche'" [debounceTime]="500"></search>
    <div class="flex gap-2">
      <p-button icon="pi pi-download" (onClick)="uploadOperation()" />
      <export (handler)="exportOperation($event)" [iconOnly]="true" label="opérations"/>
    </div>
  </div>
  <div>
    <dynamic-table [headers]="columns" [data]="operations" [pageParams]="pageable" [actions]="actions" (paginate)="onPageChange($event)"  />
  </div>
</div>
