<div class="container">
  <p-timeline [value]="boundData?.data.audits" class="w-full md:w-20rem">
    <ng-template pTemplate="content" let-event>
      <small class="p-text-secondary">{{ event.createdDate | date : 'dd/MM/yyyy, hh:mm' }}</small>
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
      <span class="font-semibold">{{ event.comment }}</span>
    </ng-template>
  </p-timeline>
  <span *ngIf="!boundData" class="font-italic">Aucune piste d'audit disponible.</span>
</div>
