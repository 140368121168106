import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from "../../models/page.model";
import {ICategoryCalculation} from '../../models/category-calculation.model';
import { IOperation } from '../../models/operation.model';

// Define interfaces

@Injectable({
  providedIn: 'root'
})
export class SuiviRisqueService {

  private apiUrl = `operation/v1/operations`;

  constructor(private http: HttpClient) { }
/*

  createVirement(virement: IVirementRequest): Observable<IVirementResponse> {
    return this.http.post<IVirementResponse>(this.apiUrl, virement);
  }

  updateVirement(id: string, virement: IVirementRequest): Observable<IVirementResponse> {
    return this.http.put<IVirementResponse>(`${this.apiUrl}/${id}`, virement);
  }

  deleteVirement(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getVirement(id: string): Observable<IVirementResponse> {
    return this.http.get<IVirementResponse>(`${this.apiUrl}/${id}`);
  }

  validateVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/validate`, {});
  }

  rejectVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/reject`, {});
  }

  executeVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/execute`, {});
  }

  cancelVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/cancel`, {});
  }
*/

  /*getAllOps(pageable: Pageable): Observable<Page<IOperation>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IVirementResponse>>(`${this.apiUrl}/institution/${institutionId}/state/${state}/category/${category}`, { params });  }*/

  getResultsForSuiviALM(pageable: Pageable, year: number): Observable<ICategoryCalculation[]> {
    let params = getPageableAsParams(pageable);

    return this.http.get<ICategoryCalculation[]>(`${this.apiUrl}/suivi-alm/year/${year}`, { params });
  }
}

