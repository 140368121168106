import {Component, OnInit} from '@angular/core';
import {IBalanceSheetCategory, ItemType} from "../../../../../core/models/balance-sheet.model";
import {BalanceSheetCategoryService} from "../../../../../core/services/balance-sheet-category.service";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../../../core/services/toast.service";
import {DEFAULT_PAGE_PARAMS, getUnpagedPageable, PageParams} from "../../../../../core/models/page.model";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Button} from "primeng/button";
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {IFlowLaw, IFlowLawCategory} from "../../../../../core/models/flow-law.model";
import {FlowLawService} from "../../../../../core/services/flow-law.service";
import {FlowLawCategoryService} from "../../../../../core/services/flow-law-category.service";
import {CalendarModule} from "primeng/calendar";

export interface FlowLawCategoryDialogData extends CustomDialogData{
  data: { flowLaw?: IFlowLaw, category?: IBalanceSheetCategory };
}

@Component({
  selector: 'app-flow-law-category-details',
  standalone: true,
  imports: [
    // Modules
    CheckboxModule, DropdownModule, FormsModule, InputTextModule, InputTextareaModule, ReactiveFormsModule,
    // Components
    Button, NgIf, Ripple, CalendarModule
  ],
  templateUrl: './flow-law-category-details.component.html',
  styleUrl: './flow-law-category-details.component.scss'
})
export class FlowLawCategoryDetailsComponent implements OnInit {

  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  data: FlowLawCategoryDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  formGroup: FormGroup = new FormGroup({});
  isLoading: boolean = false;
  categories: IBalanceSheetCategory[] = [];
  flowLaws: IFlowLaw[] = [];
  today: Date | undefined = new Date();

  constructor(
    private readonly flowLawService: FlowLawService,
    private readonly categoryService: BalanceSheetCategoryService,
    private readonly flowLawCategoryService: FlowLawCategoryService,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef,
    private readonly fb: FormBuilder
  )
  {
    this.instance = this.dialogService.getInstance(this.ref);
    this.data = this.instance.data;
    this.initForm();
  }

  private initForm() {
    const categoryId = this.data?.data.category ? this.data.data.category.id : undefined;
    const flowLawId = this.data?.data.flowLaw ? this.data.data.flowLaw.id : undefined;
    this.formGroup = this.fb.group({
      category: new FormControl(categoryId, [Validators.required]),
      flowLaw: new FormControl(flowLawId, [Validators.required]),
      startDate: new FormControl<Date | null>(null, [Validators.required]),
      expiryDate: new FormControl<Date | null>(null)
    });
    // Disable provided properties
    //TODO: add "- Acif or Passif" type to the name to differentiate items with the same name but different type (asset or liability)
    if (categoryId) this.formGroup.get('category')?.disable();
    if (flowLawId) this.formGroup.get('flowLaw')?.disable();
  }

  ngOnInit() {
    this.loadFlowLaws();
    this.loadBalanceSheetCategories();
  }

  loadBalanceSheetCategories() {
    this.categoryService.getLeafCategoriesByItemType(getUnpagedPageable(), ItemType.CONVENTIONAL).subscribe({
      next: (page) => this.categories = page.content,
      error: (err) => this.toastService.showToast('Chargement des catégories', err.error, 'error')
    });
  }

  loadFlowLaws() {
    this.flowLawService.getCurrentUserFlowLaws(getUnpagedPageable()).subscribe({
      next: (page) => this.flowLaws = page.content,
      error: (err) => this.toastService.showToast('Chargement des lois', err.error, 'error')
    });
  }


  save() {
    this.flowLawCategoryService.createFlowLawCategory(this.getFormValue()).subscribe({
      next: (value) => {
        this.toastService.showToast('Loi / Catégorie', `La loi ${value.flowLaw.name} est maintenant appliqué à la catégorie ${value.category.name} avec succès.`);
        this.ref.close();
      },
      error: (err) => this.toastService.showToast('Loi / Catégorie', err.error, 'error')
    });
  }

  getFormValue(): IFlowLawCategory {
    const formValue = this.formGroup.getRawValue();
    const category = this.categories.find(c => c.id === formValue.category);
    const law = this.flowLaws.find(c => c.id === formValue.flowLaw);

    if (!category || !law) {
      this.toastService.showToast('Formulaire invalide', 'Le formulaire est invalide.', 'error');
      throw new Error('Invalid form values');
    }

    return {
      ...formValue,
      id: '',
      flowLaw: law,
      category: category,
    };
  }
}
