<div class="flex flex-column gap-2 w-8">
  <div class="flex justify-content-start mt-8 mb-4">
    <div class="w-full">
      <form [formGroup]="twoFactorGroup">
        <div class="flex align-items-center gap-4 w-full">
          <p-checkbox (onChange)="toggleTwoFactor($event)" [binary]="true" class="w-fit" id="twoFactor" formControlName="twoFactor" />
          <label for="twoFactor">Authentication à double facteurs</label>
        </div>
      </form>
    </div>
  </div>
  <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label for="lastPassword">Ancien mot de passe</label>
      <p-password class="w-full" [feedback]="false"  [toggleMask]="true" formControlName="oldPassword" id="lastPassword" required></p-password>
    </div>
    <div class="flex flex-column gap-2">
      <label for="newPassword">Nouveau mot de passe</label>
      <p-password class="w-full" [feedback]="false"  [toggleMask]="true" formControlName="newPassword" id="newPassword" required></p-password>
    </div>
    <div class="flex flex-column gap-2">
      <label for="confirmPassword">Confirmer le nouveau mot de passe</label>
      <p-password class="w-full" [feedback]="false"  [toggleMask]="true" formControlName="confirmPassword" id="confirmPassword" required></p-password>
    </div>
    <div class="w-full flex justify-content-end">
      <p-button pRipple label="Enregistrer" [disabled]="!formGroup.valid" [loading]="isLoading" (onClick)="onSubmit()" />
    </div>
  </form>
</div>
