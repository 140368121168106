import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";
import {LimitsModel} from "../models/limits.model";
import {Page} from "../models/page.model";
import {TitresWithOperationsModel} from "../models/titres-with-operations.model";
import {IUser} from "../models/user.model";


@Injectable({
  providedIn: 'root',
})

export class LimitsService {
  private baseUrl = 'income/v1/limits';

  constructor(private http: HttpClient) {
  }

  createLimit(limitDto: LimitsModel): Observable<any> {
    return this.http.post<any>(this.baseUrl, limitDto).pipe(take(1));
  }
  getAllLimits(page: number, size: number, isPaged: boolean = true): Observable<Page<LimitsModel>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<LimitsModel>>(this.baseUrl, {params});
  }
  deleteLimit(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }
  updateLimit(id: string, limit: LimitsModel): Observable<LimitsModel> {
    return this.http.put<LimitsModel>(`${this.baseUrl}/${id}`, limit).pipe(take(1));
  }

}
