import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {PaginatorState} from "primeng/paginator";
import {CustomTableHeader} from "../../../../core/models/custom-table.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {ChartModule} from "primeng/chart";
import {DatePipe, NgIf} from "@angular/common";
import {DialogService} from "primeng/dynamicdialog";
import {ToastService} from "../../../../core/services/toast.service";
import {FormBuilder, FormsModule} from "@angular/forms";
import {OperationsTitresService} from "../../../../core/services/operationsTitres.service";
import {countriesUemoa} from "../../../../../assets/countries.uemoa";
import {VolumeWalletDTO} from "../../../../core/models/volumeWalletDTO";
import {CalendarModule} from "primeng/calendar";

@Component({
  selector: 'app-portefeuille',
  standalone: true,
  imports: [
    ButtonDirective,
    DynamicTableComponent,
    FileUploadModule,
    ChartModule,
    NgIf,
    CalendarModule,
    FormsModule
  ],
  templateUrl: './portefeuilleTab.component.html',
  styleUrl: './portefeuilleTab.component.css'
})
export class PortefeuilleTabComponent implements OnInit {

  data: any;
  dateRange: Date[] | undefined;
  startDate!: Date;
  endDate!: Date;
  countries = countriesUemoa;
  options: any;
  operationsTitres: any;
  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  columns: CustomTableHeader[] = [
    { key: 'registerOperationsTitresDTO.titresWithEmissionDTO.emission.region', column: 'Emetteur', type: 'custom' },
    { key: 'isin', column: 'ISIN', type: 'text' },
    { key: 'registerOperationsTitresDTO.account', column: 'Compte', type: 'text' },
    { key: 'typeInstrument', column: 'Instrument', type: 'text' },
    { key: 'registerOperationsTitresDTO.titresWithEmissionDTO.emission.valueDate', column: 'Date valeur', type: 'text' },
    { key: 'dueDate', column: 'Date échéance', type: 'text' },
    { key: 'registerOperationsTitresDTO.amount', column: 'Montant', type: 'text' },
    { key: 'registerOperationsTitresDTO.rate', column: 'Taux facial(%)', type: 'text' },
  ];

  constructor(public dialogService: DialogService,
              private toastService :ToastService,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private operationsTitresService: OperationsTitresService,
              private datePipe: DatePipe) {}


  onPageChange = (event: PaginatorState) => {
    this.loadWallet(getDefaultPageable(event.page, event.rows));
  }

  volume: any;
  currentYield: any;
  mediumMaturity: any;
  interest: any;

  private loadWallet(pageable: Pageable) {
    this.operationsTitresService.getAllOperationsWihtTitres(pageable.page, pageable.limit, true, undefined, 'PROPRE')
      .subscribe({
        next: (response) => {
          this.operationsTitres = response.content.map(item => ({
            ...item,
            registerOperationsTitresDTO: {
              ...item.registerOperationsTitresDTO,
              titresWithEmissionDTO: {
                ...item.registerOperationsTitresDTO.emissionRequestDTO,
                emission: {
                  ...item.registerOperationsTitresDTO.emissionRequestDTO,
                  valueDate: this.formatDate(item.registerOperationsTitresDTO.emissionRequestDTO.valueDate),
                  region: this.getCountryName(item.registerOperationsTitresDTO.emissionRequestDTO.region),

                }
              }
            },
            dueDate: this.formatDate(item.dueDate)
          }));
          this.pageable.totalRecords = response.totalElements;
        },
        error: () => {
        }
      });
  }
  private formatDate(date: Date | string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }
  private getCountryName(region: string): string {
    const country = this.countries.find(c => c.code === region);
    return country ? country.country : region;
  }

  ngOnInit(): void {
    this.dateRange = this.getYearDateRange();

    /*
        this.applyDateFilter();
    */
    const [startDate, endDate] = this.dateRange;

    const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-ddTHH:mm:ss')!;
    const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-ddTHH:mm:ss')!;

    /*
        this.loadChartWalletData(formattedStartDate, formattedEndDate);
    */

    this.loadWallet(getDefaultPageable(0, 10));
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          type: 'bar',
          label: 'Volume de titres',
          backgroundColor: documentStyle.getPropertyValue('--green-500'),
          data: [21, 84, 24, 75, 37, 65, 34],
          yAxisID: 'left-y-axis',
          borderRadius: 10,
          barThickness: 50,
          maxBarThickness: 50
        },
        {
          type: 'line',
          label: 'Taux moyens',
          backgroundColor: documentStyle.getPropertyValue('--purple-500'),
          borderColor: documentStyle.getPropertyValue('--purple-500'),
          fill: false,
          showLine: false,
          pointRadius: 0,
          data: [2.3, 2.7, 3.1, 2.9, 3.5, 2.8, 3.2],
          yAxisID: 'right-y-axis'
        }
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false
        },
        legend: {
          position: 'bottom',
          labels: {
            color: textColor,
          }
        },
        title: {
          display: true,
          text: 'Tombées sur portefeuille',
          position: 'bottom',
          font: {
            size: 20,
            weight: 'bold',
            family: 'Spline sans'
          },
          color: textColor // Couleur du titre
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        'left-y-axis': {
          type: 'linear',
          position: 'left',
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          },
          title: {
            display: true,
            text: 'Milliards - XOF',
            color: textColor,
            font: {
              size: 15,
              weight: 'bold',
              family: 'Spline sans'
            },
            position: 'top'
          }
        },
        'right-y-axis': {
          type: 'linear',
          position: 'right',
          stacked: false,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            drawOnChartArea: false
          },
          title: {
            display: true,
            text: 'Taux %',
            color: textColor,
            font: {
              size: 15,
              weight: 'bold',
              family: 'Spline sans'
            },
            position: 'top'
          }
        }
      }
    };
  }

    /*
      loadChartWalletData(startDate: string, endDate: string) {
        this.operationsTitresService.getVolumesWalletByPeriod(startDate, endDate).subscribe({
          next: (response: VolumeWalletDTO[]) => {
            const oat = response.filter(vol => vol.typeInstrument === 'OAT' && vol.account === 'PROPRE').map(vol => vol.totalAmount);
            const bat = response.filter(vol => vol.typeInstrument === 'BAT' && vol.account === 'PROPRE').map(vol => vol.totalAmount);

            const uniqueMonths = new Set(response.map(vol => {
              return `${this.getMonthName(vol.month)} ${vol.year}`;
            }));
            const labels = Array.from(uniqueMonths);

            this.data = {
              labels: labels,
              datasets: [
                {
                  label: 'OAT',
                  backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                  borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                  data: oat
                },
                {
                  label: 'BAT',
                  backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
                  borderColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
                  data: bat
                }
              ]
            };
          },
          error: (err) => {
            this.toastService.showToast('Erreur', 'Erreur chargement des données', 'error', err);
          }
        });
      }
    */
  private getMonthName(monthNumber: number): string {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('default', { month: 'long' });
  }
/*
  applyDateFilter() {
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      const startDate = this.datePipe.transform(this.dateRange[0], 'yyyy-MM-ddTHH:mm:ss');
      const endDate = this.datePipe.transform(this.dateRange[1], 'yyyy-MM-ddTHH:mm:ss');

      if (startDate && endDate) {
        this.loadChartWalletData(startDate, endDate);
      } else {
        this.toastService.showToast('Erreur', 'Erreur de formatage des dates.', 'error');
      }
    } else {
      this.toastService.showToast('Erreur', 'Veuillez sélectionner une plage de dates valide.', 'error');
    }
  }
*/
  private getYearDateRange(): Date[] {
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const endDate = new Date(currentYear, 11, 31);
    return [startDate, endDate];
  }
}
