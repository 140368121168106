<div class="flex flex-column w-full h-full align-content-between">

  <div class="flex justify-content-center mb-4">
    <div class="flex justify-content-start mt-4 pl-4 gap-2">
      <p-calendar
        [(ngModel)]="dateRange"
        selectionMode="range"
        [readonlyInput]="true"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        placeholder="Sélectionnez une plage de dates">
      </p-calendar>
<!--      <button (click)="applyDateFilter()" pButton label="Filtrer"></button>-->
    </div>
  </div>

  <div class="chart-container mt-2">
    <!--<div class="card flex flex-column">
      <h2 class="text-center mb-0">Résumé</h2>
      <hr class="w-full mb-4">
      <div class="flex flex-column flex-grow-1 justify-content-between">
        <div class="flex justify-content-between">
          <span>Volume</span>
          <span class="font-semibold text-xl">{{ volume }}XOF</span>
        </div>
        <div class="flex justify-content-between">
          <span>Rendement actuel</span>
          <span class="font-semibold text-xl">{{ currentYield }}%</span>
        </div>
        <div class="flex justify-content-between">
          <span>Maturité moyenne</span>
          <span class="font-semibold text-xl">{{ mediumMaturity }}mois</span>
        </div>
        <div class="flex justify-content-between">
          <span>Intérêts</span>
          <span class="font-semibold text-xl">{{ interest }}XOF</span>
        </div>
      </div>
    </div>-->
    <div *ngIf="data" class="card">
      <p-chart [data]="data" [options]="options" type="bar"></p-chart>
    </div>
  </div>

  <div class="flex align-items-start justify-content-between mb-2 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">Portefeuille de titres</h3>
    </div><!--
    <div class="flex-grow-1 flex gap-2 justify-content-end">

      <p-fileUpload
        chooseIcon="pi pi-download"
        mode="basic"
        (onSelect)="onUpload($event)"
        [multiple]="false"
        class="custom-file-upload"
        accept=".xlsx,.xls"
        maxFileSize="5000000"
        chooseLabel="Charger">
      </p-fileUpload>
      <button (click)="createTitleSecondary()" icon="pi pi-plus" label="Nouveau" pButton type="button"></button>
    </div>-->
  </div>
  <dynamic-table (paginate)="onPageChange($event)" [data]="operationsTitres" [headers]="columns"
                 [pageParams]="pageable"></dynamic-table>

</div>
