export const countriesUemoa = [
  {
    "country": "Bénin",
    "code": "BJ"
  },
  {
    "country": "Côte d'Ivoire",
    "code": "CI"
  },
  {
    "country": "Burkina Faso",
    "code": "BF"
  },
  {
    "country": "Guinée-Bissau",
    "code": "GW"
  },
  {
    "country": "Mali",
    "code": "ML"
  },
  {
    "country": "Niger",
    "code": "NE"
  },
  {
    "country": "Sénégal",
    "code": "SN"
  },
  {
    "country": "Togo",
    "code": "TG"
  },
]
