import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PaginatorState} from 'primeng/paginator';
import {OperationListComponent} from './operation-list.component';
import {getDefaultPageable, Pageable, PageParams, VALUE_DATE_SORT_DESC} from "../../../core/models/page.model";
import {IOperation} from "../../../core/models/operation.model";
import {OperationService} from "../../../core/services/operation.service";
import {ToastService} from "../../../core/services/toast.service";
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {SearchComponent} from "../search.component";
import {SplitButtonModule} from "primeng/splitbutton";
import {ExportComponent, ExportData} from "../export.component";

@Component({
  selector: 'app-account-operation-list',
  standalone: true,
  imports: [
    // Modules
    IconFieldModule, InputIconModule, InputTextModule, FormsModule, SplitButtonModule,
    // Components
    OperationListComponent, SearchComponent, ExportComponent
  ],
  template: `
    <div class="flex flex-column gap-4 w-full">
      <div class="flex w-full justify-content-between mt-2">
        <search (search)="onSearch($event)" [placeholder]="'Recherche'" [debounceTime]="500"></search>
        <export (handler)="exportOperation($event)" label="opérations"/>
      </div>
      <app-operation-list class="w-full" [data]="operations" [pageParams]="pageParams" [loading]="loading"
                          (paginate)="onPageChange($event)"/>
    </div>
  `
})
export class AccountOperationListComponent implements OnInit {

  accountId: string;
  operations: IOperation[] = [];
  pageParams: PageParams = {
    page: 0,
    first: 0,
    rows: 10,
    totalRecords: 0,
    rowPerPageOptions: [5, 10, 25]
  };
  query: string = '';
  loading: boolean = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private readonly operationService: OperationService,
    private readonly toastService: ToastService
  ) {
    this.accountId = this.config.data.accountId;
  }

  ngOnInit() {
    this.searchOperationsForAccount(this.query, getDefaultPageable());
  }

  onPageChange(event: PaginatorState) {
    this.searchOperationsForAccount(this.query, getDefaultPageable(event.page, event.rows, VALUE_DATE_SORT_DESC));
  }

  onSearch($event: string) {
    this.query = $event;
    this.searchOperationsForAccount(this.query);
  }

  private searchOperationsForAccount(search: string, pageable: Pageable = getDefaultPageable(0, 10, VALUE_DATE_SORT_DESC)) {
    this.operationService.searchOperationsForAccount(search, this.accountId, pageable).subscribe({
      next: (page) => {
        this.operations = page.content;
        this.pageParams = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: this.pageParams.rowPerPageOptions
        };
        this.loading = false;
      },
      error: (err) => {
        this.toastService.showToast('Chargement des opérations', err.error, 'error');
        this.loading = false;
      }
    });
  }

  exportOperation(event: ExportData) {
    console.log('Exporting data: ', event);
  }
}
