/**
 * Node.js module for Forge mask generation functions.
 *
 * @author Stefan Siegl
 *
 * Copyright 2012 Stefan Siegl <stesie@brokenpipe.de>
 */
var forge = require('./forge');
require('./mgf1');
module.exports = forge.mgf = forge.mgf || {};
forge.mgf.mgf1 = forge.mgf1;