import {Component, Input, OnInit} from '@angular/core';
import {MultiSelectModule} from "primeng/multiselect";
import {LoanBorrowingType} from "../../../../core/models/loan-borrowing.model";
import {NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

export interface LoanBorrowingFilterData {
  dates?: Date[] | null;
  types: string[];
  states: string[];
  query: string;
  isLocal: boolean;
}

@Component({
  selector: 'app-loan-borrowing-filter',
  standalone: true,
  imports: [
    // Modules
    MultiSelectModule, FormsModule, CalendarModule, ReactiveFormsModule,
    // Pipes & Directives
    NgIf
  ],
  template: `
    <div class="flex flex-column gap-4 h-full">
      <div class="w-full flex flex-column gap-4">
        <div *ngIf="showTypeFilter" class="flex flex-column gap-2">
          <label for="types" class="font-medium">Type</label>
          <p-multiSelect id="types" class="w-full" [options]="typeOptions" [(ngModel)]="selectedTypes" display="chip" [showClear]="true" />
        </div>
        <div *ngIf="showStateFilter" class="flex flex-column gap-2">
          <label for="states" class="font-medium">État</label>
          <p-multiSelect id="states" class="w-full" [options]="stateOptions" [(ngModel)]="selectedStates" display="chip" [showClear]="true" />
        </div>
        <div *ngIf="showDateFilter" class="flex flex-column gap-2">
          <label for="dates" class="font-medium">Date de déclaration</label>
          <p-calendar [touchUI]="true" class="w-full" id="dates" selectionMode="range" [autofocus]="false" [readonlyInput]="true" [(ngModel)]="dates" />
        </div>
      </div>
      <div class="flex justify-content-end flex-grow-1 align-items-end">
        <p-button label="Filtrer" icon="pi pi-filter" severity="contrast" (onClick)="onFilter()" />
      </div>
    </div>
  `
})
export class LoanBorrowingFilterComponent implements OnInit {

  @Input() showTypeFilter: boolean = true;
  @Input() showStateFilter: boolean = true;
  @Input() showDateFilter: boolean = true;
  typeOptions: string[] = [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING];
  stateOptions: string[] = ["En attente", "Validé", "Exécuté","Annulé"];
  selectedStates: string[] = [];
  selectedTypes: string[] = [];
  dates: Date[] | null = [];
  instance: DynamicDialogComponent | undefined;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.config.data?.initialData) {
      this.showDateFilter = this.config.data.showDateFilter;
      this.showStateFilter = this.config.data.showStateFilter;
      this.showTypeFilter = this.config.data.showTypeFilter
      const initialData: LoanBorrowingFilterData = this.config.data.initialData;
      this.selectedTypes = initialData.types || [];
      this.selectedStates = initialData.states || [];
      this.dates = initialData.dates || null;
    }
  }

  onFilter() {
    this.ref.close({
      dates: this.dates,
      types: this.selectedTypes,
      states: this.selectedStates
    } as LoanBorrowingFilterData);
  }
}
