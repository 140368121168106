import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IReserve, IReserveDeclaration} from "../models/reserve.model";
import {format} from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class ReserveService {

  private apiUrl = `operation/v1/mandatory-reserve`;

  constructor(private http: HttpClient) { }

  findAll(pageable: Pageable): Observable<Page<IReserve>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IReserve>>(this.apiUrl, { params });
  }

  findAllForInstitution(institutionId: string, pageable: Pageable): Observable<Page<IReserve>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IReserve>>(`${this.apiUrl}/institution/${institutionId}`, { params });
  }

  findForCurrentUser(pageable: Pageable, startDate: Date | undefined = undefined, endDate: Date | undefined = undefined): Observable<Page<IReserve>> {
    let params = getPageableAsParams(pageable);
    if (startDate && endDate){
      params = params.set('startDate', format(startDate, 'yyyy-MM-dd')).set('endDate', format(endDate, 'yyyy-MM-dd'));
    }
    return this.http.get<Page<IReserve>>(`${this.apiUrl}/institution`, { params });
  }

  findLatestForCurrentUserInstitution(pageable: Pageable): Observable<IReserve> {
    let params = getPageableAsParams(pageable);
    return this.http.get<IReserve>(`${this.apiUrl}/institution/latest`, { params });
  }

  saveNewMandatoryReserve(mandatoryReserve: IReserveDeclaration): Observable<void> {
    return this.http.post<void>(this.apiUrl, mandatoryReserve);
  }

  updateMandatoryReserve(reserveId: string, mandatoryReserve: IReserve): Observable<IReserve> {
    return this.http.put<IReserve>(`${this.apiUrl}/${reserveId}`, mandatoryReserve);
  }

  deleteMandatoryReserve(reserveId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${reserveId}`);
  }

  findLatestForInstitution(institutionId: string): Observable<IReserve> {
    return this.http.get<IReserve>(`${this.apiUrl}/institution/${institutionId}/latest`);
  }

  exportReserve(startDate: Date, endDate: Date, isExcel: boolean = true): Observable<HttpResponse<Blob>> {
    let params = new HttpParams()
      .set('isExcel', isExcel.toString())
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<Blob>(`${this.apiUrl}/export`, {
      params,
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }
}
