<div class="flex flex-column w-full h-full align-content-between">

  <div class="flex justify-content-center mb-4">
    <div class="flex justify-content-start mt-4 pl-4 gap-2">
      <p-calendar
        [(ngModel)]="dateRange"
        selectionMode="range"
        [readonlyInput]="true"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        placeholder="Sélectionnez une plage de dates">
      </p-calendar>
      <button (click)="applyDateFilter()" pButton label="Filtrer"></button>
    </div>
  </div>

  <div class="card mt-2">
    <p-chart type="bar" [data]="data" [options]="options" />
  </div>

  <div class="card">
    <p-chart type="line" [data]="data2" [options]="options2" />
  </div>

  <div class="card">
    <p-dropdown
      [options]="countriesOptions" (onChange)="filterData()" [(ngModel)]="selectedCountry" optionLabel="country"
      editable="true" id="region" optionValue="country" [showClear]="true" placeholder="Selectionner un pays">
      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img *ngIf="country.code !== 'ALL'" alt="{{ country.country }}"
               src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg"
               style="width: 18px" />
          <div>{{ country.country }}</div>
        </div>
      </ng-template>
    </p-dropdown>
    <p-chart type="line" [data]="filteredData3" [options]="options3"></p-chart>

  </div>

</div>
