import {Component, OnInit} from '@angular/core';
import {FlowLawCategoryService} from "../../../../core/services/flow-law-category.service";
import {IFlowLaw, IFlowLawCategory} from "../../../../core/models/flow-law.model";
import {DEFAULT_PAGE_PARAMS, getDefaultPageable, PageParams} from "../../../../core/models/page.model";
import {ToastService} from "../../../../core/services/toast.service";
import {AccordionModule} from "primeng/accordion";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {ViewPermissionsComponent} from "../../../group/view-permissions/view-permissions.component";
import {CheckboxModule} from "primeng/checkbox";
import {TableModule} from "primeng/table";
import {ToolbarComponent} from "../../../../shared/components/toolbar/toolbar.component";
import {PaginatorModule, PaginatorState} from "primeng/paginator";

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    // Modules
    AccordionModule, DynamicTableComponent, CheckboxModule,
    // Components
    ViewPermissionsComponent,
    // Pipes & Directives
    NgIf, NgForOf, TableModule, DatePipe, ToolbarComponent, PaginatorModule
  ],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent implements OnInit {

  flowLawCategories: IFlowLawCategory[] = [];
  flowLaws: IFlowLaw[] = [];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  private query: string = '';

  constructor(
    private flowLawCategoryService: FlowLawCategoryService,
    private toastService: ToastService
  )
  {

  }

  //TODO: add pagination

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.flowLawCategoryService.searchFlowLawCategories(this.query, getDefaultPageable(this.pageParams.page, this.pageParams.rows))
      .subscribe({
        next: (page) => {
          this.flowLawCategories = page.content;
          this.flowLaws = page.content
            .map(x => x.flowLaw)
            .filter((flowLaw, index, self) =>
            index === self.findIndex(f => f.id === flowLaw.id)
          );
          this.pageParams = {
            ...this.pageParams,
            page: page.number,
            rows: page.size,
            first: page.number * page.size,
            totalRecords: page.totalElements,
          };
        },
        error: (err) => this.toastService.showToast('Loi / Catégorie', err.error, 'error')
      });
  }

  search($event: string) {
    this.query = $event;
    this.loadData();
  }

  getDataForLaw(law: IFlowLaw) {
    return this.flowLawCategories.filter(fl => fl.flowLaw.id == law.id);
  }

  onPageChange($event: PaginatorState) {
    this.pageParams = {
      ...this.pageParams,
      page: $event.page!,
      rows: $event.rows!,
    }
    this.loadData();
  }
}
