<div class="p-4" *ngIf="operations.length <= 0">
  <div class="card flex justify-content-center w-full mb-4">
    <div class="py-3">Ajouter le fichier Excel contenant les opérations</div>
  </div>

  <div class="flex my-4 gap-2 justify-content-end">
    <p-fileUpload mode="basic" chooseLabel="Choisir"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  [showUploadButton]="false" (onSelect)="onUpload($event)"
                  [showCancelButton]="false" [multiple]="false" [maxFileSize]="1000000"  />
    <button pButton pRipple label="Charger" icon="pi pi-upload" (click)="upload()"></button>
  </div>
</div>

<app-upload-table *ngIf="operations.length > 0" [data]="operations"
                  [headers]="columns" (saveData)="saveValidOperations($event)" />
