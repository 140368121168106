import {Component, OnInit} from '@angular/core';
import {TabViewModule} from "primeng/tabview";
import {CommonModule} from "@angular/common";
import {ResumeComponent} from "./resume/resume.component";
import {AchatsComponent} from "./achats/achats.component";
import {VentesComponent} from "./ventes/ventes.component";
import {TabMenuModule} from "primeng/tabmenu";

@Component({
  selector: 'app-secondaire',
  standalone: true,
  imports: [
    TabViewModule, CommonModule, TabMenuModule
  ],
  templateUrl: './secondaire.component.html',
  styleUrl: './secondaire.component.scss'
})
export class SecondaireComponent implements OnInit{
  items: { label: string, icon?: string, command?: () => void }[] = [];
  selectedTab: { title: string, component: any } | null = null;
  activeItem: any = null;

  ngOnInit() {
    this.items = [
      { label: 'Résumé', command: () => this.selectTab('Résumé') },
      { label: 'Achats',command: () => this.selectTab('Achats') },
      { label: 'Ventes',command: () => this.selectTab('Ventes') }
    ];
    this.selectTab('Résumé');
  }

  selectTab(tabTitle: string) {
    this.activeItem = this.items.find(item => item.label === tabTitle);
    if (tabTitle === 'Résumé') {
      this.selectedTab = { title: 'Résumé', component: ResumeComponent };
    } else if (tabTitle === 'Achats') {
      this.selectedTab = { title: 'Achats', component: AchatsComponent };
    } else if (tabTitle === 'Ventes') {
      this.selectedTab = { title: 'Ventes', component: VentesComponent };
    }
  }
}
