import {Routes} from '@angular/router';
import {MainComponent} from "./pages/main/main.component";
import {LoginComponent} from "./pages/login/login.component";

import {InstitutionComponent} from "./components/institution/institution.component";
import {UserComponent} from "./components/user/user.component";
import {GroupComponent} from "./components/group/group.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {UserInfoComponent} from "./components/profile/user-info/user-info.component";
import {UpdatePasswordComponent} from "./components/profile/update-password/update-password.component";
import {ValidationComponent} from "./components/validation/validation.component";
import {EditValidationComponent} from "./components/validation/edit-validation/edit-validation.component";
import {VirementComponent} from "./components/tresorerie/correspondant/virement/virement.component";
import {SuiviComponent} from "./components/tresorerie/correspondant/suivi/suivi.component";
import {SimulationsComponent} from './components/risques/simulations/simulations.component';
import {SuiviRisqueComponent} from './components/risques/suivi/suivi-risque.component';
import {PricerOatComponent} from './components/risques/simulations/pricer-oat/pricer-oat.component';
import {PricerBatComponent} from './components/risques/simulations/pricer-bat/pricer-bat.component';
import {AdministrationRisqueComponent} from "./components/risques/administration/administration-risque.component";
import {FlowLawComponent} from "./components/risques/administration/flow-law/flow-law.component";
import {OperationsRisqueComponent} from "./components/risques/operations-risque/operations-risque.component";
import {BeneficiaryComponent} from "./components/tresorerie/correspondant/beneficiary/beneficiary.component";
import {ReleveComponent} from "./components/tresorerie/locale/releve/releve.component";
import {
  PrevisionnelOverviewComponent
} from "./components/tresorerie/locale/previsionnel/overview/previsionnel-overview.component";
import {PrevisionnelComponent} from "./components/tresorerie/locale/previsionnel/previsionnel.component";
import {
  PrevisionnelOperationsComponent
} from "./components/tresorerie/locale/previsionnel/operations/previsionnel-operations.component";
import {
  ReserveObligatoireComponent
} from "./components/tresorerie/locale/previsionnel/reserve-obligatoire/reserve-obligatoire.component";
import {LoanBorrowingComponent} from "./components/tresorerie/locale/loan-borrowing/loan-borrowing.component";
import {
  LoanBorrowingDetailsComponent
} from "./components/tresorerie/locale/loan-borrowing/loan-borrowing-details/loan-borrowing-details.component";
import {LoanBorrowingType} from "./core/models/loan-borrowing.model";
import {SharedLoanBorrowingComponent} from "./shared/components/loan-borrowing/shared-loan-borrowing.component";
import {ForeignSummaryComponent} from "./components/tresorerie/locale/releve/foreign-summary/foreign-summary.component";
import {PrimaireComponent} from "./components/fixedIncome/primaire/primaire.component";
import {SecondaireComponent} from "./components/fixedIncome/secondaire/secondaire.component";
import {PortefeuilleComponent} from "./components/fixedIncome/portefeuille/portefeuille.component";
import {OperationComponent} from "./components/fixedIncome/operation/operation.component";
import {
  BalanceSheetCategoryComponent
} from "./components/risques/administration/balance-sheet-categories/balance-sheet-category.component";
import {SummaryComponent} from "./components/risques/administration/summary/summary.component";

export const loanBorrowingRoutes: Routes = [
  {
    path: 'summary',
    component: SharedLoanBorrowingComponent,
    data: {
      page: 'Résumé',
      types: [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING]
    }
  },
  {
    path: 'loans',
    component: SharedLoanBorrowingComponent,
    data: {
      page: 'Prêts',
      types: [LoanBorrowingType.LOAN]
    }
  },
  {
    path: 'borrowings',
    component: SharedLoanBorrowingComponent,
    data: {
      page: 'Emprunts',
      types: [LoanBorrowingType.BORROWING]
    }
  },
  {
    path: 'new',
    component: LoanBorrowingDetailsComponent,
    data: { types: [] }  // This will be populated dynamically
  },
  {
    path: 'details/:id',
    component: LoanBorrowingDetailsComponent,
    data: { types: [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING] }
  },
  {
    path: '',
    redirectTo: 'summary',
    pathMatch: 'full'
  }
];

export const profileRoutes: Routes = [
  { path: 'info', component: UserInfoComponent },
  { path: 'security', component: UpdatePasswordComponent },
  { path: '', redirectTo: 'info', pathMatch: 'full' }
]

export const validationRoutes: Routes = [
  { path: '', component: ValidationComponent },
  { path: 'edit', component: EditValidationComponent }
];

export const previsionnelRoutes: Routes = [
  { path: 'overview', component: PrevisionnelOverviewComponent },
  { path: 'operations', component: PrevisionnelOperationsComponent },
  { path: 'reserve', component: ReserveObligatoireComponent },
  { path: '', redirectTo: 'overview', pathMatch: 'full' }
]

export const riskSettingsRoutes: Routes = [
  { path: 'resume', component: SummaryComponent },
  { path: 'categories', component: BalanceSheetCategoryComponent },
  { path: 'lois', component: FlowLawComponent },
  { path: '', redirectTo: 'resume', pathMatch: 'full' }
];

export const tresorerieRoutes: Routes = [
  {
    path: 'locale',
    children: [
      {
        path: 'releve',
        children: [
          { path: ':currency', component: ReleveComponent },
          { path: '', redirectTo: 'XOF', pathMatch: 'full' } // Default to local currency
        ]
      },
      { path: 'previsionnel', component: PrevisionnelComponent, children: previsionnelRoutes },
      { path: 'loan', component: LoanBorrowingComponent, children: loanBorrowingRoutes }
    ]
  },
  {
    path: 'devise',
    children: [
      {
        path: 'releve',
        children: [
          { path: 'summary', component: ForeignSummaryComponent },
          { path: ':currency', component: ReleveComponent },
          { path: '', redirectTo: 'summary', pathMatch: 'full' } // Default to EUR for foreign currency
        ]
      },
      { path: 'previsionnel', component: PrevisionnelComponent, children: previsionnelRoutes },
      { path: 'loan', component: LoanBorrowingComponent, children: loanBorrowingRoutes }
    ]
  },
  {
    path: 'correspondant',
    children: [
      { path: 'suivi', component: SuiviComponent },
      { path: 'virement', component: VirementComponent },
      { path: 'beneficiary', component: BeneficiaryComponent }
    ]
  }
];

export const riskRoutes: Routes = [
  {
    path: 'parametrage',
    component: AdministrationRisqueComponent,
    children: riskSettingsRoutes
  },
  {
    path: 'operations', component: OperationsRisqueComponent,
  },
  {
    path: 'suivi-risque', component: SuiviRisqueComponent,
  },
  {
    path: 'simulations', component: SimulationsComponent,
    children: [
      {
        path: 'pricer-bat', component: PricerBatComponent
      },
      {
        path: 'pricer-oat', component: PricerOatComponent
      },
      { path: '', redirectTo: 'pricer-bat', pathMatch: 'full' }
    ]
  },
  { path: '', redirectTo: 'parametrage', pathMatch: 'full' }
];

export const incomeRoutes: Routes = [
  { path: 'primaire', component: PrimaireComponent},
  { path: 'secondaire', component: SecondaireComponent},
  { path: 'portefeuille', component: PortefeuilleComponent},
  { path: 'operation', component: OperationComponent},
  { path: '', redirectTo: 'primaire', pathMatch: 'full' }
];
export const mainRoutes: Routes = [
  { path: 'validation', component: ValidationComponent, children: validationRoutes },
  { path: 'institution', component: InstitutionComponent },
  { path: 'user', component: UserComponent },
  { path: 'group', component: GroupComponent },
  { path: 'profile', component: ProfileComponent, children: profileRoutes },
  { path: 'tresorerie', children: tresorerieRoutes },
  {path: 'risque-alm', children: riskRoutes},
  { path: 'fixedincome', children: incomeRoutes },
  { path: '', redirectTo: 'user', pathMatch: 'full' }
];

export const adminRoutes: Routes = [
  { path: 'profile', component: ProfileComponent, children: profileRoutes },
  { path: 'institution', component: InstitutionComponent },
  { path: 'user', component: UserComponent },
  { path: 'group', component: GroupComponent },
  { path: '', redirectTo: 'user', pathMatch: 'full' }
]

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'main', component: MainComponent, children: mainRoutes },
  { path: 'admin', component: MainComponent, children: adminRoutes },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];
