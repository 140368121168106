import {Component, OnInit} from '@angular/core';
import {countriesUemoa} from "../../../../../../assets/countries.uemoa";
import {DropdownModule} from "primeng/dropdown";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf, UpperCasePipe} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {CalendarModule} from "primeng/calendar";
import {ChipsModule} from "primeng/chips";
import {Ripple} from "primeng/ripple";
import {finalize} from "rxjs";
import {DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../../../core/services/toast.service";
import {LimitsModel} from "../../../../../core/models/limits.model";
import {LimitsService} from "../../../../../core/services/limits.service";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";

@Component({
  selector: 'app-add-limit',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    NgIf,
    PrimeTemplate,
    ReactiveFormsModule,
    UpperCasePipe,
    CalendarModule,
    ChipsModule,
    Ripple
  ],
  templateUrl: './add-limit.component.html',
  styleUrl: './add-limit.component.scss'
})
export class AddLimitComponent implements OnInit {

  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;
  isLoading: boolean = false;
  countries = countriesUemoa;
  formGroup = new FormGroup({
    region: new FormControl('',  {nonNullable: true ,validators: [Validators.required] }),
    startDate: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    endDate: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    maxAmount: new FormControl(),
  });

  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private limitsService: LimitsService,
    private toastService :ToastService
  ){}
  ngOnInit(): void {
    this.boundData = this.config.data;
    if (this.boundData?.isCreate) {
      this.isCreate = true;
    } else {
      this.formGroup.patchValue(this.boundData?.data);
      if (this.boundData?.isView) {
        this.formGroup.disable();
      }
    }
  }
  addLimit() {
    if (this.formGroup.valid) {
      const formData = this.formGroup.value;
      const limitData: LimitsModel = {
        id: this.config.data?.data?.id,
        region: formData.region || '' ,
        startDate: formData.startDate || '',
        endDate: formData.endDate || '',
        maxAmount: formData.maxAmount || 0,
      };
      this.isLoading = true;
      this.limitsService.createLimit(limitData)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: () => {
            this.toastService.showToast('Succès', 'Limite créée avec succès.', 'success');
            this.ref.close();
          },
          error: (err) => {
            this.toastService.showToast('Erreur', 'Echec de la création de la limite', err);
            this.ref.close();
          }
        });
    }
  }
  updateLimit() {
    this.isLoading = true;
    const limit = this.formGroup.value as LimitsModel;
    this.limitsService.updateLimit(this.boundData?.data.id, limit).subscribe({
      next: () => {
        this.toastService.showToast('Success','La limite a été modifié avec succès.','success');
        this.isLoading = false;
        this.ref.close();
      },
      error: () => {
        this.toastService.showToast('Erreur', 'Erreur lors de la modification de la limite.', 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

}
