import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, map, Observable, throwError} from "rxjs";
import {Page} from "../models/page.model";
import {DetailsWithSubmissionModel, SubmissionModel} from "../models/details-submissionn.model";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SubmissionService {
  private baseUrl = 'income/v1/submission';

  constructor(private http: HttpClient) {
  }
  getSubmissionByDetailsEmission(detailsEmissionId: string, page: number, size: number, isPaged: boolean = true): Observable<Page<DetailsWithSubmissionModel>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<DetailsWithSubmissionModel>>(`${this.baseUrl}/${detailsEmissionId}`, { params });
  }
  getAllSubmissionWithDetails(page: number, size: number, isPaged: boolean = true): Observable<Page<DetailsWithSubmissionModel>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<DetailsWithSubmissionModel>>(this.baseUrl, {params});
  }
  registerSubmission(detailsEmissionId: string, dto: SubmissionModel): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${detailsEmissionId}`, dto);
  }
  exportSubmissions(): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/submissions`, { responseType: 'blob' });
  }
  deleteSubmission(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }
  public importSubmission(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${this.baseUrl}/import`, formData, { responseType: 'text' }).pipe(
      take(1),
      map(response => {
        try {
          return JSON.parse(response);
        } catch (e) {
          console.error(response);
          throw new Error('La réponse du serveur n\'est pas au format JSON attendu.');
        }
      }),
      catchError(error => {
        if (error.status === 400) {
          return throwError(() => new Error('Format de fichier invalide. Veuillez vérifier votre fichier.'));
        }
        return throwError(() => error);
      })
    );
  }
}

