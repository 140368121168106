import {Component, ViewChild} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {FileUpload, FileUploadModule} from "primeng/fileupload";
import {PrimeTemplate} from "primeng/api";
import {UpperCasePipe} from "@angular/common";
import {FullCalendarComponent} from "@fullcalendar/angular";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {PaginatorState} from "primeng/paginator";
import {SubmissionService} from "../../../../core/services/submission.service";
import {DetailsWithSubmissionModel} from "../../../../core/models/details-submissionn.model";
import { saveAs } from 'file-saver';
import {ToastService} from "../../../../core/services/toast.service";

@Component({
  selector: 'app-soumissions',
  standalone: true,
  imports: [
    DropdownModule,
    DynamicTableComponent,
    FileUploadModule,
    PrimeTemplate,
    UpperCasePipe
  ],
  templateUrl: './soumissions.component.html',
  styleUrl: './soumissions.component.css'
})
export class SoumissionsComponent {

  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  boundData?: CustomDialogData;
  primary: DetailsWithSubmissionModel[] = [];

  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  ref?: DynamicDialogRef;
  actions: CustomAction[] = [];
  columns: CustomTableHeader[] = [
    { key: 'isin', column: 'ISIN', type: 'text' },
    { key: 'typeInstrument', column: 'Instrument', type: 'text' },
    { key: 'amountSubmission', column: 'Montant Soumis', type: 'text' },
    { key: 'rateSubmission', column: 'Taux Soumis', type: 'text' },
    { key: 'submissionRequestDTO.state', column: 'Statut', type: 'state' }
  ];

  constructor(private submissionService: SubmissionService,
              private toastService :ToastService) {}

  ngOnInit(): void {
    this.actions = this.getActions();
    this.loadSubmission(getDefaultPageable(0, 10));
  }

  private loadSubmission(pageable: Pageable) {
    this.submissionService.getAllSubmissionWithDetails(pageable.page, pageable.limit, true)
      .subscribe({
        next: (response) => {

          this.primary = response.content.map(item => ({
            ...item,
            amountSubmission: item.submissionRequestDTO.amountSubmission,
            rateSubmission: item.submissionRequestDTO.rateSubmission
          }));
          this.pageable.totalRecords = response.totalElements;
        },
        error: (error) => {
        }
      });
  }
  onUpload(event: any) {
    const file = event.files[0];
    if (file) {
      this.submissionService.importSubmission(file).subscribe({
        next: (response) => {
          this.toastService.showToast('Succès', `Le fichier a été importé avec succès.`, 'success', response);
          this.loadSubmission(getDefaultPageable(0, 10));
          this.resetFileUpload();
        },
        error: (error) => {
          let errorMessage = `Échec de l'importation du fichier ${file.name}.`;
          if (error.error && typeof error.error === 'string') {
            errorMessage += ' Détail : ' + error.error;
          } else if (error instanceof SyntaxError) {
            errorMessage += ' La réponse du serveur n\'est pas au format JSON attendu.';
          }
          this.toastService.showToast('Erreur', errorMessage, 'error');
          this.resetFileUpload();
        }
      });
    }
  }
  resetFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
  }
  onPageChange = (event: PaginatorState) => {
    this.loadSubmission(getDefaultPageable(event.page, event.rows));
  }

  exportSoumission(): void {
    this.submissionService.exportSubmissions().subscribe(blob => {
      const fileName = 'soumissions.xlsx';
      saveAs(blob, fileName);
    }, error => {
    });
  }
  getActions(): CustomAction[] {
    return [
      {
        role: 'UPDATE_EMISSION',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteSubmission(item),
        visible: true
      },
    ];
  }
  private deleteSubmission(primary: DetailsWithSubmissionModel) {
    this.submissionService.deleteSubmission(primary.submissionRequestDTO.id).subscribe({
      next: () => {
        this.toastService.showToast('Soumission supprimée', `La soumission a été supprimée avec succès.`);
        this.loadSubmission(getDefaultPageable());
      },
      error: (err) => {
        this.toastService.showToast('Erreur lors de la suppression du titre de la soumission', err, 'error');
      }
    });  }
}
