import {Component, OnInit} from '@angular/core';
import {InstitutionService} from "../../core/services/institution.service";
import {getInstitutionTypeByValue, IInstitution, InstitutionType} from "../../core/models/institution.model";
import {ToastService} from "../../core/services/toast.service";
import {TableModule} from "primeng/table";
import {NgClass, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {Button, ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ModuleService} from "../../core/services/module.service";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {countries} from "../../../assets/countries";
import {TagModule} from "primeng/tag";
import {InputTextModule} from "primeng/inputtext";
import {Ripple} from "primeng/ripple";
import {InputTextareaModule} from "primeng/inputtextarea";
import {DropdownModule} from "primeng/dropdown";
import {AvatarModule} from "primeng/avatar";
import {ApiUrlPipe} from "../../core/pipes/api-url.pipe";
import {IModule} from "../../core/models/module.model";

@Component({
  selector: 'app-institution',
  standalone: true,
  imports: [
    // Modules
    TableModule, ReactiveFormsModule, TagModule, InputTextModule, Button, InputTextareaModule, DropdownModule,
    FileUploadModule, AvatarModule,
    // Directives & Pipes
    NgIf, NgForOf, ButtonDirective, ApiUrlPipe, UpperCasePipe, NgClass, Ripple,
    // Components
    DynamicTableComponent, ToolbarComponent,
  ],
  templateUrl: './institution.component.html',
  styleUrl: './institution.component.scss'
})
export class InstitutionComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    type: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    phone: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    address: new FormControl(''),
    country: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl(''),
    modules: new FormControl<IModule[]>([]),
  });
  institution: IInstitution = {} as IInstitution;
  uploadedFiles: File[] = [];
  isLoading: boolean = false;
  isEdit: boolean = false;
  types: string[] = Object.values(InstitutionType);
  countries = countries;

  constructor(private service: InstitutionService, private productService: ModuleService, private toastService: ToastService) {}

  ngOnInit() {
    this.service.getCurrentUserInstitution().subscribe({
      next: institution => this.patchForm(institution),
      error: err => this.toastService.showToast('Erreur lors du chargement de l\'institution', err, 'error')
    })
  }

  patchForm(institution: IInstitution) {
    this.isEdit = false;
    this.institution = institution;
    this.formGroup.patchValue(this.institution);
    this.formGroup.patchValue({ modules: this.institution.modules });
    this.formGroup.disable();
  }

  onUpload(event: FileSelectEvent) {
    for(let file of event.currentFiles) {
      this.uploadedFiles = [];
      this.uploadedFiles.push(file);
    }
  }

  update() {
    this.isLoading = true;
    const institutionDto = this.formGroup.value as IInstitution;
    institutionDto.type = <string>getInstitutionTypeByValue(institutionDto.type);
    this.service.updateInstitution(this.institution.id, institutionDto).subscribe({
      next: (institution) => {
        this.toastService.showToast('Institution modifié',`L'institution ${institution.name} a été modifiée avec succès.`);
        this.isLoading = false;
        this.patchForm(institution);
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification de l\'institution', error);
        this.isLoading = false;
      }
    });
  }

  enableEditing() {
    this.isEdit = true;
    this.formGroup.enable();
  }

}
