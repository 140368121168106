import {Component, OnInit} from '@angular/core';
import {PricerFormComponent} from '../../pricer-form/pricer-form.component';
import {PricerResultComponent} from '../../pricer-result/pricer-result.component';
import {ISimulationResult} from '../../../../../../core/models/simulation.model';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {InputNumberModule} from 'primeng/inputnumber';
import { DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-detail-simulation',
  standalone: true,
  imports: [PricerResultComponent, PricerFormComponent, CommonModule, FormsModule, InputNumberModule, DynamicDialogModule],
  templateUrl: './detail-simulation.component.html',
  styleUrl: './detail-simulation.component.scss'
})
export class SimulationResultDetailComponent implements OnInit {
  duration: number | undefined;
  rateOfReturn: number | undefined;
  netAmount: number | undefined;
  interest: number | undefined;
  couponCouru: number | undefined;
  placementPrice: number | undefined;
  interestCourus: number | undefined;
  simulationResults: ISimulationResult | undefined;
  instrumentType = 'BAT'
  readonly data: any;
  results: ISimulationResult | undefined

  constructor(private config: DynamicDialogConfig,) {
    this.data = this.config.data;
  }

  get isBAT(): boolean {
    return this.instrumentType === 'BAT';
  }

  get isOAT(): boolean {
    return this.instrumentType === 'OAT';
  }

  ngOnInit() {
    if(this.data.data){
      this.results = this.data.data;
      this.duration = this.results!.duration;
      this.couponCouru = this.results!.couponCouru;
      this.interestCourus = this.results!.interestCourus;
      this.interest = this.results!.interest;
      this.netAmount = this.results!.netAmount;
      this.rateOfReturn = this.results!.rateOfReturn;
      this.placementPrice = this.results!.placementPrice
    }

  }
}
