import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../../../core/services/account.service";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {ToastService} from "../../../../core/services/toast.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../../../shared/components/toolbar/toolbar.component";
import {CustomAction} from "../../../../core/models/custom-table.model";
import {AccountType, IAccount} from "../../../../core/models/account.model";
import {DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../../../core/utils/utils";
import {PaginatorModule, PaginatorState} from "primeng/paginator";
import {AccountOperationListComponent} from "../../../../shared/components/operations/account-operation-list.component";
import {CurrencyPipe, DatePipe, NgForOf, NgIf} from "@angular/common";
import {Button} from "primeng/button";
import {MenuModule} from "primeng/menu";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-suivi',
  standalone: true,
  imports: [DynamicTableComponent, ToolbarComponent, NgForOf, DatePipe, Button, CurrencyPipe, MenuModule, NgIf, PaginatorModule],
  templateUrl: './suivi.component.html',
  styleUrl: './suivi.component.scss'
})
export class SuiviComponent implements OnInit {

  accounts: IAccount[] = [];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];

  constructor(private accountService: AccountService, private toastService: ToastService, public dialogService: DialogService) { }

  ngOnInit() {
    this.getAccounts();
    this.actions = this.getActions();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_VIREMENT',
        icon: 'pi pi-info-circle',
        label: 'Opérations',
        severity: 'info',
        emit: (item) => this.viewOperations(item),
        visible: true
      },
      {
        role: 'READ_VIREMENT',
        icon: 'pi pi-chart-line',
        label: 'Soldes',
        severity: 'primary',
        emit: (item) => this.listBalances(item),
        visible: true
      }
    ];
  }

  // TODO: Implement the following methods
  viewOperations(account: IAccount) {
    this.ref = this.dialogService.open(AccountOperationListComponent, {
      header: `Opérations - ${account.libelle}`,
      width: '80%',
      height: '80%',
      data: {
        accountId: account.id
      },
      contentStyle: { overflow: 'auto' }
    });
  }

  // TODO: Implement the following methods
  listBalances(account: IAccount) {

  }

  getMenuItems(account: IAccount): MenuItem[] {
    return this.actions
      .filter(action => typeof action.visible === 'function' ? action.visible(account) : action.visible)
      .map(action => ({
        label: action.label,
        icon: action.icon,
        command: () => action.emit(account)
      }));
  }

  getAccounts(pageable: Pageable = getDefaultPageable()) {
    return this.accountService.getAccountsByType(AccountType.NOSTRO, pageable).subscribe({
      next: page => {
        this.accounts = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: (err) => this.toastService.showToast('Chargement des comptes', err.error, 'error')
    });
  }

  onPageChange = (event: PaginatorState) => this.getAccounts(getDefaultPageable(event.page, event.rows));


}
