import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CheckboxChangeEvent, CheckboxModule} from "primeng/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf} from "@angular/common";
import {IPermission} from "../../../core/models/permission.model";
import {Button} from "primeng/button";
import {PermissionService} from "../../../core/services/permission.service";
import {Direction, getUnpagedPageable, Sort} from "../../../core/models/page.model";
import {ToastService} from "../../../core/services/toast.service";
import {getInstitutionTypeByValue, InstitutionType} from "../../../core/models/institution.model";

@Component({
  selector: 'group-permissions',
  standalone: true,
  imports: [
    // Modules
    CheckboxModule, FormsModule, ReactiveFormsModule, Button,
    // Pipes & Directives
    NgForOf
  ],
  templateUrl: './view-permissions.component.html',
  styleUrl: './view-permissions.component.scss'
})
export class ViewPermissionsComponent implements OnInit {

  @Input({ required: true }) selectedPermissions: IPermission[] = [];
  @Input() disabled: boolean | undefined = true;
  @Output() permissionsChange = new EventEmitter<IPermission[]>();

  permissions: IPermission[] = [];
  sort: Sort = { direction: Direction.ASC, orders: [{ property: 'code', ignoreCase: false }] };

  constructor(private permissionService: PermissionService, private toastService: ToastService) {}

  ngOnInit() {
    this.permissionService.getPermissionsByInstitutionType(getInstitutionTypeByValue(InstitutionType.BANK)!, getUnpagedPageable(this.sort))
      .subscribe({
        next: (page) => this.permissions = page.content,
        error: (err) => this.toastService.showToast('Erreur lors de la capture des permissions', err, 'error')
      });
  }

  checked($event: CheckboxChangeEvent) {
    this.permissionsChange.emit($event.checked as IPermission[]);
  }
}
