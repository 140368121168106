import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginatorModule, PaginatorState} from "primeng/paginator";
import {ButtonModule} from "primeng/button";
import {CheckboxModule} from "primeng/checkbox";
import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault
} from "@angular/common";
import {MenuItem, SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {PageParams} from "../../../core/models/page.model";
import {TagModule} from "primeng/tag";
import {CustomAction, CustomTableHeader} from "../../../core/models/custom-table.model";
import {HasRoleDirective} from "../../../core/directives/has-role.directive";
import {StorageService} from "../../../core/services/storage.service";
import {SpeedDialModule} from "primeng/speeddial";
import {SplitButtonModule} from "primeng/splitbutton";
import {Menu, MenuModule} from "primeng/menu";
import {countriesUemoa} from "../../../../../../alima-admin/src/assets/countries.uemoa";
import {DragDropModule} from "primeng/dragdrop";
import {InputTextModule} from "primeng/inputtext";
import {StyleClassModule} from "primeng/styleclass";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {Ripple} from "primeng/ripple";

@Component({
  selector: 'dynamic-table',
  standalone: true,
  imports: [
    // Modules
    ButtonModule, CheckboxModule, PaginatorModule, SharedModule, TableModule, TagModule, DragDropModule,
    SplitButtonModule, MenuModule, SpeedDialModule, SplitButtonModule, MenuModule,
    SpeedDialModule, SplitButtonModule, MenuModule, InputTextModule, StyleClassModule, OverlayPanelModule,
    // Pipes & Directives
    CurrencyPipe, DatePipe, NgIf, NgStyle, NgForOf, NgSwitch, NgSwitchCase, NgSwitchDefault, HasRoleDirective,
    Ripple, NgClass, DecimalPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent implements OnInit {

  @Input({required: true}) headers : CustomTableHeader[] = [];
  @Input({required: true}) data : any[] = [];
  @Input() pageParams?: PageParams;
  @Input() actions: CustomAction[] = [];
  @Output() paginate = new EventEmitter<PaginatorState>();
  items: MenuItem[] = [];
  selectedData: any;
  countries = countriesUemoa;
  constructor(
    private readonly tokenManager: StorageService
  ) {}

  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc?.[part], obj);
  }

  ngOnInit() {
    if (this.actions.length > 0) {
      const jwt = this.tokenManager.getToken();
      this.actions = this.actions.filter(action =>
        jwt.authorities.some(role => action.role === role) &&
        (typeof action.visible === 'boolean' ? action.visible : true)
      );
    }
  }

  getActionsForRow(rowData: any): MenuItem[] {
    return this.actions
      .filter(action =>
        typeof action.visible === 'function' ? action.visible(rowData) : action.visible
      )
      .map(action => ({
        label: action.label,
        icon: action.icon,
        command: () => action.emit(rowData)
      }));
  }

  emit(data: any, $event: MouseEvent, menu: Menu) {
    this.selectedData = data;
    menu.model = this.getActionsForRow(data);
    menu.toggle($event);
  }

  getCountryFlag(region: string): string {
    const country = this.countries.find(c => c.country === region);
    if (country) {
      return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code.toUpperCase()}.svg`;
    }
    return '';
  }
}
