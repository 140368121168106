import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'apiUrl',
  standalone: true
})
export class ApiUrlPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) return '';
    return `${environment.apiURL}${value}`;
  }

}
