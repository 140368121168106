import {Component, OnInit} from '@angular/core';
import {ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {EmissionPrimaireService} from "../../../../core/services/emission.primaire.service";
import {TitresDTO} from "../../../../core/models/titres.model";
import {OperationsTitresModel} from "../../../../core/models/operationsTitres.model";
import {OperationsTitresService} from "../../../../core/services/operationsTitres.service";
import {ToastService} from "../../../../core/services/toast.service";
import {PageParams} from "../../../../core/models/page.model";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-new-title-secondary',
  standalone: true,
  imports: [ButtonDirective, DynamicTableComponent, InputTextModule, PaginatorModule, ReactiveFormsModule, CalendarModule, NgIf, Ripple],
  templateUrl: './new-title-secondary.component.html',
  styleUrl: './new-title-secondary.component.scss'
})
export class NewTitleSecondaryComponent implements OnInit {

  accountOptions = [
    { code: 'PROPRE', name: 'PROPRE' },
    { code: 'TIERS', name: 'TIERS' }
  ];

  sensOptions = [
    { code: 'ACHAT', name: 'ACHAT' },
    { code: 'VENTE', name: 'VENTE' }
  ];

  showBeneficiary = false;

  formGroup = new FormGroup({
    sens: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    amount: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    isin: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    counterpart: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    rate: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    account: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    beneficiary: new FormControl('', { nonNullable: false })

  });
  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  isCreate: boolean = false;
  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  titresList: TitresDTO[] = [];
  isins: { label: string, value: string }[] = [];

  constructor(private emissionPrimaireService: EmissionPrimaireService,
              private toastService :ToastService,
              private operationsTitresService: OperationsTitresService,
              private ref: DynamicDialogRef,
  ) {
  }

  ngOnInit() {
    this.loadIsins();
  }

  loadIsins(): void {
    this.emissionPrimaireService.getAllTitres(0, 100, true).subscribe({
      next: (response) => {
        this.titresList = response.content;
        this.isins = this.titresList.map(titre => ({
          label: titre.isin,
          value: titre.isin
        }));
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
        this.isLoading = false;
      }
    })
  }

  onAccountChange(accountValue: string): void {
    this.showBeneficiary = accountValue === 'TIERS';

    if (this.showBeneficiary) {
      this.formGroup.get('beneficiary')?.setValidators([Validators.required]);
    } else {
      this.formGroup.get('beneficiary')?.clearValidators();
    }

    this.formGroup.get('beneficiary')?.updateValueAndValidity();
  }

  addOperationsTitres(): void {
    if (this.formGroup.valid) {
      const isinValue = this.formGroup.value.isin;
      const titre = this.titresList.find(titre => titre.isin === isinValue);

      if (!titre) {
        console.error();
        return;
      }
      const dto: OperationsTitresModel = {
        sens: this.formGroup.value.sens || '',
        account: this.formGroup.value.account || '',
        beneficiary: this.formGroup.value.beneficiary || '',
        amount: this.formGroup.value.amount || '',
        rate: Number(this.formGroup.value.rate),
        counterpart: this.formGroup.value.counterpart || '',
        titres: {
          id: titre.id,
          typeInstrument: titre.typeInstrument || '',
          dueDate: titre.dueDate || '',
          rate: titre.rate || 0,
          unitNominalValue: titre.unitNominalValue || 0,
          isin: titre.isin,
          duration: titre.duration || 0,
          emission: titre.emission
           }
      };

      this.isLoading = true;

      this.operationsTitresService.registerOperationsTitres(dto.titres.id, dto).subscribe({
        next: () => {
          this.toastService.showToast('Succès', `Opération sur le titre ${dto.titres.isin} créée avec succès.`, 'success');
          this.ref.close();
        },
        error: (err) => {
          this.toastService.showToast('Erreur', 'Echec de la soumission', err);
          this.ref.close();
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    } else {
      console.error();
    }
  }
}

