import {Component, OnInit} from '@angular/core';
import {BalanceHistoryChartComponent} from "../../../../../shared/charts/balance-history.component";
import {MultiCurrencyBalanceChartComponent} from "../../../../../shared/charts/multi-currency-balance-chart.component";
import {AccountBalanceService} from "../../../../../core/services/account.balance.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {ICurrencyBalance} from "../../../../../core/models/account.model";
import {CurrencyPipe, NgIf} from "@angular/common";
import {DynamicTableComponent} from "../../../../../shared/components/dynamic-table/dynamic-table.component";
import {SplitButtonModule} from "primeng/splitbutton";
import {CustomAction, CustomTableHeader} from "../../../../../core/models/custom-table.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-foreign-summary',
  standalone: true,
  imports: [
    // Modules
    SplitButtonModule,
    // Components
    BalanceHistoryChartComponent, MultiCurrencyBalanceChartComponent, DynamicTableComponent,
    // Pipes & Directives
    CurrencyPipe, NgIf
  ],
  templateUrl: './foreign-summary.component.html',
  styleUrl: './foreign-summary.component.scss'
})
export class ForeignSummaryComponent implements OnInit {

  balances: ICurrencyBalance[] = [];
  balanceColumns: CustomTableHeader[] = [
    {
      key: 'currency.code',
      column: 'Devise',
      type: 'text'
    },
    {
      key: 'balance',
      column: 'Solde',
      type: 'currency',
      currencyKey: 'currency.code'
    }
  ];
  balancesTableActions: CustomAction[] = [
    {
      role: 'READ_OPERATION',
      icon: 'pi pi-info-circle',
      label: 'Consulter',
      severity: 'secondary',
      emit: (item: ICurrencyBalance) => this.showCurrencySummary(item),
      visible: true
    }
  ];

  constructor(
    private readonly balanceService: AccountBalanceService,
    private readonly toastService: ToastService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.loadForeignBalances();
  }

  loadForeignBalances(): void {
    this.balanceService.getLatestCurrencyBalance(new Date(), false).subscribe({
      next: (balances) => this.balances = balances,
      error: (error) => this.toastService.showToast('Chargement des soldes étrangers', error.error, 'error')
    })
  }

  private showCurrencySummary(item: ICurrencyBalance): void {
    this.router.navigate(['/main/tresorerie/devise/releve', item.currency.code]).then();
  }
}
