<div class="h-full flex flex-column gap-2">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mb-4">
    <div class="flex flex-column gap-2">
      <label for="amount" class="font-medium">Réserve obligatoire</label>
      <p-inputNumber id="amount" [showButtons]="true" formControlName="amount" />
    </div>
    <div *ngIf="!boundData?.isCreate" class="flex flex-column gap-2">
      <label for="reserveAmount" class="font-medium">Réserve constituée</label>
      <p-inputNumber id="reserveAmount" [showButtons]="true" formControlName="reserveAmount" />
    </div>
    <div class="flex flex-column gap-2" [style]="{ 'max-width': '512px' }">
      <label for="dates" class="font-medium">Date de déclaration</label>
      <p-calendar [maxDate]="today" id="dates" selectionMode="range" [autofocus]="false" [readonlyInput]="true" formControlName="date" />
      <small id="dates-help">Renseigner ici la date de déclaration de la réserve obligatoire.</small>
    </div>
  </form>
  <div class="flex flex-grow-1 mt-4 align-items-end justify-content-end">
    <p-button *ngIf="!boundData?.isView" [disabled]="!formGroup.valid" [label]="'Enregistrer'" (onClick)="create()" />
  </div>
</div>
