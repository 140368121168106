import {Component, Input, OnInit} from '@angular/core';
import {CustomTableHeader} from '../../../core/models/custom-table.model';
import {PaginatorModule, PaginatorState} from 'primeng/paginator';
import {TableModule} from 'primeng/table';
import {DynamicTableComponent} from "../dynamic-table/dynamic-table.component";
import {IOperation} from "../../../core/models/operation.model";
import {CurrencyPipe, DatePipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from "@angular/common";
import {StorageService} from "../../../core/services/storage.service";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'app-operation-list',
  standalone: true,
  imports: [
    // Modules
    TableModule, TooltipModule, PaginatorModule,
    // Pipes & Directives
    NgForOf, NgSwitch, NgSwitchCase, DatePipe, CurrencyPipe, NgSwitchDefault, NgClass, NgIf,
  ],
  template: `
    <p-table [value]="data" [columns]="headers" [lazy]="true" [loading]="loading">
      <ng-template pTemplate="header" let-columns>
        <tr class="header">
          <th scope="col" [ngClass]="{'text-center': col.key !== 'operationDate' && col.key !== 'valueDate', 'text-start': col.key === 'operationDate' || col.key === 'valueDate'}"
              *ngFor="let col of columns">{{col.column}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" class="truncate" [pTooltip]="getNestedValue(rowData, col.key)"
              [ngClass]="{'text-center': col.key == 'debit' || col.key == 'credit', 'text-start': col.key === 'operationDate' || col.key === 'valueDate' || col.key === 'libelle'}">
            <ng-container [ngSwitch]="col.key">
              <ng-container *ngSwitchCase="'debit'">
                {{ rowData.sens === 'Débit' ? (rowData.amount | currency : (col.currencyKey ? getNestedValue(rowData, col.currencyKey) : 'XOF') : 'symbol' : '1.0-0' : 'fr-FR') : '' }}
              </ng-container>
              <ng-container *ngSwitchCase="'credit'">
                {{ rowData.sens === 'Crédit' ? (rowData.amount | currency : (col.currencyKey ? getNestedValue(rowData, col.currencyKey) : 'XOF') : 'symbol' : '1.0-0' : 'fr-FR') : '' }}
              </ng-container>
              <ng-container *ngSwitchCase="'operationDate'">
                {{ rowData[col.key] | date:'dd/MM/yyyy' }}
              </ng-container>
              <ng-container *ngSwitchCase="'valueDate'">
                {{ rowData[col.key] | date:'dd/MM/yyyy' }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ getNestedValue(rowData, col.key) }}
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="flex-auto">
      <p-paginator *ngIf="pageParams" (onPageChange)="paginate.emit($event)" [showCurrentPageReport]="true"
                   [first]="pageParams.first" [rows]="pageParams.rows" [totalRecords]="pageParams.totalRecords"
                   [rowsPerPageOptions]="pageParams.rowPerPageOptions" />
    </div>
  `
})
export class OperationListComponent extends DynamicTableComponent implements OnInit {

  @Input() override headers: CustomTableHeader[] = [
    { column: 'Date opération', key: 'operationDate', type: 'date' },
    { column: 'Date valeur', key: 'valueDate', type: 'date' },
    { column: 'Libellé', key: 'libelle', type: 'text' },
    { column: 'Débit', key: 'debit', type: 'currency', currencyKey: 'currency.code' },
    { column: 'Crédit', key: 'credit', type: 'currency', currencyKey: 'currency.code'  },
  ];
  @Input() override data: IOperation[] = [];
  @Input() loading: boolean = false;

  constructor(private readonly storageService: StorageService) {
    super(storageService);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  onPageChange(event: PaginatorState) {
    this.paginate.emit(event);
  }

}
