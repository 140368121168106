import {Component, OnInit} from '@angular/core';
import {ApiUrlPipe} from "../../../../../core/pipes/api-url.pipe";
import {AvatarModule} from "primeng/avatar";
import {Button} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {FileUploadModule} from "primeng/fileupload";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CommonModule, DatePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {PrimeTemplate} from "primeng/api";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Ripple} from "primeng/ripple";
import {TagModule} from "primeng/tag";
import {ToolbarComponent} from "../../../../../shared/components/toolbar/toolbar.component";
import {CalendarModule} from "primeng/calendar";
import {countriesUemoa} from "../../../../../../../../alima-admin/src/assets/countries.uemoa";
import {PrimaireEmissionModel} from "../../../../../core/models/primaire.emission.model";
import {EmissionPrimaireService} from "../../../../../core/services/emission.primaire.service";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../../../core/services/toast.service";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";

interface TypeInstrument {
  name: string;
  code: string;
}
@Component({
  selector: 'app-emissions-detail',
  standalone: true,
  imports: [ApiUrlPipe, AvatarModule, Button, DropdownModule, FileUploadModule, InputTextModule, InputTextareaModule, NgForOf, NgIf, PaginatorModule, PrimeTemplate, ReactiveFormsModule, Ripple, TagModule, ToolbarComponent, UpperCasePipe, CommonModule, CalendarModule, DatePipe],
  templateUrl: './emissions-detail.component.html',
  styleUrl: './emissions-detail.component.scss'
})
export class EmissionsDetailComponent implements OnInit{

  formGroup = new FormGroup({
    isin: new FormControl('',  {nonNullable: true ,validators: [Validators.required] }),
    region: new FormControl('',  {nonNullable: true ,validators: [Validators.required] }),
    typeInstrument: new FormControl(''),
    operationDate: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    valueDate:  new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    dueDate: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    rate: new FormControl(),
    unitNominalValue: new FormControl(),
    amount: new FormControl(),
  });
  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;
  emission: PrimaireEmissionModel = {} as PrimaireEmissionModel;
  typeInstrument: TypeInstrument[] | undefined;

  countries = countriesUemoa;

  constructor(
    private readonly emissionPrimaireService : EmissionPrimaireService,
    private readonly toastService: ToastService,
    private readonly datePipe: DatePipe,
    private readonly ref: DynamicDialogRef,
    private readonly dialogService: DialogService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  update() {
    // TODO: Implement this method
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    if (this.boundData?.isCreate) {
      this.isCreate = true;
    }
    else {
      this.emission = this.boundData?.data as PrimaireEmissionModel;
      this.formGroup.patchValue(this.boundData?.data);
      if (this.boundData?.isView) this.formGroup.disable();
    }
    this.typeInstrument = [
      { name: 'OAT', code: 'OAT' },
      { name: 'BAT', code: 'BAT' },
    ];
  }

  createEmission() {

    const emissionDto: PrimaireEmissionModel = {
      region: this.formGroup.get('region')?.value || '',
      valueDate: this.formatDate(this.formGroup.get('valueDate')?.value || ''),
      operationDate: this.formatDate(this.formGroup.get('operationDate')?.value || ''),
      amount: this.formGroup.get('amount')?.value,
      titresDTO: [{
        typeInstrument: this.formGroup.get('typeInstrument')?.value || '',
        dueDate: this.formatDate(this.formGroup.get('dueDate')?.value || ''),
        rate: this.formGroup.get('rate')?.value,
        unitNominalValue: this.formGroup.get('unitNominalValue')?.value,
        isin: this.formGroup.get('isin')?.value
      }]
    };

    this.emissionPrimaireService.createEmission(emissionDto).subscribe({
      next: (emission) => {
        this.toastService.showToast('Emission créé', `L'émission a été créé avec succès.`);
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Création de l\'émission', error, 'error');
        this.ref.close();
      }
    });
  }

  private formatDate(date: Date | string): string {
    if (date instanceof Date) return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss') || '';
    return date;
  }

}
