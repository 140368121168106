import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getDefaultPageable, getPageableAsParams, Page, Pageable} from '../models/page.model';
import {ILoanBorrowing, ILoanBorrowingSummary} from "../models/loan-borrowing.model";
import {LoanBorrowingFilterData} from "../../shared/components/loan-borrowing/loan-borrowing-filter/loan-borrowing-filter.component";

@Injectable({
  providedIn: 'root'
})
export class LoanBorrowingService {

  private readonly apiUrl = `operation/v1/loan-borrowings`;

  constructor(private readonly http: HttpClient) { }

  getTypesOfCounterparty(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/counterparty-types`);
  }

  createLoanBorrowing(loanBorrowing: ILoanBorrowing): Observable<ILoanBorrowing> {
    return this.http.post<ILoanBorrowing>(this.apiUrl, loanBorrowing);
  }

  searchLoanBorrowings(query: string, pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable).set('query', query);
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/search`, { params });
  }

  uploadLoanBorrowings(file: File): Observable<Set<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<Set<any>>(`${this.apiUrl}/upload`, formData);
  }

  createLoanBorrowings(loanBorrowings: ILoanBorrowing[]): Observable<Set<ILoanBorrowing>> {
    return this.http.post<Set<ILoanBorrowing>>(`${this.apiUrl}/batch`, loanBorrowings);
  }

  getLoanBorrowingById(id: string): Observable<ILoanBorrowing> {
    return this.http.get<ILoanBorrowing>(`${this.apiUrl}/${id}`);
  }

  getAllLoanBorrowings(pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<ILoanBorrowing>>(this.apiUrl, { params });
  }

  getLoanBorrowingsByInstitution(institutionId: string, pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/institution/${institutionId}`, { params });
  }

  getUpcomingLoanBorrowingsForInstitution(query: string = '', pageable: Pageable = getDefaultPageable()): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable).set('query', query);
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/future`, { params });
  }

  getLoanBorrowingsByStateAndInstitutionId(institutionId: string, state: string, pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/institution/${institutionId}/state/${state}`, { params });
  }

  getLoanBorrowingsByDateRangeAndInstitutionId(institutionId: string, startDate: Date, endDate: Date, pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable)
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString());
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/institution/${institutionId}/dateRange`, { params });
  }

  updateLoanBorrowing(id: string, loanBorrowing: ILoanBorrowing): Observable<ILoanBorrowing> {
    return this.http.put<ILoanBorrowing>(`${this.apiUrl}/${id}`, loanBorrowing);
  }

  deleteLoanBorrowing(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  validateLoanBorrowing(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/validate`, {});
  }

  rejectLoanBorrowing(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/reject`, {});
  }

  executeLoanBorrowing(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/execute`, {});
  }

  cancelLoanBorrowing(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/cancel`, {});
  }

  getLoanBorrowingsByType(type: string, pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/type/${type}`, { params });
  }

  getLoanBorrowingsByTypeAndInstitution(type: string, institutionId: string, pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/institution/${institutionId}/type/${type}`, { params });
  }

  searchLoanBorrowingsByDateRange(query: string, startDate: Date, endDate: Date, pageable: Pageable): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable)
      .set('query', query)
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString());
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/dateRange`, { params });
  }

  getUpcomingLoanBorrowings(daysAhead: number = 30): Observable<Page<ILoanBorrowing>> {
    let params = new HttpParams().set('daysAhead', daysAhead.toString());
    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/upcoming`, { params });
  }

  getLoanBorrowingSummary(startDate: Date, endDate: Date): Observable<ILoanBorrowingSummary[]> {
    let params = new HttpParams()
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString());
    return this.http.get<ILoanBorrowingSummary[]>(`${this.apiUrl}/summary`, { params });
  }

  advancedSearch(
    options: LoanBorrowingFilterData,
    pageable: Pageable = getDefaultPageable()
  ): Observable<Page<ILoanBorrowing>> {
    let params = getPageableAsParams(pageable);

    if (options.query) params = params.set('query', options.query);
    if (options.types) params = params.set('types', options.types.join(','));
    if (options.states) params = params.set('states', options.states.join(','));
    if (options.isLocal) params = params.set('isLocal', options.isLocal.toString());

    // Safely handle date parameters
    const startDate = options.dates?.[0];
    const endDate = options.dates?.[1];
    if (startDate) params = params.set('startDate', startDate.toISOString().split('T')[0]);
    if (endDate) params = params.set('endDate', endDate.toISOString().split('T')[0]);

    return this.http.get<Page<ILoanBorrowing>>(`${this.apiUrl}/advanced-search`, { params });
  }
}
