import {Component, OnInit} from '@angular/core';
import {NgComponentOutlet, NgIf} from "@angular/common";
import {TabMenuModule} from "primeng/tabmenu";
import {ResumePortefeuilleTabComponent} from "../portefeuille/resumePortefeuilleTab/resumePortefeuilleTab.component";
import {PortefeuilleTabComponent} from "../portefeuille/portefeuilleTab/portefeuilleTab.component";
import {LimiteTabComponent} from "../portefeuille/limiteTab/limiteTab.component";
import {RefinancingComponent} from "./refinancing/refinancing.component";
import {IntradayComponent} from "./intraday/intraday.component";
import {MarginalWicketComponent} from "./marginal-wicket/marginal-wicket.component";

@Component({
  selector: 'app-operation',
  standalone: true,
  imports: [
    NgComponentOutlet,
    NgIf,
    TabMenuModule
  ],
  templateUrl: './operation.component.html',
  styleUrl: './operation.component.scss'
})
export class OperationComponent implements OnInit{
  items: { label: string, icon?: string, command?: () => void }[] = [];
  selectedTab: { title: string, component: any } | null = null;
  activeItem: any = null;

  ngOnInit() {
    this.items = [
      { label: 'Refinancement', command: () => this.selectTab('Refinancement') },
      { label: 'Intraday',command: () => this.selectTab('Intraday') },
      { label: 'Guichet marginal',command: () => this.selectTab('Guichet marginal') }
    ];
    this.selectTab('Intraday');
  }

  selectTab(tabTitle: string) {
    this.activeItem = this.items.find(item => item.label === tabTitle);
    if (tabTitle === 'Refinancement') {
      this.selectedTab = { title: 'Refinancement', component: RefinancingComponent };
    } else if (tabTitle === 'Intraday') {
      this.selectedTab = { title: 'Intraday', component: IntradayComponent };
    } else if (tabTitle === 'Guichet marginal') {
      this.selectedTab = { title: 'Guichet marginal', component: MarginalWicketComponent };
    }
  }
}
