import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {IconFieldModule} from "primeng/iconfield";
import {InputIconModule} from "primeng/inputicon";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";

@Component({
  selector: 'search',
  standalone: true,
  imports: [
    IconFieldModule,
    InputIconModule,
    FormsModule,
    InputTextModule
  ],
  template: `
    <div class="flex w-full">
      <p-iconField iconPosition="right">
        <p-inputIcon styleClass="pi pi-search"/>
        <input [(ngModel)]="searchTerm" (ngModelChange)="onSearchChange($event)"
               type="text" pInputText
               [placeholder]="placeholder"/>
      </p-iconField>
    </div>
  `
})
export class SearchComponent implements OnInit, OnDestroy {

  @Input() placeholder: string = 'Rechercher';
  @Input() debounceTime: number = 500;
  @Output() search = new EventEmitter<string>();

  searchTerm: string = '';
  private searchSubject = new Subject<string>();
  private searchSubscription!: Subscription;

  ngOnInit() {
    this.searchSubscription = this.searchSubject.pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(term => this.search.emit(term));
  }

  ngOnDestroy() {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
  }

  onSearchChange = (term: string) => this.searchSubject.next(term);
}
