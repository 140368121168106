export enum ModulesEnum {
  ADMINISTRATION = 'ADMINISTRATION',
  TRESORERIE = 'TRESORERIE',
  FOREX = 'FOREX',
  FIXED_INCOME = 'FIXED_INCOME',
  RISK_MANAGEMENT = 'RISK_MANAGEMENT',
  BACK_OFFICE = 'BACK_OFFICE'

}

export enum ProductsEnum {
  ADMINISTRATION = 'ADMINISTRATION',
  VIREMENT = 'VIREMENT',
  LOCALE = 'LOCALE',
  DEVISE = 'DEVISE',
  RISK_MANAGEMENT = 'RISK_MANAGEMENT'
}

export enum PermissionsEnum {
  // Administration permissions
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  READ_USER = 'READ_USER',
  CREATE_INSTITUTION = 'CREATE_INSTITUTION',
  UPDATE_INSTITUTION = 'UPDATE_INSTITUTION',
  DELETE_INSTITUTION = 'DELETE_INSTITUTION',
  READ_INSTITUTION = 'READ_INSTITUTION',
  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  READ_GROUP = 'READ_GROUP',
  CREATE_VALIDATION = 'CREATE_VALIDATION',
  UPDATE_VALIDATION = 'UPDATE_VALIDATION',
  DELETE_VALIDATION = 'DELETE_VALIDATION',
  READ_VALIDATION = 'READ_VALIDATION',

  // Tresorerie permissions
  CREATE_VIREMENT = 'CREATE_VIREMENT',
  UPDATE_VIREMENT = 'UPDATE_VIREMENT',
  DELETE_VIREMENT = 'DELETE_VIREMENT',
  READ_VIREMENT = 'READ_VIREMENT',
  CREATE_LOCALE = 'CREATE_LOCALE',
  UPDATE_LOCALE = 'UPDATE_LOCALE',
  DELETE_LOCALE = 'DELETE_LOCALE',
  READ_LOCALE = 'READ_LOCALE',
  CREATE_DEVISE = 'CREATE_DEVISE',
  UPDATE_DEVISE = 'UPDATE_DEVISE',
  DELETE_DEVISE = 'DELETE_DEVISE',
  READ_DEVISE = 'READ_DEVISE',

  /**
   *
   * TODO: FOREX and FIXED_INCOME
   */
    // FOREX

    // Risk Management permissions
  CREATE_SIMULATION = 'CREATE_SIMULATION',
  UPDATE_SIMULATION = 'UPDATE_SIMULATION',
  DELETE_SIMULATION = 'DELETE_SIMULATION',
  READ_SIMULATION = 'READ_SIMULATION',
  READ_AGGREGATIONS = 'READ_AGGREGATIONS',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  READ_CATEGORY = 'READ_CATEGORY',

  CREATE_REGULATION_LAW = 'CREATE_REGULATION_LAW',
  UPDATE_REGULATION_LAW = 'UPDATE_REGULATION_LAW',
  DELETE_REGULATION_LAW = 'DELETE_REGULATION_LAW',
  READ_REGULATION_LAW = 'READ_REGULATION_LAW',

}


