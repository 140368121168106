import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CustomDialogData } from "../../../core/models/custom-dialog.model";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "../../../core/services/toast.service";
import { StorageService } from "../../../core/services/storage.service";
import { IProduct } from "../../../core/models/product.model";
import { ValidationService } from "../../../core/services/validation.service";
import { ProductService } from "../../../core/services/product.service";
import { GroupService } from "../../../core/services/group.service";
import { Direction, getUnpagedPageable, Sort } from "../../../core/models/page.model";
import { SelectItemGroup } from "primeng/api";
import { IGroup } from "../../../core/models/group.model";
import { IValidation, IValidationLevel } from "../../../core/models/validation.model";
import {StepperModule} from "primeng/stepper";
import {AccordionModule} from "primeng/accordion";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {PaginatorModule} from "primeng/paginator";
import {PickListModule} from "primeng/picklist";
import {DragDropModule} from "primeng/dragdrop";
import {Button} from "primeng/button";
import {NgIf} from "@angular/common";
import {CheckboxModule} from "primeng/checkbox";

@Component({
  selector: 'edit-validation',
  standalone: true,
  imports: [
    // Modules
    StepperModule, AccordionModule, InputTextModule, InputTextareaModule, PaginatorModule,
    ReactiveFormsModule, PickListModule, DragDropModule, CheckboxModule,
    // Pipes & Directives
    Button, NgIf,
  ],
  templateUrl: './edit-validation.component.html',
  styleUrl: './edit-validation.component.scss'
})
export class EditValidationComponent implements OnInit {
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  isEditMode = false;
  existingValidationLevels: IValidationLevel[] = [];

  formGroup = new FormGroup({
    product: new FormControl<IProduct | null>(null, { nonNullable: true, validators: [Validators.required] }),
    numberOfValidation: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
    isRequired: new FormControl<boolean>(true, { nonNullable: true, validators: [Validators.required] }),
    orderAbsolute: new FormControl<boolean>(true, { nonNullable: true, validators: [Validators.required] }),
  });

  sort: Sort = { direction: Direction.DESC, orders: [{ property: 'code', ignoreCase: false }] };
  groupedProducts: SelectItemGroup[] = [];
  selectedGroups: IGroup[] = [];
  groups: IGroup[] = [];

  constructor(
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef,
    private readonly groupService: GroupService,
    private readonly validationService: ValidationService,
    private readonly productService: ProductService,
    private readonly tokenManager: StorageService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    this.isEditMode = !!this.boundData?.data;

    const jwt = this.tokenManager.getToken();
    if (this.isEditMode && this.boundData?.data) {
      const validation = this.boundData.data as IValidation;
      this.validationService.findValidationByProductIdAndInstitutionId(jwt.institution_id, validation.product.id)
        .subscribe({
          next: (validation) => {
            this.formGroup.patchValue({
              product: validation.product,
              numberOfValidation: validation.numberOfValidation,
              isRequired: validation.isRequired,
              orderAbsolute: validation.orderAbsolute
            });
            this.existingValidationLevels = validation.validationLevels;
            this.selectedGroups = validation.validationLevels
              .sort((a, b) => a.level - b.level)
              .map(level => level.group);
          },
          error: (err) => this.toastService.showToast('Erreur lors du chargement de la validation', err, 'error')
        });
    }
    if (this.boundData?.isView) this.formGroup.disable();
    this.loadProducts(jwt.institution_id);
    this.loadGroups(jwt.institution_id);
  }

  private loadProducts(institutionId: string) {
    this.productService.getProductsForInstitution(institutionId, getUnpagedPageable(this.sort))
      .subscribe({
        next: (page) => {
          const groupedProductsMap = new Map<string, SelectItemGroup>();
          page.content.forEach(product => {
            if (!groupedProductsMap.has(product.module.code)) {
              groupedProductsMap.set(product.module.code, {
                label: product.module.description,
                value: product.module.code,
                items: []
              });
            }
            const group = groupedProductsMap.get(product.module.code);
            if (group) group.items.push({ label: product.description, value: product });
          });
          this.groupedProducts = Array.from(groupedProductsMap.values());
        },
        error: (err) => this.toastService.showToast('Erreur lors de la capture des produits', err, 'error')
      });
  }

  private loadGroups(institutionId: string) {
    this.groupService.getGroupsByInstitutionId(institutionId, getUnpagedPageable())
      .subscribe({
        next: (page) => this.groups = page.content,
        error: (err) => this.toastService.showToast('Erreur lors de la capture des groupes', err, 'error')
      });
  }

  save() {
    if (!this.formGroup.valid) return;

    const jwt = this.tokenManager.getToken();
    const validation = this.formGroup.value as IValidation;

    // Preserve existing validation level IDs when updating
    validation.validationLevels = this.selectedGroups.map((group, index) => {
      const existingLevel = this.existingValidationLevels.find(level => level.group.id === group.id);
      return {
        id: existingLevel?.id,
        level: index,
        group: group
      } as IValidationLevel;
    });

    const request$ = this.isEditMode ?
      this.validationService.updateValidation(jwt.institution_id, validation.product.id, validation) :
      this.validationService.createValidation(validation);

    request$.subscribe({
      next: () => {
        this.toastService.showToast(
          this.isEditMode ? 'Validation modifiée' : 'Validation créée',
          this.isEditMode ? 'La validation a été modifiée avec succès' : 'La validation a été créée avec succès'
        );
        this.ref.close(true);
      },
      error: (err) => this.toastService.showToast(
        this.isEditMode ? 'Modification de la validation' : 'Création de la validation',
        err.error,
        'error'
      )
    });
  }
}
