import { Component } from '@angular/core';
import {ChartModule} from "primeng/chart";
import {ChartData, ChartDataset, ChartOptions} from "chart.js";
import {NgForOf} from "@angular/common";
import {ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {PaginatorState} from "primeng/paginator";
import {TabViewModule} from "primeng/tabview";

@Component({
  selector: 'app-intraday',
  standalone: true,
  imports: [
    ChartModule,
    NgForOf,
    ButtonDirective,
    DynamicTableComponent,
    TabViewModule
  ],
  templateUrl: './intraday.component.html',
  styleUrl: './intraday.component.scss'
})
export class IntradayComponent {

  activeTabIndex: number = 0;
  intraday: any;
  pageable: PageParams = { page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50] };
  actions: CustomAction[] = [];
  columnsAdvance: CustomTableHeader[] = [
    { key: 'status', column: 'Statut', type: 'text' },
    { key: 'time', column: 'Heure', type: 'text' },
    { key: 'amount', column: 'Montant(Milliards)', type: 'text' },
    { key: 'rate', column: 'Taux', type: 'text' },
  ];

  columnsHistory: CustomTableHeader[] = [
    { key: 'date', column: 'Date', type: 'text' },
    { key: 'amount', column: 'Montant(Milliards)', type: 'text' },
    { key: 'rate', column: 'Taux', type: 'text' },
    { key: 'trader', column: 'Trader', type: 'text' },
    { key: 'status', column: 'Statut', type: 'text' },
  ];

  data: ChartData<'line'> = {
    labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10', 'Day 11', 'Day 12'],
    datasets: [
      {
        data: [10, -20, 30, -40, 50, -60, 25, 50, -14, 30, -50, 10],
        borderColor: '#317AC1',
        fill: false,
        stepped: true as true | false | 'before' | 'after' | 'middle'
      } as ChartDataset<'line'>
    ]
  };

  options: ChartOptions<'line'> = {
    responsive: true,
    interaction: {
      intersect: false,
      axis: 'x'
    },
    plugins: {
      title: {
        display: true,
        text: 'Evolution des taux de la BCEAO',
        position: 'bottom'
      }
    }
  };





  data2: ChartData<'line'> = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        data: this.generateRandomData(12),
        borderColor: '#F27438',
      }
    ]
  };

  options2: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Evolution des volumes échangés sur le marché',
        position: 'bottom'
      }
    }
  };

  data3: ChartData<'line'> = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        data: this.generateRandomData(12),
        borderColor: '#A7001E',
      }
    ]
  };

  options3: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'

      },
      title: {
        display: true,
        text: 'Evolution de nos encours',
        position: 'bottom'
      },
    }
  };

  data4: ChartData<'bar'> = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        data: this.generateRandomData(12),
        backgroundColor: '#8FB43A',
      },
    ]
  };

  options4: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Historique journalier des avances intraday',
        position: 'bottom'
      }
    }
  };

  data5: ChartData<'line'> = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'Encours',
        data: Array(12).fill(15),
        borderColor: '#A7001E',
        borderWidth: 2,
        fill: false,
      }
    ]
  };

  options5: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: false,
      },
      x: {
        ticks: {
          autoSkip: false,
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Suivi des avances',
        position: 'bottom'
      },
    }
  };

  private generateRandomData(count: number): number[] {
    return Array.from({ length: count }, () => Math.floor(Math.random() * 200 - 100));
  }

  onPageChange = (event: PaginatorState) => {
    this.loadIntraday(getDefaultPageable(event.page, event.rows));
  }

  private loadIntraday(defaultPageable: Pageable) {
  }

  addNew() {
  }
}
