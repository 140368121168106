import {Component, OnDestroy, OnInit} from '@angular/core';
import {ForecastCashFlowService} from "../../../../../core/services/forecast-cash-flow.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {UploadForecastComponent} from "./upload/upload-forecast.component";
import {customDynamicDialog, getFilenameFromHeader} from "../../../../../core/utils/utils";
import {take} from "rxjs";
import {CustomAction, CustomTableHeader} from "../../../../../core/models/custom-table.model";
import {
  DEFAULT_PAGE_PARAMS,
  getDefaultPageable,
  Pageable,
  PageParams,
  VALUE_DATE_SORT_ASC
} from "../../../../../core/models/page.model";
import {IForecastCashFlow} from "../../../../../core/models/forecast-cash-flow.model";
import {ToolbarComponent} from "../../../../../shared/components/toolbar/toolbar.component";
import {DynamicTableComponent} from "../../../../../shared/components/dynamic-table/dynamic-table.component";
import {PaginatorState} from "primeng/paginator";
import {ExportComponent, ExportData} from "../../../../../shared/components/export.component";
import {SearchComponent} from "../../../../../shared/components/search.component";
import {Button} from "primeng/button";
import {Router} from "@angular/router";

@Component({
  selector: 'app-operations',
  standalone: true,
  imports: [
    // Components
    ToolbarComponent, DynamicTableComponent, ExportComponent, SearchComponent,
    // Pipes & Directives
    Button
  ],
  templateUrl: './previsionnel-operations.component.html',
  styleUrl: './previsionnel-operations.component.scss'
})
export class PrevisionnelOperationsComponent implements OnInit, OnDestroy {

  operations: IForecastCashFlow[] = [];
  columns: CustomTableHeader[] = [
    { key: 'category', column: 'Catégorie', type: 'text' },
    { key: 'type', column: 'Type', type: 'text' },
    { key: 'libelle', column: 'Libelle', type: 'text' },
    { key: 'amount', column: 'Montant', type: 'currency', currencyKey: 'currency.code' },
    { key: 'operationDate', column: 'Date opération', type: 'date' },
    { key: 'valueDate', column: 'Date valeur', type: 'date' }
  ];
  pageable: PageParams = DEFAULT_PAGE_PARAMS;
  ref: DynamicDialogRef | undefined;
  toolbarActions: CustomAction[] = [];
  query: string = '';
  isLocal: boolean = false;

  constructor(
    private readonly forecastService: ForecastCashFlowService,
    private readonly toastService: ToastService,
    public dialogService: DialogService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    if (this.router.url.includes('locale')) this.isLocal = true;
    this.toolbarActions = this.getToolbarActions();
    this.loadForecastCashFlow();
  }

  ngOnDestroy() {
    this.ref?.close();
  }


  getToolbarActions(): CustomAction[] {
    return [
      {
        role: 'READ_OPERATION',
        icon: 'pi pi-download',
        label: 'Charger',
        severity: 'secondary',
        emit: () => this.uploadOperation(),
        visible: true
      },
    ];
  }

  uploadOperation() {
    const options = { position: 'center', width: '75vw', height: '75vh' };
    this.ref = this.dialogService.open(UploadForecastComponent, customDynamicDialog('Chargement d\'opérations futures', {}, options));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadForecastCashFlow());
  }

  onPageChange($event: PaginatorState) {
    this.loadForecastCashFlow(this.query, getDefaultPageable($event.page, $event.rows, VALUE_DATE_SORT_ASC));
  }

  private loadForecastCashFlow(query: string = this.query, pageable: Pageable = getDefaultPageable(0, 10, VALUE_DATE_SORT_ASC)) {
    this.forecastService.search(query, this.isLocal, pageable).subscribe({
      next: (page) => {
        this.operations = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: this.pageable.rowPerPageOptions
        };
      },
      error: (err) => this.toastService.showToast('Chargement des opérations futures', err.error, 'error')
    });
  }

  onSearch($event: string) {
    this.query = $event;
    this.loadForecastCashFlow(this.query, getDefaultPageable(this.pageable.page, this.pageable.rows, VALUE_DATE_SORT_ASC));
  }

  exportOperation($event: ExportData) {
    if ($event.dates?.[0] == null || $event.dates?.[1] == null) {
      this.toastService.showToast('Export opérations', 'Veuillez renseigner les dates de début et de fin.', 'error');
      return;
    }
    this.forecastService.exportForecastCashFlows($event.dates![0], $event.dates![1], $event.extension == 'excel' )
      .subscribe({
        next: response => {
          const blob = response.body;
          const filename = getFilenameFromHeader(response);

          if (blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename || `releve_operations_pervisionnelles.xlsx`;
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            this.toastService.showToast('Export des soldes', 'Une erreur est survenue lors de l\'export des soldes', 'error');
          }
        },
        error: err => this.toastService.showToast('Export des soldes', err.error, 'error')
      });
  }
}
