import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CustomDialogData} from "../../../../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../../../../core/services/toast.service";
import {IReserve, IReserveDeclaration} from "../../../../../../core/models/reserve.model";
import {ReserveService} from "../../../../../../core/services/reserve.service";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {PaginatorModule} from "primeng/paginator";
import {CalendarModule} from "primeng/calendar";
import {MessageModule} from "primeng/message";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-reserve-details',
  standalone: true,
  imports: [
    InputTextModule,
    InputTextareaModule,
    PaginatorModule,
    ReactiveFormsModule,
    CalendarModule,
    MessageModule,
    NgIf
  ],
  templateUrl: './reserve-details.component.html',
  styleUrl: './reserve-details.component.scss'
})
export class ReserveDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    amount: new FormControl<number>(0, {nonNullable: true, validators: [Validators.required]}),
    reserveAmount: new FormControl<number>(0),
    date: new FormControl<Date[]>([], {nonNullable: true, validators: [Validators.required]}),
  });
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  reserve: IReserve = {} as IReserve;
  today: Date = new Date();

  constructor(
    private readonly reserveService: ReserveService,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    this.reserve = this.boundData?.data || {} as IReserve;
    if (!this.boundData?.isCreate) this.formGroup.patchValue(this.boundData?.data);
    if (this.boundData?.isView) this.formGroup.disable();
  }



  create() {
    const areDatesValid = this.formGroup.get('date')?.value.length == 2 && this.formGroup.get('date') != null;
    if (!areDatesValid) {
      this.toastService.showToast('Erreur', 'Veuillez renseigner les dates de début et de fin', 'error');
      return;
    }
    const reserve = {
      amount: this.formGroup.value.amount,
      reserveAmount: this.formGroup.value.reserveAmount,
      startDate: this.formGroup.get('date')!.value[0],
      endDate: this.formGroup.get('date')!.value[1],
    } as IReserveDeclaration;
    const summary = 'Nouvelle entrée de réserve';
    this.reserveService.saveNewMandatoryReserve(reserve).subscribe({
      next: () => {
        this.toastService.showToast(summary, `La nouvelle entrée de réserve obligatoire a été enregistré avec succès.`);
        this.ref.close();
      },
      error: (err) => this.toastService.showToast(summary, err.error, 'error')
    });
  }

}
