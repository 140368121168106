<div class="flex mb-4 flex-column w-full h-full align-content-between">

  <div class="chart-container">
    <div class="card">
      <p-chart [data]="data" [options]="options" type="line"></p-chart>
    </div>
    <div class="card">
      <p-chart [data]="dataTWO" [options]="optionsTWO" type="bar"></p-chart>
    </div>
  </div>
  <div class="flex align-items-start justify-content-between mb-4 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">Titres en achat</h3>
    </div>
    <div class="flex-grow-1 flex gap-2 justify-content-end">
      <button (click)="createTitleSecondary()" icon="pi pi-plus" label="Nouveau" pButton type="button"></button>
    </div>
  </div>
  <dynamic-table (paginate)="onPageChange($event)" [data]="operationsTitres" [headers]="columns"
                 [pageParams]="pageable"></dynamic-table>
</div>
