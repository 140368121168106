<div class="flex flex-column w-full h-full align-content-between">

  <div class="flex align-items-start justify-content-between mb-2 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">Limites appliquées aux titres</h3>
    </div>
    <div class="flex-grow-1 flex gap-2 justify-content-end">
      <button (click)="addLimit()"  icon="pi pi-plus" label="Nouveau" pButton type="button"></button>
    </div>
  </div>
  <dynamic-table (paginate)="onPageChange($event)" [data]="limits" [headers]="columns"
                 [actions]="actions" [pageParams]="pageable"></dynamic-table>

</div>
