<div class="flex flex-column history-container">

  <p>Historique des simulations</p>

  <div class="card flex flex-row justify-content-between align-items-center">

    <div class="flex flex-row gap-2 simulation-results-buttons simulation-start-button">
      <div class="submit-result">
        <p-button (onClick)="createSubmissionFromSimulation()" styleClass="p-button-alm-primary" [disabled]="!selectedSimulations.length" icon="pi pi-check" label="Valider résultat(s)"></p-button>
      </div>
      <p-button (onClick)="cancalSimulations()" [disabled]="!selectedSimulations.length" icon="pi pi-trash" label="Annuler résultat(s)" outlined="true" severity="warning"></p-button>
    </div>
    <div class="flex flex-row gap-2">
      <div class="flex flex-column">
        <p-floatLabel>
          <p-dropdown (onChange)="onFilterChange($event.value)"
                      [ngClass]="'custom-dropdown'"
                      [(ngModel)]="selectedStatus"
                      [options]="statusOptions"
          >
          </p-dropdown>
          <label for="status">Filtrer par statut</label>
        </p-floatLabel>
      </div>
      <!-- Country Filter Dropdown -->
      <p-floatLabel>

        <p-dropdown (onChange)="onFilterChange($event.value)"
                    [ngClass]="'custom-dropdown'"
                    [(ngModel)]="selectedCountry"
                    [options]="countryOptions"
                    optionLabel="country"
                    optionValue="code">
        </p-dropdown>
        <label for="country">Filter par pays</label>
      </p-floatLabel>
    </div>


  </div>
  <div class="history-table">
    <dynamic-table (paginate)="onPageChange($event)" (selectToggle)="onRowSelected($event)" [actions]="actions"
                   [data]="filteredSimulations" [headers]="columns" [pageParams]="pageable">

    </dynamic-table>
  </div>
</div>

