import {Component, OnDestroy, OnInit} from '@angular/core';
import {IUser} from "../../core/models/user.model";
import {getDefaultPageable, Pageable, PageParams} from "../../core/models/page.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {UserService} from "../../core/services/user.service";
import {ToastService} from "../../core/services/toast.service";
import {customConfirmationDialog, customDynamicDialog, DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../core/utils/utils";
import {CustomDialogData} from "../../core/models/custom-dialog.model";
import {UserDetailsComponent} from "./user-details/user-details.component";
import {take} from "rxjs";
import {PaginatorState} from "primeng/paginator";
import {DynamicTableComponent} from "../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../core/models/custom-table.model";
import {StorageService} from "../../core/services/storage.service";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [DynamicTableComponent, ToolbarComponent],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnInit, OnDestroy {

  users: IUser[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'lastname',
      column: 'Nom',
      type: 'text'
    },
    {
      key: 'firstname',
      column: 'Prénom',
      type: 'text'
    },
    {
      key: 'phoneNumber',
      column: 'Téléphone',
      type: 'text'
    },
    {
      key: 'email',
      column: 'Email',
      type: 'text'
    },
    {
      key: 'group.name',
      column: 'Groupe',
      type: 'text'
    },
    {
      key: 'enabled',
      column: 'Status',
      type: 'boolean'
    }
  ]
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];

  constructor(
    private userService: UserService,
    private toastService: ToastService,
    public dialogService: DialogService,
    private tokenManagerService: StorageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadUsers();
    this.actions = this.getActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  loadUsers(pageable: Pageable = getDefaultPageable()) {
    const jwt = this.tokenManagerService.getToken();
    this.userService.getUsersByInstitution(jwt.institution_id, pageable).subscribe({
      next: page => {
        this.users = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: err => {
        this.toastService.showToast('Erreur lors du chargement des users', err, 'error');
      }
    })
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_USER',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item) => this.viewOrEditUser(item),
        visible: true
      },
      {
        role: 'UPDATE_USER',
        icon: 'pi pi-pen-to-square',
        label: 'Éditer',
        severity: 'primary',
        emit: (item) => this.viewOrEditUser(item, true),
        visible: true
      },
      {
        role: 'UPDATE_USER',
        icon: 'pi pi-lock-open',
        label: 'Activer',
        severity: 'primary',
        emit: (item) => this.toggleUser(item),
        visible: (item: IUser) => !item.enabled
      },
      {
        role: 'UPDATE_USER',
        icon: 'pi pi-lock',
        label: 'Désactiver',
        severity: 'primary',
        emit: (item) => this.toggleUser(item),
        visible: (item: IUser) => item.enabled
      },
      {
        role: 'DELETE_USER',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.confirmDeletion(item),
        visible: true
      }
    ];
  }

  createUser() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(UserDetailsComponent, customDynamicDialog('Création d\'user', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadUsers());
  }

  viewOrEditUser(user: IUser, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: user} as CustomDialogData;
    this.ref = this.dialogService.open(UserDetailsComponent, customDynamicDialog('Détails d\'user', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadUsers());
  }

  confirmDeletion(user: IUser) {
    const message = `Êtes-vous sûr de vouloir supprimer l'utilisateur ${user.firstname} ${user.lastname} ?`;
    const header = 'Suppression de l\'utilisateur';
    const options = customConfirmationDialog(header, message, { accept: () => this.deleteUser(user) });
    this.confirmationService.confirm(options);
  }

  deleteUser(user: IUser) {
    this.userService.deleteUser(user.id).subscribe({
      next: () => {
        this.toastService.showToast('User supprimée', `L'utilisateur ${user.firstname} ${user.lastname} a été supprimée avec succès.`);
        this.loadUsers();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la suppression de l\'user', err, 'error');
      }
    });
  }

  toggleUser(user: IUser) {
    this.userService.toggleUser(user.id).subscribe({
      next: () => {
        this.toastService.showToast('User modifié', `L'utilisateur ${user.firstname} ${user.lastname} a été modifié avec succès.`);
        this.loadUsers();
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la modification de l\'user', err, 'error');
      }
    });
  }

  onPageChange = (event: PaginatorState) => this.loadUsers(getDefaultPageable(event.page, event.rows));

}
