import { Component, OnInit } from '@angular/core';
import { CustomTableHeader } from "../../../../../core/models/custom-table.model";
import { IReserve } from "../../../../../core/models/reserve.model";
import { DEFAULT_PAGE_PARAMS, getDefaultPageable, Pageable, PageParams } from "../../../../../core/models/page.model";
import {PaginatorModule, PaginatorState} from "primeng/paginator";
import { DynamicTableComponent } from "../../../../../shared/components/dynamic-table/dynamic-table.component";
import { DateRangeComponent } from "../../../../../shared/date-range/date-range.component";
import {ExportComponent, ExportData} from "../../../../../shared/components/export.component";
import { ReserveService } from "../../../../../core/services/reserve.service";
import { ToastService } from "../../../../../core/services/toast.service";
import {Button} from "primeng/button";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {ReserveDetailsComponent} from "./reserve-details/reserve-details.component";
import {customDynamicDialog, getFilenameFromHeader} from "../../../../../core/utils/utils";
import {take} from "rxjs";
import {DialogService} from "primeng/dynamicdialog";

@Component({
  selector: 'app-reserve-table',
  standalone: true,
  imports: [
    DynamicTableComponent,
    DateRangeComponent,
    ExportComponent,
    PaginatorModule,
    Button
  ],
  template: `
    <div class="flex flex-column gap-2">
      <div class="flex justify-content-between mb-4">
        <div class="flex gap-2">
          <date-range (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)"/>
        </div>
      </div>
      <dynamic-table [data]="reserves" [headers]="columns" [pageParams]="pageParams" (paginate)="onPageChange($event)"/>
    </div>
  `
})
export class ReserveTableComponent implements OnInit {
  reserves: IReserve[] = [];
  columns: CustomTableHeader[] = [
    { key: 'date', column: 'Date', type: 'date' },
    { key: 'balance', column: 'Solde du jour', type: 'currency' },
    { key: 'amount', column: 'Réserve obligatoire', type: 'currency' },
    { key: 'reserveAmount', column: 'Réserve constituée', type: 'currency' }
  ];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  rangeDates: Date[] | null = null;
  timeGroupings = [
    { label: 'Hebdomadaire', value: 'week' },
    { label: 'Mensuel', value: 'month' },
    { label: 'Trimestriel', value: 'quarter' },
    { label: 'Annuel', value: 'year' }
  ];
  selectedTimeGrouping: 'day' | 'week' | 'biweekly' | 'month' | 'quarter' | 'year' = 'week';

  constructor(
    private readonly reserveService: ReserveService,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService
  ) {}

  ngOnInit() {
    this.loadReserve();
  }

  loadReserve(page: Pageable = getDefaultPageable(this.pageParams.page, this.pageParams.rows)) {
    const apiCall = this.rangeDates?.length == 2
      ? this.reserveService.findForCurrentUser(page, this.rangeDates[0], this.rangeDates[1])
      : this.reserveService.findForCurrentUser(page);

    apiCall.subscribe({
      next: (page) => {
        this.reserves = page.content;
        this.pageParams = {
          ...this.pageParams,
          totalRecords: page.totalElements
        };
      },
      error: (err) => this.toastService.showToast('Historique des réserves obligatoires', err.error, 'error')
    });
  }

  onDateRangeSelected(dateRange: Date[]) {
    this.rangeDates = dateRange;
    this.loadReserve();
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Historique des soldes', message, 'error');
  }

  onPageChange($event: PaginatorState) {
    this.pageParams = {
      ...this.pageParams,
      page: $event.page!,
      rows: $event.rows!
    }
    this.loadReserve(getDefaultPageable($event.page, $event.rows));
  }


}
