import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, catchError, map, Observable, throwError} from "rxjs";
import {Page} from "../models/page.model";
import {PrimaireEmissionModel} from "../models/primaire.emission.model";
import {take} from "rxjs/operators";
import {EmissionWithTitresDTO} from "../models/emission.model";
import {TitresDTO} from "../models/titres.model";

@Injectable({
  providedIn: 'root',
})
export class EmissionPrimaireService {
  private readonly baseUrl = 'income/v1/emission';

  constructor(private readonly http: HttpClient) {}

  getAllEmissionsWithTitres(page: number, size: number, isPaged: boolean = true): Observable<Page<EmissionWithTitresDTO>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<EmissionWithTitresDTO>>(`${this.baseUrl}/with-details`, { params });
  }

  getAllEmissions(page: number, size: number, isPaged: boolean = true): Observable<Page<EmissionWithTitresDTO>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<EmissionWithTitresDTO>>(this.baseUrl, { params });
  }

  getAllDetailsEmission(page: number, size: number, isPaged: boolean = true): Observable<Page<TitresDTO>> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<TitresDTO>>(`${this.baseUrl}/details`, { params });
  }

  getAllTitres(page: number, size: number, isPaged: boolean = true, typeInstrument?: string): Observable<Page<TitresDTO>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    if (typeInstrument) {
      params = params.set('typeInstrument', typeInstrument);
    }

    return this.http.get<Page<TitresDTO>>(`${this.baseUrl}/details`, { params });
  }

  createEmission(emissionDto: PrimaireEmissionModel): Observable<any> {
    return this.http.post<any>(this.baseUrl, emissionDto).pipe(take(1));
  }

  addTitreToEmission(emissionId: string, titreDto: TitresDTO): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${emissionId}/titres`, titreDto).pipe(
      take(1)
    );
  }

  getEmissionPrimaireById(id: string): Observable<EmissionWithTitresDTO> {
    return this.http.get<EmissionWithTitresDTO>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  delete(id: string, isDetails: boolean = false): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`, {
      params: { isDetails: isDetails.toString() }
    }).pipe(take(1));
  }


  public importEmission(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${this.baseUrl}/upload`, formData, { responseType: 'text' }).pipe(
      take(1),
      map(response => {
        try {
          return JSON.parse(response);
        } catch (e) {
          throw new Error('La réponse du serveur n\'est pas au format JSON attendu.');
        }
      }),
      catchError(error => {
        if (error.status === 400) {
          return throwError(() => new Error('Format de fichier invalide. Veuillez vérifier votre fichier.'));
        }
        return throwError(() => error);
      })
    );
  }
}
