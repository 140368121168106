// services/permission.service.ts

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IPermission} from "../models/permission.model";

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private baseUrl = 'core/v1/permissions';

  constructor(private http: HttpClient) {}

  createPermission(permissionDto: IPermission): Observable<IPermission> {
    return this.http.post<IPermission>(this.baseUrl, permissionDto).pipe(take(1));
  }

  updatePermission(id: string, permissionDto: IPermission): Observable<IPermission> {
    return this.http.put<IPermission>(`${this.baseUrl}/${id}`, permissionDto).pipe(take(1));
  }

  deletePermission(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  restorePermission(id: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${id}/restore`, {}).pipe(take(1));
  }

  getPermissionById(id: string): Observable<IPermission> {
    return this.http.get<IPermission>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getPermissionByCode(code: string): Observable<IPermission> {
    return this.http.get<IPermission>(`${this.baseUrl}/code/${code}`).pipe(take(1));
  }

  getAllPermissions(pageable: Pageable): Observable<Page<IPermission>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IPermission>>(this.baseUrl, { params }).pipe(take(1));
  }

  getPermissionsByInstitutionType(type: string, pageable: Pageable): Observable<Page<IPermission>> {
    const params = getPageableAsParams(pageable).append('type', type);
    return this.http.get<Page<IPermission>>(`${this.baseUrl}/type`, { params }).pipe(take(1));
  }

  getPermissionsByModule(module: string, pageable: Pageable): Observable<Page<IPermission>> {
    const params = getPageableAsParams(pageable).append('module', module);
    return this.http.get<Page<IPermission>>(`${this.baseUrl}/module`, { params }).pipe(take(1));
  }
}
