import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, map, Observable, throwError} from "rxjs";
import {OperationsTitresModel} from "../models/operationsTitres.model";
import {Page} from "../models/page.model";
import {TitresWithOperationsModel} from "../models/titres-with-operations.model";
import {take} from "rxjs/operators";
import {VolumeResponseDTO} from "../models/volumeResponseDTO";
import {VolumeWalletDTO} from "../models/volumeWalletDTO";
import {VolumeWalletDurationDTO} from "../models/VolumeWalletDurationDTO";

@Injectable({
  providedIn: 'root',
})

export class OperationsTitresService {
  private baseUrl = 'income/v1/operationsTitres';

  constructor(private http: HttpClient) {}

  registerOperationsTitres(titresId: string, dto: OperationsTitresModel): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${titresId}`, dto);
  }

  getAllOperationsWihtTitres(page: number, size: number, isPaged: boolean = true, sens?: string, account?: string): Observable<Page<TitresWithOperationsModel>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    if (sens) {
      params = params.set('sens', sens);
    }
    if (account) {
      params = params.set('account', account);
    }

    return this.http.get<Page<TitresWithOperationsModel>>(this.baseUrl, { params });
  }

  public importOperationsTitres(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${this.baseUrl}/import`, formData, { responseType: 'text' }).pipe(
      take(1),
      map(response => {
        try {
          return JSON.parse(response);
        } catch (e) {
          console.error(response);
          throw new Error('La réponse du serveur n\'est pas au format JSON attendu.');
        }
      }),
      catchError(error => {
        if (error.status === 400) {
          return throwError(() => new Error('Format de fichier invalide. Veuillez vérifier votre fichier.'));
        }
        return throwError(() => error);
      })
    );
  }

  getVolumesByPeriod(startDate: string, endDate: string): Observable<VolumeResponseDTO[]> {
    return this.http.get<VolumeResponseDTO[]>(`${this.baseUrl}/volumes`, {
      params: { startDate, endDate }
    });
  }

  getVolumesWalletByPeriod(startDate: string, endDate: string): Observable<VolumeWalletDTO[]> {
    return this.http.get<VolumeWalletDTO[]>(`${this.baseUrl}/volumesWallet`, {
      params: { startDate, endDate }
    });
  }

  getVolumesWalletByDuration(startDate: string, endDate: string): Observable<VolumeWalletDurationDTO[]> {
    return this.http.get<VolumeWalletDurationDTO[]>(`${this.baseUrl}/duration`, {
      params: { startDate, endDate }
    });
  }


}
