<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label class="font-medium">ISIN</label>
      <p-dropdown
        [options]="numberWicket"
        optionLabel="label"
        optionValue="value"
        formControlName="number"
        placeholder="Sélectionnez un numéro">
      </p-dropdown>
      <small id="isin-help">Veuillez sélectionner un numéro.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Montant retenu</label>
      <input type="number"  pInputText id="retainedAmount" aria-describedby="retainedAmount-help" formControlName="retainedAmount" />
      <small  id="retainedAmount-help">Renseigner ici le montant retenu.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Montant proposé</label>
      <input type="number"  pInputText id="proposedAmount" aria-describedby="proposedAmount-help" formControlName="proposedAmount" />
      <small  id="proposedAmount-help">Renseigner ici le montant proposé.</small>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end justify-content-end ">
    <p-button
      class="my-4"
      pRipple
      [style]="{width: '100%'}"
      [label]="'Enregistrer'"
      [disabled]="!formGroup.valid || isLoading"
      [loading]="isLoading"
      (onClick)=createResultWicketOperation()
    ></p-button>
  </div>
</div>
