import { Component, OnInit } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { DateRangeComponent } from "../../../../../shared/date-range/date-range.component";
import { GenericLineChartComponent, LineChartDataset } from "../../../../../shared/charts/line-chart.component";
import { ReserveService } from "../../../../../core/services/reserve.service";
import { ToastService } from "../../../../../core/services/toast.service";
import { getUnpagedPageable } from "../../../../../core/models/page.model";
import { IReserve } from "../../../../../core/models/reserve.model";

@Component({
  selector: 'app-reserve-chart',
  standalone: true,
  imports: [
    FormsModule,
    DropdownModule,
    DateRangeComponent,
    GenericLineChartComponent
  ],
  template: `
    <div class="flex flex-column gap-4 w-full align-items-center">
      <div class="w-full flex justify-content-between align-items-center">
        <date-range
          (dateRangeSelected)="onDateRangeSelected($event)"
          (invalidRange)="onInvalidRange($event)">
        </date-range>

        <p-dropdown
          [options]="timeGroupings"
          [(ngModel)]="selectedTimeGrouping"
          (onChange)="onTimeGroupingChange()"
          optionLabel="label"
          optionValue="value">
        </p-dropdown>
      </div>
      <line-chart class="w-9" [title]="''" [datasets]="chartDatasets" [xAxisType]="'time'"
                  [xAxisTimeUnit]="selectedTimeGrouping" [yAxisLabel]="'Montant'" [currency]="'XOF'" />
    </div>
  `
})
export class ReserveChartComponent implements OnInit {
  chartDatasets: LineChartDataset[] = [];
  timeGroupings = [
    { label: 'Hebdomadaire', value: 'week' },
    { label: 'Mensuel', value: 'month' },
    { label: 'Trimestriel', value: 'quarter' },
    { label: 'Annuel', value: 'year' }
  ];
  selectedTimeGrouping: 'day' | 'week' | 'biweekly' | 'month' | 'quarter' | 'year' = 'week';
  rangeDates: Date[] | null = null;
  reserves: IReserve[] = [];

  constructor(
    private readonly reserveService: ReserveService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const pageable = getUnpagedPageable();
    const apiCall = this.rangeDates?.length == 2
      ? this.reserveService.findForCurrentUser(pageable, this.rangeDates[0], this.rangeDates[1])
      : this.reserveService.findForCurrentUser(pageable);

    apiCall.subscribe({
      next: (page) => {
        this.reserves = page.content;
        this.prepareChartData();
      },
      error: (err) => this.toastService.showToast('Historique des réserves obligatoires', err.error, 'error')
    });
  }

  prepareChartData() {
    const amountData = this.groupDataByTime(
      this.reserves.map(reserve => ({
        date: new Date(reserve.date),
        value: reserve.amount
      }))
    );

    const reserveAmountData = this.groupDataByTime(
      this.reserves.map(reserve => ({
        date: new Date(reserve.date),
        value: reserve.reserveAmount
      }))
    );

    this.chartDatasets = [
      {
        label: 'Réserve obligatoire',
        data: amountData,
        color: '#FF6384'
      },
      {
        label: 'Réserve constituée',
        data: reserveAmountData,
        color: '#36A2EB'
      }
    ];
  }

  groupDataByTime(data: Array<{ date: Date; value: number }>): Array<{ x: Date; y: number }> {
    const groupedData: { [key: string]: { sum: number, count: number } } = {};

    data.forEach(item => {
      const groupDate = this.getGroupDate(item.date);
      const key = groupDate.toISOString();

      if (!groupedData[key]) {
        groupedData[key] = { sum: 0, count: 0 };
      }
      groupedData[key].sum += item.value;
      groupedData[key].count++;
    });

    return Object.entries(groupedData)
      .map(([dateString, { sum, count }]) => ({
        x: new Date(dateString),
        y: sum / count  // average
      }))
      .sort((a, b) => a.x.getTime() - b.x.getTime());
  }

  getGroupDate(date: Date): Date {
    const d = new Date(date);
    switch (this.selectedTimeGrouping) {
      case 'week':
        d.setDate(d.getDate() - d.getDay() + 1);  // Start of week (Monday)
        break;
      case 'biweekly':
        d.setDate(d.getDate() <= 15 ? 1 : 16);
        break;
      case 'month':
        d.setDate(1);
        break;
      case 'quarter': {
        const quarter = Math.floor(d.getMonth() / 3);
        d.setMonth(quarter * 3, 1);
        break;
      }
      case 'year':
        d.setMonth(0, 1);
        break;
    }
    d.setHours(0, 0, 0, 0);
    return d;
  }

  onTimeGroupingChange() {
    this.prepareChartData();
  }

  onDateRangeSelected(dateRange: Date[]) {
    this.rangeDates = dateRange;
    this.loadData();
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Historique des soldes', message, 'error');
  }
}
