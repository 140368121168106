import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IProduct} from "../models/product.model";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private baseUrl = 'core/v1/products';

  constructor(private http: HttpClient) { }

  createProduct(productDto: IProduct): Observable<IProduct> {
    return this.http.post<IProduct>(`${this.baseUrl}`, productDto);
  }

  updateProduct(id: string, productDto: IProduct): Observable<IProduct> {
    return this.http.put<IProduct>(`${this.baseUrl}/${id}`, productDto);
  }

  deleteProduct(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  getProductById(id: string): Observable<IProduct> {
    return this.http.get<IProduct>(`${this.baseUrl}/${id}`);
  }

  getProductByCode(code: string): Observable<IProduct> {
    let params = new HttpParams().set('code', code);
    return this.http.get<IProduct>(`${this.baseUrl}/code`, { params});
  }

  getAllProducts(pageable: Pageable): Observable<Page<IProduct>> {
    let params =  getPageableAsParams(pageable);
    return this.http.get<Page<IProduct>>(this.baseUrl, { params });
  }

  getProductsForModule(module: string, pageable: Pageable): Observable<Page<IProduct>> {
    let params =  getPageableAsParams(pageable).set('module', module)
    return this.http.get<Page<IProduct>>(`${this.baseUrl}/module`, { params });
  }

  getProductsForInstitution(institutionId: string, pageable: Pageable): Observable<Page<IProduct>> {
    let params =  getPageableAsParams(pageable).set('institution', institutionId)
    return this.http.get<Page<IProduct>>(`${this.baseUrl}/institution`, { params });
  }
}
