<div class="flex flex-column">
  <!-- Toolbar Section -->
  <toolbar (search)="search($event)" [title]="''"></toolbar>

  <!-- Main Content Section -->
  <div class="flex flex-column gap-3 mt-3">
    <!-- Accordion for Flow Laws -->
    <p-accordion [activeIndex]="0" collapseIcon="pi pi-minus" expandIcon="pi pi-plus" multiple="true">
      <p-accordionTab *ngFor="let law of flowLaws" [header]="law.name + ' / Code:  ' + law.code">

        <!-- Associations Section -->
        <div class="flex flex-column gap-3 mt-2">
          <div *ngFor="let assoc of getDataForLaw(law)" class="w-full p-2 border-1 border-round border-gray-200 bg-gray-50 ">
            <div class="flex justify-content-between align-items-center">
              <div class="flex flex-column gap-2">
                <!-- Category Information -->
                <div class="flex gap-2 align-items-center">
                  <span class="font-bold">Catégorie :</span>
                  <span>{{ assoc.category.name }}</span>
                </div>
                <div *ngIf="assoc.flowLaw.code" class="flex gap-2 align-items-center">
                  <span class="font-bold">Code :</span>
                  <span>{{ assoc.category.code }}</span>
                </div>
                <div *ngIf="assoc.flowLaw.description" class="flex gap-2 align-items-center">
                  <span class="font-bold bg-orange-50">Description :</span>
                  <span>{{ assoc.category.description ? assoc.category.description : 'Non disponible' }}</span>
                </div>
              </div>
              <div class="flex flex-column align-items-center justify-content-start">
                <span class="font-bold">Date de validité</span>
                <span class="flex gap-2">
                  <span>{{ assoc.startDate | date: 'dd/MM/yyyy' }}</span>
                  <span>-</span>
                  <span>{{ assoc.expiryDate | date: 'dd/MM/yyyy' }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Flow Rates Table -->
        <div class="mt-3">
          <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="[1]" scrollable="true">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col">Mois</th>
                <th scope="col" *ngFor="let flowRate of law.flowRates" class="text-center">{{ flowRate.month }}</th>
              </tr>
            </ng-template>
            <ng-template let-rowData pTemplate="body">
              <tr>
                <td class="font-bold text-center">Taux (%)</td>
                <td *ngFor="let flowRate of law.flowRates" class="text-center">{{ flowRate.rate }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>

  <p-paginator *ngIf="pageParams" (onPageChange)="onPageChange($event)" [first]="pageParams.first" [rows]="pageParams.rows"
               [totalRecords]="pageParams.totalRecords" [rowsPerPageOptions]="pageParams.rowPerPageOptions" />
</div>
