<div class="w-full flex flex-column gap-4 overflow-scroll">
  <p-table styleClass="w-full p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }"
    [sortMode]="'multiple'" [value]="data" [scrollable]="true" responsiveLayout="stack">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" *ngFor="let header of headers" [pSortableColumn]="header.key" [ngStyle]="{'background-color': '#004F78', 'border-right': '1px solid #ddd', 'color': 'white'}">
          <div class="flex justify-content-center align-items-center">
            <span>{{ header.column }}</span>
            <div class="flex justify-content-center align-items-center">
              <p-sortIcon *ngIf="header.sortable" [field]="header.key" pStyleClass="text-white" style="margin-right: 0;"></p-sortIcon>
              <p-columnFilter *ngIf="header.filterable" [field]="header.key" matchMode="contains" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <button pButton pRipple type="button" icon="pi pi-filter" (click)="op.toggle($event); $event.stopPropagation()"
                          class="p-button-text p-button-rounded p-button-sm p-button-plain md:border-left-3"
                          [ngClass]="{'p-button-outlined': value}" style="color: white;">
                  </button>
                  <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '250px'}" styleClass="p-2">
                    <ng-template pTemplate>
                      <div class="p-fluid">
                      <span class="p-input-icon-left w-full">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText [ngModel]="value" (ngModelChange)="filter($event)"
                               placeholder="Rechercher..." class="p-inputtext-sm w-full">
                      </span>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                </ng-template>
              </p-columnFilter>
            </div>
          </div>
        </th>
        <th *ngIf="this.actions.length > 0" scope="col" [ngStyle]="{'background-color': '#004F78', 'color': 'white'}">
          Actions
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td *ngFor="let header of headers" class="truncate text-center" [pTooltip]="header.type == 'text' ? getNestedValue(item, header.key) : ''">
          <ng-container [ngSwitch]="header.type">
            <!-- Case for 'select' to show check/uncheck icons -->
            <ng-container *ngSwitchCase="'select'">
              <div class="flex align-items-center justify-content-center">
                <p-checkbox (ngModelChange)="onRowSelectToggle(data)"
                            [(ngModel)]="item.isSelected"
                            [disabled]="item.status !== SimulationState.EN_ATTENTE" binary="true"></p-checkbox>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'text'">{{ getNestedValue(item, header.key) }}</ng-container>
            <ng-container *ngSwitchCase="'currency'">
              {{ getNestedValue(item, header.key) | currency : (header.currencyKey ? getNestedValue(item, header.currencyKey) : 'XOF') : 'symbol' : '1.0-0' : 'fr-FR' }}
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              {{ getNestedValue(item, header.key) | number:'1.0-0':'fr' }}
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              {{ getNestedValue(item, header.key) | date:'dd/MM/yyyy' }}
            </ng-container>
            <ng-container *ngSwitchCase="'custom'">
              <span *ngIf="header.key === 'region'" class="flex align-items-center">
                <img [src]="getCountryFlag(getNestedValue(data, 'region')) || 'default-flag-url.svg'" alt="{{ getNestedValue(data, 'region') }}" style="width: 20px; height: 15px; margin-right: 10px;" />
                {{ getNestedValue(data, 'region') }}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="'boolean'">
              <i class="pi" [ngClass]="{'pi-check-circle text-green-500': getNestedValue(item, header.key), 'pi-times-circle text-red-500': !getNestedValue(item, header.key)}"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'state'" class="flex align-items-center">
              <span>{{ getNestedValue(data, header.key) }}</span>
              <ng-container [ngSwitch]="getNestedValue(data, header.key)">
                <span *ngSwitchCase="'En attente'" class="flex align-items-center">
                  <i class="pi pi-stopwatch mr-1" style="color: var(--yellow-800)"></i>
                  <span style="color: var(--yellow-800)">{{getNestedValue(data, header.key)}}</span>
                </span>
                <span *ngSwitchCase="'Validé'" class="flex align-items-center">
                  <i class="pi pi-check-circle mr-1" style="color: var(--green-500)"></i>
                  <span style="color: var(--green-500)">{{getNestedValue(data, header.key)}}</span>
                </span>
                <span *ngSwitchCase="'Rejeté'" class="flex align-items-center">
                  <i class="pi pi-times-circle mr-1" style="color: var(--red-500)"></i>
                  <span style="color: var(--red-500)">{{getNestedValue(data, header.key)}}</span>
                </span>
                <span *ngSwitchCase="'Exécuté'" class="flex align-items-center">
                  <i class="pi pi-check-circle mr-1" style="color: var(--green-500)"></i>
                  <span style="color: var(--green-500)">{{getNestedValue(data, header.key)}}</span>
                  <span>Exécuté</span>
                </span>
                <span *ngSwitchCase="'Annulé'" class="flex align-items-center">
                  <i class="pi pi-times-circle mr-1" style="color: var(--red-500)"></i>
                  <span style="color: var(--red-500)">{{getNestedValue(data, header.key)}}</span>
                </span>
                <span *ngSwitchDefault>{{ getNestedValue(data, header.key) }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="this.actions.length > 0">
          <div class="card flex justify-content-center">
            <p-button [rounded]="true" size="small" [text]="true" [outlined]="true" severity="secondary"
                      (onClick)="emit(item, $event, menu);" icon="pi pi-ellipsis-v"
                      [disabled]="getActionsForRow(data).length === 0" />
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator *ngIf="pageParams" (onPageChange)="paginate.emit($event)" [first]="pageParams.first"
               [rows]="pageParams.rows" [totalRecords]="pageParams.totalRecords"
               [rowsPerPageOptions]="pageParams.rowPerPageOptions" />
</div>
<p-menu #menu [popup]="true"></p-menu>
