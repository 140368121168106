<div class="container">
  <p-stepper>
    <p-stepperPanel header="Produit à configurer">
      <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column">
          <div class="border-2 p-4 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
            <div class="h-full flex flex-column gap-2">
              <form [formGroup]="formGroup" class="flex flex-column gap-4 mb-4">
                <div class="flex flex-column gap-2">
                  <label for="product" class="font-medium">Produit</label>
                  <div class="card flex justify-content-center">
                    <p-dropdown class="w-full" id="product" [options]="groupedProducts" formControlName="product"
                                placeholder="Sélectionner le produit" [group]="true">
                      <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center">
                          <span>{{ group.label }}</span>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="flex flex-column gap-2">
                  <label for="nbValid" class="font-medium">Nombre de validations</label>
                  <p-inputNumber [showButtons]="true" [min]="0" [max]="10" id="nbValid" inputId="integeronly" formControlName="numberOfValidation" />
                </div>
                <div class="flex gap-2">
                  <p-checkbox [binary]="true" formControlName="isRequired" id="isRequired" />
                  <label for="isRequired">Validations obligatoires</label>
                </div>
                <div class="flex gap-2">
                  <p-checkbox [binary]="true" formControlName="orderAbsolute" id="orderAbsolute" />
                  <label for="orderAbsolute">Ordre absolu</label>
                </div>
              </form>
            </div>
          </div>
          <div *ngIf="!boundData?.isView" class="flex pt-4 justify-content-end">
            <p-button label="Suivant" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit()" />
          </div>
        </div>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Ordre de validation">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div class="flex flex-column h-full">
          <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
            <p-pickList [disabled]="boundData?.isView" [source]="groups" [target]="selectedGroups" sourceHeader="Groupes" targetHeader="Chaines de validation"
                        [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="772px">
              <ng-template let-product pTemplate="item">
                <div class="flex flex-wrap p-2 align-items-center gap-3">
                  <div class="flex-1 flex flex-column gap-2">
                    <span class="font-bold">{{ product.name }}</span>
                    <span>{{ product.description }}</span>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
          <div *ngIf="!boundData?.isView" class="flex pt-4 justify-content-between align-items-end">
            <p-button label="Précédent" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
            <p-button label="Enregistrer" icon="pi pi-arrow-right" iconPos="right" (onClick)="save()" />
          </div>
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</div>
