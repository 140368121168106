<div class="flex flex-column w-full h-full align-content-between">

  <div class="flex align-items-start justify-content-between mb-2 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">Emissions</h3>
    </div>

    <div class="flex-grow-1 flex gap-2 justify-content-end">
      <button type="button" pButton icon="pi pi-plus" label="Nouveau" (click)="addNewRefinancing()"></button>
    </div>
  </div>
  <dynamic-table [headers]="columnsEmission" [data]="refinancing" [pageParams]="pageableRefinancing"
                 [actions]="actions" (paginate)="onPageChangeRefinancing($event)"></dynamic-table>

  <div class="flex align-items-start justify-content-between mb-2 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">Résultats</h3>
    </div>

    <div class="flex-grow-1 flex gap-2 justify-content-end">
      <button type="button" pButton icon="pi pi-plus" label="Nouveau" (click)="addNewResult()"></button>
    </div>
  </div>
  <dynamic-table [headers]="columnsResultat" [data]="result" [pageParams]="pageableResult"
                 [actions]="actions" (paginate)="onPageChangeResult($event)"></dynamic-table>

</div>
