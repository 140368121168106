import {Injectable} from "@angular/core";
import {RegisterResultWicketOperationModel} from "../models/registerResultWicketOperation.model";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Page} from "../models/page.model";
import {WicketOperationRequestModel} from "../models/WicketOperationRequest.model";

@Injectable({
  providedIn: 'root',
})
export  class ResultWicketOperationService {

  private baseUrl = 'income/v1/resultWicketOperation';

  constructor(private http: HttpClient) {
  }

  registerResultWicketOperation(wicketOperationID: string, dto: RegisterResultWicketOperationModel): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${wicketOperationID}`, dto);
  }

  getAllResultWicketOperations(page: number, size: number, isPaged: boolean = true): Observable<Page<RegisterResultWicketOperationModel>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<RegisterResultWicketOperationModel>>(this.baseUrl, {params});
  }

}
