<div class="modal-content">
  <form [formGroup]="pricerForm">
    <div class="form-body">
      <!-- Pays Emetteur -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="paysEmetteur">Emetteur</label>
        <p-dropdown
          (ngModelChange)="onCountryChange($event)"
          [(ngModel)]="selectedCountry"
          [options]="uemoaCountriesWithEmissions"
          emptyMessage="Aucun pays émetteur trouvé."
          formControlName="paysEmetteur"
          id="paysEmetteur"
          optionLabel="country"
          placeholder="Pays">
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center">
              <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg">
              <span>{{ country.country }}</span>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="selectedItem">
            <div class="flex align-items-center">
              <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg">
              <span>{{ country.country }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <!-- ISIN Code -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="codeISIN">Code ISIN</label>
        <p-dropdown
          (ngModelChange)="onIsinCodeChange($event)"
          [(ngModel)]="selectedIsinCode"
          [options]="isinCodes"
          emptyMessage="Aucun code ISIN trouvé."
          formControlName="codeISIN"
          id="codeISIN" placeholder="ISIN Code">
        </p-dropdown>
      </div>

      <!-- Valeur Nominale Unitaire -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="libelle">Valeur nominale unitaire</label>
        <p-inputNumber aria-describedby="valeur nominale unitaire" currency="XOF" formControlName="valeurNominaleUnitaire"
                       inputId="valeurnominale" locale="fr-FR" readonly suffix=" F CFA"></p-inputNumber>
      </div>

      <!-- Montant en adjudication -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="montantGlobalEmission">Montant en adjudication</label>
        <p-inputNumber readonly
          aria-describedby="montant global emission" formControlName="montantGlobalEmission"
          locale="fr-FR" suffix=" F CFA" inputId="montantGlobalEmission"
          readonly >
        </p-inputNumber>
      </div>

      <!-- Date Valeur -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="dateValeur">Date de valeur</label>
        <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="currentDateValeur" [showIcon]="true" placeholder = "Date de valeur" formControlName="dateValeur" id="dateValeur"></p-calendar>
      </div>

      <!-- Date Echeance -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="dateEcheance">Date d'échéance</label>
        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder = "Date d'échéance" formControlName="dateEcheance" id="dateEcheance"></p-calendar>
      </div>

      <!-- Conditionally displayed fields for OAT -->
      <ng-container *ngIf="isOAT">
        <!-- Maturité -->
        <div class="flex flex-column gap-1 field">
          <label class="block" for="maturite">Maturité (années)</label>
          <p-inputNumber aria-describedby="maturité" formControlName="maturite" inputId="maturite" placeholder="Maturité"></p-inputNumber>
        </div>

        <!-- Différé -->
        <div class="flex flex-column gap-1 field">
          <label class="block" for="differe">Différé (années)</label>
          <p-inputNumber aria-describedby="différé" formControlName="differe" inputId="differe" placeholder="Différé"></p-inputNumber>
        </div>

        <!-- Périodicité -->
        <div class="flex flex-column gap-1 field">
          <label class="block" for="periodicity">Périodicité (années)</label>
          <p-dropdown (ngModelChange)="onPeriodicityChange($event)" [(ngModel)]="selectedPeriodicity"
                      [options]="periodicities" aria-describedby="Périodicité"
                      emptyMessage="Aucune périodicité disponible." formControlName="periodicity" inputId="periodicity"
                      placeholder="Périodicité"></p-dropdown>
        </div>

        <!-- Mode d'amortissement -->
        <div class="flex flex-column gap-1 field">
          <label class="block" for="amortissement">Mode d'amortissement</label>
          <p-dropdown (ngModelChange)="onAmortissementModeChange($event)" [(ngModel)]="selectedAmortissementMode"
                      [options]="modesAmortissement" emptyMessage="Aucun mode d'amortissement disponible."
                      formControlName="amortissement" id="amortissement" placeholder="Mode d'amortissement"></p-dropdown>
        </div>

        <!-- Taux d'intérêt -->
        <div class="flex flex-column gap-1 field">
          <label class="block" for="tauxInteretCoupon">Taux d'intérêt (coupon)</label>
          <p-inputNumber [maxFractionDigits]="6" [minFractionDigits]="2" aria-describedby="Taux d'intérêt" formControlName="tauxInteretCoupon"
                         inputId="tauxInteretCoupon" mode="decimal" placeholder="Taux d'intérêt coupon" suffix="%"></p-inputNumber>
        </div>
        <!-- Taux de rendement -->
        <div class="flex flex-column gap-1 field">
          <label class="block" for="tauxRendement">Taux de rendement souhaité</label>
          <p-inputNumber [maxFractionDigits]="6" [minFractionDigits]="2" aria-describedby="Taux de rendement" formControlName="tauxRendement"
                         inputId="tauxRendement" mode="decimal" placeholder="Taux de rendement" suffix="%"></p-inputNumber>
        </div>
      </ng-container>

      <!-- Conditionally displayed fields for BAT -->
      <ng-container *ngIf="isBAT">
        <!-- Taux pré-compté -->
        <div class="flex flex-column gap-1 field">
          <label class="block" for="tauxPreCompte">Taux pré-compté</label>
          <p-inputNumber [min]="0.1" [maxFractionDigits]="2" [minFractionDigits]="2" aria-describedby="taux pré-compté"
                         formControlName="tauxPreCompte" inputId="tauxPreCompte" mode="decimal" suffix="%"></p-inputNumber>
        </div>
      </ng-container>
      <!-- Montant à Placer -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="montantAPlacer">Montant à placer</label>
        <p-inputNumber [max]="isFormReady ? globalEmissionMontant : valeurNominaleUnitaire" [min]="valeurNominaleUnitaire" [showButtons]="true"
                       [step]="valeurNominaleUnitaire" aria-describedby="montant à placer"
                       buttonLayout="horizontal" currency="XOF"
                       decrementButtonClass="p-button-alm-primary" decrementButtonIcon="pi pi-minus"
                       formControlName="montantAPlacer" incrementButtonClass="p-button-alm-primary"
                       incrementButtonIcon="pi pi-plus" inputId="montantAPlacer"
                       locale="fr-FR" suffix=" F CFA" placeholder = "F CFA">

        </p-inputNumber>
      </div>

    </div>
  </form>

  <div class="simulation-start-button submit-button">
    <p-button (click)="onSubmit()" [disabled]="!this.pricerForm.valid" styleClass="p-button-alm-primary" class="simulation-submit-button" label="Lancer la simulation des résultats" type="submit"></p-button>
  </div>
</div>
