import {Component} from '@angular/core';
import {VirementService} from "../../../../../core/services/virement.service";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {NgForOf, NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {IVirementExcel} from "../../../../../core/models/virement.model";
import {CustomTableHeader} from "../../../../../core/models/custom-table.model";
import {DynamicTableComponent} from "../../../../../shared/components/dynamic-table/dynamic-table.component";
import {PageParams} from "../../../../../core/models/page.model";
import {UploadTableComponent} from "../../../../../shared/components/upload-table/upload-table.component";

@Component({
  selector: 'app-upload-virement',
  standalone: true,
  imports: [FileUploadModule, NgIf, NgForOf, Ripple, DynamicTableComponent, UploadTableComponent],
  templateUrl: './upload-virement.component.html',
  styleUrl: './upload-virement.component.scss'
})
export class UploadVirementComponent {

  instance: DynamicDialogComponent | undefined;
  uploadedFile: File = {} as File;
  isLoading: boolean = false;
  virements: IVirementExcel[] = [];
  columns: CustomTableHeader[] = [
    { key: 'beneficiary.name', column: 'Bénéficiaire', type: 'text' },
    { key: 'operationDate', column: 'Date opération', type: 'date' },
    { key: 'valueDate', column: 'Date valeur', type: 'date' },
    { key: 'libelle', column: 'Libelle', type: 'text' },
    { key: 'amount', column: 'Montant', type: 'currency', currencyKey: 'devise.code' }, // Specify the currency key here
    { key: 'account.number', column: 'Numéro compte', type: 'text' },
    { key: 'account.libelle', column: 'Libelle compte', type: 'text' },
  ];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: [10,20,50]};

  constructor(private virementService: VirementService, private dialogService: DialogService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  onUpload(event: FileSelectEvent) {
    this.uploadedFile = event.files[event.currentFiles.length - 1];
  }

  upload() {
    this.virementService.uploadVirement(this.uploadedFile).subscribe({
      next: (data) => this.virements = data,
      error: (error) => console.error('Error uploading virement: ', error),
    });
  }

  saveValidVirements(validVirements: IVirementExcel[]) {
    console.log('Valid virements: ', validVirements);
    /**
    this.virementService.saveVirements(validVirements).subscribe({
      next: () => {
        // Handle successful save
        this.ref.close(true);
      },
      error: (error) => console.error('Error saving virements: ', error),
    });
     */
  }
}
