import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {RouterOutlet} from "@angular/router";
import {TabMenuModule} from "primeng/tabmenu";

@Component({
  selector: 'app-loan-borrowing',
  standalone: true,
  imports: [
    RouterOutlet,
    TabMenuModule
  ],
  template: `
    <div class="card h-full w-full">
      <p-tabMenu [model]="items" class="mb-2" />
      <div class="h-full w-full py-2">
        <router-outlet class="h-full" />
      </div>
    </div>
  `
})
export class LoanBorrowingComponent implements OnInit {

  items: MenuItem[] | undefined;

  ngOnInit() {
    this.items = [
      { label: 'Résumé', routerLink: 'summary' },
      { label: 'Prêts', routerLink: 'loans' },
      { label: 'Emprunts', routerLink: 'borrowings' },
    ]
  }

}
