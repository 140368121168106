import { Component } from '@angular/core';

@Component({
  selector: 'app-operations-risque',
  standalone: true,
  imports: [],
  templateUrl: './operations-risque.component.html',
  styleUrl: './operations-risque.component.scss'
})
export class OperationsRisqueComponent {

}
