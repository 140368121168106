export enum State {
  PENDING = "En attente",
  VALIDATED = "Validé",
  REJECTED = "Rejeté",
  EXECUTED = "Exécuté",
  CANCELED = "Annulé"
}




