import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {TabMenuModule} from "primeng/tabmenu";
import {Router} from "@angular/router";

@Component({
  selector: 'app-previsionnel',
  standalone: true,
  imports: [
    TabMenuModule
  ],
  template: `
    <div class="card h-full w-full">
      <p-tabMenu [model]="items" />
      <div class="h-full w-full py-2">
        <router-outlet class="h-full" />
      </div>
    </div>
  `
})
export class PrevisionnelComponent implements OnInit {

  constructor(private readonly router: Router) {}

  items: MenuItem[] | undefined;

  ngOnInit() {
    this.items = [
      { label: 'Prévisionnel', routerLink: 'overview' },
      { label: 'Opérations', routerLink: 'operations' },
    ];
    if (this.router.url.includes('locale')) {
      this.items.push({ label: 'Réserve obligatoire', routerLink: 'reserve' });
    }
  }

}
