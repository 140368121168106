<div class="flex flex-column w-full h-full align-content-between">

  <div class="chart-container">
    <div class="card">
      <p-chart [data]="data" [options]="options" type="line"></p-chart>
    </div>
    <div class="card">
      <p-chart [data]="dataTWO" [options]="optionsTWO" type="bar"></p-chart>
    </div>
  </div>
  <div class="flex align-items-start justify-content-between mb-4 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">Titres en vente</h3>
    </div>
    <div class="flex-grow-1 flex gap-2 justify-content-end">
      <!--<p-dropdown
        [disabled]="boundData?.isView || false"
        [options]="countries"
        [showClear]="true"
        editable="true"
        id="pays"
        optionLabel="country"
        optionValue="country"
        placeholder="Filtrer par pays"
        (onChange)="onCountryChange($event)">
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img alt="{{ country.country }}"
                 src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg"
                 style="width: 18px"/>
            <div>{{ country.country }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <p-dropdown
        [disabled]="boundData?.isView || false"
        [options]="countries"
        [showClear]="true"
        editable="true"
        id="pays"
        optionLabel="country"
        optionValue="country"
        placeholder="Filtrer par pays"
        (onChange)="onCountryChange($event)">
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img alt="{{ country.country }}"
                 src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg"
                 style="width: 18px"/>
            <div>{{ country.country }}</div>
          </div>
        </ng-template>
      </p-dropdown>-->
      <button (click)="createTitleSecondary()" icon="pi pi-plus" label="Nouveau" pButton type="button"></button>
    </div>
  </div>
  <dynamic-table (paginate)="onPageChange($event)" [data]="operationsTitres" [headers]="columns"
                 [pageParams]="pageable"></dynamic-table>
</div>
