import { Component, Input, OnInit } from '@angular/core';
import { ChartModule } from "primeng/chart";
import { DropdownModule } from "primeng/dropdown";
import { ICurrencyBalance } from "../../core/models/account.model";
import { AccountBalanceService } from "../../core/services/account.balance.service";
import { ToastService } from "../../core/services/toast.service";
import { StorageService } from "../../core/services/storage.service";
import { fr } from "date-fns/locale";
import 'chartjs-adapter-date-fns';
import { startOfDay, startOfWeek, startOfMonth, startOfQuarter, isSameDay } from 'date-fns';
import { FormsModule } from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { DateRangeComponent } from "../date-range/date-range.component";
import { IReserve } from "../../core/models/reserve.model";
import {getUnpagedPageable, Pageable} from "../../core/models/page.model";
import {ReserveService} from "../../core/services/reserve.service";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-balance-history-chart',
  standalone: true,
  imports: [ChartModule, DropdownModule, FormsModule, CalendarModule, DateRangeComponent, NgIf, NgClass],
  template: `
    <div class="flex justify-content-between">
      <span class="font-semibold text-xl">{{ title }}</span>
      <div class="flex gap-2">
        <date-range [maxDate]="maxDate" (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)"/>
        <p-dropdown [options]="timeGroupings" [(ngModel)]="selectedTimeGrouping" (onChange)="onTimeGroupingChange()" />
      </div>
    </div>
    <div [ngClass]="{ 'px-4' : rawData.length > 0 }">
      <p-chart *ngIf="rawData.length > 0" [responsive]="true" type="line" [data]="chartData" [options]="chartOptions" />
      <span class=" py-2 text-gray-400 font-semibold font-italic" *ngIf="rawData.length == 0">Aucun entrée de solde pour la devise sur la période correspondante</span>
    </div>
  `
})
export class BalanceHistoryChartComponent implements OnInit {

  @Input() institutionId: string | undefined;
  @Input() title: string = 'Évolution du solde';
  @Input() showReserves: boolean = false;
  @Input() currency: string = 'XOF';
  reserves: IReserve[] = [];

  rangeDates: Date[] = [];
  maxDate: Date = new Date();

  chartData: any;
  chartOptions: any;
  rawData: ICurrencyBalance[] = [];

  private readonly colorMap: Map<string, string> = new Map();
  private usedHues: number[] = [];

  timeGroupings = [
    { label: 'Journalier', value: 'day' },
    { label: 'Hebdomadaire', value: 'week' },
    { label: 'Mensuel', value: 'month' },
    { label: 'Trimestriel', value: 'quarter' }
  ];
  selectedTimeGrouping = 'week';

  constructor(
    private readonly accountBalanceService: AccountBalanceService,
    private readonly reserveService: ReserveService,
    private readonly toastService: ToastService,
    private readonly storageService: StorageService,
  ) {}

  ngOnInit() {
    const sixMonthsBefore = new Date(new Date().setMonth(new Date().getMonth() - 6));
    this.rangeDates = [sixMonthsBefore, new Date()];
    if (!this.institutionId) this.institutionId = this.storageService.getToken().institution_id;
    this.loadBalanceHistory();
  }

  onDateRangeSelected(dateRange: Date[]) {
    this.rangeDates = dateRange;
    this.loadBalanceHistory();
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Évolution du solde', message, 'error');
  }

  loadBalanceHistory() {
    // Now we'll load all currencies
    this.accountBalanceService.getBalanceHistoryByCurrency(this.currency, this.rangeDates[0], this.rangeDates[1])
      .subscribe({
        next: (data: ICurrencyBalance[]) => {
          this.rawData = data;
          if (this.showReserves) this.loadReserve();
          this.prepareChartData();
        },
        error: () => this.toastService.showToast('Chargement des soldes', 'Une erreur est survenue lors du chargement de l\'historique des soldes', 'error')
      });
  }

  loadReserve(page: Pageable = getUnpagedPageable()) {
    const apiCall = this.rangeDates?.length == 2 ? this.reserveService.findForCurrentUser(page, this.rangeDates[0], this.rangeDates[1]) : this.reserveService.findForCurrentUser(page);
    apiCall.subscribe({
      next: (page) => {
        this.reserves = page.content;
        this.prepareChartData();
      },
      error: (err) => this.toastService.showToast('Historique des réserves obligatoires', err.error, 'error')
    });
  }

  onTimeGroupingChange() {
    this.prepareChartData();
  }

  private generateRandomColor(identifier: string): string {
    // If we already have a color for this identifier, return it
    if (this.colorMap.has(identifier)) {
      return this.colorMap.get(identifier)!;
    }

    let hue: number;
    const minHueDistance = 30; // Minimum distance between hues to ensure distinct colors

    // Try to find a hue that's not too close to existing ones
    do {
      hue = Math.floor(Math.random() * 360);
    } while (
      this.usedHues.some(
        usedHue => Math.abs(usedHue - hue) < minHueDistance ||
          Math.abs(usedHue - hue - 360) < minHueDistance
      ));

    this.usedHues.push(hue);

    // Generate color with good saturation and lightness for visibility
    const color = `hsl(${hue}, 70%, 45%)`;
    this.colorMap.set(identifier, color);

    return color;
  }

  prepareChartData() {
    // Reset color mappings when preparing new data
    this.colorMap.clear();
    this.usedHues = [];

    // Group data by currency first
    const currencyGroups = this.groupDataByCurrency(this.rawData);

    // For each currency, group by time
    const currencyDatasets = new Map<string, ICurrencyBalance[]>();
    currencyGroups.forEach((data, currencyCode) => {
      currencyDatasets.set(currencyCode, this.groupDataByTime(data, this.selectedTimeGrouping));
    });

    // Get all unique dates across all currencies
    const allDates = new Set<number>();
    currencyDatasets.forEach(dataset => {
      dataset.forEach(item => {
        allDates.add(item.date.getTime());
      });
    });

    // Add reserve dates if showing reserves
    if (this.showReserves && this.reserves.length > 0) {
      const groupedReserves = this.groupReservesByTime(this.reserves, this.selectedTimeGrouping);
      groupedReserves.forEach(reserve => {
        allDates.add(new Date(reserve.date).getTime());
      });
    }

    const labels = Array.from(allDates).sort((a, b) => a - b).map(timestamp => new Date(timestamp));

    // Create datasets for each currency
    const datasets = [];

    currencyDatasets.forEach((data, currencyCode) => {
      const balanceData = labels.map(date => {
        const item = data.find(d => isSameDay(d.date, date));
        return item ? item.balance : null;
      });

      datasets.push({
        label: `${currencyCode.toUpperCase()}`,
        data: balanceData,
        borderColor: this.generateRandomColor(currencyCode),
        tension: 0.1
      });
    });

    // Add reserves dataset if enabled
    if (this.showReserves && this.reserves.length > 0) {
      const groupedReserves = this.groupReservesByTime(this.reserves, this.selectedTimeGrouping);
      const reserveData = labels.map(date => {
        const reserve = groupedReserves.find(r => isSameDay(r.date, date));
        return reserve ? reserve.reserveAmount : null;
      });

      datasets.push({
        label: 'Réserves',
        data: reserveData,
        borderColor: this.generateRandomColor('reserves'),
        tension: 0.1,
        borderDash: [5, 5] // Make reserve line dashed
      });
    }

    this.chartData = { labels, datasets };
    this.setChartOptions();
  }
  private groupDataByCurrency(data: ICurrencyBalance[]): Map<string, ICurrencyBalance[]> {
    const groups = new Map<string, ICurrencyBalance[]>();
    data.forEach(item => {
      const currencyCode = item.currency.code;
      if (!groups.has(currencyCode)) {
        groups.set(currencyCode, []);
      }
      groups.get(currencyCode)?.push(item);
    });
    return groups;
  }

  groupDataByTime(data: ICurrencyBalance[], grouping: string): ICurrencyBalance[] {
    const groupedData: { [key: string]: ICurrencyBalance } = {};

    data.forEach(item => {
      let groupDate: Date = this.getGroupDate(new Date(item.date), grouping);
      const key = `${groupDate.getTime()}-${item.currency.code}`;

      if (!groupedData[key] || (item.type === 'Clôture' && new Date(item.date) > new Date(groupedData[key].date))) {
        groupedData[key] = { ...item, date: groupDate };
      }
    });

    return Object.values(groupedData);
  }

  groupReservesByTime(reserves: IReserve[], grouping: string): IReserve[] {
    const groupedData: { [key: string]: IReserve } = {};

    reserves.forEach(reserve => {
      let groupDate: Date = this.getGroupDate(new Date(reserve.date), grouping);
      const key = groupDate.getTime().toString();

      if (!groupedData[key] || new Date(reserve.date) > new Date(groupedData[key].date)) {
        groupedData[key] = { ...reserve, date: groupDate };
      }
    });

    return Object.values(groupedData);
  }

  getGroupDate(date: Date, grouping: string): Date {
    switch (grouping) {
      case 'day':
        return startOfDay(date);
      case 'week':
        return startOfWeek(date, { weekStartsOn: 1 });
      case 'month':
        return startOfMonth(date);
      case 'quarter':
        return startOfQuarter(date);
      default:
        return date;
    }
  }

  setChartOptions() {
    const timeUnit = this.getTimeUnit();
    this.chartOptions = {
      responsive: true,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: timeUnit,
            displayFormats: this.getDisplayFormat(timeUnit)
          },
          adapters: {
            date: { locale: fr }
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value: number) => {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                notation: 'compact'
              }).format(value);
            }
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              let label = context.dataset.label || '';
              if (label) label += ': ';
              if (context.parsed.y !== null) {
                // Extract currency code from dataset label
                const currencyCode = context.dataset.label.includes('Solde')
                  ? context.dataset.label.split(' ')[1]
                  : 'XOF';
                label += new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: currencyCode
                }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      }
    };
  }

  getTimeUnit(): 'day' | 'week' | 'month' | 'quarter' {
    switch (this.selectedTimeGrouping) {
      case 'day':
        return 'day';
      case 'week':
        return 'week';
      case 'month':
        return 'month';
      case 'quarter':
        return 'quarter';
      default:
        return 'day';
    }
  }

  getDisplayFormat(timeUnit: string) {
    switch (timeUnit) {
      case 'day':
        return { day: 'dd/MM/yyyy' };
      case 'week':
        return { week: 'dd/MM/yyyy' };
      case 'month':
        return { month: 'MM/yyyy' };
      case 'quarter':
        return { quarter: "QQQ yyyy" };
      default:
        return { day: 'dd/MM/yyyy' };
    }
  }
}
