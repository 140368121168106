import {Component, inject, OnDestroy} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {ToastModule} from "primeng/toast";
import {StorageService} from "./core/services/storage.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule,ConfirmDialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnDestroy {

  tokenManager = inject(StorageService);

  ngOnDestroy() {
    this.tokenManager.clearStorage()
  }
}
