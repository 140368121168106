import { Component, OnInit } from '@angular/core';
import { CommonModule, NgComponentOutlet } from "@angular/common";
import { TabViewModule } from "primeng/tabview";
import { EmissionsComponent } from "./emissions/emissions.component";
import { SoumissionsComponent } from "./soumissions/soumissions.component";
import { TabMenuModule } from "primeng/tabmenu";
import { ResultatsComponent } from "./resultats/resultats.component";

@Component({
  selector: 'app-primaire',
  standalone: true,
  imports: [CommonModule, TabViewModule, NgComponentOutlet, TabMenuModule],
  templateUrl: './primaire.component.html',
  styleUrls: ['./primaire.component.css']
})
export class PrimaireComponent implements OnInit {

  items: { label: string, icon?: string, command?: () => void }[] = [];
  selectedTab: { title: string, component: any } | null = null;
  activeItem: any = null;

  ngOnInit() {
    this.items = [
      { label: 'Emissions', command: () => this.selectTab('Emissions') },
      { label: 'Soumissions', command: () => this.selectTab('Soumissions') },
      { label: 'Résultats', command: () => this.selectTab('Résultats') }
    ];
    this.selectTab('Emissions');
  }

  selectTab(tabTitle: string) {
    this.activeItem = this.items.find(item => item.label === tabTitle);
    if (tabTitle === 'Emissions') {
      this.selectedTab = { title: 'Emissions', component: EmissionsComponent };
    } else if (tabTitle === 'Soumissions') {
      this.selectedTab = { title: 'Soumissions', component: SoumissionsComponent };
    } else if (tabTitle === 'Résultats') {
      this.selectedTab = { title: 'Résultats', component: ResultatsComponent };
    }
  }
}
