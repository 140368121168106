import { setWeek } from "../../../setWeek.js";
import { startOfWeek } from "../../../startOfWeek.js";
import { numericPatterns } from "../constants.js";
import { Parser } from "../Parser.js";
import { parseNDigits, parseNumericPattern } from "../utils.js";

// Local week of year
export class LocalWeekParser extends Parser {
  priority = 100;
  parse(dateString, token, match) {
    switch (token) {
      case "w":
        return parseNumericPattern(numericPatterns.week, dateString);
      case "wo":
        return match.ordinalNumber(dateString, {
          unit: "week"
        });
      default:
        return parseNDigits(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 53;
  }
  set(date, _flags, value, options) {
    return startOfWeek(setWeek(date, value, options), options);
  }
  incompatibleTokens = ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "i", "t", "T"];
}