import {Component, OnInit} from '@angular/core';
import {NgComponentOutlet, NgForOf, NgIf} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {TabViewModule} from "primeng/tabview";
import {PortefeuilleTabComponent} from "./portefeuilleTab/portefeuilleTab.component";
import {LimiteTabComponent} from "./limiteTab/limiteTab.component";
import {ResumePortefeuilleTabComponent} from "./resumePortefeuilleTab/resumePortefeuilleTab.component";
import {TabMenuModule} from "primeng/tabmenu";


@Component({
  selector: 'app-portefeuille',
  standalone: true,
    imports: [
        NgComponentOutlet,
        NgForOf,
        PrimeTemplate,
        TabViewModule,
        NgIf,
        TabMenuModule
    ],
  templateUrl: './portefeuille.component.html',
  styleUrl: './portefeuille.component.scss'
})
export class PortefeuilleComponent implements OnInit{

  items: { label: string, icon?: string, command?: () => void }[] = [];
  selectedTab: { title: string, component: any } | null = null;
  activeItem: any = null;

  ngOnInit() {
    this.items = [
      { label: 'Résumé', command: () => this.selectTab('Résumé') },
      { label: 'Portefeuille',command: () => this.selectTab('Portefeuille') },
      { label: 'Limites',command: () => this.selectTab('Limites') }
    ];
    this.selectTab('Résumé');
  }

  selectTab(tabTitle: string) {
    this.activeItem = this.items.find(item => item.label === tabTitle);
    if (tabTitle === 'Résumé') {
      this.selectedTab = { title: 'Résumé', component: ResumePortefeuilleTabComponent };
    } else if (tabTitle === 'Portefeuille') {
      this.selectedTab = { title: 'Portefeuille', component: PortefeuilleTabComponent };
    } else if (tabTitle === 'Limites') {
      this.selectedTab = { title: 'Limites', component: LimiteTabComponent };
    }
  }
}
