import {Component, Input, OnInit} from '@angular/core';
import {ChartModule} from "primeng/chart";
import {DropdownModule} from "primeng/dropdown";
import {ICurrencyBalance} from "../../core/models/account.model";
import {AccountBalanceService} from "../../core/services/account.balance.service";
import {ToastService} from "../../core/services/toast.service";
import {fr} from "date-fns/locale";
import 'chartjs-adapter-date-fns';
import {isSameDay, startOfDay, startOfMonth, startOfQuarter, startOfWeek} from 'date-fns';
import {FormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {DateRangeComponent} from "../date-range/date-range.component";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-multi-currency-balance-chart',
  standalone: true,
  imports: [ChartModule, DropdownModule, FormsModule, CalendarModule, DateRangeComponent, NgIf, NgClass],
  template: `
    <div class="flex justify-content-between">
      <span class="font-semibold text-xl">{{ title }}</span>
      <div class="flex gap-2">
        <date-range [maxDate]="maxDate" (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)"/>
        <p-dropdown [options]="timeGroupings" [(ngModel)]="selectedTimeGrouping" (onChange)="onTimeGroupingChange()" />
      </div>
    </div>
    <div [ngClass]="{ 'px-4': rawData.length > 0 }">
      <p-chart *ngIf="rawData.length > 0" [responsive]="true" type="line" [data]="chartData" [options]="chartOptions" />
      <span class="py-2 text-gray-400 font-semibold font-italic" *ngIf="rawData.length == 0">
        Aucune entrée de solde pour les devises sur la période correspondante
      </span>
    </div>
  `
})
export class MultiCurrencyBalanceChartComponent implements OnInit {

  @Input() title: string = 'Évolution des soldes';

  rangeDates: Date[] = [];
  maxDate: Date = new Date();

  chartData: any;
  chartOptions: any;
  rawData: ICurrencyBalance[] = [];

  private readonly colorMap: Map<string, string> = new Map();
  private usedHues: number[] = [];

  timeGroupings = [
    { label: 'Journalier', value: 'day' },
    { label: 'Hebdomadaire', value: 'week' },
    { label: 'Mensuel', value: 'month' },
    { label: 'Trimestriel', value: 'quarter' }
  ];
  selectedTimeGrouping = 'week';

  constructor(
    private readonly accountBalanceService: AccountBalanceService,
    private readonly toastService: ToastService,
  ) {}

  ngOnInit() {
    const sixMonthsBefore = new Date(new Date().setMonth(new Date().getMonth() - 6));
    this.rangeDates = [sixMonthsBefore, new Date()];
    this.loadBalanceHistory();
  }

  onDateRangeSelected(dateRange: Date[]) {
    this.rangeDates = dateRange;
    this.loadBalanceHistory();
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Évolution des soldes', message, 'error');
  }

  loadBalanceHistory() {
    // Load all currencies
    this.accountBalanceService.getInstitutionBalanceHistory(this.rangeDates[0], this.rangeDates[1], false)
      .subscribe({
        next: (data: ICurrencyBalance[]) => {
          this.rawData = data;
          this.prepareChartData();
        },
        error: () => this.toastService.showToast('Chargement des soldes', 'Une erreur est survenue lors du chargement de l\'historique des soldes', 'error')
      });
  }

  onTimeGroupingChange() {
    this.prepareChartData();
  }

  private generateRandomColor(identifier: string): string {
    if (this.colorMap.has(identifier)) {
      return this.colorMap.get(identifier)!;
    }

    let hue: number;
    const minHueDistance = 30;

    do {
      hue = Math.floor(Math.random() * 360);
    } while (
      this.usedHues.some(
        usedHue => Math.abs(usedHue - hue) < minHueDistance ||
          Math.abs(usedHue - hue - 360) < minHueDistance
      ));

    this.usedHues.push(hue);
    const color = `hsl(${hue}, 70%, 45%)`;
    this.colorMap.set(identifier, color);

    return color;
  }

  prepareChartData() {
    this.colorMap.clear();
    this.usedHues = [];

    // Group data by currency first
    const currencyGroups = this.groupDataByCurrency(this.rawData);

    // For each currency, group by time
    const currencyDatasets = new Map<string, ICurrencyBalance[]>();
    currencyGroups.forEach((data, currencyCode) => {
      currencyDatasets.set(currencyCode, this.groupDataByTime(data, this.selectedTimeGrouping));
    });

    // Get all unique dates across all currencies
    const allDates = new Set<number>();
    currencyDatasets.forEach(dataset => {
      dataset.forEach(item => {
        allDates.add(item.date.getTime());
      });
    });

    const labels = Array.from(allDates)
      .sort((a, b) => a - b)
      .map(timestamp => new Date(timestamp));

    // Create datasets for each currency
    const datasets = Array.from(currencyDatasets.entries()).map(([currencyCode, data]) => {
      const balanceData = labels.map(date => {
        const item = data.find(d => isSameDay(d.date, date));
        return item ? item.balance : null;
      });

      return {
        label: currencyCode.toUpperCase(),
        data: balanceData,
        borderColor: this.generateRandomColor(currencyCode),
        tension: 0.1
      };
    });

    this.chartData = { labels, datasets };
    this.setChartOptions();
  }

  private groupDataByCurrency(data: ICurrencyBalance[]): Map<string, ICurrencyBalance[]> {
    const groups = new Map<string, ICurrencyBalance[]>();
    data.forEach(item => {
      const currencyCode = item.currency.code;
      if (!groups.has(currencyCode)) {
        groups.set(currencyCode, []);
      }
      groups.get(currencyCode)?.push(item);
    });
    return groups;
  }

  groupDataByTime(data: ICurrencyBalance[], grouping: string): ICurrencyBalance[] {
    const groupedData: { [key: string]: ICurrencyBalance } = {};

    data.forEach(item => {
      let groupDate: Date = this.getGroupDate(new Date(item.date), grouping);
      const key = `${groupDate.getTime()}-${item.currency.code}`;

      if (!groupedData[key] || (item.type === 'Clôture' && new Date(item.date) > new Date(groupedData[key].date))) {
        groupedData[key] = { ...item, date: groupDate };
      }
    });

    return Object.values(groupedData);
  }

  getGroupDate(date: Date, grouping: string): Date {
    switch (grouping) {
      case 'day':
        return startOfDay(date);
      case 'week':
        return startOfWeek(date, { weekStartsOn: 1 });
      case 'month':
        return startOfMonth(date);
      case 'quarter':
        return startOfQuarter(date);
      default:
        return date;
    }
  }

  setChartOptions() {
    const timeUnit = this.getTimeUnit();
    this.chartOptions = {
      responsive: true,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: timeUnit,
            displayFormats: this.getDisplayFormat(timeUnit)
          },
          adapters: {
            date: { locale: fr }
          }
        },
        y: {
          beginAtZero: true
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              let label = context.dataset.label || '';
              if (label) label += ': ';
              if (context.parsed.y !== null) {
                const currencyCode = context.dataset.label === 'Réserves' ? 'XOF' : context.dataset.label;
                label += new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: currencyCode
                }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      }
    };
  }

  getTimeUnit(): 'day' | 'week' | 'month' | 'quarter' {
    switch (this.selectedTimeGrouping) {
      case 'day':
        return 'day';
      case 'week':
        return 'week';
      case 'month':
        return 'month';
      case 'quarter':
        return 'quarter';
      default:
        return 'day';
    }
  }

  getDisplayFormat(timeUnit: string) {
    switch (timeUnit) {
      case 'day':
        return { day: 'dd/MM/yyyy' };
      case 'week':
        return { week: 'dd/MM/yyyy' };
      case 'month':
        return { month: 'MM/yyyy' };
      case 'quarter':
        return { quarter: "QQQ yyyy" };
      default:
        return { day: 'dd/MM/yyyy' };
    }
  }
}
