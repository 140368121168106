<div class="flex flex-column gap-4 my-2">
  <div class="flex justify-content-between">
    <div class="flex gap-2">
      <span class="font-bold text-2xl">Évolution des Réserves Obligatoires</span>
    </div>
  </div>
  <div class="py-2">
    <div class="flex justify-content-between mb-4">
      <div class="flex gap-2">
        <date-range (dateRangeSelected)="onDateRangeSelected($event)" (invalidRange)="onInvalidRange($event)"/>
        <p-dropdown [options]="timeGroupings" [(ngModel)]="selectedTimeGrouping" (onChange)="onTimeGroupingChange()" />
      </div>
      <div class="flex gap-2">
        <div class="flex gap-2">
          <p-button icon="pi pi-plus" (onClick)="createNewEntry()" />
          <export label="Opérations futures" [iconOnly]="true" (handler)="exportReserve($event)" />
        </div>
      </div>
    </div>
    <line-chart [title]="''" [datasets]="chartDatasets" [xAxisType]="'time'"
                [xAxisTimeUnit]="selectedTimeGrouping" [yAxisLabel]="'Montant'" [currency]="'XOF'" />
  </div>
  <div class="py-2">
    <dynamic-table [data]="reserves" [headers]="columns" [pageParams]="pageParams" (paginate)="onPageChange($event)"/>
  </div>
</div>
