<toolbar [title]="pageTitle | uppercase"></toolbar>
<p class="subheader">Simulateur Pricer Obligations et Bons</p>
<div class="card">
  <p-tabMenu [model]="items" [(activeItem)]="activeItem">
    <ng-template pTemplate="item" let-item>
      <ng-container *ngIf="item.route; else elseBlock">
        <a [routerLink]="item.route" class="p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="justify-content-center">{{ item.label }}</span>
        </a>
      </ng-container>
      <ng-template #elseBlock>
        <a [href]="item.url" class="p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </ng-template>
    </ng-template>
  </p-tabMenu>


  <!-- Child route content will be displayed here -->
  <router-outlet></router-outlet>
</div>

