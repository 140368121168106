<div class="flex flex-column w-full h-full align-content-between">


  <div class="flex align-items-start justify-content-between mb-2 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">LISTE DES TITRES</h3>
    </div>
    <div class="flex-grow-1 flex gap-2 justify-content-end">

    <p-fileUpload
        chooseIcon="pi pi-download"
        mode="basic"
        (onSelect)="onUpload($event)"
        [multiple]="false"
        class="custom-file-upload"
        accept=".xlsx,.xls"
        maxFileSize="5000000"
        chooseLabel="Charger titres">
      </p-fileUpload>
    </div>
  </div>

  <dynamic-table [headers]="columns" [data]="titres" [pageParams]="pageable"
                 [actions]="actions" (paginate)="onPageChange($event)" />

  <div class="mb-2">
    <h3 class="text-gray-500">CALENDRIER DES EMISSIONS</h3>
  </div>

  <div class="flex align-items-start justify-content-between mb-4">
    <div>
      <p-dropdown
        [options]="countries"
        optionLabel="country"
        [showClear]="true"
        placeholder="Filtrer par pays"
        (onChange)="onCalendarCountryChange($event)">
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img alt="{{ country.country }}"
                 src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg"
                 style="width: 18px" />
            <div>{{ country.country }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <full-calendar #calendar [options]="calendarOptions"></full-calendar>

  <p-dialog header="Caractéristiques de l'offre" [(visible)]="displayDialog" modal="modal">
    <table class="event-info-table">
      <tr>
        <td>Nom :</td>
        <td><strong>{{selectedEvent?.title}}</strong></td>
      </tr>
      <tr>
        <td>Date de l'opération :</td>
        <td><strong>{{selectedEvent?.operationDate}}</strong></td>
      </tr>
      <tr>
        <td>Montant mis en adjudication :</td>
        <td><strong>{{selectedEvent?.amount | number:'1.2-2':'fr-FR' }} F CFA</strong></td>
      </tr>
      <tr>
        <td>Date de valeur :</td>
        <td><strong>{{selectedEvent?.valueDate}}</strong></td>
      </tr>
    </table>

    <div class="flex justify-content-between align-items-center mb-2">
      <h3>Titres</h3>
      <p-button pButton icon="pi pi-plus" class="p-button-custom" (click)="onMoreButtonClick()" />
    </div>

    <table class="details-table">
      <thead>
      <tr>
        <th>ISIN</th>
        <th>Instrument</th>
        <th>Echéance</th>
        <th>Valeur Nominale Unitaire</th>
        <th>Durée (en mois)</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let detail of filteredTitres">
        <td>{{detail.isin}}</td>
        <td>{{detail.typeInstrument}}</td>
        <td>{{detail.dueDate}}</td>
        <td>{{detail.unitNominalValue}}</td>
        <td>{{detail.duration}}</td>
      </tr>
      </tbody>
    </table>
    <p-footer>
      <button type="button" pButton icon="pi pi-times" (click)="closeDialog()" severity="danger" label="Fermer"></button>
    </p-footer>
  </p-dialog>

  <p-dialog header="Caractéristiques de l'émission" [(visible)]="displayMoreDetailsDialog" modal="modal" [breakpoints]="{'960px': '75vw', '640px': '90vw'}">
    <p>Date de valeur : <strong>{{selectedEvent?.valueDate}}</strong></p>
    <p-footer>
      <button type="button" pButton icon="pi pi-times" (click)="closeMoreDetailsDialog()" severity="secondary" label="Modifier"></button>
    </p-footer>
  </p-dialog>
</div>
