import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IRepaymentEntry, IRepaymentEntrySummary} from '../models/repayment-entry.model';
import {ILoanBorrowing, IRepaymentFrequency, LoanBorrowingType} from '../models/loan-borrowing.model';

@Injectable({
  providedIn: 'root'
})
export class RepaymentService {

  private readonly apiUrl = `operation/v1/repayments`;

  constructor(private readonly http: HttpClient) { }

  getRepaymentEntriesByLoanBorrowingId(loanBorrowingId: string): Observable<IRepaymentEntry[]> {
    return this.http.get<IRepaymentEntry[]>(`${this.apiUrl}/${loanBorrowingId}`);
  }

  simulateRepaymentEntries(loanBorrowing: ILoanBorrowing): Observable<IRepaymentEntry[]> {
    return this.http.post<IRepaymentEntry[]>(`${this.apiUrl}/simulate`, loanBorrowing);
  }

  postponeRepaymentEntries(loanBorrowingId: string, paymentNumber: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${loanBorrowingId}/postpone/${paymentNumber}`, {});
  }

  anticipateRepaymentEntries(loanBorrowingId: string, paymentNumber: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${loanBorrowingId}/anticipate/${paymentNumber}`, {});
  }

  validateRepaymentEntry(loanBorrowingId: string, paymentNumber: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${loanBorrowingId}/validate/${paymentNumber}`, {});
  }

  updateRepaymentEntry(loanBorrowingId: string, paymentNumber: number, repaymentEntry: IRepaymentEntry): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${loanBorrowingId}/${paymentNumber}`, repaymentEntry);
  }

  getFrequencies(): Observable<IRepaymentFrequency[]> {
    return this.http.get<IRepaymentFrequency[]>(`${this.apiUrl}/frequency`);
  }

  getRepaymentSummary(
    startDate: Date,
    endDate: Date,
    isLocal: boolean,
    types: LoanBorrowingType[] = [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING]
  ): Observable<IRepaymentEntrySummary[]> {
    let params = new HttpParams()
      .set('types', types.join(','))
      .set('isLocal', isLocal.toString())
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);

    return this.http.get<IRepaymentEntrySummary[]>(`${this.apiUrl}/summary`, { params });
  }
}
