export const chartTauxData = {
  actifs: [
    3.12,
    3.45,
    3.55,
    4.00,
  ],
  passifs: [
    2.18,
    2.62,
    3,
    2.7
  ]
}


/*
// Example BAT emissions
export const emissionsBATData: IEmission[] = [
  {
    region: "CI",
    valueDate: "2024-08-28",
    operationDate: "2024-08-27",
    amount: 60000000000.00,
    detailsEmission: [
      {
        typeInstrument: "BAT",
        dueDate: "2024-11-26",
        rate: 0,
        unitNominalValue: 1000000.00,
        isin: "CI0000008001"
      }
    ]
  },
  {
    region: "CI",
    valueDate: "2024-08-28",
    operationDate: "2024-08-27",
    amount: 60000000000.00,
    detailsEmission: [
      {
        typeInstrument: "BAT",
        dueDate: "2025-08-06",
        rate: 0,
        unitNominalValue: 1000000.00,
        isin: "CI0000008002"
      }
    ]
  },
  {
    region: "SN",
    valueDate: "2024-08-22",
    operationDate: "2024-08-21",
    amount: 25000000000.00,
    detailsEmission: [
      {
        typeInstrument: "BAT",
        dueDate: "2025-06-25",
        rate: 0,
        unitNominalValue: 1000000.00,
        isin: "SN0000008001"
      }
    ]
  }
];

// Example OAT emissions
export const emissionsOATData: IEmission[] = [
  {
    region: "CI",
    valueDate: "2024-08-28",
    operationDate: "2024-08-27",
    amount: 60000000000.00,
    detailsEmission: [
      {
        typeInstrument: "OAT",
        dueDate: "2027-08-08",
        rate: 6,
        unitNominalValue: 10000.00,
        isin: "CI0000008003"
      }
    ]
  },
  {
    region: "CI",
    valueDate: "2024-09-24",
    operationDate: "2026-08-24",
    amount: 60000000000.00,
    detailsEmission: [
      {
        typeInstrument: "OAT",
        dueDate: "2029-08-28",
        rate: 6,
        unitNominalValue: 10000.00,
        isin: "CI0000008004"
      }
    ]
  },
  {
    region: "SN",
    valueDate: "2024-08-22",
    operationDate: "2024-08-21",
    amount: 25000000000.00,
    detailsEmission: [
      {
        typeInstrument: "OAT",
        dueDate: "2027-06-27",
        rate: 6,
        unitNominalValue: 10000.00,
        isin: "SN0000008002"
      }
    ]
  },
  {
    region: "SN",
    valueDate: "2024-08-22",
    operationDate: "2024-08-21",
    amount: 25000000000.00,
    detailsEmission: [
      {
        typeInstrument: "OAT",
        dueDate: "2029-08-22",
        rate: 6,
        unitNominalValue: 10000.00,
        isin: "SN0000008003"
      }
    ]
  }
];*/


