import {Component, OnInit} from '@angular/core';
import {Button} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {NgClass, NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Ripple} from "primeng/ripple";
import {ToastService} from "../../../core/services/toast.service";
import {UserService} from "../../../core/services/user.service";
import {IUser} from "../../../core/models/user.model";
import {IGroup} from "../../../core/models/group.model";
import {SelectButtonModule} from "primeng/selectbutton";

@Component({
  selector: 'app-user-info',
  standalone: true,
  imports: [
    // Modules
    Button, DropdownModule, InputTextModule, PaginatorModule, ReactiveFormsModule, Ripple,
    // Pipes & Directives
    NgIf, SelectButtonModule, NgClass
  ],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss'
})
export class UserInfoComponent implements OnInit {

  user: IUser | undefined;
  formGroup = new FormGroup({
    firstname: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    lastname: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    username: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl('', {nonNullable: true, validators: [Validators.required, Validators.email]}),
    phoneNumber: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    group: new FormControl<IGroup | null>(null, {nonNullable: true, validators: [Validators.required]}),
    enabled: new FormControl(true, {nonNullable: true}),
  });
  isEditing: boolean = false;
  isLoading: boolean = false;


  constructor(private userService: UserService, private toastService: ToastService) {}

  ngOnInit() {
    this.userService.getSelf().subscribe({
      next: user => {
        this.user = user;
        this.formGroup.patchValue(user);
        this.formGroup.disable();
      },
      error: () => this.toastService.showToast('Erreur', 'Une erreur est survenue lors de la récupération de vos informations', 'error')
    });
  }

  update() {
    this.isLoading = true;
    const userDto = this.formGroup.value as IUser;
    this.userService.updateSelf(userDto).subscribe({
      next: (user) => {
        this.toastService.showToast('Modifications enregistrées',`Vos informations personnelles ont été modifiées avec succès.`);
        this.isLoading = false;
        this.isEditing = false;
        this.ngOnInit();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification', error);
        this.isLoading = false;
      }
    });
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) this.formGroup.enable();
    else this.formGroup.disable();
  }
}
