import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ChartModule} from "primeng/chart";
import {ButtonDirective} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DialogService} from "primeng/dynamicdialog";
import {ToastService} from "../../../../core/services/toast.service";
import {OperationsTitresService} from "../../../../core/services/operationsTitres.service";
import {DatePipe, NgIf, UpperCasePipe} from "@angular/common";
import {VolumeWalletDTO} from "../../../../core/models/volumeWalletDTO";
import {DropdownModule} from "primeng/dropdown";
import {countriesUemoa} from "../../../../../assets/countries.uemoa";
import {VolumeWalletDurationDTO} from "../../../../core/models/VolumeWalletDurationDTO";

@Component({
  selector: 'app-resume',
  standalone: true,
  imports: [
    ChartModule,
    ButtonDirective,
    CalendarModule,
    FormsModule,
    DropdownModule,
    ReactiveFormsModule,
    UpperCasePipe,
    NgIf
  ],
  templateUrl: './resumePortefeuilleTab.component.html',
  styleUrl: './resumePortefeuilleTab.component.scss'
})
export class ResumePortefeuilleTabComponent implements OnInit {

  dateRange: Date[] | undefined;
  previousDateRange: Date[] | undefined;
  data: any;
  data2: any;
  data3: any;
  filteredData3: any;
  options: any;
  options2: any;
  options3: any;
  countries = countriesUemoa;
  countriesOptions = [...countriesUemoa];
  selectedCountry: string | null = null;

  constructor(public dialogService: DialogService,
              private toastService :ToastService,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private operationsTitresService: OperationsTitresService,
              private datePipe: DatePipe) {}



  ngOnInit(): void {

    this.dateRange = this.getYearDateRange();
    this.previousDateRange = [...this.dateRange];
    this.applyDateFilter();
    const [startDate, endDate] = this.dateRange;

    const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-ddTHH:mm:ss')!;
    const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-ddTHH:mm:ss')!;

    this.loadChartWalletData(formattedStartDate, formattedEndDate);
    this.loadChartWalletDataForDuration(formattedStartDate, formattedEndDate);

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');


    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false
        },
        legend: {
          position: 'bottom',
          labels: {
            color: textColor
          }
        },
        title: {
          display: true,
          text: 'Evolution du portefeuille',
          color: textColor,
          font: {
            size: 25
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y1: {
          position: 'right',
          ticks: {
            color: textColorSecondary
          },
          grid: {
            drawOnChartArea: false
          }
        }
      }
    };

    /*this.data2 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'OAT',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4
        },
        {
          label: 'BAT',
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--green-500'),
          tension: 0.4
        },
        {
          label: 'Moyenne',
          data: [14, 34, 28, 45, 54, 12, 65],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4
        }
      ]
    };*/

    this.options2 = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false
        },
        legend: {
          position: 'bottom',
          labels: {
            color: textColor
          }
        },
        title: {
          display: true,
          text: 'Duration du portefeuille',
          color: textColor,
          font: {
            size: 25
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

    this.data3 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Limites',
          data: [14, 34, 28, 45, 54, 12, 65],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4
        },
        {
          label: 'Bénin',
          data: [20, 30, 40, 50, 60, 70, 80],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4
        },
        {
          label: 'Côte d\'Ivoire',
          data: [15, 25, 35, 45, 55, 65, 75],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--green-500'),
          tension: 0.4
        },
        {
          label: 'Burkina Faso',
          data: [12, 22, 32, 42, 52, 62, 72],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--red-500'),
          tension: 0.4
        },
        {
          label: 'Guinée-Bissau',
          data: [18, 28, 38, 48, 58, 68, 78],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--yellow-500'),
          tension: 0.4
        },
        {
          label: 'Sénégal',
          data: [15, 18, 28, 18, 38, 98, 48],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--black-500'),
          tension: 0.4
        }
      ]
    };

    this.filteredData3 = { ...this.data3 };

    this.options3 = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false
        },
        legend: {
          position: 'bottom',
          labels: {
            color: textColor
          }
        },
        title: {
          display: true,
          text: 'Evolution du portefeuille',
          color: textColor,
          font: {
            size: 25
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  applyDateFilter() {
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      const startDate = this.datePipe.transform(this.dateRange[0], 'yyyy-MM-ddTHH:mm:ss');
      const endDate = this.datePipe.transform(this.dateRange[1], 'yyyy-MM-ddTHH:mm:ss');

      if (startDate && endDate) {
        if (!this.previousDateRange || this.dateRange[0].getTime() !== this.previousDateRange[0].getTime() || this.dateRange[1].getTime() !== this.previousDateRange[1].getTime()) {
          this.loadChartWalletData(startDate, endDate);
          this.previousDateRange = [...this.dateRange];
        }
      } else {
        this.toastService.showToast('Erreur', 'Erreur de formatage des dates.', 'error');
      }
    } else {
      this.toastService.showToast('Erreur', 'Veuillez sélectionner une plage de dates valide.', 'error');
    }
  }

  filterData() {
    if (this.selectedCountry) {
      this.filteredData3 = {
        labels: this.data3.labels,
        datasets: this.data3.datasets.filter((ds: { label: string }) =>
          ds.label === this.selectedCountry || ds.label === 'Limites')
      };
    } else {
      this.filteredData3 = {
        labels: this.data3.labels,
        datasets: this.data3.datasets.filter((ds: { label: string }) => ds.label !== 'Limites')
      };
    }
  }

  loadChartWalletData(startDate: string, endDate: string) {
    this.operationsTitresService.getVolumesWalletByPeriod(startDate, endDate).subscribe({
      next: (response: VolumeWalletDTO[]) => {
        const oat = response.filter(vol => vol.typeInstrument === 'OAT' && vol.account === 'PROPRE').map(vol => vol.totalAmount);
        const bat = response.filter(vol => vol.typeInstrument === 'BAT' && vol.account === 'PROPRE').map(vol => vol.totalAmount);
        const averageRates = response.filter(vol => (vol.typeInstrument === 'OAT' || vol.typeInstrument === 'BAT') && vol.account === 'PROPRE').map(vol => vol.averageRate);

        const uniqueMonths = new Set(response.map(vol => {return `${this.getMonthName(vol.month)} ${vol.year}`;}));
        const labels = Array.from(uniqueMonths);

        this.data = {
          labels: labels,
          datasets: [
            {
              type: 'line',
              label: 'Taux',
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
              fill: false,
              tension: 0.4,
              yAxisID: 'y1',
              data: averageRates
            },
            {
              label: 'BAT',
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-700'),
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-700'),
              data: bat
            },
            {
              label: 'OAT',
              backgroundColor: '#9683EC',
              borderColor: '#9683EC',
              data: oat
            }
            ,

          ]
        };
      },
      error: (err) => {
        this.toastService.showToast('Erreur', 'Erreur chargement des données', 'error', err);
      }
    });
  }

  loadChartWalletDataForDuration(startDate: string, endDate: string) {
    this.operationsTitresService.getVolumesWalletByDuration(startDate, endDate).subscribe({
      next: (response: VolumeWalletDurationDTO[]) => {
        const oat = response.filter(vol => vol.typeInstrument === 'OAT' && vol.account === 'PROPRE').map(vol => vol.dureeMoyenne);
        const bat = response.filter(vol => vol.typeInstrument === 'BAT' && vol.account === 'PROPRE').map(vol => vol.dureeMoyenne);

        const uniqueMonths = new Set(response.map(vol => {return `${this.getMonthName(vol.month)} ${vol.year}`;}));
        const labels = Array.from(uniqueMonths);

        this.data2 = {
          labels: labels,
          datasets: [

            {
              label: 'OAT',
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
              data: oat
            },
            {
              label: 'BAT',
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
              data: bat
            },
            {
              label: 'Moyenne',
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--purple-800'),
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--purple-800'),
              data: [15, 18, 28, 18, 38, 98, 48],
            },
          ]
        };
      },
      error: (err) => {
        this.toastService.showToast('Erreur', 'Erreur chargement des données', 'error', err);
      }
    });
  }

  private getYearDateRange(): Date[] {
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const endDate = new Date(currentYear, 11, 31);
    return [startDate, endDate];
  }
  private getMonthName(monthNumber: number): string {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('default', { month: 'long' });
  }

  protected readonly countriesUemoa = countriesUemoa;
}
