import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getPageableAsParams, Page, Pageable } from '../models/page.model';
import {IAccountBalance, ICurrencyBalance} from "../models/account.model";

@Injectable({
  providedIn: 'root'
})
export class AccountBalanceService {

  private readonly apiUrl = `operation/v1/balance`;

  constructor(private readonly http: HttpClient) { }

  /**
   * Get the latest balance for a specific account.
   * @param accountId The id of the account.
   * @returns The latest IAccountBalance.
   */
  getLatestAccountBalance(accountId: string): Observable<IAccountBalance> {
    let params = new HttpParams().set('accountId', accountId);
    return this.http.get<IAccountBalance>(`${this.apiUrl}/account/latest`, { params });
  }

  /**
   * Get balance for a specific account on a specific date.
   * @param accountId The id of the account.
   * @param date The date for the balance.
   * @param type The type of balance (default is CLOSING).
   * @returns The IAccountBalance.
   */
  getBalance(accountId: string, date: Date, type: string = 'Clôture'): Observable<IAccountBalance> {
    let params = new HttpParams()
      .set('accountId', accountId)
      .set('date', date.toISOString().split('T')[0])
      .set('type', type);
    return this.http.get<IAccountBalance>(`${this.apiUrl}/account/balance`, { params });
  }

  /**
   * Get balances for a specific account with pagination.
   * @param accountId The id of the account.
   * @param pageable The pagination parameters.
   * @returns A page of IAccountBalance.
   */
  getBalancesByAccountId(accountId: string, pageable: Pageable): Observable<Page<IAccountBalance>> {
    let params = getPageableAsParams(pageable).set('accountId', accountId);
    return this.http.get<Page<IAccountBalance>>(`${this.apiUrl}/account`, { params });
  }

  /**
   * Get all account balances with pagination.
   * @param pageable The pagination parameters.
   * @returns A page of IAccountBalance.
   */
  getAccountBalances(pageable: Pageable): Observable<Page<IAccountBalance>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccountBalance>>(this.apiUrl, { params });
  }

  /**
   * Get the latest balance for each account with pagination.
   * @param pageable The pagination parameters.
   * @returns A page of IAccountBalance.
   */
  getLatestBalanceForEachAccount(pageable: Pageable): Observable<Page<IAccountBalance>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccountBalance>>(`${this.apiUrl}/latest`, { params });
  }

  /**
   * Get balance history for a specific account.
   * @param accountId The id of the account.
   * @param startDate The start date of the history.
   * @param endDate The end date of the history.
   * @returns A list of IAccountBalance.
   */
  getAccountBalanceHistory(accountId: string, startDate: Date, endDate: Date): Observable<IAccountBalance[]> {
    let params = new HttpParams()
      .set('accountId', accountId)
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<IAccountBalance[]>(`${this.apiUrl}/history/account`, { params });
  }

  /**
   * Get balance history for an institution.
   * @param startDate The start date of the history.
   * @param endDate The end date of the history.
   * @param isLocalCurrency Whether to get local currency balances (default is true).
   * @returns A list of ICurrencyBalance.
   */
  getInstitutionBalanceHistory(startDate: Date, endDate: Date, isLocalCurrency: boolean = true): Observable<ICurrencyBalance[]> {
    let params = new HttpParams()
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0])
      .set('isLocalCurrency', isLocalCurrency.toString());
    return this.http.get<ICurrencyBalance[]>(`${this.apiUrl}/history/institution`, { params });
  }

  getBalanceHistoryByCurrency(currency: string, startDate: Date, endDate: Date): Observable<ICurrencyBalance[]> {
    let params = new HttpParams()
      .set('currency', currency)
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<ICurrencyBalance[]>(`${this.apiUrl}/history/institution/currency`, { params });
  }

  /**
   * Get the latest currency balance - either local or foreign - for an institution.
   *
   * @param date
   * @param isLocalCurrency
   */
  getLatestCurrencyBalance(date: Date = new Date(), isLocalCurrency: boolean = true): Observable<ICurrencyBalance[]> {
    let params = new HttpParams()
      .set('date', date.toISOString().split('T')[0])
      .set('isLocalCurrency', isLocalCurrency.toString());
    return this.http.get<ICurrencyBalance[]>(`${this.apiUrl}/latest/institution`, { params });
  }

  /**
   * Get the latest balance for a specific currency.
   *
   * @param currency
   * @param date
   */
  getLatestBalanceByCurrency(currency: string, date: Date = new Date()): Observable<ICurrencyBalance> {
    let params = new HttpParams()
      .set('currency', currency)
      .set('date', date.toISOString().split('T')[0]);
    return this.http.get<ICurrencyBalance>(`${this.apiUrl}/latest/institution/currency`, { params });
  }

  /**
   * Delete an account balance.
   * @param accountId The id of the account.
   * @param date The date of the balance to delete.
   * @param type The type of balance to delete (default is CLOSING).
   * @returns An Observable for the deletion operation.
   */
  deleteAccountBalance(accountId: string, date: Date, type: string = 'Clôture'): Observable<void> {
    let params = new HttpParams()
      .set('accountId', accountId)
      .set('date', date.toISOString().split('T')[0])
      .set('type', type);
    return this.http.delete<void>(this.apiUrl, { params });
  }
}
