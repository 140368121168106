import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { Chart } from 'chart.js';
import { fr } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';

Chart.defaults.locale = fr.code;
// Register the French locale
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error());
