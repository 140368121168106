import {Component} from '@angular/core';
import {TabMenuModule} from "primeng/tabmenu";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ TabMenuModule ],
  template: `
    <div class="flex flex-column h-full">
      <p-tabMenu [model]="items" />
      <div class="h-full w-6">
        <router-outlet />
      </div>
    </div>
  `
})
export class ProfileComponent {

  items: MenuItem[] = [
    {
      label: 'Profil',
      icon: 'pi pi-user-edit',
      routerLink: 'info'
    },
    {
      label: 'Sécurité',
      icon: 'pi pi-shield',
      routerLink: 'security'
    },
  ];

}
