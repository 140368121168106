import {Component, OnDestroy, OnInit} from '@angular/core';
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {DateRangeComponent} from "../../../../../shared/date-range/date-range.component";
import {ToastService} from "../../../../../core/services/toast.service";
import {ExportComponent, ExportData} from "../../../../../shared/components/export.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {GroupDetailsComponent} from "../../../../group/group-details/group-details.component";
import {customDynamicDialog, getFilenameFromHeader} from "../../../../../core/utils/utils";
import {take} from "rxjs";
import {ReserveService} from "../../../../../core/services/reserve.service";
import {
  DEFAULT_PAGE_PARAMS, DEFAULT_SORT_ASC,
  getDefaultPageable,
  getUnpagedPageable,
  Pageable,
  PageParams
} from "../../../../../core/models/page.model";
import {IReserve} from "../../../../../core/models/reserve.model";
import {ReserveDetailsComponent} from "./reserve-details/reserve-details.component";
import {DynamicTableComponent} from "../../../../../shared/components/dynamic-table/dynamic-table.component";
import {CustomTableHeader} from "../../../../../core/models/custom-table.model";
import {PaginatorState} from "primeng/paginator";
import {GenericLineChartComponent, LineChartDataset} from "../../../../../shared/charts/line-chart.component";
import {FormsModule} from "@angular/forms";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";

@Component({
  selector: 'app-reserve-obligatoire',
  standalone: true,
  imports: [
    // Modules
    CalendarModule, DropdownModule,
    // Components
    DateRangeComponent, ExportComponent, DynamicTableComponent, GenericLineChartComponent, FormsModule
  ],
  templateUrl: './reserve-obligatoire.component.html',
  styleUrl: './reserve-obligatoire.component.scss'
})
export class ReserveObligatoireComponent implements OnInit, OnDestroy {

  reserves: IReserve[] = [];
  columns: CustomTableHeader[] = [
    { key: 'date', column: 'Date', type: 'date' },
    { key: 'balance', column: 'Solde du jour', type: 'currency' },
    { key: 'amount', column: 'Réserve obligatoire', type: 'currency' },
    { key: 'reserveAmount', column: 'Réserve constituée', type: 'currency' }
  ];
  rangeDates: Date[] | null = null;
  ref: DynamicDialogRef | undefined;
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  chartDatasets: LineChartDataset[] = [];
  timeGroupings = [
    { label: 'Hebdomadaire', value: 'week' },
    { label: 'Mensuel', value: 'month' },
    { label: 'Trimestriel', value: 'quarter' },
    { label: 'Annuel', value: 'year' }
  ];
  selectedTimeGrouping: 'day' | 'week' | 'biweekly' | 'month' | 'quarter' | 'year' = 'week';

  constructor(
    private readonly toastService: ToastService,
    public readonly dialogService: DialogService,
    private readonly reserveService: ReserveService
  ) {}

  ngOnInit() {
    this.rangeDates = null;
    this.loadReserve();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  loadReserve(page: Pageable = getDefaultPageable(this.pageParams.page, this.pageParams.rows)) {
    const apiCall = this.rangeDates?.length == 2 ? this.reserveService.findForCurrentUser(page, this.rangeDates[0], this.rangeDates[1]) : this.reserveService.findForCurrentUser(page);
    apiCall.subscribe({
      next: (page) => {
        this.reserves = page.content;
        this.pageParams = {
          ...this.pageParams,
          totalRecords: page.totalElements
        };
        this.prepareChartData();
      },
      error: (err) => this.toastService.showToast('Historique des réserves obligatoires', err.error, 'error')
    });
  }

  prepareChartData() {
    const amountData = this.groupDataByTime(this.reserves.map(reserve => ({ date: new Date(reserve.date), value: reserve.amount })));
    const reserveAmountData = this.groupDataByTime(this.reserves.map(reserve => ({ date: new Date(reserve.date), value: reserve.reserveAmount })));

    this.chartDatasets = [
      {
        label: 'Réserve obligatoire',
        data: amountData,
        color: '#FF6384'
      },
      {
        label: 'Réserve constituée',
        data: reserveAmountData,
        color: '#36A2EB'
      }
    ];
  }

  groupDataByTime(data: Array<{ date: Date; value: number }>): Array<{ x: Date; y: number }> {
    const groupedData: { [key: string]: { sum: number, count: number } } = {};

    data.forEach(item => {
      const groupDate = this.getGroupDate(item.date);
      const key = groupDate.toISOString();

      if (!groupedData[key]) {
        groupedData[key] = { sum: 0, count: 0 };
      }
      groupedData[key].sum += item.value;
      groupedData[key].count++;
    });

    return Object.entries(groupedData)
      .map(([dateString, { sum, count }]) => ({
        x: new Date(dateString),
        y: sum / count  // average
      }))
      .sort((a, b) => a.x.getTime() - b.x.getTime());
  }

  getGroupDate(date: Date): Date {
    const d = new Date(date);
    switch (this.selectedTimeGrouping) {
      case 'week':
        d.setDate(d.getDate() - d.getDay() + 1);  // Start of week (Monday)
        break;
      case 'biweekly':
        d.setDate(d.getDate() <= 15 ? 1 : 16);
        break;
      case 'month':
        d.setDate(1);
        break;
      case 'quarter': {
        const quarter = Math.floor(d.getMonth() / 3);
        d.setMonth(quarter * 3, 1);
        break;
      }
      case 'year':
        d.setMonth(0, 1);
        break;
    }
    d.setHours(0, 0, 0, 0);
    return d;
  }

  onTimeGroupingChange() {
    this.prepareChartData();
  }

  onDateRangeSelected(dateRange: Date[]) {
    this.rangeDates = dateRange;
    this.loadReserve(getUnpagedPageable(DEFAULT_SORT_ASC));
  }

  onInvalidRange(message: string) {
    this.toastService.showToast('Historique des soldes', message, 'error');
  }

  exportReserve($event: ExportData) {
    const summary = 'Export de l\'historique des réserves obligatoires';
    const detail = 'Veuillez renseigner les dates de début et de fin.';
    if ($event.dates?.[0] == null || $event.dates?.[1] == null) {
      this.toastService.showToast(summary, detail, 'error');
    }
    this.reserveService.exportReserve($event.dates![0], $event.dates![1], $event.extension === 'excel')
      .subscribe({
        next: response => {
          const blob = response.body;
          const filename = getFilenameFromHeader(response);
          if (blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename || `releve_reserve_obligatoire.xlsx`;
            link.click();
            window.URL.revokeObjectURL(url);
          } else {
            this.toastService.showToast(summary, 'Une erreur est survenue lors de l\'export.', 'error');
          }
        },
        error: err => this.toastService.showToast(summary, err.error, 'error')
      });
  }

  showReserveDetails(reserve: IReserve) {
    const dialogOptions = { position: 'center', height: '80vh', width: '60rem' }
    this.ref = this.dialogService.open(GroupDetailsComponent, customDynamicDialog('Détails de la réserve obligatoire', reserve, dialogOptions));
  }

  createNewEntry() {
    const data = { isCreate: true } as CustomDialogData;
    this.ref = this.dialogService.open(ReserveDetailsComponent, customDynamicDialog('Nouvelle entrée de réserve obligatoire', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.ngOnInit())
  }

  onPageChange($event: PaginatorState) {
    this.pageParams = {
      ...this.pageParams,
      page: $event.page!,
      rows: $event.rows!
    }
    this.loadReserve(getDefaultPageable($event.page, $event.rows));
  }

}
