import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Button} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Ripple} from "primeng/ripple";
import {WicketOperationRequestModel} from "../../../../core/models/WicketOperationRequest.model";
import {PageParams} from "../../../../core/models/page.model";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {EmissionPrimaireService} from "../../../../core/services/emission.primaire.service";
import {ToastService} from "../../../../core/services/toast.service";
import {OperationsTitresService} from "../../../../core/services/operationsTitres.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {WicketOperationService} from "../../../../core/services/wicketOperation.service";
import {DatePipe} from "@angular/common";
import {RegisterResultWicketOperationModel} from "../../../../core/models/registerResultWicketOperation.model";
import {ResultWicketOperationService} from "../../../../core/services/resultWicketOperation.service";

@Component({
  selector: 'app-new-result-wicket',
  standalone: true,
    imports: [
        Button,
        CalendarModule,
        DropdownModule,
        InputTextModule,
        ReactiveFormsModule,
        Ripple
    ],
  templateUrl: './new-result-wicket.component.html',
  styleUrl: './new-result-wicket.component.scss'
})
export class NewResultWicketComponent implements OnInit {


  constructor(public dialogService: DialogService,
              private toastService: ToastService,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private wicketOperationService: WicketOperationService,
              private resultWicketOperationService: ResultWicketOperationService,
              private datePipe: DatePipe,
              private ref: DynamicDialogRef,
  ) {

  }

  formGroup = new FormGroup({
    retainedAmount: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    proposedAmount: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    number: new FormControl('', {nonNullable: true, validators: [Validators.required]}),

  });

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  isCreate: boolean = false;
  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  wicketList: WicketOperationRequestModel[] = [];
  numberWicket: { label: string, value: string }[] = [];

  loadWicketOperationNumber(): void {
    this.wicketOperationService.getAllWicketOperations(0, 100, true).subscribe({
      next: (response) => {
        this.wicketList = response.content;
        this.numberWicket = this.wicketList.map(wicketOperation => ({
          label: wicketOperation.number,
          value: wicketOperation.number
        }));
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
        this.isLoading = false;
      }
    })
  }

  ngOnInit(): void {
    this.loadWicketOperationNumber();
  }

  createResultWicketOperation() {

    if (this.formGroup.valid) {
      const numberValue = this.formGroup.value.number;
      const wicket = this.wicketList.find(wicket => wicket.number === numberValue);

      if (!wicket) {
        console.error();
        return;
      }
      const dto: RegisterResultWicketOperationModel = {
        proposedAmount: Number(this.formGroup.value.proposedAmount),
        retainedAmount: Number(this.formGroup.value.retainedAmount),
        wickedOperation: {
          id: wicket.id,
          number: wicket.number,
          nature: wicket.nature,
          valueDate: wicket.valueDate,
          dueDate: wicket.dueDate,
          rate: wicket.rate,
          maxAmount: wicket.maxAmount,
          duration: wicket.duration
        }
      };
      this.isLoading = true;

      this.resultWicketOperationService.registerResultWicketOperation(dto.wickedOperation.id, dto).subscribe({
        next: () => {
          this.toastService.showToast('Succès', `Résultat créée avec succès.`, 'success');
          this.ref.close();
        },
        error: (err) => {
          this.toastService.showToast('Erreur', 'Echec', err);
          this.ref.close();
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    } else {
      console.error();
    }
  }

}
