import { Component } from '@angular/core';

@Component({
  selector: 'app-resultats',
  standalone: true,
  imports: [],
  templateUrl: './resultats.component.html',
  styleUrl: './resultats.component.scss'
})
export class ResultatsComponent {

}
