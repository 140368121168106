<div class="flex flex-column gap-4">
  <div class="flex flex-column">
    <span class="font-bold text-2xl">Récapitulatif des devises étrangères</span>
  </div>
  <div class="flex align-items-center gap-2 w-full">
    <app-multi-currency-balance-chart class="w-full" />
  </div>
  <div class="flex flex-column my-2 gap-2">
    <div class="flex justify-content-between align-items-center">
      <span class="font-semibold text-xl">Dernier solde à jour</span>
    </div>
    <div *ngIf="balances.length > 0">
      <dynamic-table [headers]="balanceColumns" [data]="balances" [actions]="balancesTableActions" />
    </div>
    <span *ngIf="balances.length === 0" class="py-2 text-gray-400 font-semibold font-italic">Aucun compte de devise étrangère</span>
  </div>

</div>
