import {Component, OnInit} from '@angular/core';
import {PrimeTemplate} from "primeng/api";
import {TimelineModule} from "primeng/timeline";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {DatePipe, NgIf} from "@angular/common";
import { IVirementResponse} from "../../../core/models/virement.model";
import {IAudit} from "../../../core/models/audit.model";

@Component({
  selector: 'app-audit',
  standalone: true,
  imports: [PrimeTemplate, TimelineModule, DatePipe, NgIf],
  templateUrl: './audit.component.html',
  styleUrl: './audit.component.scss'
})
export class AuditComponent implements OnInit {

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  audits: IAudit[] = [];

  constructor(
    private readonly dialogService: DialogService,
    private readonly ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    this.audits = this.boundData?.data.audits.sort((a: IAudit, b: IAudit) => {
      return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
    });
  }

}
