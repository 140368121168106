import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IFlowLawCategory} from "../models/flow-law.model";

@Injectable({
  providedIn: 'root'
})
export class FlowLawCategoryService {
  private readonly apiUrl = `risque/v1/flow-law-categories`;

  constructor(private readonly http: HttpClient) { }

  createFlowLawCategory(category: IFlowLawCategory): Observable<IFlowLawCategory> {
    return this.http.post<IFlowLawCategory>(this.apiUrl, category);
  }

  searchFlowLawCategories(query: string = '', pageable: Pageable): Observable<Page<IFlowLawCategory>> {
    const params = getPageableAsParams(pageable).set('query', query);
    return this.http.get<Page<IFlowLawCategory>>(`${this.apiUrl}/search`, { params });
  }

  getFlowLawCategoryById(id: string): Observable<IFlowLawCategory> {
    return this.http.get<IFlowLawCategory>(`${this.apiUrl}/${id}`);
  }

  getAllFlowLawCategories(pageable: Pageable): Observable<Page<IFlowLawCategory>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IFlowLawCategory>>(this.apiUrl, { params });
  }

  getFlowLawCategoriesByFlowLaw(flowLawId: string, pageable: Pageable): Observable<Page<IFlowLawCategory>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IFlowLawCategory>>(`${this.apiUrl}/flow-law/${flowLawId}`, { params });
  }

  getValidFlowLawCategories(date: Date, pageable: Pageable): Observable<Page<IFlowLawCategory>> {
    const params = getPageableAsParams(pageable)
      .set('date', date.toISOString().split('T')[0]);
    return this.http.get<Page<IFlowLawCategory>>(`${this.apiUrl}/valid`, { params });
  }

  getCurrentUserFlowLawCategories(pageable: Pageable): Observable<Page<IFlowLawCategory>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IFlowLawCategory>>(`${this.apiUrl}/institution`, { params });
  }

  deleteFlowLawCategory(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
