import {Message, MessageService} from 'primeng/api';
import {Injectable} from "@angular/core";

/**
 * Service class for showing messages.
 */
@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(private messageService: MessageService) {}

  /**
   * Shows a toast message.
   *
   * @param {string}  summary       The summary of the toast message.
   * @param {string}  detail        The detail of the toast message.
   * @param {string}  severity      The severity of the toast message.
   * @param {Message} customOptions Custom options for the toast message.
   */
  showToast(summary: string, detail: string, severity: string = 'success', customOptions: Message = {} as Message) {
    this.messageService.add({closable: true, life: 5000, sticky: false, summary, detail, severity, ...customOptions});
  }
}
