<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label for="region" class="font-medium">Emetteur</label>
      <p-dropdown
        [options]="countries" formControlName="region" optionLabel="country"
        editable="true" id="region" optionValue="code" [showClear]="true" placeholder="Choisir un pays">
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img alt="{{ country.country }}"
                 src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg"
                 style="width: 18px" />
            <div>{{ country.country }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small *ngIf="isCreate" id="pays-help">Sélectionner l'emetteur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Date de début</label>
      <p-calendar formControlName="startDate" dateFormat="dd/mm/yy" />
      <small *ngIf="isCreate" id="startDate-help">Renseigner ici la date de début.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Date de fin</label>
      <p-calendar formControlName="endDate" dateFormat="dd/mm/yy" />
      <small *ngIf="isCreate" id="endDate-help">Renseigner ici la date de fin.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Montant maximal</label>
      <input type="number" pInputText id="maxAmount" aria-describedby="maxAmount-help" formControlName="maxAmount" min="0" step="0.01" />
      <small *ngIf="isCreate" id="maxAmount-help">Renseigner ici le montant maximal.</small>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end justify-content-end ">
    <p-button class="my-4" pRipple *ngIf="!formGroup.disabled"  [style]="{width: '100%'}"  [label]=" isCreate  ? 'Enregistrer' : 'Modifier'" [disabled]="!formGroup.valid"
              [loading]="isLoading" (onClick)="isCreate ? addLimit() : updateLimit()"></p-button>
  </div>
</div>

