import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ChartModule } from 'primeng/chart';

@Component({
  selector: 'app-suivi-custom-chart',
  standalone: true,
  imports: [ChartModule],
  templateUrl: './suivi-chart.component.html',
  styleUrl: './suivi-chart.component.scss'
})
export class SuiviChartComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() options: any;
  @Input() legendContainerId: string = 'legend-container';

  @ViewChild('chart') chartRef!: ElementRef;
  chartInstance: any;

  constructor() {}

  ngOnInit() {
    Chart.register(...registerables);
    this.registerHtmlLegendPlugin();
  }

  ngAfterViewInit() {
    this.renderChart();
  }

  registerHtmlLegendPlugin() {
    const htmlLegendPlugin = {
      id: 'htmlLegend',
      beforeInit: (chart: Chart, args: any, options: any) => {
        const legendContainer = document.getElementById(this.legendContainerId);
        if (legendContainer) {
          legendContainer.innerHTML = ''; // Clear any existing content
        }
      },
      afterUpdate: (chart: Chart, args: any, options: any) => {
        const legendContainer = document.getElementById(this.legendContainerId);
        if (legendContainer) {
          legendContainer.innerHTML = '';

          const columnContainer = document.createElement('div');
          columnContainer.style.display = 'flex';
          columnContainer.style.flexDirection = 'row';
          columnContainer.style.flexWrap = 'wrap';
          columnContainer.style.width = '100%'; // Adjust width as needed
          columnContainer.style.justifyContent = 'space-between'

          if (chart.legend && chart.legend.legendItems) {
            const items = chart.legend.legendItems;

            // Separate line chart items from bar chart items
            const lineChartItems = items.filter(item => item.text.includes('Taux'));
            const barChartItems = items.filter(item => !item.text.includes('Taux'));

            // Create column for a subset of items
            const createColumn = (itemsSubset: any[], isLineChart: boolean = false) => {
              const column = document.createElement('div');
              column.style.flex = '1 1 1 0%';
              column.style.marginRight = '1px'; // Space between columns

              const ul = document.createElement('ul');
              ul.style.listStyle = 'none';
              ul.style.padding = '0';
              ul.style.marginTop = '0';

              itemsSubset.forEach((item) => {
                const li = document.createElement('li');
                li.style.display = 'flex';
                li.style.alignItems = 'center';
                li.style.marginBottom = '0';
                li.style.marginTop = '0';

                const colorBox = document.createElement('div');
                colorBox.style.width = isLineChart ? '30px' : '17px';
                colorBox.style.height = isLineChart ? '3px' : '16px';
                colorBox.style.backgroundColor = item.fillStyle || '#CCCCCC';
                colorBox.style.border = item.text.includes('Gap') ? '1px dashed black' : 'none'; // Dashed border for 'Gap'
                colorBox.style.marginRight = '10px';

                const text = document.createElement('span');
                text.style.color = '#474747';
                text.innerText = item.text;
                text.style.fontSize = '11px';

                li.appendChild(colorBox);
                li.appendChild(text);
                ul.appendChild(li);
              });

              column.appendChild(ul);
              return column;
            };

            // Split items into balance-sheet-categories and line chart items
            const numCategoriesPerColumn = 4;
            const gapIndex = numCategoriesPerColumn * 2; // Assuming gap is right after 8 items

            // First two columns for balance-sheet-categories
            columnContainer.appendChild(createColumn(barChartItems.slice(0, numCategoriesPerColumn)));
            columnContainer.appendChild(createColumn(barChartItems.slice(numCategoriesPerColumn, numCategoriesPerColumn * 2)));

            // Third column for gap
            columnContainer.appendChild(createColumn(barChartItems.slice(gapIndex, gapIndex + 1))); // Adjust if needed

            // Fourth column for line charts
            columnContainer.appendChild(createColumn(lineChartItems, true));
          }

          legendContainer.appendChild(columnContainer);
        }
      },
    };

    Chart.register(htmlLegendPlugin);
  }

  renderChart() {
    const ctx = this.chartRef.nativeElement.getContext('2d');
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
    this.chartInstance = new Chart(ctx, {
      type: 'bar',
      data: this.data,
      options: this.options,

    });
  }
}
