<div class="flex flex-column results-container">
  <p>Résultats de la simulation</p>
  <div class="flex flex-wrap result-fields-container">

    <!-- Durée de vie résiduelle -->
    <div class="flex flex-column gap-1 field">
      <label class="block" for="duration">Durée de vie {{ isMarcheSecondaire() ? "résiduelle" : ""}} (jours)</label>
      <p-inputNumber locale="fr-FR" [(ngModel)]="duration" aria-describedby="Durée de vie résiduelle"
                     inputId="duration" placeholder="-" readonly
      ></p-inputNumber>
    </div>

    <!-- Taux de rendement -->
    <div class="flex flex-column gap-1 field">
      <label class="block" for="rateOfReturn">Taux de rendement</label>
      <p-inputNumber [(ngModel)]="rateOfReturn" [maxFractionDigits]="6" [minFractionDigits]="2" aria-describedby="Taux de rendement" inputId="rateOfReturn"

                     mode="decimal" placeholder="-" readonly suffix="%"></p-inputNumber>
    </div>

    <!-- Montant net -->
    <div class="flex flex-column gap-1 field">
      <label class="block" for="netAmount">Montant net</label>
      <p-inputNumber [(ngModel)]="netAmount" aria-describedby="montant net" currency="XOF"

                     inputId="netAmount" locale="fr-FR" mode="currency" placeholder="-" readonly></p-inputNumber>
    </div>

    <ng-container *ngIf="isBAT">
      <!-- Intérêts générés -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="interest">Intérêts</label>
        <p-inputNumber [(ngModel)]="interest" aria-describedby="Intérêts" currency="XOF" inputId="interest"
                       locale="fr-FR" mode="currency" placeholder="-" readonly></p-inputNumber>
      </div>
    </ng-container>
    <ng-container *ngIf="isOAT">
      <div class="flex flex-column gap-1 field">
        <label class="block" for="couponCouru">Coupon couru</label>
        <p-inputNumber [(ngModel)]="couponCouru"  [minFractionDigits]="12" [maxFractionDigits]="13" aria-describedby="Coupon couru" currency="XOF"
                       inputId="couponCouru" locale="fr-FR" mode="decimal" placeholder="-" readonly suffix=" %"></p-inputNumber>
      </div>
      <div class="flex flex-column gap-1 field">
        <label class="block" for="placementPrice">Prix du placement</label>
        <p-inputNumber [(ngModel)]="placementPrice" aria-describedby="Prix du placement" currency="XOF"

                       inputId="placementPrice" locale="fr-FR" mode="currency" placeholder="-" readonly suffix=" F CFA"></p-inputNumber>
      </div>
      <div class="flex flex-column gap-1 field">
        <label class="block" for="interestCourus">Intérêts courus</label>
        <p-inputNumber [(ngModel)]="interestCourus" aria-describedby="Intérêts courus" currency="XOF"

                       inputId="interestCourus" locale="fr-FR" mode="currency" placeholder="-" readonly></p-inputNumber>
      </div>

    </ng-container>


  </div>
  <div class="card flex justify-content-center simulation-results-buttons simulation-start-button">
    <div class="submit-result">
      <p-button styleClass="p-button-alm-primary" icon="pi pi-check" label="Enregistrer résultat" (onClick)="saveSimulation()" [disabled]="isButtonDisabled()"></p-button>
    </div>
    <p-button icon="pi pi-trash" label="Supprimer résultat" outlined="true" severity="warning" (onClick)="showResetForm()" [disabled]="isButtonDisabled()"></p-button>
  </div>

</div>
