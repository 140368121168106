import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChartModule} from 'primeng/chart';
import {startOfMonth, startOfQuarter, startOfWeek} from 'date-fns';
import {fr} from 'date-fns/locale';
import {IForecastCashFlowOverview} from "../../../../../../core/models/forecast-cash-flow.model";

@Component({
  selector: 'app-prev-overview-chart',
  template: `
    <p-chart type="bar" [data]="chartData" [options]="chartOptions" />
  `,
  standalone: true,
  imports: [ChartModule]
})
export class PrevOverviewChartComponent implements OnChanges {

  @Input() data: IForecastCashFlowOverview[] = [];
  @Input() timeGrouping: string = 'daily';
  @Input() dates: Date[] = [];
  @Input() currency: string = 'XOF';

  @Input() currentBalance: number = 0;
  chartData: any;
  chartOptions: any;
  isDataReady: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] || changes['timeGrouping'] || changes['dates'] || changes['currentBalance'] || changes['currency']) {
      this.processChartData();
    }
  }

  processChartData() {
    console.log('Currency:', this.currency);
    if (!this.dates || this.dates.length < 2 || !this.data || this.data.length === 0) {
      this.isDataReady = false;
      return;
    }

    const allDates = this.getAllDatesBetween(this.dates[0], this.dates[1]);
    const groupedData = this.groupDataByTime(this.data, this.timeGrouping);

    const labels = allDates.map(date => this.getGroupedDate(date, this.timeGrouping));
    const uniqueLabels = [...new Set(labels.map(date => date.getTime()))]
      .sort((a, b) => a - b)
      .map(timestamp => new Date(timestamp));

    const entries = uniqueLabels.map(date =>
      groupedData.find(item => item.key.date.getTime() === date.getTime() && item.key.type === 'Entrée')?.amount ?? 0
    );

    const exits = uniqueLabels.map(date =>
      groupedData.find(item => item.key.date.getTime() === date.getTime() && item.key.type === 'Sortie')?.amount ?? 0
    );

    const balances = this.calculateBalances(uniqueLabels, entries, exits);

    this.chartData = {
      labels: uniqueLabels,
      datasets: [
        {
          type: 'bar',
          label: 'Entrées prévisionnelles',
          backgroundColor: 'rgb(0,134,10)',
          data: entries,
          yAxisID: 'y-axis-1'
        },
        {
          type: 'bar',
          label: 'Sorties prévisionnelles',
          backgroundColor: 'rgb(255,0,0)',
          data: exits,
          yAxisID: 'y-axis-1'
        },
        {
          type: 'line',
          label: 'Solde d\'ouverture de trésorerie',
          borderColor: 'rgba(0, 0, 0, 1)',
          data: balances.map(b => ({ x: b.date, y: b.opening })),
          yAxisID: 'y-axis-2'
        },
        {
          type: 'line',
          label: 'Solde de fermeture de trésorerie',
          borderColor: 'rgba(255, 159, 64, 1)',
          data: balances.map(b => ({ x: b.date, y: b.closing })),
          yAxisID: 'y-axis-2'
        }
      ]
    };

    this.setChartOptions();
    this.isDataReady = true;
  }

  private setChartOptions() {
    const timeUnit = this.getTimeUnit();
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: timeUnit,
            displayFormats: this.getDisplayFormat(timeUnit)
          },
          adapters: {
            date: { locale: fr }
          }
        },
        'y-axis-1': {
          type: 'linear',
          display: true,
          position: 'left',
          title: {
            display: true,
            text: 'Entrées / Sorties',
            font: {
              weight: 'bold'
            }
          },
          ticks: {
            callback: (value: number) => {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                notation: 'compact'
              }).format(value);
            }
          }
        },
        'y-axis-2': {
          type: 'linear',
          display: true,
          position: 'right',
          title: {
            display: true,
            text: 'Soldes',
            font: {
              weight: 'bold'
            }
          },
          ticks: {
            callback: (value: number) => {
              return new Intl.NumberFormat('fr-FR', {
                style: 'decimal',
                notation: 'compact'
              }).format(value);
            }
          },
          grid: {
            drawOnChartArea: false
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              let label = context.dataset.label || '';
              if (label) label += ': ';
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('fr-FR', { style: 'currency', currency: this.currency }).format(context.parsed.y);
              }
              return label;
            }
          }
        },
        legend: {
          position: 'bottom',
        }
      }
    };
  }

  private groupDataByTime(data: IForecastCashFlowOverview[], grouping: string): IForecastCashFlowOverview[] {
    const groupedMap = new Map<string, IForecastCashFlowOverview>();

    data.forEach(item => {
      const groupedDate = this.getGroupedDate(new Date(item.key.date), grouping);
      const mapKey = `${groupedDate.getTime()}-${item.key.type}`;

      if (groupedMap.has(mapKey)) {
        const existing = groupedMap.get(mapKey)!;
        existing.amount += item.amount;
      } else {
        groupedMap.set(mapKey, {
          ...item,
          key: {
            ...item.key,
            date: groupedDate
          }
        });
      }
    });

    return Array.from(groupedMap.values());
  }
  private getGroupedDate(date: Date, grouping: string): Date {
    switch (grouping) {
      case 'week':
        return startOfWeek(date, { weekStartsOn: 1 });
      case 'biweekly': {
        const dayOfMonth = date.getDate();
        return dayOfMonth <= 15 ? new Date(date.getFullYear(), date.getMonth(), 1) : new Date(date.getFullYear(), date.getMonth(), 16);
      }
      case 'month':
        return startOfMonth(date);
      case 'quarter':
        return startOfQuarter(date);
      default:
        return date;
    }
  }

  private calculateBalances(dates: Date[], entries: number[], exits: number[]): Array<{date: Date, opening: number, closing: number}> {
    let balance = this.currentBalance;
    return dates.map((date, index) => {
      const opening = balance;
      balance += entries[index] - exits[index];
      return { date, opening, closing: balance };
    });
  }

  private getTimeUnit(): 'day' | 'week' | 'month' | 'quarter' {
    switch (this.timeGrouping) {
      case 'daily': return 'day';
      case 'week':
      case 'biweekly': return 'week';
      case 'month': return 'month';
      case 'quarter': return 'quarter';
      default: return 'day';
    }
  }

  private getDisplayFormat(timeUnit: string) {
    switch (timeUnit) {
      case 'week': return { week: 'dd/MM/yyyy' };
      case 'month': return { month: 'MM/yyyy' };
      case 'quarter': return { quarter: "QQQ yyyy" };
      default: return { day: 'dd/MM/yyyy' };
    }
  }

  getAllDatesBetween(startDate: Date, endDate: Date): Date[] {
    const dates: Date[] = [];
    const currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);

    const normalizedEndDate = new Date(endDate);
    normalizedEndDate.setHours(23, 59, 59, 999);

    while (currentDate <= normalizedEndDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }
}
