<div class="p-4" *ngIf="virements.length <= 0">
  <div class="card flex justify-content-center w-full mb-4">
    <p-fileUpload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="w-full" (onSelect)="onUpload($event)" [showUploadButton]="false"
                  [showCancelButton]="false" [multiple]="false" [maxFileSize]="1000000">
      <ng-template pTemplate="toolbar">
        <div class="py-3">Ajouter le fichier Excel contenant les virements</div>
      </ng-template>
    </p-fileUpload>
  </div>
  <div class="flex my-4 gap-2 justify-content-end">
    <p-button pRipple class="mb-4" label="Charger" [disabled]="uploadedFile == undefined"
              [loading]="isLoading" (onClick)="upload()" />
  </div>
</div>
<app-upload-table
  *ngIf="virements.length > 0"
  [headers]="columns"
  [data]="virements"
  (saveData)="saveValidVirements($event)">
</app-upload-table>
