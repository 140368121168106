import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getDefaultPageable, getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IOperation} from "../models/operation.model";

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private readonly apiUrl = `operation/v1/operations`;

  constructor(private readonly http: HttpClient) { }


  searchOperation(query: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.append('query', query);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/institution/search`, { params });
  }

  searchOperationsForAccount(query: string, accountId: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable)
      .set('query', query)
      .set('accountId', accountId);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/account/search`, { params });
  }

  /**
   * Create a new operation.
   * @param operation The operation DTO to create.
   * @returns The created IOperation.
   */
  createOperation(operation: IOperation): Observable<IOperation> {
    return this.http.post<IOperation>(this.apiUrl, operation);
  }

  /**
   * Get a specific operation by its id.
   * @param id The id of the operation.
   * @returns The IOperation.
   */
  getOperationById(id: string): Observable<IOperation> {
    return this.http.get<IOperation>(`${this.apiUrl}/${id}`);
  }

  /**
   * Get all operations with optional pagination.
   * @param pageable The parameter of the requested page.
   * @returns A page of IOperation.
   */
  getAllOperations(pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(this.apiUrl, { params });
  }

  /**
   * Get operations by account id with optional pagination.
   * @param accountId The id of the account.
   * @param pageable The page params.
   * @returns A page of IOperation.
   */
  getOperationsByAccountId(accountId: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/account/${accountId}`, { params });
  }

  getFutureOperationsByAccountId(accountId: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.append('id', accountId);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/future/account`, { params });
  }

  searchFutureOperations(query: string = '', currency: string = 'XOF', pageable: Pageable = getDefaultPageable()): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable)
      .set('query', query)
      .set('currency', currency);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/future`, { params });
  }

  /**
   * Get local operations for the current institution.
   * @param pageable The page params.
   * @returns A page of IOperation.
   */
  getLocalOperationsForInstitution(pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/local`, { params });
  }

  /**
   * Get foreign operations for the current institution.
   * @param pageable The page params.
   * @returns A page of IOperation.
   */
  getForeignOperationsForInstitution(pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/foreign`, { params });
  }

  /**
   * Get operations by state and institution id.
   * @param institutionId The id of the institution.
   * @param state The state of the operations.
   * @param pageable The page params.
   * @returns A page of IOperation.
   */
  getOperationsByStateAndInstitutionId(institutionId: string, state: string, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/institution/${institutionId}/state/${state}`, { params });
  }

  /**
   * Get operations by date range and institution id.
   * @param institutionId The id of the institution.
   * @param startDate The start date of the range.
   * @param endDate The end date of the range.
   * @param pageable The page params.
   * @returns A page of IOperation.
   */
  getOperationsByDateRangeAndInstitutionId(institutionId: string, startDate: Date, endDate: Date, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.set('startDate', startDate.toISOString());
    params = params.set('endDate', endDate.toISOString());
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/institution/${institutionId}/dateRange`, { params });
  }

  /**
   * Get operations by start date and institution id.
   * @param institutionId The id of the institution.
   * @param startDate The start date.
   * @param pageable The page params.
   * @returns A page of IOperation.
   */
  getOperationsByStartDate(institutionId: string, startDate: Date, pageable: Pageable): Observable<Page<IOperation>> {
    let params = getPageableAsParams(pageable);
    params = params.set('startDate', startDate.toISOString());
    return this.http.get<Page<IOperation>>(`${this.apiUrl}/institution/${institutionId}/startDate`, { params });
  }

  /**
   * Update an existing operation by its id.
   * @param id The id of the operation to update.
   * @param operation The updated IOperation.
   * @returns The updated IOperation.
   */
  updateOperation(id: string, operation: IOperation): Observable<IOperation> {
    return this.http.put<IOperation>(`${this.apiUrl}/${id}`, operation);
  }

  /**
   * Delete an operation by its id.
   * @param id The id of the operation to delete.
   * @returns An Observable for the deletion.
   */
  deleteOperation(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  exportOperations(accountId: string, startDate: Date, endDate: Date, isExcel: boolean = true): Observable<HttpResponse<Blob>> {
    let params = new HttpParams()
      .set('accountId', accountId)
      .set('isExcel', isExcel.toString())
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<Blob>(`${this.apiUrl}/export`, { params, observe: 'response', responseType: 'blob' as 'json' });
  }
}
