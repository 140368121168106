import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {DialogService, DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {CommonModule, DatePipe, NgForOf, UpperCasePipe} from '@angular/common';
import {ISimulation, ISimulationResult} from '../../../../../core/models/simulation.model';
import {DynamicTableComponent} from '../../../../../shared/components/dynamic-table/dynamic-table.component';
import {CustomAction, CustomTableHeader} from '../../../../../core/models/custom-table.model';
import {getDefaultPageable, Pageable, PageParams} from '../../../../../core/models/page.model';
import {ToastService} from '../../../../../core/services/toast.service';
import {SimulationService} from '../../../../../core/services/risque-alm/simulation.service';
import {
  customConfirmationDialog,
  customDynamicDialog,
  DEFAULT_ROWS_PER_PAGE_OPTIONS
} from '../../../../../core/utils/utils';
import {PaginatorState} from 'primeng/paginator';
import {countriesUemoa} from '../../../../../../assets/country-flags';
import {SimulationState} from '../../../../../core/enums/simulation-state.enums';
import {FormsModule} from '@angular/forms';
import {FloatLabelModule} from "primeng/floatlabel"
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PermissionsEnum} from '../../../../../core/enums/access-controls.enums';
import {CustomDialogData} from '../../../../../core/models/custom-dialog.model';
import {take} from 'rxjs';
import {SimulationResultDetailComponent} from '../dialogs/detail-simulation/detail-simulation.component';
import {BulkSubmissionModel, SubmissionModel} from "../../../../../core/models/details-submissionn.model";


@Component({
  selector: 'app-pricer-history',
  standalone: true,
  imports: [DynamicDialogModule, DropdownModule, FormsModule,
    ButtonModule,
    DynamicTableComponent, NgForOf, DialogModule, DatePipe, UpperCasePipe, CommonModule, FloatLabelModule, ConfirmDialogModule, SimulationResultDetailComponent],
  templateUrl: './pricer-history.component.html',
  styleUrl: './pricer-history.component.scss'
})
export class PricerHistoryComponent implements OnInit, OnDestroy {
  @Input() instrumentType : 'BAT' | 'OAT' = 'BAT';
  @Input() doReload = false;
  @Output() requestedTitres = new EventEmitter<string[]>();


  selectedSimulations: ISimulation[] = [];
  fetchedSimulations: ISimulation[] = [];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  actions: CustomAction[] = [];

  countries = countriesUemoa;

  columns: CustomTableHeader[] = [
    {key: 'select', column: '-', type: 'select'},
    {key: 'status', column: 'Statut', type: 'status'},
    {key: 'simulationCode', column: 'Reference', type: 'text'},
    {key: 'codeISIN', column: 'ISIN', type: 'text'},
    {key: 'region', column: 'Emetteur', type: 'custom'},
    {key: 'dateEcheance', column: 'Date d\'échéance', type: 'date'},
    {key: 'dateValeur', column: 'Date de valeur', type: 'date'},
    {key: 'valeurNominaleUnitaire', column: 'Valeur nominale unitaire<br>(F CFA)', type: 'number'},
    {key: 'montantGlobalEmission', column: 'Montant en adjudication<br>(F CFA)', type: 'number'},
    {key: 'tauxPreCompte', column: 'Taux pré-compté<br>(%)', type: 'text'},

  ];

  selectedStatus: string = '';
  selectedCountry: string = '';
  filteredSimulations: ISimulation[] = [];

  ref: DynamicDialogRef | undefined;

  statusOptions = [
    {label: 'Tous', value: 'ALL'},
    {label: 'En attente', value: SimulationState.EN_ATTENTE},
    {label: 'Validée', value: SimulationState.VALIDEE},
    {label: 'Annulée', value: SimulationState.ANNULEE}
  ];

  countryOptions = [
    {country: 'Tous les pays', code: 'ALL'},
    ...this.countries.map(country => ({country: country.country, code: country.code}))
  ];

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: ToastService,
    private readonly dialogService: DialogService,
    private readonly simulationService: SimulationService) {
  }

  ngOnInit() {
    this.fetchSimulations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['doReload']?.currentValue) this.fetchSimulations();
  }

  ngOnDestroy() {
    if (this.ref) this.ref.close();
  }

  get isBAT(): boolean {
    return this.instrumentType === 'BAT';
  }

  get isOAT(): boolean {
    return this.instrumentType === 'OAT';
  }

  fetchSimulations(pageable: Pageable = getDefaultPageable()) {
    this.simulationService.getSimulations(pageable, this.instrumentType).subscribe({
      next: (simulationsPage) => {
        //TODO: ADD LOADERS
        this.fetchedSimulations = simulationsPage.content.map(simulation => {
          // Check if this item is already in the selectedItems list
          const isSelected = !!this.selectedSimulations.find(item => item.id === simulation.id);
          return {
            ...simulation,
            region: this.getCountryName(simulation.paysEmetteurCode), // Add country name
            isSelected // Restore the isSelected state from the selectedItems list
          };
        });
        this.actions = this.getActions();

        this.getUniqueCountriesOptions(this.fetchedSimulations);
        this.applyFilters();
        const page = simulationsPage?.page;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        };
      },
      error: (error) => {
        console.log("Error fetching simulation", error)
      }
    });
  }

  getActions(): CustomAction[] {
    return [
      {
        role: PermissionsEnum.READ_SIMULATION,
        icon: 'pi pi-eye',
        label: 'Afficher résultat',
        severity: 'info',
        emit: (item) => this.viewEditSimulation(item.id, item.simulationCode),
        visible: true
      },
      {
        role: PermissionsEnum.UPDATE_SIMULATION,
        icon: 'pi pi-plus-circle',
        label: 'Modifier',
        severity: 'primary',
        emit: (item) => this.viewEditSimulation(item.id, item.simulationCode, true),
        visible: true
      },
      {
        role: PermissionsEnum.DELETE_SIMULATION,
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.createSubmissionFromSimulation(),
        visible: true
      },
    ];
  }

  onPageChange = (event: PaginatorState) => this.fetchSimulations(getDefaultPageable(event.page, event.rows));

  applyFilters() {
    this.filteredSimulations = this.fetchedSimulations.filter((simulation: ISimulation) => {
      const matchesStatus = this.selectedStatus && this.selectedStatus !== 'ALL' ? simulation.status === this.selectedStatus : true;
      const matchesCountry = this.selectedCountry && this.selectedCountry !== 'ALL' ? simulation.paysEmetteurCode === this.selectedCountry : true;
      return matchesStatus && matchesCountry;
    });
  }

  getUniqueCountriesOptions(simulations: ISimulation[]) {
    // Extract unique country codes from simulations
    const uniqueCountries = Array.from(new Set(simulations.map(sim => sim.paysEmetteurCode)));

    // Build the dropdown options based on these countries
    this.countryOptions = [
      {country: 'Tous les pays', code: 'ALL'},  // Always keep the 'All' option
      ...uniqueCountries.map(code => {
        const countryName = this.getCountryName(code);
        return {country: countryName, code: code};
      })
    ];
  }

  // Trigger filtering when a dropdown value changes
  onFilterChange(event: string) {
    this.applyFilters();
  }


  createSubmissionFromSimulation() {
    const header = "Confirmation Requise";
    const message = "Voulez-vous générer une nouvelle soumission à partir de la simulation sélectionnée ?"
    const dialogOptions = customConfirmationDialog(
      header, message,
      {
        acceptButtonStyleClass: 'p-button-success',
        accept: () => {
          const bulkSubmissionItemsList = this.prepareItemsForBulkSubmission();
          this.simulationService.createSubmissionFromSimulation(bulkSubmissionItemsList).subscribe({
            next: (response) => {
              const successMessage = this.selectedSimulations.length === 1 ? 'Une nouvelle soumission a bien été enregistrée.' : 'Les soumissions ont bien été créées.'
              this.messageService.showToast('Création soumission', successMessage, 'success');
              this.selectedSimulations = [];
              this.fetchSimulations()
            },
            error: (err) => {
              this.messageService.showToast('Validation', "Une erreur est survenue lors de la validation. Veuillez réessayer plus tard.", 'warning');
              console.error('Error updating simulations status:', err);
            }
          });

        },
      }
    );

    this.confirmationService.confirm(dialogOptions);

  }

  prepareItemsForBulkSubmission() {
    return this.selectedSimulations.reduce((acc: BulkSubmissionModel[], simulation: ISimulation) => {
      const {titreId, montantAPlacer, tauxPreCompte, tauxInteretCoupon, id} = simulation;

      // Check if we already have a BulkSubmissionModel for this titreId
      let bulkSubmission = acc.find(sub => sub.titresId === titreId);

      // If not found, create a new BulkSubmissionModel for this titreId
      if (!bulkSubmission) {
        bulkSubmission = {
          titresId: titreId,
          submissionRequest: [],
        };
        acc.push(bulkSubmission);
      }

      // Step 2: Create the SubmissionModel for this simulation result
      const submissionModel: SubmissionModel = {
        id: id!,
        amountSubmission: montantAPlacer,
        rateSubmission: simulation.instrument === 'BAT' ? tauxPreCompte! : tauxInteretCoupon!,
      };

      // Add the submission to the bulkSubmission's submissionRequest list
      bulkSubmission.submissionRequest.push(submissionModel);

      return acc;
    }, [])
  }


  cancalSimulations() {
    const header = "Confirmation Requise";
    const message = "Voulez-vous annuler la simulation sélectionnée ?"
    const dialogOptions = customConfirmationDialog(
      header, message,
      {
        acceptButtonStyleClass: 'p-button-success',
        accept: () => {

          const simulationIDsForCancellationOrValidation = Array.from(this.selectedSimulations.map(simulation => simulation.id!));
          this.simulationService.cancelSimulation(simulationIDsForCancellationOrValidation).subscribe({
            next: (response) => {
              const successMessage = simulationIDsForCancellationOrValidation.length === 1 ? 'La simulation a bien été annulée.' : 'Les simulations ont bien été annulées.'
              this.messageService.showToast('Annulation', successMessage, 'success');
              this.selectedSimulations = [];
              this.fetchSimulations()
            },
            error: (err) => {
              this.messageService.showToast('Annulation', "Une erreur est survenue lors de l'annulation. Veuillez réessayer plus tard.", 'danger');
              console.error('Error updating simulations status:', err);
            }
          });
        },
      }
    );

    this.confirmationService.confirm(dialogOptions);
  }

  onRowSelected(rowData: any) {
    const index = this.selectedSimulations.findIndex(item => item.id === rowData.id);

    // Toggle the selection state
    rowData.isSelected = !rowData.isSelected;

    if (rowData.isSelected && index === -1) {
      // If selected and not already in the list, add it
      this.selectedSimulations.push(rowData);
    } else if (!rowData.isSelected && index !== -1) {
      // If unselected, remove it from the list
      this.selectedSimulations.splice(index, 1);
    }
  }


  viewEditSimulation(simulationId: string, simulationCode: string, isEdit?: boolean) {
    this.simulationService.getSimulationResult(simulationId).subscribe({
      next: (result: ISimulationResult) => {
        if (result) {
          const header = 'Résultat Simulation - ' + simulationCode
          console.log("anne result", result, simulationId, simulationCode)
          const data = {isView: !isEdit, isEdit, data: result} as CustomDialogData;
          const dialogConfig = customDynamicDialog(
            header,  // Header
            data,
            {position: 'center', height: '75vh'}
          );
          this.ref = this.dialogService.open(SimulationResultDetailComponent, dialogConfig);
          this.ref.onClose.pipe(take(1)).subscribe();
        }

      }
    })

  }

  private getCountryName(region: string): string {
    const country = this.countries.find(c => c.code === region);
    return country ? country.country : region;
  }
}
