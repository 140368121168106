import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IForecastCashFlow, IForecastCashFlowExcel, IForecastCashFlowOverview} from '../models/forecast-cash-flow.model';


@Injectable({
  providedIn: 'root'
})
export class ForecastCashFlowService {

  private readonly apiUrl = `operation/v1/forecast-cash-flow`;

  constructor(private readonly http: HttpClient) { }

  fetchOverview(startDate: Date, endDate: Date, currency: string = 'XOF'): Observable<IForecastCashFlowOverview[]> {
    let params = new HttpParams()
      .set('currency', currency)
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<IForecastCashFlowOverview[]>(`${this.apiUrl}/overview`, { params });
  }

  /**
   * Search for forecast cash flows.
   * @param query The search query.
   * @param isLocal Whether to search for local forecast cash flows.
   * @param pageable The parameter of the requested page.
   * @returns A page of IForecastCashFlow.
   */
  search(query: string | null, isLocal: boolean = true, pageable: Pageable): Observable<Page<IForecastCashFlow>> {
    let params = getPageableAsParams(pageable);
    params = params.set('isLocal', isLocal.toString());
    if (query) params = params.set('query', query);
    return this.http.get<Page<IForecastCashFlow>>(`${this.apiUrl}/search`, { params });
  }

  /**
   * Parse an Excel file and return a set of ForecastCashFlowExcelDTO objects.
   * @param file The Excel file to parse.
   * @returns A set of IForecastCashFlowExcel.
   */
  parseExcelFile(file: File): Observable<IForecastCashFlowExcel[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<IForecastCashFlowExcel[]>(`${this.apiUrl}/upload`, formData);
  }

  /**
   * Upload forecast cash flows.
   * @param forecastCashFlows The forecast cash flows to upload.
   * @returns A list of IForecastCashFlow.
   */
  uploadForecastCashFlows(forecastCashFlows: IForecastCashFlow[]): Observable<IForecastCashFlow[]> {
    return this.http.post<IForecastCashFlow[]>(`${this.apiUrl}/batch`, forecastCashFlows);
  }

  /**
   * Create a new forecast cash flow.
   * @param forecastCashFlow The forecast cash flow DTO to create.
   * @returns The created IForecastCashFlow.
   */
  createForecastCashFlow(forecastCashFlow: IForecastCashFlow): Observable<IForecastCashFlow> {
    return this.http.post<IForecastCashFlow>(this.apiUrl, forecastCashFlow);
  }

  /**
   * Get a specific forecast cash flow by its id.
   * @param id The id of the forecast cash flow.
   * @returns The IForecastCashFlow.
   */
  getForecastCashFlowById(id: string): Observable<IForecastCashFlow> {
    return this.http.get<IForecastCashFlow>(`${this.apiUrl}/${id}`);
  }

  /**
   * Get all forecast cash flows with optional pagination and sorting.
   * @param pageable The parameter of the requested page.
   * @returns A page of IForecastCashFlow.
   */
  getAllForecastCashFlows(pageable: Pageable): Observable<Page<IForecastCashFlow>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IForecastCashFlow>>(this.apiUrl, { params });
  }

  /**
   * Get forecast cash flows by institution id with optional pagination and sorting.
   * @param institutionId The id of the institution.
   * @param pageable The page params.
   * @returns A page of IForecastCashFlow.
   */
  getForecastCashFlowsByInstitutionId(institutionId: string, pageable: Pageable): Observable<Page<IForecastCashFlow>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IForecastCashFlow>>(`${this.apiUrl}/institution/${institutionId}`, { params });
  }

  /**
   * Get forecast cash flows by date range with optional pagination and sorting.
   * @param startDate The start date of the range.
   * @param endDate The end date of the range.
   * @param pageable The page params.
   * @returns A page of IForecastCashFlow.
   */
  getForecastCashFlowsByDateRange(startDate: Date, endDate: Date, pageable: Pageable): Observable<Page<IForecastCashFlow>> {
    let params = getPageableAsParams(pageable)
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<Page<IForecastCashFlow>>(`${this.apiUrl}/date-range`, { params });
  }

  /**
   * Get forecast cash flows by category with optional pagination and sorting.
   * @param category The cash flow category.
   * @param pageable The page params.
   * @returns A page of IForecastCashFlow.
   */
  getForecastCashFlowsByCategory(category: string, pageable: Pageable): Observable<Page<IForecastCashFlow>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IForecastCashFlow>>(`${this.apiUrl}/category/${category}`, { params });
  }

  /**
   * Get forecast cash flows by type with optional pagination and sorting.
   * @param type The cash flow type.
   * @param pageable The page params.
   * @returns A page of IForecastCashFlow.
   */
  getForecastCashFlowsByType(type: string, pageable: Pageable): Observable<Page<IForecastCashFlow>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IForecastCashFlow>>(`${this.apiUrl}/type/${type}`, { params });
  }

  /**
   * Update an existing forecast cash flow by its id.
   * @param id The id of the forecast cash flow to update.
   * @param forecastCashFlow The updated IForecastCashFlow.
   * @returns The updated IForecastCashFlow.
   */
  updateForecastCashFlow(id: string, forecastCashFlow: IForecastCashFlow): Observable<IForecastCashFlow> {
    return this.http.put<IForecastCashFlow>(`${this.apiUrl}/${id}`, forecastCashFlow);
  }

  /**
   * Delete a forecast cash flow by its id.
   * @param id The id of the forecast cash flow to delete.
   * @returns An Observable for the deletion.
   */
  deleteForecastCashFlow(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  exportForecastCashFlows(startDate: Date, endDate: Date, isExcel: boolean = true): Observable<HttpResponse<Blob>> {
    let params = new HttpParams()
      .set('isExcel', isExcel.toString())
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<Blob>(`${this.apiUrl}/export`, { params, observe: 'response', responseType: 'blob' as 'json' });
  }

  exportForecastCashFlowOverview(startDate: Date, endDate: Date, currency: string = 'XOF'): Observable<HttpResponse<Blob>> {
    let params = new HttpParams()
      .set('currency', currency)
      .set('startDate', startDate.toISOString().split('T')[0])
      .set('endDate', endDate.toISOString().split('T')[0]);
    return this.http.get<Blob>(`${this.apiUrl}/overview/export`, { params, observe: 'response', responseType: 'blob' as 'json' });
  }
}
