<div class="flex flex-column h-full pb-4">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label for="creditAccount" class="font-medium">Bénéficiaire</label>
      <p-dropdown id="creditAccount" aria-describedby="creditAccount-help" formControlName="beneficiary" [options]="beneficiaries"
                  optionLabel="name" placeholder="Sélectionner le bénéficiaire" [style]="{ maxWidth: '100%' }">
      </p-dropdown>
      <small *ngIf="boundData?.isCreate" id="creditAccount-help">Sélectionner le bénéficiaire.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="debitAccount" class="font-medium">Compte de débit</label>
      <p-dropdown id="debitAccount" aria-describedby="debitAccount-help" formControlName="account" [options]="accounts"
                  optionLabel="libelle" placeholder="Sélectionner un compte de débit">
        <ng-template let-compte pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ compte.libelle }} - {{ compte.number }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small *ngIf="boundData?.isCreate" id="debitAccount-help">Sélectionner le compte de débit.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="libelle" class="font-medium">Libellé</label>
      <input pInputText id="libelle" aria-describedby="libelle-help" formControlName="libelle" />
      <small *ngIf="boundData?.isCreate" id="libelle-help">Renseigner ici le libellé du virement.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="devise" class="font-medium">Devise</label>
      <p-dropdown id="devise" aria-describedby="devise-help" formControlName="devise" [editable]="true" filter="true"
                  filterBy="code,description" [options]="devises" optionLabel="description" placeholder="Sélectionner une devise">
        <ng-template let-currency pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ currency.code }} - {{ currency.description }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small *ngIf="boundData?.isCreate" id="devise-help">Sélectionner la devise du compte.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="amount" class="font-medium">Montant</label>
      <p-inputNumber  id="amount" aria-describedby="amount-help" formControlName="amount" inputId="integeronly"/>
      <small *ngIf="boundData?.isCreate" id="amount-help">Renseigner ici le montant du virement.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="operationDate" class="font-medium">Date de l'opération</label>
      <p-calendar id="operationDate" formControlName="operationDate" />
      <small *ngIf="boundData?.isCreate" id="operationDate-help">Sélectionner la date de l'opération.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="valueDate" class="font-medium">Date de valeur</label>
      <p-calendar id="valueDate" formControlName="valueDate" />
      <small *ngIf="boundData?.isCreate" id="valueDate-help">Sélectionner la date de valeur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="description" class="font-medium">Commentaires</label>
      <textarea id="description" aria-describedby="description-help" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
      <small *ngIf="boundData?.isCreate" id="description-help">Renseigner ici des commentaires relatifs au virement.</small>
    </div>
  </form>
  <div class="flex my-4 gap-2 justify-content-end">
    <p-button pRipple *ngIf="!formGroup.disabled" [style]="{width: '100%'}" class="mb-4"
              [label]=" boundData?.isCreate ? 'Enregistrer' : 'Modifier'" [disabled]="!formGroup.valid"
              [loading]="isLoading" (onClick)="boundData?.isCreate ? create() : update()"></p-button>
  </div>
</div>
