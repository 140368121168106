<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div  class="flex flex-column gap-2">
      <label for="isin" class="font-medium">ISIN</label>
      <input pInputText id="isin" aria-describedby="isin-help" formControlName="isin" />
      <small *ngIf="isCreate" id="isin-help">Renseigner ici le isin.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="region" class="font-medium">Emetteur</label>
      <p-dropdown
        [options]="countries" formControlName="region" optionLabel="country"
        editable="true" id="region" optionValue="code" [showClear]="true" placeholder="Choisir un pays">
        <ng-template let-country pTemplate="item">
          <div class="flex align-items-center gap-2">
            <img alt="{{ country.country }}"
                 src="https://purecatamphetamine.github.io/country-flag-icons/3x2/{{ country.code | uppercase }}.svg"
                 style="width: 18px" />
            <div>{{ country.country }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <small *ngIf="isCreate" id="pays-help">Sélectionner le pays de l'emetteur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="type" class="font-medium">Instrument</label>
      <p-dropdown id="type" formControlName="typeInstrument" [options]="typeInstrument" optionLabel="name" optionValue="code" placeholder="Choisir un type d'instrument" />
      <small *ngIf="isCreate" id="typeInstrument-help">Sélectionner le type d'instrument.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="opDate" class="font-medium">Date Opération</label>
      <p-calendar formControlName="operationDate" id="opDate"/>
      <small *ngIf="isCreate" id="operationDate-help">Renseigner ici la date d'opération.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="valueDate" class="font-medium">Date Valeur</label>
      <p-calendar formControlName="valueDate" id="valueDate" />
      <small *ngIf="isCreate" id="valueDate-help">Renseigner ici la date de valeur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="dueDate" class="font-medium">Echéance</label>
      <p-calendar formControlName="dueDate" id="dueDate" />
      <small *ngIf="isCreate" id="dueDate-help">Renseigner ici la date d'échance.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="rate" class="font-medium">Taux</label>
      <input type="number"  pInputText id="rate" aria-describedby="rate-help" formControlName="rate" />
      <small *ngIf="isCreate" id="rate-help">Renseigner ici le taux.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="unitNominalValue" class="font-medium">Valeur Nominale Unitaire</label>
      <input type="number"  pInputText id="unitNominalValue" aria-describedby="unitNominalValue-help" formControlName="unitNominalValue" />
      <small *ngIf="isCreate" id="unitNominalValue-help">Renseigner ici la valeur nominale unitaire.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="amount" class="font-medium">Montant</label>
      <input type="number"  pInputText id="amount" aria-describedby="amount-help" formControlName="amount" />
      <small *ngIf="isCreate" id="amount-help">Renseigner ici le montant en adjudication.</small>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end justify-content-end ">
    <p-button
      class="my-4"
      pRipple
      [style]="{width: '100%'}"
      [label]=" isCreate ? 'Enregistrer' : 'Modifier'"
      [disabled]="!formGroup.valid || isLoading"
      [loading]="isLoading"
      (onClick)="isCreate ? createEmission() : update()"
    ></p-button>
  </div>
</div>
