<div class="flex flex-column h-full">
  <form [formGroup]="titreForm" class="flex flex-column gap-4">
    <div class="flex flex-row gap-4">

      <div class="flex flex-column gap-2 w-full">
        <label class="font-medium">ISIN</label>
        <input pInputText id="isin" aria-describedby="isin-help" formControlName="isin" />
        <small id="isin-help">Renseigner ici le ISIN.</small>
      </div>

    </div>

    <div class="flex flex-row gap-4">

      <div class="flex flex-column gap-2 w-full">
        <label class="font-medium">Date d'Échéance</label>
        <p-calendar dateFormat="dd/mm/yy" formControlName="dueDate" />
        <small id="dueDate-help">Renseigner ici la date d'échéance.</small>
      </div>

      <div class="flex flex-column gap-2 w-full">
        <label class="font-medium">Type d'Instrument</label>
        <p-dropdown
          formControlName="typeInstrument"
          [options]="typeInstrumentOptions"
          optionLabel="name"
          optionValue="code"
          placeholder="Choisir un type d'instrument" />
        <small id="typeInstrument-help">Sélectionner le type d'instrument.</small>
      </div>

    </div>

    <div class="flex flex-row gap-4">

      <div class="flex flex-column gap-2 w-full">
        <label class="font-medium">Valeur Nominale Unitaire</label>
        <input type="number" pInputText id="unitNominalValue" aria-describedby="unitNominalValue-help" formControlName="unitNominalValue" />
        <small id="unitNominalValue-help">Renseigner ici la valeur nominale unitaire.</small>
      </div>

      <div class="flex flex-column gap-2 w-full">
        <label class="font-medium">Taux</label>
        <input type="number" pInputText id="rate" aria-describedby="rate-help" formControlName="rate" />
        <small id="rate-help">Renseigner ici le taux.</small>
      </div>

    </div>

  </form>

  <div class="flex flex-grow-1 gap-2 align-items-end justify-content-end ">
    <p-button
      class="my-4"
      pRipple
      [style]="{width: '100%'}"
      label="Ajouter Titre"
      [disabled]="!titreForm.valid"
      (onClick)="addTitre()"
    ></p-button>
  </div>
</div>
