<p-table [value]="data" [columns]="headers" styleClass="p-datatable-sm" [tableStyle]="{'min-width': '50rem'}"
         [(selection)]="selectedItems" dataKey="index" (selectionChange)="onSelectionChange($event)" (onRowSelect)="onRowSelect($event)" [paginator]="true"
         [rows]="rows" [totalRecords]="data.length" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
         currentPageReportTemplate="{first} - {last}/{totalRecords}" datakey="index"
         [first]="first" (onPage)="onPageChange($event)">
  <ng-template pTemplate="header">
    <tr class="header">
      <th scope="col" style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th scope="col" *ngFor="let header of headers">
        {{ header.column }}
      </th>
      <th scope="col" style="width: 4rem">Erreurs</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
    <tr>
      <td>
        <p-tableCheckbox  [value]="item" [disabled]="isCheckboxDisabled(rowIndex)" />
      </td>
      <td *ngFor="let header of headers">
        <ng-container [ngSwitch]="header.type">
          <ng-container *ngSwitchCase="'text'">
            {{ getNestedValue(item, header.key) }}
          </ng-container>
          <ng-container *ngSwitchCase="'currency'">
            {{ getNestedValue(item, header.key) | currency:(header.currencyKey ? getNestedValue(item, header.currencyKey) : 'XOF') : 'symbol' : '1.2-2' : 'fr-FR' }}
          </ng-container>
          <ng-container *ngSwitchCase="'number'">
            {{ getNestedValue(item, header.key) | number:'1.2-2' }}
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            {{ getNestedValue(item, header.key) | date:'dd/MM/yyyy' }}
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <i class="pi" [ngClass]="{'pi-check-circle text-green-500': getNestedValue(item, header.key), 'pi-times-circle text-red-500': !getNestedValue(item, header.key)}"></i>
          </ng-container>
          <ng-container *ngSwitchCase="'state'">
            <ng-container [ngSwitch]="getNestedValue(item, header.key)">
                            <span *ngSwitchCase="'PENDING'" class="text-teal-800">
                                <i class="pi pi-stopwatch mr-1"></i>En attente
                            </span>
              <span *ngSwitchCase="'ACTIVE'" class="text-green-500">
                                <i class="pi pi-check-circle mr-1"></i>Active
                            </span>
              <span *ngSwitchCase="'INACTIVE'" class="text-red-500">
                                <i class="pi pi-times-circle mr-1"></i>Inactive
                            </span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ getNestedValue(item, header.key) }}
          </ng-container>
        </ng-container>
      </td>
      <td>
        <i class="pi pi-info-circle"
           [ngClass]="{
                     'text-blue-500 cursor-pointer': item.errors && item.errors.length > 0,
                     'text-gray-500': !item.errors || item.errors.length === 0
                   }"
           [pTooltip]="item.errors && item.errors.length > 0 ? item.errors.join('\n') : ''"
           [tooltipDisabled]="!item.errors || item.errors.length === 0">
        </i>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="flex justify-content-end mt-6">
  <p-button label="Enregistrer" (onClick)="onSave()"></p-button>
</div>
