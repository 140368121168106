<div class="flex flex-column h-full pb-4">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label for="creditAccount" class="font-medium">Bénéficiaire</label>
      <p-dropdown id="creditAccount" aria-describedby="creditAccount-help" formControlName="beneficiary" [options]="beneficiaries"
                  optionLabel="name" optionValue="id" placeholder="Sélectionner le bénéficiaire" [style]="{ maxWidth: '100%' }">
      </p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="account" class="font-medium">Compte</label>
      <p-dropdown id="account" aria-describedby="debitAccount-help" formControlName="account" [options]="accounts"
                  optionLabel="libelle" optionValue="id" placeholder="Sélectionner le compte de débit" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="libelle" class="font-medium">Libellé</label>
      <input pInputText id="libelle" aria-describedby="libelle-help" formControlName="libelle" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="devise" class="font-medium">Devise</label>
      <p-dropdown id="devise" aria-describedby="devise-help" formControlName="currency" [editable]="true" filter="true"
                  filterBy="code,description" [options]="currencies" optionValue="id" optionLabel="description"
                  placeholder="Sélectionner une devise">
      </p-dropdown>
      <small *ngIf="boundData?.isCreate" id="devise-help">Sélectionner la devise du compte.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="amount" class="font-medium">Montant</label>
      <p-inputNumber  id="amount" aria-describedby="amount-help" formControlName="amount" inputId="integeronly"/>
      <small *ngIf="boundData?.isCreate" id="amount-help">Renseigner ici le montant du virement.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="operationDate" class="font-medium">Date de l'opération</label>
      <p-calendar [minDate]="today" dateFormat="dd/mm/yy" id="operationDate" formControlName="operationDate" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="valueDate" class="font-medium">Date de valeur</label>
      <p-calendar [minDate]="today" dateFormat="dd/mm/yy" id="valueDate" formControlName="valueDate" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="description" class="font-medium">Commentaires</label>
      <textarea id="description" aria-describedby="description-help" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
    </div>
  </form>
  <div class="flex my-4 gap-2 justify-content-end">
    <p-button pRipple *ngIf="!formGroup.disabled" [style]="{width: '100%'}" class="mb-4"
              [label]=" boundData?.isCreate ? 'Enregistrer' : 'Modifier'" [disabled]="!formGroup.valid"
              [loading]="isLoading" (onClick)="boundData?.isCreate ? create() : update()"></p-button>
  </div>
</div>
