<app-pricer-form instrumentType="OAT"  (simulationData)="handleSimulationData($event)" [resetForm]="resetForm"></app-pricer-form>
<app-pricer-result instrumentType="OAT" [simulationData]="simulationData" (reset)="handleReset($event)" (simulationCreated)="reload()" ></app-pricer-result>
<app-pricer-history instrumentType="OAT"  [doReload]="doReload" ></app-pricer-history>









