import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IVirementExcel, IVirementRequest, IVirementResponse} from "../models/virement.model";
import {getPageableAsParams, Page, Pageable} from "../models/page.model";

// Define interfaces

@Injectable({
  providedIn: 'root'
})
export class VirementService {

  private apiUrl = `operation/v1/virement`;

  constructor(private http: HttpClient) { }

  createVirement(virement: IVirementRequest): Observable<IVirementResponse> {
    return this.http.post<IVirementResponse>(this.apiUrl, virement);
  }

  uploadVirement(file: File): Observable<IVirementExcel[]> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<IVirementExcel[]>(`${this.apiUrl}/upload`, formData);
  }

  updateVirement(id: string, virement: IVirementRequest): Observable<IVirementResponse> {
    return this.http.put<IVirementResponse>(`${this.apiUrl}/${id}`, virement);
  }

  deleteVirement(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getVirement(id: string): Observable<IVirementResponse> {
    return this.http.get<IVirementResponse>(`${this.apiUrl}/${id}`);
  }

  validateVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/validate`, {});
  }

  rejectVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/reject`, {});
  }

  executeVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/execute`, {});
  }

  cancelVirement(id: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${id}/cancel`, {});
  }

  getAllVirements(pageable: Pageable): Observable<Page<IVirementResponse>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IVirementResponse>>(this.apiUrl, { params });
  }

  getVirementsByState(state: string, pageable: Pageable): Observable<Page<IVirementResponse>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IVirementResponse>>(`${this.apiUrl}/state/${state}`, { params });
  }

  getVirementsByStateAndInstitution(state: string, institutionId: string, pageable: Pageable): Observable<Page<IVirementResponse>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IVirementResponse>>(`${this.apiUrl}/institution/${institutionId}/state/${state}`, { params });
  }

  getVirementsByInstitution(institutionId: string, pageable: Pageable): Observable<Page<IVirementResponse>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IVirementResponse>>(`${this.apiUrl}/institution/${institutionId}`, { params });
  }
}
