<p-sidebar #sidebarRef [visible]="true" [modal]="false">
  <ng-template pTemplate="headless">
    <div class="flex flex-column h-full">
      <div class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
          <span class="inline-flex align-items-center gap-2">
              <span class="font-semibold text-4xl">alima <span class="text-sm text-color-secondary">by finappli</span></span>
          </span>
      </div>
      <div class="overflow-y-auto">
        <ul class="list-none p-3 m-0">
          <li>
            <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
                 class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
              <span class="font-medium">PRINCIPAL</span>
              <i class="pi pi-chevron-down"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li>
                <a pRipple class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-chart-bar mr-2"></i>
                  <span class="font-medium">Dashboard</span>
                </a>
              </li>
              <li *hasModule="'TRESORERIE'">
                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                   leaveToClass="hidden" leaveActiveClass="slideup"
                   class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-wallet mr-2"></i>
                  <span class="font-medium">Trésorerie</span>
                  <i class="pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                  <li>
                    <a pRipple pStyleClass="@next" enterFromClass="hidden"
                       enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
                       class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                      <span class="font-medium">Locale</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                    </a>
                    <ul  class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                      <li>
                        <a routerLink="tresorerie/locale/releve" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Relevé</span>
                        </a>
                      </li>
                      <li>
                        <a routerLink="tresorerie/locale/previsionnel" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Prévisionnel</span>
                        </a>
                      </li>
                      <li>
                        <a routerLink="tresorerie/locale/loan" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Prêts & Emprunts</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a pRipple pStyleClass="@next" enterFromClass="hidden"
                       enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
                       class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                      <span class="font-medium">Devise</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                    </a>
                    <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                      <li>
                        <a routerLink="tresorerie/devise/releve" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Relevé</span>
                        </a>
                      </li>
                      <li>
                        <a routerLink="tresorerie/devise/previsionnel" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Prévisionnel</span>
                        </a>
                      </li>
                      <li>
                        <a routerLink="tresorerie/devise/loan" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Prêts & Emprunts</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li *hasModule="'TRESORERIE'">
                    <a pRipple pStyleClass="@next" enterFromClass="hidden"
                       enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
                       class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                      <span class="font-medium">Correspondant</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                    </a>
                    <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                      <li>
                        <a routerLink="tresorerie/correspondant/suivi" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Relevé</span>
                        </a>
                      </li>
                      <li *hasProduct="'VIREMENT'">
                        <a routerLink="tresorerie/correspondant/virement" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Virements</span>
                        </a>
                      </li>
                      <li>
                        <a routerLink="tresorerie/correspondant/beneficiary" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                          <span class="font-medium">Bénéficiaires</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li *hasModule="'FOREX'">
                <a routerLink="forex" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-box mr-2"></i>
                  <span class="font-medium">Forex</span>
                </a>
              </li>
              <li *hasModule="'FIXED_INCOME'">
                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                     leaveToClass="hidden" leaveActiveClass="slideup"
                     class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-sync mr-2"></i>
                  <span class="font-medium">Fixed Income</span>
                  <i class="pi pi-chevron-down ml-auto"></i>
                </a>
                <ul class="list-none p-0 m-0 overflow-hidden">
                  <li *hasProduct="'FIXED_INCOME'">
                    <a routerLink="primaire" routerLinkActive="active" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                      <span class="font-medium">Primaire</span>
                    </a>
                  </li>
                  <li *hasProduct="'FIXED_INCOME'">
                    <a routerLink="secondaire" routerLinkActive="active" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                      <span class="font-medium">Secondaire</span>
                    </a>
                  </li>
                  <li *hasProduct="'FIXED_INCOME'">
                    <a routerLink="portefeuille" routerLinkActive="active" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                      <span class="font-medium">Portefeuille</span>
                    </a>
                  </li>
                  <li *hasProduct="'FIXED_INCOME'">
                    <a routerLink="operation" routerLinkActive="active" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                      <span class="font-medium">Opération</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li *hasModule="'RISK_MANAGEMENT'">
                <a routerLink="risk" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-exclamation-circle mr-2"></i>
                  <span class="font-medium">Risques</span>
                </a>
              </li>
              <li *hasModule="'BACK_OFFICE'">
                <a routerLink="back-office" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-gift mr-2"></i>
                  <span class="font-medium">Back office</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="list-none p-3 m-0">
          <li>
            <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                 leaveActiveClass="slideup" class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
              <span class="font-medium">ADMINISTRATION</span>
              <i class="pi pi-chevron-down"></i>
            </div>
            <ul class="list-none p-0 m-0 overflow-hidden">
              <li>
                <a *hasRole="'READ_INSTITUTION'" routerLink="institution" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-home mr-2"></i>
                  <span class="font-medium">Institutions</span>
                </a>
              </li>
              <li *hasRole="'READ_USER'">
                <a routerLink="user" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-users mr-2"></i>
                  <span class="font-medium">Utilisateurs</span>
                </a>
              </li>
              <li *hasRole="'READ_GROUP'">
                <a routerLink="group" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-link mr-2"></i>
                  <span class="font-medium">Groupes</span>
                </a>
              </li>
              <li *hasRole="'READ_VALIDATION'">
                <a routerLink="validation" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-verified mr-2"></i>
                  <span class="font-medium">Validation</span>
                </a>
              </li>
              <li *hasRole="'UPDATE_GROUP'">
                <a routerLink="validation" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-verified mr-2"></i>
                  <span class="font-medium">Chaînes de validation</span>
                </a>
              </li>
              <li>
                <a routerLink="profile" pRipple class="no-underline flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                  <i class="pi pi-cog mr-2"></i>
                  <span class="font-medium">Paramètres</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="mt-auto">
        <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
        <a (click)="logout()" pRipple class="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" shape="circle" />
          <div class="flex flex-column">
            <span class="font-bold">{{ username.toUpperCase() }}</span>
            <span class="font-medium">{{ institution }}</span>
          </div>
          <i class="pi pi-sign-out ml-auto"></i>
        </a>
      </div>
    </div>
  </ng-template>
</p-sidebar>
