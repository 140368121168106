import { Component } from '@angular/core';
import {ButtonDirective} from "primeng/button";
import {ChartModule} from "primeng/chart";
import {DropdownModule} from "primeng/dropdown";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {PrimeTemplate} from "primeng/api";
import {DatePipe, UpperCasePipe} from "@angular/common";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {DetailsWithSubmissionModel} from "../../../../core/models/details-submissionn.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {CustomTableHeader} from "../../../../core/models/custom-table.model";
import {PaginatorState} from "primeng/paginator";
import {NewTitleSecondaryComponent} from "../new-title-secondary/new-title-secondary.component";
import {take} from "rxjs";
import {customDynamicDialog} from "../../../../core/utils/utils";
import {TitresWithOperationsModel} from "../../../../core/models/titres-with-operations.model";
import {OperationsTitresService} from "../../../../core/services/operationsTitres.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {countriesUemoa} from "../../../../../assets/countries.uemoa";

@Component({
  selector: 'app-ventes',
  standalone: true,
    imports: [
        ButtonDirective,
        ChartModule,
        DropdownModule,
        DynamicTableComponent,
        PrimeTemplate,
        UpperCasePipe
    ],
  templateUrl: './ventes.component.html',
  styleUrl: './ventes.component.scss'
})
export class VentesComponent {


  boundData?: CustomDialogData;
  countries = countriesUemoa;
  secondary: DetailsWithSubmissionModel[] = [];
  ref?: DynamicDialogRef;
  operationsTitres: TitresWithOperationsModel[] = [];
  form: FormGroup;


  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  columns: CustomTableHeader[] = [
    { key: 'registerOperationsTitresDTO.titresWithEmissionDTO.emission.region', column: 'Emetteur', type: 'custom' },
    { key: 'isin', column: 'ISIN', type: 'text' },
    { key: 'registerOperationsTitresDTO.account', column: 'Compte', type: 'text' },
    { key: 'typeInstrument', column: 'Instrument', type: 'text' },
    { key: 'registerOperationsTitresDTO.titresWithEmissionDTO.emission.valueDate', column: 'Date valeur', type: 'text' },
    { key: 'dueDate', column: 'Date échéance', type: 'text' },
    { key: 'registerOperationsTitresDTO.amount', column: 'Montant', type: 'text' },
    { key: 'rate', column: 'Taux facial(%)', type: 'text' },
  ];

  data: any;
  dataONE: any
  dataTWO: any;

  options: any;
  optionsONE:any;
  optionsTWO: any;

  constructor(public dialogService: DialogService,
              private operationsTitresService : OperationsTitresService,
              private fb: FormBuilder,
              private datePipe: DatePipe) {

    this.form = this.fb.group({});

  }

  ngOnInit() {
    this.loadOperationsTitres(getDefaultPageable(0, 10));
    this.form.get('registerOperationsTitresDTO.titresWithEmissionDTO.emission.region')?.valueChanges.subscribe(selectedCountry => {
      this.filter(selectedCountry);
    });

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Courbe des taux - XOF',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
          tension: 0.4
        }
      ]
    };
    this.dataONE = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Achats',
          backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--red-500'),
          borderColor: getComputedStyle(document.documentElement).getPropertyValue('--red-500'),
          data: [65, 59, 80, 81, 56, 55, 40]
        },
        {
          label: 'Ventes',
          backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
          borderColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
          data: [28, 48, 40, 19, 86, 27, 90]
        }
      ]
    };
    this.dataTWO = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Taux moyens - XOF',
          backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
          borderColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
          data: [65, 59, 80, 81, 56, 55, 40]
        },
      ]
    };

    this.options = this.createChartOptions(0.9);
    this.optionsONE = this.createChartOptions(0.9, 'x');
    this.optionsTWO = this.createChartOptions(0.9, 'y');
  }
  createChartOptions(aspectRatio: number, indexAxis: string = 'x'): any {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
      indexAxis: indexAxis,
      maintainAspectRatio: false,
      aspectRatio: aspectRatio,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  onPageChange = (event: PaginatorState) => {
    this.loadOperationsTitres(getDefaultPageable(event.page, event.rows));
  }
  private loadOperationsTitres(pageable: Pageable): void {
    this.operationsTitresService.getAllOperationsWihtTitres(pageable.page, pageable.limit, true, 'VENTE')
      .subscribe({
        next: (response) => {
          this.operationsTitres = response.content.map(item => ({
            ...item,
            registerOperationsTitresDTO: {
              ...item.registerOperationsTitresDTO,
              titresWithEmissionDTO: {
                ...item.registerOperationsTitresDTO.emissionRequestDTO,
                emission: {
                  ...item.registerOperationsTitresDTO.emissionRequestDTO,
                  valueDate: this.formatDate(item.registerOperationsTitresDTO.emissionRequestDTO.valueDate),
                  region: this.getCountryName(item.registerOperationsTitresDTO.emissionRequestDTO.region),
                }
              }
            },
            dueDate: this.formatDate(item.dueDate)  // Appliquer le formatage de la date d'échéance ici
          }));
          this.pageable.totalRecords = response.totalElements;
        },
        error: () => {
        }
      });
  }
  private formatDate(date: Date | string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }

  createTitleSecondary() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(NewTitleSecondaryComponent, customDynamicDialog('Titres - Marché secondaire', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadOperationsTitres(getDefaultPageable()));

  }
  private getCountryName(region: string): string {
    const country = this.countries.find(c => c.code === region);
    return country ? country.country : region;
  }
  private filter(selectedCountry: string): void {
    if (selectedCountry) {
      this.operationsTitres = this.operationsTitres.filter(detail =>
        this.getCountryName(detail.registerOperationsTitresDTO.emissionRequestDTO.region) === selectedCountry
      );
    } else {
      this.loadOperationsTitres(getDefaultPageable(0, 10));
    }
  }

  onCountryChange(event: any): void {
    const selectedCountry = event.value;
    this.filter(selectedCountry);
  }
}
