import {ICurrency} from "./devise.model";
import {IBank} from "./bank.model";

export interface IAccount {
  id: string;
  institutionId: string;
  number: string;
  libelle: string;
  currency: ICurrency;
  isLocalCurrency: boolean;
  rib: string;
  ribKey: string;
  iban: string;
  codeAgency: string;
  type: AccountType;
  bank: IBank;
  lastBalance: IAccountBalance;
}

export interface IAccountBalance {
  accountId: string;
  date: Date;
  balance: number;
  availableBalance: number;
  type: string;
}

export interface ICurrencyBalance {
  currency: ICurrency;
  date: Date;
  balance: number;
  availableBalance: number;
  type: string;
}

export enum AccountType {
  LOCALE = "Devise locale",
  FOREIGN = "Devise étrangère",
  NOSTRO = "NOSTRO"
}
