<div class="box">
  <div class="flex flex-wrap result-fields-container">

    <!-- Durée de vie résiduelle -->
    <div class="flex flex-column gap-1 field">
      <label class="block" for="duration">Durée de vie {{isBAT ? 'résiduelle ' : ' '}}(jours)</label>
      <p-inputNumber [(ngModel)]="duration" locale="fr-FR" inputId="duration" placeholder="-" readonly
      ></p-inputNumber>
    </div>

    <!-- Taux de rendement -->
    <div class="flex flex-column gap-1 field">
      <label class="block" for="rateOfReturn">Taux de rendement</label>
      <p-inputNumber [(ngModel)]="rateOfReturn" [maxFractionDigits]="6" [minFractionDigits]="2" inputId="rateOfReturn"
                     mode="decimal" placeholder="-" readonly suffix="%"></p-inputNumber>
    </div>

    <!-- Montant net -->
    <div class="flex flex-column gap-1 field">
      <label class="block" for="netAmount">Montant net</label>
      <p-inputNumber [(ngModel)]="netAmount" currency="XOF"
                     inputId="netAmount" locale="fr-FR" mode="currency" placeholder="-" readonly></p-inputNumber>
    </div>

    <ng-container *ngIf="isBAT">
      <!-- Intérêts générés -->
      <div class="flex flex-column gap-1 field">
        <label class="block" for="interest">Intérêts</label>
        <p-inputNumber [(ngModel)]="interest" currency="XOF" inputId="interest"
                       locale="fr-FR" mode="currency" placeholder="-" readonly></p-inputNumber>
      </div>
    </ng-container>
    <ng-container *ngIf="isOAT">
      <div class="flex flex-column gap-1 field">
        <label class="block" for="couponCouru">Coupon couru</label>
        <p-inputNumber [(ngModel)]="couponCouru" [maxFractionDigits]="13" [minFractionDigits]="12" currency="XOF"
                       inputId="couponCouru" locale="fr-FR" mode="decimal" placeholder="-" readonly suffix=" %"></p-inputNumber>
      </div>
      <div class="flex flex-column gap-1 field">
        <label class="block" for="placementPrice">Prix du placement</label>
        <p-inputNumber [(ngModel)]="placementPrice" currency="XOF"

                       inputId="placementPrice" locale="fr-FR" mode="currency" placeholder="-" readonly suffix=" F CFA"></p-inputNumber>
      </div>
      <div class="flex flex-column gap-1 field">
        <label class="block" for="interestCourus">Intérêts courus</label>
        <p-inputNumber [(ngModel)]="interestCourus" currency="XOF"

                       inputId="interestCourus" locale="fr-FR" mode="currency" placeholder="-" readonly></p-inputNumber>
      </div>

    </ng-container>


  </div>
</div>
