import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ChartModule} from "primeng/chart";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {PaginatorState} from "primeng/paginator";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {CustomTableHeader} from "../../../../core/models/custom-table.model";
import {DetailsWithSubmissionModel} from "../../../../core/models/details-submissionn.model";
import {FileUpload, FileUploadModule} from "primeng/fileupload";
import {DropdownModule} from "primeng/dropdown";
import {DatePipe, NgIf, UpperCasePipe} from "@angular/common";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {NewTitleSecondaryComponent} from "../new-title-secondary/new-title-secondary.component";
import {take} from "rxjs";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {customDynamicDialog} from "../../../../core/utils/utils";
import {TitresWithOperationsModel} from "../../../../core/models/titres-with-operations.model";
import {ToastService} from "../../../../core/services/toast.service";
import {OperationsTitresService} from "../../../../core/services/operationsTitres.service";
import {FormBuilder, FormGroup, FormsModule} from "@angular/forms";
import {CalendarModule} from "primeng/calendar";
import {VolumeResponseDTO} from "../../../../core/models/volumeResponseDTO";
import {countriesUemoa} from "../../../../../assets/country-flags";


@Component({
  selector: 'app-resume',
  standalone: true,
  imports: [
    ChartModule,
    DynamicTableComponent,
    FileUploadModule,
    DropdownModule,
    UpperCasePipe,
    NgIf,
    CalendarModule,
    FormsModule
  ],
  templateUrl: './resume.component.html',
  styleUrl: './resume.component.css'
})
export class ResumeComponent implements OnInit{

  @ViewChild('fileUpload') fileUpload!: FileUpload;
  dateRange: Date[] | undefined;
  dernierTaux: number = 2.5;
  moyenne: number = 2.3;
  ecartType: number = 0.15;
  etm: number = 0.136
  centile: number = 5.925
  dateHaut: string = '27/04/2024'
  dateBas: string = '05/01/2024'

  boundData?: CustomDialogData;
  countries = countriesUemoa;
  secondary: DetailsWithSubmissionModel[] = [];
  ref?: DynamicDialogRef;
  form: FormGroup;

  startDate!: Date;
  endDate!: Date;
  operationsTitres: TitresWithOperationsModel[] = [];
  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  columns: CustomTableHeader[] = [
    { key: 'registerOperationsTitresDTO.titresWithEmissionDTO.emission.region', column: 'Emetteur', type: 'custom' },
    { key: 'isin', column: 'ISIN', type: 'text' },
    { key: 'registerOperationsTitresDTO.sens', column: 'Sens', type: 'text' },
    { key: 'registerOperationsTitresDTO.account', column: 'Compte', type: 'text' },
    { key: 'typeInstrument', column: 'Instrument', type: 'text' },
    { key: 'registerOperationsTitresDTO.titresWithEmissionDTO.emission.valueDate', column: 'Date valeur', type: 'text' },
    { key: 'dueDate', column: 'Date échéance', type: 'text' },
    { key: 'registerOperationsTitresDTO.amount', column: 'Montant', type: 'text' },
    { key: 'registerOperationsTitresDTO.rate', column: 'Taux facial(%)', type: 'text' },
  ];

  data: any;
  dataONE: any
  dataTWO: any;

  options: any;
  optionsONE:any;
  optionsTWO: any;

  constructor(public dialogService: DialogService,
              private toastService :ToastService,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private operationsTitresService: OperationsTitresService,
              private datePipe: DatePipe) {

    this.form = this.fb.group({});

  }

  ngOnInit() {

    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    this.dateRange = [thirtyDaysAgo, today];

    // Appliquer le filtre initial
    this.applyDateFilter();
    this.endDate = new Date();
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 30);

    const formattedStartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-ddTHH:mm:ss')!;
    const formattedEndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-ddTHH:mm:ss')!;

    this.loadChartData(formattedStartDate, formattedEndDate);
    this.loadOperationsTitres(getDefaultPageable(0, 10));
    this.form.get('registerOperationsTitresDTO.titresWithEmissionDTO.emission.region')?.valueChanges.subscribe(selectedCountry => {
      this.filter(selectedCountry);
    });
    this.form.get('registerOperationsTitresDTO.account')?.valueChanges.subscribe(selectedOwnership => {
      this.filterAccount(selectedOwnership);
    });

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Courbe des taux - XOF',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
          tension: 0.4
        }
      ]
    };
    this.dataTWO = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Taux moyens - XOF',
          backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
          borderColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
          data: [65, 59, 80, 81, 56, 55, 40]
        },
      ]
    };

    this.options = this.createChartOptions(0.9, 'x', 'Courbe des taux');
    this.optionsONE = this.createChartOptions(0.9, 'x', 'Volume des titres');
    this.optionsTWO = this.createChartOptions(0.9, 'y', 'Taux moyen');
  }

  applyDateFilter() {
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      const startDate = this.datePipe.transform(this.dateRange[0], 'yyyy-MM-ddTHH:mm:ss');
      const endDate = this.datePipe.transform(this.dateRange[1], 'yyyy-MM-ddTHH:mm:ss');

      if (startDate && endDate) {
        this.loadChartData(startDate, endDate);
      } else {
        this.toastService.showToast('Erreur', 'Erreur de formatage des dates.', 'error');
      }
    } else {
      this.toastService.showToast('Erreur', 'Veuillez sélectionner une plage de dates valide.', 'error');
    }
  }
  createChartOptions(aspectRatio: number, indexAxis: string = 'x', title: string = ''): any {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
      indexAxis: indexAxis,
      maintainAspectRatio: false,
      aspectRatio: aspectRatio,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        },
        title: {
          display: true,
          text: title,
          color: textColor,
          font: {
            size: 20
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  onPageChange = (event: PaginatorState) => {
    this.loadOperationsTitres(getDefaultPageable(event.page, event.rows));
  }
  private loadOperationsTitres(pageable: Pageable): void {
    this.operationsTitresService.getAllOperationsWihtTitres(pageable.page, pageable.limit, true)
      .subscribe({
        next: (response) => {
          this.operationsTitres = response.content.map(item => ({
            ...item,
            registerOperationsTitresDTO: {
              ...item.registerOperationsTitresDTO,
              titresWithEmissionDTO: {
                ...item.registerOperationsTitresDTO.emissionRequestDTO,
                emission: {
                  ...item.registerOperationsTitresDTO.emissionRequestDTO,
                  valueDate: this.formatDate(item.registerOperationsTitresDTO.emissionRequestDTO.valueDate),
                  region: this.getCountryName(item.registerOperationsTitresDTO.emissionRequestDTO.region),

                }
              }
            },
            dueDate: this.formatDate(item.dueDate)
          }));
          this.pageable.totalRecords = response.totalElements;
        },
        error: () => {
        }
      });
  }

  createTitleSecondary() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(NewTitleSecondaryComponent, customDynamicDialog('Titres - Marché secondaire', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadOperationsTitres(getDefaultPageable(0,10)));

  }
  private formatDate(date: Date | string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }

  onUpload(event: any) {
    const file = event.files[0];
    if (file) {
      this.operationsTitresService.importOperationsTitres(file).subscribe({
        next: (response) => {
          this.toastService.showToast('Succès', `Le fichier a été importé avec succès.`, 'success', response);
          this.loadOperationsTitres(getDefaultPageable(0, 10));
          this.resetFileUpload();
        },
        error: (error) => {
          let errorMessage = `Échec de importation du fichier ${file.name}.`;
          if (error.error && typeof error.error === 'string') {
            this.toastService.showToast('Erreur', error.error(), 'error');
          } else if (error instanceof SyntaxError) {
            this.toastService.showToast('Erreur', 'La réponse du serveur est pas au format JSON.', 'error');
          }
          this.toastService.showToast('Erreur', errorMessage, 'error');
          this.resetFileUpload();
        }
      });
    }
  }

  resetFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
  }

  private getCountryName(region: string): string {
    const country = this.countries.find((c: any) => c.code === region);
    return country ? country.country : region;
  }

  private filter(selectedCountry: string): void {
    if (selectedCountry) {
      this.operationsTitres = this.operationsTitres.filter(detail =>
        this.getCountryName(detail.registerOperationsTitresDTO.emissionRequestDTO.region) === selectedCountry
      );
    } else {
      this.loadOperationsTitres(getDefaultPageable(0, 10));
    }
  }
  private filterAccount(selectedOwnership: string): void {
    if (selectedOwnership) {
      this.operationsTitres = this.operationsTitres.filter(detail =>
        detail.registerOperationsTitresDTO.account === selectedOwnership
      );
    } else {
      this.loadOperationsTitres(getDefaultPageable(0, 10));
    }
  }

  loadChartData(startDate: string, endDate: string) {
    this.operationsTitresService.getVolumesByPeriod(startDate, endDate).subscribe({
      next: (response: VolumeResponseDTO[]) => {
        const achats = response.filter(vol => vol.sens === 'ACHAT').map(vol => vol.totalAmount);
        const ventes = response.filter(vol => vol.sens === 'VENTE').map(vol => vol.totalAmount);

        const uniqueMonths = new Set(response.map(vol => {
          return `${this.getMonthName(vol.month)} ${vol.year}`;
        }));
        const labels = Array.from(uniqueMonths);

        this.dataONE = {
          labels: labels,
          datasets: [
            {
              label: 'Achats',
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--green-500'),
              data: achats
            },
            {
              label: 'Ventes',
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--red-500'),
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--red-500'),
              data: ventes
            }
          ]
        };
        this.cd.detectChanges();
      },
      error: (err) => {
        this.toastService.showToast('Erreur', 'Erreur chargement des données', 'error', err);
      }
    });
  }

// Méthode auxiliaire pour obtenir le nom du mois
  private getMonthName(monthNumber: number): string {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('default', { month: 'long' });
  }
}
