import {Component, OnInit} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {IGroup} from "../../../core/models/group.model";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {UserService} from "../../../core/services/user.service";
import {GroupService} from "../../../core/services/group.service";
import {ToastService} from "../../../core/services/toast.service";
import {IUser} from "../../../core/models/user.model";
import {Direction, getUnpagedPageable, Sort} from "../../../core/models/page.model";
import {StorageService} from "../../../core/services/storage.service";

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    // Modules
    CheckboxModule, DropdownModule, InputTextModule, ReactiveFormsModule, Button, Ripple,
    // Pipes & Directives
    NgIf
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    firstname: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    lastname: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    username: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl('', {nonNullable: true, validators: [Validators.required, Validators.email]}),
    phoneNumber: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    group: new FormControl<IGroup | null>(null, {nonNullable: true, validators: [Validators.required]}),
    enabled: new FormControl(true, {nonNullable: true}),
  });
  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;
  groups: IGroup[] = [];
  user: IUser = {} as IUser;
  groupSort: Sort = { direction: Direction.ASC, orders: [{ property: 'name', ignoreCase: false }] };

  constructor(private userService: UserService, private dialogService: DialogService, private groupService: GroupService,
              private toastService: ToastService, private ref: DynamicDialogRef, private tokenManager: StorageService) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    const jwt = this.tokenManager.getToken();
    this.getGroups(jwt.institution_id);
    this.boundData = this.instance?.data;
    if (this.boundData?.isCreate) this.isCreate = true;
    else {
      this.user = this.boundData?.data as IUser;
      this.formGroup.patchValue(this.boundData?.data);
      this.formGroup.patchValue({group: this.user.group});
      if (this.boundData?.isView) this.formGroup.disable();
    }
  }

  getGroups(institutionId: string) {
    this.groupService.getGroupsByInstitutionId(institutionId, getUnpagedPageable(this.groupSort)).subscribe({
      next: (page) => this.groups = page.content,
      error: () => {
        const summary = 'Récupération des groupes';
        const detail = 'Une erreur est survenue lors de la récupération des groupes. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    });
  }

  create() {
    this.isLoading = true;
    const userDto = this.formGroup.value as IUser;
    this.userService.createUser(userDto).subscribe({
      next: (user) => {
        this.toastService.showToast('Utilisateur créé',`L'utilisateur ${user.firstname} ${user.lastname} a été créé avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la Création l\'utilisateur', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const userDto = this.formGroup.value as IUser;
    this.userService.updateUser(this.boundData?.data.id, userDto).subscribe({
      next: (user) => {
        this.toastService.showToast('Utilisateur modifié',`L'utilisateur ${user.firstname} ${user.lastname} a été modifié avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification de l\'utilisateur', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  toggleUser() {
    const enabled = this.boundData?.data?.enabled;
    this.userService.toggleUser(this.boundData?.data.id).subscribe({
      next: () => {
        const summary = enabled ? 'Utilisateur désactivé' : 'Utilisateur activé';
        const detail = `L'utilisateur a été ${enabled ? 'désactivé' : 'activé'} avec succès.`;
        this.toastService.showToast(summary, detail);
        this.ref.close();
      },
      error: () => {
        const summary = enabled ? 'Désactivation de l\'utilisateur' : 'Activation de l\'utilisateur';
        const detail = 'Une erreur est survenue lors de la modification de l\'utilisateur. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    })
  }

}
