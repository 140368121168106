import { Component } from '@angular/core';
import {IForecastCashFlow, IForecastCashFlowExcel} from "../../../../../../core/models/forecast-cash-flow.model";
import {CustomTableHeader} from "../../../../../../core/models/custom-table.model";
import {DEFAULT_PAGE_PARAMS, PageParams} from "../../../../../../core/models/page.model";
import {ForecastCashFlowService} from "../../../../../../core/services/forecast-cash-flow.service";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../../../../core/services/toast.service";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {UploadTableComponent} from "../../../../../../shared/components/upload-table/upload-table.component";
import {NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [
    FileUploadModule,
    UploadTableComponent,
    NgIf,
    Ripple
  ],
  templateUrl: './upload-forecast.component.html',
  styleUrl: './upload-forecast.component.scss'
})
export class UploadForecastComponent {

  instance: DynamicDialogComponent | undefined;
  uploadedFile: File = {} as File;
  isLoading: boolean = false;
  operations: IForecastCashFlowExcel[] = [];
  columns: CustomTableHeader[] = [
    { key: 'category', column: 'Catégorie', type: 'text' },
    { key: 'type', column: 'Type', type: 'text' },
    { key: 'libelle', column: 'Libelle', type: 'text' },
    { key: 'amount', column: 'Montant', type: 'currency', currencyKey: 'currency.code' },
    { key: 'operationDate', column: 'Date opération', type: 'date' },
    { key: 'valueDate', column: 'Date valeur', type: 'date' }
  ];
  pageable: PageParams = DEFAULT_PAGE_PARAMS;

  constructor(
    private forecastService: ForecastCashFlowService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  onUpload(event: FileSelectEvent) {
    this.uploadedFile = event.files[event.currentFiles.length - 1];
  }

  upload() {
    this.forecastService.parseExcelFile(this.uploadedFile).subscribe({
      next: (data) => this.operations = data,
      error: (error) => this.toastService.showToast('Chargement d\'opérations futures', error.error, 'error'),
    });
  }

  saveValidOperations(validOperations: IForecastCashFlowExcel[]) {
    const operations = validOperations.map((op: IForecastCashFlowExcel) => {
      return {
        id: '',
        institutionId: '',
        operationDate: op.operationDate,
        valueDate: op.valueDate,
        category: op.category,
        type: op.type,
        amount: op.amount,
        libelle: op.libelle,
        isLocalCurrency: op.isLocalCurrency,
        currency: op.currency,
        isDeleted: false,
      } as IForecastCashFlow
    });
    const summary = 'Chargement d\'opérations futures';
    this.forecastService.uploadForecastCashFlows(operations).subscribe({
      next: () => {
        this.toastService.showToast(summary, 'Les opérations futures ont été chargées avec succès.');
        this.ref.close();
      },
      error: (err) => this.toastService.showToast(summary, err.error, 'error')
    })
  }

}
