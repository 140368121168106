import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Button} from "primeng/button";
import {ToolbarModule} from "primeng/toolbar";
import {InputTextModule} from "primeng/inputtext";
import {NgForOf, NgIf} from "@angular/common";
import {CustomAction} from "../../../core/models/custom-table.model";
import {StorageService} from "../../../core/services/storage.service";

@Component({
  selector: 'toolbar',
  standalone: true,
  imports: [Button, ToolbarModule, InputTextModule, NgIf, NgForOf],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent implements OnInit {

  @Input({required: true}) title: string = '';
  @Input() actions: CustomAction[] = [];
  @Output() search = new EventEmitter<string>();

  constructor(private readonly storageService: StorageService) {}

  ngOnInit() {
    if (this.actions.length > 0) {
      const jwt = this.storageService.getToken();
      this.actions = this.actions.filter(action =>
        jwt.authorities.some(role => action.role === role) &&
        (typeof action.visible === 'boolean' ? action.visible : true)
      );
    }
  }

  onSearch(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.search.emit(value);
  }
}
