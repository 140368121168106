  <div class="flex flex-column h-full">
    <form [formGroup]="formGroup" class="flex flex-column gap-4">
      <div class="flex flex-column gap-2">
        <label class="font-medium">ISIN</label>
        <p-dropdown
          [options]="isins"
          optionLabel="label"
          optionValue="value"
          formControlName="isin"
          placeholder="Sélectionnez un ISIN">
        </p-dropdown>
        <small id="isin-help">Veuillez sélectionner le code ISIN.</small>
      </div>
      <div class="flex flex-column gap-2">
        <label class="font-medium">Sens</label>
        <p-dropdown
          formControlName="sens"
          [options]="sensOptions"
          optionLabel="name"
          optionValue="code"
          placeholder="Choisir un sens" />
        <small id="sens-help">Veuillez sélectionner un sens.</small>
      </div>
      <div class="flex flex-column gap-2">
        <label class="font-medium">Compte</label>
        <p-dropdown
          formControlName="account"
          [options]="accountOptions"
          optionLabel="name"
          optionValue="code"
          placeholder="Choisir un type de compte"
          (onChange)="onAccountChange($event.value)"
        ></p-dropdown>
        <small id="account-help">Veuillez sélectionner le type de compte.</small>
      </div>
      <div class="flex flex-column gap-2" *ngIf="showBeneficiary">
        <label class="font-medium">Bénéficiaire</label>
        <input type="text" pInputText id="beneficiary" aria-describedby="beneficiary-help" formControlName="beneficiary" />
        <small id="beneficiary-help">Veuillez renseigner le bénéficiaire.</small>
      </div>
      <div class="flex flex-column gap-2">
        <label class="font-medium">Montant (F CFA)</label>
        <input type="number" pInputText id="amount" aria-describedby="amount-help" formControlName="amount" min="0" step="0.01" />
        <small id="amount-help">Renseigner ici le montant.</small>
      </div>
      <div class="flex flex-column gap-2">
        <label class="font-medium">Contrepartie</label>
        <input type="text" pInputText id="counterpart" aria-describedby="counterpart-help" formControlName="counterpart" />
        <small id="counterpart-help">Veuillez sélectionner une contrepartie.</small>
      </div>
      <div class="flex flex-column gap-2">
        <label class="font-medium">Taux / Prix</label>
        <input type="number" pInputText id="rate" aria-describedby="rate-help" formControlName="rate" min="0" step="0.01" />
        <small id="rate-help">Renseigner ici le taux.</small>
      </div>
    </form>
    <div class="flex flex-grow-1 gap-2 align-items-end justify-content-end ">
      <p-button class="my-4" pRipple  [style]="{width: '100%'}" [label]="'Enregistrer'" (onClick)="addOperationsTitres()"></p-button>
    </div>
  </div>

