export interface IBeneficiary {
  id?: string;
  name: string;
  accountNumber: string;
  bank: string;
  agency: string;
  iban?: string;
  bic?: string;
  rib?: string;
  type: string;
}

export enum BeneficiaryType {
  INDIVIDUAL = "Personne physique",
  COMPANY = "Personne morale"
}
