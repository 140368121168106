<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mt-4">
    <div class="flex flex-column gap-2">
      <label for="category" class="font-medium">Catégorie</label>
      <p-dropdown id="category" aria-describedby="category-help" formControlName="category" optionValue="id"
                  [options]="categories" optionLabel="name" placeholder="Sélectionner la catégorie">

      </p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="law" class="font-medium">Loi appliquée</label>
      <p-dropdown id="law" aria-describedby="law-help"  formControlName="flowLaw" optionValue="id"
                  optionLabel="name" [options]="flowLaws" placeholder="Sélectionner la loi"></p-dropdown>
    </div>
    <div class="flex flex-column gap-2">
      <label for="startDate" class="font-medium">Date de début</label>
      <p-calendar [minDate]="today" id="startDate" dateFormat="dd/mm/yy" formControlName="startDate"></p-calendar>
    </div>
    <div class="flex flex-column gap-2">
      <label for="expiryDate" class="font-medium">Date d'expiration</label>
      <p-calendar id="expiryDate" dateFormat="dd/mm/yy" formControlName="expiryDate"></p-calendar>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end mt-4 justify-content-end">
    <p-button pRipple *ngIf="data?.isCreate" label="Enregistrer" [disabled]="!formGroup.valid" [loading]="isLoading" (onClick)="save()"></p-button>
  </div>
</div>
