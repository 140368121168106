import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {PasswordModule} from "primeng/password";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {InputSwitchModule} from "primeng/inputswitch";
import {ToastService} from "../../../core/services/toast.service";
import {IUser} from "../../../core/models/user.model";
import {CheckboxChangeEvent, CheckboxModule} from "primeng/checkbox";
import {UserService} from "../../../core/services/user.service";
import {PasswordDTO} from "../../../core/models/password.model";

@Component({
  selector: 'app-update-password',
  standalone: true,
  imports: [ReactiveFormsModule, PasswordModule, Button, Ripple, InputSwitchModule, CheckboxModule],
  templateUrl: './update-password.component.html',
  styleUrl: './update-password.component.scss'
})
export class UpdatePasswordComponent implements OnInit {

  formGroup = new FormGroup({
    oldPassword: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    newPassword: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    confirmPassword: new FormControl('', {nonNullable: true, validators: [Validators.required]})
  });
  isLoading: boolean = false;
  twoFactorGroup: FormGroup = new FormGroup({ twoFactor: new FormControl(false, {nonNullable: true}) });
  user: IUser | undefined;

  constructor(private toastService: ToastService, private userService: UserService) {}

  ngOnInit() {
    this.userService.getSelf().subscribe({
      next: (user) => {
        this.user = user;
        this.twoFactorGroup.patchValue({twoFactor: user.twoFactorAuthenticationEnabled});
      },
      error: () => this.toastService.showToast('Récupération de l\'utilisateur', 'Une erreur est survenue lors de la récupération de l\'utilisateur', 'error')
    })
  }

  onSubmit() {
    this.isLoading = true;
    // Call the API to update the password
    const passwordDto = this.formGroup.value as PasswordDTO;
    this.userService.updatePassword(passwordDto).subscribe({
      next: () => {
        this.toastService.showToast('Mise à jour du mot de passe', 'Le mot de passe a été mis à jour avec succès');
        this.isLoading = false;
        this.formGroup.reset();
      },
      error: (err) => {
        this.toastService.showToast('Mise à jour du mot de passe', err.error, 'error');
        this.isLoading = false
      }
    });
  }

  toggleTwoFactor($event: CheckboxChangeEvent) {
    const summary = !$event.checked ? 'Activation de l\'authentification à deux facteurs' : 'Désactivation de l\'authentification à deux facteurs';
    this.userService.toggleTwoFactor().subscribe({
      next: () => {
        const detail = !$event.checked ? 'L\'activation de l\'authentification à double facteurs s\'est déroulé avec succès'  : 'La désactivation de l\'authentification à double facteur s\'est déroulé avec succès';
        this.toastService.showToast(summary, detail);
        this.twoFactorGroup.patchValue({twoFactor: $event.checked});
        this.ngOnInit();
      },
      error: () => {
        const detail = !$event.checked ? 'Une erreur est survenue lors de l\'activation de l\'authentification à deux facteurs' : 'Une erreur est survenue lors de la désactivation de l\'authentification à deux facteurs';
        this.toastService.showToast(summary, detail, 'error');
        this.ngOnInit();
      }
    })
  }
}
