import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getDefaultPageable, getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IFlowLaw} from '../models/flow-law.model';

@Injectable({
  providedIn: 'root'
})
export class FlowLawService {
  private readonly apiUrl = `risque/v1/flow-laws`;

  constructor(private readonly http: HttpClient) { }

  createFlowLaw(flowLaw: IFlowLaw): Observable<IFlowLaw> {
    return this.http.post<IFlowLaw>(this.apiUrl, flowLaw);
  }

  searchFlowLaws(query: string = '', pageable: Pageable = getDefaultPageable()): Observable<Page<IFlowLaw>> {
    const params = getPageableAsParams(pageable).set('query', query);
    return this.http.get<Page<IFlowLaw>>(`${this.apiUrl}/search`, { params });
  }
  getFlowLawById(id: string): Observable<IFlowLaw> {
    return this.http.get<IFlowLaw>(`${this.apiUrl}/${id}`);
  }

  getAllFlowLaws(pageable: Pageable): Observable<Page<IFlowLaw>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IFlowLaw>>(this.apiUrl, { params });
  }

  getCurrentUserFlowLaws(pageable: Pageable): Observable<Page<IFlowLaw>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IFlowLaw>>(`${this.apiUrl}/current-user`, { params });
  }

  getFlowLawsByInstitution(institutionId: string, pageable: Pageable): Observable<Page<IFlowLaw>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IFlowLaw>>(`${this.apiUrl}/institution/${institutionId}`, { params });
  }

  deleteFlowLaw(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getFlowRateForMonth(flowLawId: string, month: number): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/${flowLawId}/rate/${month}`);
  }
}
