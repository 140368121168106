import {Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuItem } from '../../../core/models/menu-item.model';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  template: `
    <div class="menu-item">
      <!-- Menu item with submenu -->
      <div *ngIf="item.subMenus?.length"
           class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700
                 hover:surface-100 transition-duration-150 transition-colors"
           [routerLink]="item.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
           (click)="$event.preventDefault(); toggleSubmenu()">
        <i *ngIf="item.icon" class="{{item.icon}} mr-2"></i>
        <span class="font-medium">{{item.label}}</span>
        <i class="pi ml-auto" [class.pi-chevron-down]="isExpanded" [class.pi-chevron-right]="!isExpanded"></i>
      </div>

      <a *ngIf="!item.subMenus?.length"
         [routerLink]="item.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
         class="flex no-underline align-items-center cursor-pointer p-3 border-round text-700
               hover:surface-100 transition-duration-150 transition-colors menu-link">
        <i *ngIf="item.icon" class="{{item.icon}} mr-2"></i>
        <span class="font-medium">{{item.label}}</span>
      </a>

      <!-- Submenu items -->
      <div *ngIf="item.subMenus?.length" [@slideInOut]="isExpanded" class="submenu-container"
           [class.pl-2]="level === 0" [class.pl-3]="level === 1" [class.pl-4]="level >= 2">
        <app-menu-item *ngFor="let subItem of item.subMenus" [item]="subItem" [level]="level + 1" [expanded]="expanded" />
      </div>
    </div>
  `,
  styles: [`
    .menu-item {
      .active {
        background-color: var(--primary-color);
        color: var(--surface-200) !important;
      }

      .submenu-container {
        overflow: hidden;
      }

      .active:hover {
        color: var(--surface-600) !important;
      }
    }

    :host {
      display: block;
    }
  `],
  animations: [
    trigger('slideInOut', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0', overflow: 'hidden' })),
      transition('true <=> false', animate('300ms ease-in-out'))
    ])
  ]
})
export class MenuItemComponent implements OnInit {

  @Input() item!: MenuItem;
  @Input() expanded: boolean = true;
  @Input() level: number = 0;

  private _isExpanded: boolean = true;

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  ngOnInit() {
    this._isExpanded = this.expanded;
  }

  toggleSubmenu() {
    this._isExpanded = !this._isExpanded;
  }
}
