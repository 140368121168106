<div class="flex flex-column h-full pb-4">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label class="font-medium">Type du bénéficiaire</label>
      <p-dropdown formControlName="type" [options]="typeBeneficiary" placeholder="Choisir le type" />
      <small  id="nature-help">Sélectionner la nature.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="libelle" class="font-medium">Nom / Raison sociale</label>
      <input pInputText id="libelle" aria-describedby="libelle-help" formControlName="name" />
      <small *ngIf="boundData?.isCreate" id="libelle-help">Renseigner ici le nom ou la raison sociale du bénéficiaire.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="bank" class="font-medium">Banque</label>
      <input pInputText id="bank" aria-describedby="bank-help" formControlName="bank" />
      <small *ngIf="boundData?.isCreate" id="bank-help">Renseigner ici la banque du bénéficiaire.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="iban" class="font-medium">Code d'identification bancaire</label>
      <input pInputText id="bic" aria-describedby="bic-help" formControlName="bic" />
      <small *ngIf="boundData?.isCreate" id="bic-help">Renseigner ici le BIC de la banque du bénéficiaire.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="number" class="font-medium">Numéro de compte</label>
      <input pInputText id="number" aria-describedby="number-help" formControlName="accountNumber" />
      <small *ngIf="boundData?.isCreate" id="number-help">Renseigner ici le numéro de compte du bénéficiaire.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="rib" class="font-medium">Relevé d'identification bancaire</label>
      <input pInputText id="rib" aria-describedby="rib-help" formControlName="rib" />
      <small *ngIf="boundData?.isCreate" id="rib-help">Renseigner ici le RIB du bénéficiaire.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="iban" class="font-medium">Numéro de compte bancaire international</label>
      <input pInputText id="iban" aria-describedby="iban-help" formControlName="iban" />
      <small *ngIf="boundData?.isCreate" id="iban-help">Renseigner ici l'IBAN du bénéficiaire.</small>
    </div>
  </form>
  <div class="flex my-4 gap-2 justify-content-end">
    <p-button pRipple *ngIf="!formGroup.disabled" [style]="{width: '100%'}" class="mb-4"
              [label]=" boundData?.isCreate ? 'Enregistrer' : 'Modifier'" [disabled]="!formGroup.valid"
              [loading]="isLoading" (onClick)="boundData?.isCreate ? create() : update()"></p-button>
  </div>
</div>
