import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IValidation} from '../models/validation.model';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  private apiUrl = 'core/v1/validations';

  constructor(private http: HttpClient) { }

  /**
   * Create a new validation
   *
   * @param validationDTO validation data
   * @return created validation
   */
  createValidation(validationDTO: IValidation): Observable<IValidation> {
    return this.http.post<IValidation>(`${this.apiUrl}`, validationDTO);
  }

  /**
   * Update an existing validation
   *
   * @param institutionId institution id
   * @param productId product id
   * @param validation validation data
   * @return updated validation
   */
  updateValidation(institutionId: string, productId: string, validation: IValidation): Observable<IValidation> {
    const params = new HttpParams().set('institutionId', institutionId).set('productId', productId);
    return this.http.put<IValidation>(`${this.apiUrl}`, validation, { params });
  }

  /**
   * Find all validations by institution id
   *
   * @param institutionId institution id
   * @param pageable pageable
   * @return page of validations
   */
  findValidationsByInstitutionId(institutionId: string, pageable: Pageable): Observable<Page<IValidation>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IValidation>>(`${this.apiUrl}/institution/${institutionId}`, { params });
  }

  /**
   * Find a validation by its product and institution id
   *
   * @param institutionId institution id
   * @param productId product id
   * @return validation
   */
  findValidationByProductIdAndInstitutionId(institutionId: string, productId: string): Observable<IValidation> {
    const params = new HttpParams().set('institutionId', institutionId).set('productId', productId);
    return this.http.get<IValidation>(`${this.apiUrl}/unique`, { params });
  }

  /**
   * Delete a validation by its product and institution id
   *
   * @param institutionId institution id
   * @param productId product id
   */
  deleteValidation(institutionId: string, productId: string): Observable<void> {
    const params = new HttpParams().set('institutionId', institutionId).set('productId', productId);
    return this.http.delete<void>(`${this.apiUrl}`, { params });
  }
}
