<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div  class="flex flex-column gap-2">
      <label class="font-medium">Numéro</label>
      <input  pInputText id="number" aria-describedby="number-help" formControlName="number" />
      <small  id="number-help">Renseigner ici le numéro.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Nature</label>
      <p-dropdown formControlName="nature" [options]="natureOptions" optionLabel="name" optionValue="code" placeholder="Choisir la nature" />
      <small  id="nature-help">Sélectionner la nature.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Date Valeur</label>
      <p-calendar dateFormat="dd/mm/yy" formControlName="valueDate" />
      <small  id="valueDate-help">Renseigner ici la date de valeur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Echéance</label>
      <p-calendar dateFormat="dd/mm/yy" formControlName="dueDate" />
      <small  id="dueDate-help">Renseigner ici la date d'échance.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Taux</label>
      <input type="number"  pInputText id="rate" aria-describedby="rate-help" formControlName="rate" />
      <small  id="rate-help">Renseigner ici le taux.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Montant maximum</label>
      <input type="number"  pInputText id="maxAmount" aria-describedby="maxAmount-help" formControlName="maxAmount" />
      <small  id="maxAmount-help">Renseigner ici le montant maximum.</small>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end justify-content-end ">
    <p-button
      class="my-4"
      pRipple
      [style]="{width: '100%'}"
      [label]="'Enregistrer'"
      [disabled]="!formGroup.valid || isLoading"
      [loading]="isLoading"
      (onClick)=createWicketOperation()
    ></p-button>
  </div>
</div>
