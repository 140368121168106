<div class="flex flex-column h-full pb-4">
  <form [formGroup]="formGroup" class="flex flex-column gap-4">
    <div class="flex flex-column gap-2">
      <label class="font-medium">Compte Crédit</label>
      {{ this.boundData?.data.credit.account.libelle }} - {{ this.boundData?.data.credit.account.number }}
      <small *ngIf="boundData?.isCreate" id="creditAccount-help">Sélectionner le compte de crédit.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Compte Débit</label>
      {{ this.boundData?.data.debit.account.libelle }} - {{ this.boundData?.data.debit.account.number }}
      <small *ngIf="boundData?.isCreate" id="debitAccount-help">Sélectionner le compte de débit.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="libelle" class="font-medium">Libellé</label>
      <input pInputText id="libelle" aria-describedby="libelle-help" formControlName="libelle" />
      <small *ngIf="boundData?.isCreate" id="libelle-help">Renseigner ici le libellé du virement.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label class="font-medium">Devise</label>
      <small *ngIf="boundData?.isCreate" id="devise-help">Sélectionner la devise du compte.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="amount" class="font-medium">Montant</label>
      <p-inputNumber  id="amount" aria-describedby="amount-help" formControlName="amount" inputId="integeronly"/>
      <small *ngIf="boundData?.isCreate" id="amount-help">Renseigner ici le montant du virement.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="operationDate" class="font-medium">Date de l'opération</label>
      <p-calendar id="operationDate" formControlName="operationDate" />
      <small *ngIf="boundData?.isCreate" id="operationDate-help">Sélectionner la date de l'opération.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="valueDate" class="font-medium">Date de valeur</label>
      <p-calendar id="valueDate" formControlName="valueDate" />
      <small *ngIf="boundData?.isCreate" id="valueDate-help">Sélectionner la date de valeur.</small>
    </div>
    <div class="flex flex-column gap-2">
      <label for="description" class="font-medium">Commentaires</label>
      <textarea id="description" aria-describedby="description-help" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
      <small *ngIf="boundData?.isCreate" id="description-help">Renseigner ici des commentaires relatifs au virement.</small>
    </div>
  </form>
</div>
