<p-table [value]="data" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }">
  <ng-template pTemplate="header">
    <tr>
      <th scope="col" *ngFor="let header of headers" [pSortableColumn]="header.key" [ngStyle]="{'background-color': '#004F78', 'border-right': '1px solid #ddd', 'color': 'white'}">
        <div class="flex justify-content-between align-items-center">
          <span>{{ header.column }}</span>
          <div class="flex justify-content-between align-items-center">
            <p-sortIcon [field]="header.key" pStyleClass="text-white" style="margin-right: 0;"></p-sortIcon>
            <p-columnFilter [field]="header.key" matchMode="contains" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <button pButton pRipple type="button" icon="pi pi-filter" (click)="op.toggle($event); $event.stopPropagation()"
                        class="p-button-text p-button-rounded p-button-sm p-button-plain md:border-left-3"
                        [ngClass]="{'p-button-outlined': value}" style="color: white;">
                </button>
                <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '250px'}" styleClass="p-2">
                  <ng-template pTemplate>
                    <div class="p-fluid">
                      <span class="p-input-icon-left w-full">
                        <i class="pi pi-search"></i>
                        <input type="text" pInputText [ngModel]="value" (ngModelChange)="filter($event)"
                          placeholder="Rechercher..." class="p-inputtext-sm w-full">
                      </span>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </ng-template>
            </p-columnFilter>
          </div>
        </div>
      </th>
      <th *ngIf="this.actions.length > 0" scope="col" [ngStyle]="{'background-color': '#004F78', 'color': 'white'}">
        Actions
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td *ngFor="let header of headers" class="truncate text-center" [pTooltip]="header.type == 'text' ? getNestedValue(item, header.key) : ''">
        <ng-container [ngSwitch]="header.type">
          <ng-container *ngSwitchCase="'text'">{{ getNestedValue(item, header.key) }}</ng-container>
          <ng-container *ngSwitchCase="'currency'">
            {{ getNestedValue(item, header.key) | currency : (header.currencyKey ? getNestedValue(item, header.currencyKey) : 'XOF') : 'symbol' : '1.0-0' : 'fr-FR' }}
          </ng-container>
          <ng-container *ngSwitchCase="'number'">
            {{ getNestedValue(item, header.key) | number:'1.0-0':'fr' }}
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            {{ getNestedValue(item, header.key) | date:'dd/MM/yyyy' }}
          </ng-container>
          <span *ngSwitchCase="'text'">{{ getNestedValue(data, header.key) }}</span>
          <span *ngSwitchCase="'currency'">{{ getNestedValue(data, header.key) | currency : 'XOF' }}</span>
          <span *ngSwitchCase="'number'">{{ getNestedValue(data, header.key) | number: '1.2-2' }}</span>
          <span *ngSwitchCase="'date'">{{ getNestedValue(data, header.key) | date : 'dd/MM/yyyy' }}</span>
          <span *ngSwitchCase="'date'">{{ getNestedValue(data, header.key) | date : 'dd/MM/yyyy, hh:mm' }}</span>
          <ng-container *ngSwitchCase="'custom'">
            <div *ngIf="header.key === 'region'" class="flex align-items-center">
              <img [src]="getCountryFlag(getNestedValue(data, 'region')) || 'default-flag-url.svg'" alt="{{ getNestedValue(data, 'region') }}" style="width: 20px; height: 15px; margin-right: 10px;" />
              {{ getNestedValue(data, 'region') }}
            </div>
          </ng-container>
          <span *ngSwitchCase="'state'">
            <span *ngIf="getNestedValue(data, header.key) == 'PENDING'">
              <p-tag [style]="{ background: 'var(--yellow-200)' }">
                <span class="pi pi-stopwatch mr-1" [style]="{ color: 'var(--yellow-800)' }"></span>
                <span [style]="{ color: 'var(--yellow-800)' }">En attente</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'VALIDATED'">
              <p-tag [style]="{ background:'var(--green-200)' }">
                <span class="pi pi-check-circle mr-1"  [style]="{ color: 'var(--green-500)'  }"></span>
                <span [style]="{ color: 'var(--green-500)' }">Validée</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'REJECTED'">
              <p-tag [style]="{ background: 'var(--red-200)' }">
                <span class="pi pi-times-circle mr-1"  [style]="{ color: 'var(--red-500)' }"></span>
                <span [style]="{ color: 'var(--red-500)' }">Rejetée</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'ACTIVE'">
              <p-tag [style]="{ background: 'var(--green-200)' }">
                <span class="pi pi-check-circle mr-1" [style]="{ color: 'var(--green-500)' }"></span>
                <span [style]="{ color: 'var(--green-500)' }">Active</span>
              </p-tag>
            </span>
            <span *ngIf="getNestedValue(data, header.key) == 'INACTIVE'">
              <p-tag [style]="{ background: 'var(--red-200)' }">
                <span class="pi pi-times-circle mr-1" [style]="{ color: 'var(--red-500)' }"></span>
                <span [style]="{ color: 'var(--red-500)' }">Inactive</span>
              </p-tag>
            </span>
          </span>
          <ng-container *ngSwitchCase="'boolean'">
            <i class="pi" [ngClass]="{'pi-check-circle text-green-500': getNestedValue(item, header.key), 'pi-times-circle text-red-500': !getNestedValue(item, header.key)}"></i>
          </ng-container>
          <span *ngSwitchDefault>{{ getNestedValue(data, header.key) }}</span>
          <ng-container *ngSwitchCase="'state'">
            <ng-container [ngSwitch]="getNestedValue(item, header.key)">
              <span *ngSwitchCase="'PENDING'" class="text-teal-800">
                  <i class="pi pi-stopwatch mr-1"></i>
              </span>
              <span *ngSwitchCase="'ACTIVE'" class="text-green-500">
                  <i class="pi pi-check-circle mr-1"></i>
              </span>
              <span *ngSwitchCase="'INACTIVE'" class="text-red-500">
                  <i class="pi pi-times-circle mr-1"></i>
              </span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>{{ getNestedValue(item, header.key) }}</ng-container>
        </ng-container>
      </td>
      <td *ngIf="this.actions.length > 0">
        <div class="card flex justify-content-center">
          <p-button [rounded]="true" size="small" [text]="true" [outlined]="true" severity="secondary"
                    (onClick)="emit(item, $event, menu);" icon="pi pi-ellipsis-v"
                    [disabled]="getActionsForRow(data).length === 0" />
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-menu #menu [popup]="true"></p-menu>
<div class="flex-auto">
  <p-paginator *ngIf="pageParams" (onPageChange)="paginate.emit($event)" [showCurrentPageReport]="true"
               [first]="pageParams.first" [rows]="pageParams.rows" [totalRecords]="pageParams.totalRecords"
               [rowsPerPageOptions]="pageParams.rowPerPageOptions" />
</div>
