<div class="h-full flex flex-column gap-2">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mb-4">
    <div class="flex flex-column gap-2">
      <label for="name" class="font-medium">Nom</label>
      <input pInputText id="name" aria-describedby="name-help" formControlName="name" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="desc" class="font-medium">Description</label>
      <textarea id="desc" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
    </div>
  </form>
  <p-accordion *ngIf="boundData?.isView" class="my-4" [multiple]="true" [activeIndex]="0">
    <p-accordionTab class="mb-2" header="Permissions">
      <group-permissions [disabled]="boundData?.isEdit" [selectedPermissions]="permissions" (permissionsChange)="permissionChange($event)" />
    </p-accordionTab>
    <p-accordionTab class="mb-2" header="Utilisateurs">
      <dynamic-table [data]="users" [headers]="userCols" [pageParams]="pageable" (paginate)="onPageChange($event)" />
    </p-accordionTab>
  </p-accordion>
  <div class="flex flex-grow-1 gap-2 mt-4 align-items-end justify-content-end">
    <p-button *ngIf="!boundData?.isCreate" [severity]="boundData?.data?.enabled ? 'danger' : 'success'" [label]="boundData?.data?.enabled ? 'Désactiver' : 'Activer'" (onClick)="toggleGroup()" />
    <p-button *ngIf="boundData?.isCreate" label="Enregistrer" (onClick)="create()" />
    <p-button *ngIf="boundData?.isEdit" label="Modifier" (onClick)="update()" />
  </div>
</div>
