<div class="flex flex-column gap-4 py-4">
  <div class="flex justify-content-between">
    <div class="flex gap-2 align-items-baseline py-2">
      <span class="text-xl text-color-secondary font-semibold">Prêts et emprunts</span>
      <span class="text-xl text-color-secondary font-semibold">/</span>
      <span class="text-xl font-bold">{{ isCreate ? 'Nouveau' : loanBorrowing.libelle }}</span>
    </div>
    <p-button *ngIf="!isCreate" icon="pi pi-pen-to-square" [outlined]="true" severity="secondary" (onClick)="enableForm()"/>
  </div>
  <div class="flex justify-content-between w-full">
    <div class="flex flex-column gap-2 w-full">
      <form class="w-full flex flex-column gap-4 p-4 shadow-3 border-round-md" [formGroup]="loanBorrowingForm">
        <div class="flex gap-4 w-full">
          <div class="flex w-3 flex-column gap-2">
            <label for="type" class="font-medium">Type</label>
            <p-dropdown id="type" aria-describedby="type-help" formControlName="type" [options]="repaymentTypes"/>
            <small *ngIf="isCreate" id="type-help">Renseigner ici le type d'opération </small>
          </div>
          <div class="flex w-3 flex-column gap-2">
            <label for="amount" class="font-medium">Montant</label>
            <p-inputNumber [min]="0" inputId="integeronly" id="amount" aria-describedby="amount-help" formControlName="amount" />
            <small *ngIf="isCreate" id="amount-help">Renseigner ici le montant.</small>
          </div>
          <div class="flex w-3 flex-column gap-2">
            <label for="counterparty" class="font-medium">Contrepartie</label>
            <input pInputText id="counterparty" aria-describedby="counterparty-help" formControlName="counterparty" />
            <small *ngIf="isCreate" id="counterparty-help">Renseigner ici la contrepartie.</small>
          </div>
        </div>
        <div class="flex gap-4 w-full">
          <div class="flex w-3 flex-column gap-2">
            <label for="counterparty-type" class="font-medium">Type de la contrepartie</label>
            <p-dropdown id="counterparty-type" aria-describedby="currency-help" formControlName="counterpartyType"
                        [editable]="true" [options]="counterPartyTypes"></p-dropdown>
            <small *ngIf="isCreate" id="counterparty-type-help">Renseigner ici le type de la contrepartie.</small>
          </div>
          <div class="flex w-3 flex-column gap-2">
            <label for="currency" class="font-medium">Devise</label>
            <p-dropdown id="currency" aria-describedby="currency-help" formControlName="currency"
                        [editable]="true" filter="true" filterBy="code,description" [options]="currencies"
                        optionLabel="description" optionValue="code">
              <ng-template let-currency pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ currency.description }} - {{ currency.code }} - {{ currency.symbol }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <small *ngIf="isCreate" id="currency-help">Renseigner ici la devise de l'opération.</small>
          </div>
          <div class="flex w-3 flex-column gap-2">
            <label for="interestRate" class="font-medium">Taux (%)</label>
            <p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2"
                           [min]="0" [max]="100" id="interestRate"
                           aria-describedby="interestRate-help" formControlName="interestRate" />
            <small *ngIf="isCreate" id="interestRate-help">Renseigner ici le taux d'intérêts.</small>
          </div>
        </div>
        <div class="flex gap-4 w-full">
          <div class="flex w-3 flex-column gap-2">
            <label for="frequency" class="font-medium">Amortissement</label>
            <p-dropdown id="frequency" aria-describedby="frequency-help" formControlName="frequency"
                        [options]="frequencies" optionLabel="description"  optionValue="description"/>
            <small *ngIf="isCreate" id="frequency-help">Renseigner ici le type d'amortissement </small>
          </div>
          <div class="flex flex-column gap-2 w-3">
            <label for="valueDate" class="font-medium">Date de valeur</label>
            <p-calendar [minDate]="today" id="valueDate" selectionMode="single" [autofocus]="false" [readonlyInput]="true" formControlName="valueDate" />
            <small *ngIf="isCreate" id="value-help">Renseigner ici la date de valeur de l'opération.</small>
          </div>
          <div class="flex flex-column gap-2 w-3">
            <label for="maturityDate" class="font-medium">Date de maturité</label>
            <p-calendar [minDate]="today" id="maturityDate" selectionMode="single" [autofocus]="false" [readonlyInput]="true" formControlName="maturityDate" />
            <small *ngIf="isCreate" id="maturityDate-help">Renseigner ici la date de maturité de l'opération.</small>
          </div>
        </div>
        <div class="flex gap-4 w-9">
          <div class="flex w-full flex-column gap-2">
            <label for="libelle" class="font-medium">Libellé</label>
            <input pInputText id="libelle" aria-describedby="libelle-help" formControlName="libelle" />
          </div>
        </div>
        <div class="flex py-4 gap-4 justify-content-end">
          <p-button *ngIf="isCreate" outlined="true" severity="secondary" label="Tableau d'amortissement" [disabled]="loanBorrowingForm.invalid" (onClick)="simulateRepaymentEntries()" />
          <p-button *ngIf="formEnabled" label="Enregistrer" class="p-button-rounded p-button-success" [disabled]="loanBorrowingForm.invalid" (onClick)="onSubmit()"/>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="!isCreate && loanBorrowing">
    <app-repayment-list [showHeader]="false" [loanBorrowing]="loanBorrowing" [repaymentEntries]="repaymentEntries" />
  </div>
</div>
<p-dialog *ngIf="dialogVisible" header="Tableau d'amortissement" [modal]="true" [(visible)]="dialogVisible" [style]="{ width: '80rem' }">
  <app-repayment-list [loanBorrowing]="loanBorrowing" [repaymentEntries]="repaymentEntries"></app-repayment-list>
</p-dialog>
