// institution-dto.model.ts
import {IModule} from "./module.model";

export interface IInstitution {
  createdBy: string;
  createdDate: string; // LocalDateTime can be represented as a string
  lastModifiedBy: string;
  lastModifiedDate: string;
  id: string; // UUID can be represented as a string
  name: string;
  description: string;
  logoUrl: string;
  address: string;
  phone: string;
  email: string;
  country: string;
  province: string;
  type: string;
  state: string;
  modules: IModule[];
}

export enum InstitutionType {
  ADMINISTRATION = 'Institution d\'administration',
  BANK = 'Institution financière'
}

export function getInstitutionTypeByValue(value: string): string | undefined {
  const keys = Object.keys(InstitutionType).filter((key) => InstitutionType[key as keyof typeof InstitutionType] === value);
  return keys.length > 0 ? keys[0] : undefined;
}

export function getInstitutionTypeValue(key: string): string | undefined {
  return InstitutionType[key as keyof typeof InstitutionType];
}
