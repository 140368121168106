import {ICurrency} from "./devise.model";
import {IAudit} from "./audit.model";

export interface ILoanBorrowing {
  id: string,
  institutionId: string,
  valueDate: string,
  operationDate: Date,
  maturityDate: Date,
  amount: number,
  libelle: string,
  description: string,
  counterparty: string,
  counterpartType: string,
  interestRate: number,
  type: string,
  frequency: string,
  currency: ICurrency,
  audits: IAudit[],
  canValidate: boolean,
  canReject: boolean,
  canCancel: boolean,
  canExecute: boolean,
  canEdit: boolean,
  canDelete: boolean
}

export interface ILoanBorrowingSummaryKey {
  date: Date,
  type: string,
}

export interface ILoanBorrowingSummary {
  key: ILoanBorrowingSummaryKey,
  amount: number
}

export interface IRepaymentFrequency {
  code: string,
  description: string
}

export enum LoanBorrowingType {
  LOAN = 'Prêts',
  BORROWING = 'Emprunts'
}
