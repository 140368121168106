<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mt-4">
    <div class="flex flex-column gap-2">
      <label for="name" class="font-medium">Nom</label>
      <input pInputText id="name" aria-describedby="name-help" formControlName="name" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="code" class="font-medium">Code</label>
      <input pInputText id="code" aria-describedby="code-help" formControlName="code" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="desc" class="font-medium">Description</label>
      <textarea id="desc" rows="5" cols="30" pInputTextarea formControlName="description"></textarea>
    </div>
    <div class="flex flex-column gap-3" formArrayName="flowRates">
      <div class="w-full flex justify-content-between align-items-center">
        <label for="desc" class="font-semibold">Règles d'écoulement</label>
        <p-button *ngIf="!data?.isView" text="true" size="small" icon="pi pi-plus" severity="secondary" [rounded]="true" (onClick)="addFlowRate()"/>
      </div>
      <div class="flex flex-column gap-2" *ngFor="let rate of flowRates.controls; let i = index" [formGroupName]="i">
        <div class="flex gap-1 justify-content-between">
          <div class="flex flex-column">
            <label [for]="'month-' + i" class="text-xs font-medium">Mois</label>
            <p-inputNumber [id]="'month-' + i" inputId="integeronly" formControlName="month" />
          </div>
          <div class="flex flex-column">
            <label [for]="'percentage-' + i" class="text-xs font-medium">Pourcentage</label>
            <p-inputNumber [id]="'percentage-' + i" inputId="integeronly" formControlName="percentage" />
          </div>
        </div>
        <div class="flex w-fit">
          <p-button size="small" severity="secondary" icon="pi pi-trash" (onClick)="removeFlowRate(i)"></p-button>
        </div>
      </div>
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end mt-4 justify-content-end">
    <p-button pRipple *ngIf="data?.isCreate" label="Enregistrer" [disabled]="!formGroup.valid" [loading]="isLoading" (onClick)="save()" />
    <p-button pRipple severity="secondary" *ngIf="data?.isEdit && flowLaw?.canUpdate" label="Modifier" [disabled]="!formGroup.valid" [loading]="isLoading" (onClick)="update()" />
  </div>
</div>
