// services/group.service.ts
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IGroup} from "../models/group.model";

@Injectable({
  providedIn: 'root',
})
export class GroupService {

  private readonly baseUrl = 'core/v1/groups';

  constructor(private readonly http: HttpClient) {}

  createGroup(groupDto: IGroup): Observable<IGroup> {
    return this.http.post<IGroup>(this.baseUrl, groupDto).pipe(take(1));
  }

  updateGroup(id: string, groupDto: IGroup): Observable<IGroup> {
    return this.http.put<IGroup>(`${this.baseUrl}/${id}`, groupDto).pipe(take(1));
  }

  deleteGroup(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  toggleGroup(id: string): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/${id}/toggle`, {}).pipe(take(1));
  }

  getGroupById(id: string): Observable<IGroup> {
    return this.http.get<IGroup>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getGroupByName(name: string): Observable<IGroup> {
    return this.http.get<IGroup>(`${this.baseUrl}/name/${name}`).pipe(take(1));
  }

  getAllGroups(pageable: Pageable): Observable<Page<IGroup>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IGroup>>(this.baseUrl, { params }).pipe(take(1));
  }

  getGroupsByInstitutionId(institutionId: string, pageable: Pageable): Observable<Page<IGroup>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IGroup>>(`${this.baseUrl}/institution/${institutionId}`, { params }).pipe(take(1));
  }
}
