import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { IForecastCashFlowOverview } from "../../../../../../core/models/forecast-cash-flow.model";

interface GroupedData {
  [type: string]: {
    [category: string]: {
      [date: string]: number
    }
  }
}

interface TableRow {
  label: string;
  type: 'type' | 'category' | 'balance';
  values: { [key: string]: number };
}

@Component({
  selector: 'app-prev-overview-table',
  template: `
    <p-table class="mb-4 prev-overview-table" [value]="tableData" [scrollable]="true" styleClass="p-datatable-sm h-full">
      <ng-template pTemplate="header">
        <tr class="font-bold period header">
          <th class="text-left period-header sticky-col" style="background-color: #f9fafb;">Période</th>
          <th *ngFor="let date of dates" class="date-header">{{ date | date : 'dd/MM/yyyy' }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-first="first">
        <tr [ngClass]="{
        'font-bold': row.type === 'type' || row.type === 'balance',
        'type-row': row.type === 'type',
        'first-type-row': first && row.type === 'type',
        'balance-row': row.type === 'balance',
        'green': row.label === 'Entrée',
        'red': row.label === 'Sortie'
        }">
          <td class="text-left sticky-col">
            <div [ngClass]="{'pl-3': row.type === 'category'}">
              <span>{{ row.label }}</span>
              <span *ngIf="row.type === 'type'"> Prévisionnelle</span>
            </div>
          </td>
          <td *ngFor="let date of dates" class="value-cell">{{ formatNumber(row.values[date.toISOString()]) }}</td>
        </tr>
      </ng-template>
    </p-table>
  `,
  styles: [`
    .prev-overview-table ::ng-deep .p-datatable-wrapper {
      overflow-x: auto;
    }
    .prev-overview-table ::ng-deep .p-datatable-table {
      min-width: 100%;
    }
    .prev-overview-table ::ng-deep .p-datatable-thead > tr > th,
    .prev-overview-table ::ng-deep .p-datatable-tbody > tr > td {
      padding: 0.5rem 1rem !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .prev-overview-table ::ng-deep .period-header {
      min-width: 200px;
    }
    .prev-overview-table ::ng-deep .date-header,
    .prev-overview-table ::ng-deep .value-cell {
      min-width: 150px;
    }
    .text-left {
      text-align: left;
    }
    .pl-3 {
      padding-left: 1rem;
    }
    .font-bold {
      td {
        font-weight: bold !important;
      }
    }
    .sticky-col {
      position: sticky;
      left: 0;
      background-color: inherit;
      z-index: 1;
    }
    td, th {
      text-align: center;
    }
    td {
      border-bottom: 4px solid transparent !important;
    }
    .balance-row > td {
      border-top: 8px solid transparent !important;
      border-bottom: 8px solid transparent !important;
    }
    .type-row > td, {
      border-top: 4px solid transparent !important;
      border-bottom: 4px solid transparent !important;
    }
    .prev-overview-table ::ng-deep .p-datatable-tbody > tr.balance-row > td {
      background: var(--surface-100);
    }
    .prev-overview-table ::ng-deep .p-datatable-tbody > tr.type-row.green {
      background: var(--green-100);
    }
    .prev-overview-table ::ng-deep .p-datatable-tbody > tr.type-row.red {
      background: var(--red-100);
    }
    .prev-overview-table ::ng-deep .p-datatable-tbody > tr > td {
      border-width: 0;
    }
  `],
  imports: [TableModule, ButtonModule, NgClass, NgForOf, DatePipe, NgIf],
  standalone: true
})
export class PrevOverviewTableComponent implements OnChanges {

  @Input() data: IForecastCashFlowOverview[] = [];
  @Input() timeGrouping: string = 'daily';
  @Input() currentBalance: number = 0;

  dates: Date[] = [];
  tableData: TableRow[] = [];
  groupedData: GroupedData = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] || changes['timeGrouping'] || changes['currentBalance']) this.processData();
  }

  processData() {
    this.groupData();
    this.extractDates();
    this.generateTableData();
    this.addBalanceRows();
  }

  groupData() {
    this.groupedData = {};
    for (const item of this.data) {
      const { type, category } = item.key;
      const dateKey = this.getGroupedDate(item.key.date).toISOString();

      if (!this.groupedData[type]) this.groupedData[type] = {};
      if (!this.groupedData[type][category]) this.groupedData[type][category] = {};
      if (!this.groupedData[type][category][dateKey]) this.groupedData[type][category][dateKey] = 0;
      this.groupedData[type][category][dateKey] += item.amount;
    }
  }

  extractDates() {
    const allDates = new Set<string>();
    for (const type in this.groupedData) {
      for (const category in this.groupedData[type]) {
        Object.keys(this.groupedData[type][category]).forEach(date => allDates.add(date));
      }
    }
    this.dates = Array.from(allDates).map(date => new Date(date)).sort((a, b) => a.getTime() - b.getTime());
  }

  generateTableData() {
    this.tableData = [];

    const typeOrder = ['Entrée', 'Sortie'];

    const sortedTypes = Object.keys(this.groupedData).sort((a, b) => {
      return typeOrder.indexOf(a) - typeOrder.indexOf(b);
    });

    for (const type of sortedTypes) {
      const typeRow: TableRow = {
        label: type,
        type: 'type',
        values: {}
      };

      const categoryRows: TableRow[] = [];

      for (const category in this.groupedData[type]) {
        const categoryRow: TableRow = {
          label: category,
          type: 'category',
          values: {}
        };

        for (const date of this.dates) {
          const dateKey = date.toISOString();
          const amount = this.groupedData[type][category][dateKey] || 0;
          categoryRow.values[dateKey] = amount;
          typeRow.values[dateKey] = (typeRow.values[dateKey] || 0) + amount;
        }

        categoryRows.push(categoryRow);
      }

      this.tableData.push(typeRow);
      this.tableData.push(...categoryRows);
    }
  }

  addBalanceRows() {
    const openingBalanceRow: TableRow = {
      label: 'Solde d\'ouverture',
      type: 'balance',
      values: {}
    };

    const closingBalanceRow: TableRow = {
      label: 'Solde de clôture',
      type: 'balance',
      values: {}
    };

    let runningBalance = this.currentBalance;

    for (const date of this.dates) {
      const dateKey = date.toISOString();
      openingBalanceRow.values[dateKey] = runningBalance;

      const incomes = this.groupedData['Entrée'] ?
        Object.values(this.groupedData['Entrée']).reduce((sum, category) => sum + (category[dateKey] || 0), 0) : 0;
      const expenses = this.groupedData['Sortie'] ?
        Object.values(this.groupedData['Sortie']).reduce((sum, category) => sum + (category[dateKey] || 0), 0) : 0;

      runningBalance += incomes - expenses;
      closingBalanceRow.values[dateKey] = runningBalance;
    }

    this.tableData.unshift(openingBalanceRow);
    this.tableData.push(closingBalanceRow);
  }

  getGroupedDate(date: Date): Date {
    const groupedDate = new Date(date);
    switch (this.timeGrouping) {
      case 'daily':
        return groupedDate;
      case 'week':
        groupedDate.setDate(groupedDate.getDate() - groupedDate.getDay());
        break;
      case 'biweekly':
        groupedDate.setDate(groupedDate.getDate() <= 15 ? 1 : 16);
        break;
      case 'month':
        groupedDate.setDate(1);
        break;
      case 'quarter': {
        const quarterMonth = Math.floor(groupedDate.getMonth() / 3) * 3;
        groupedDate.setMonth(quarterMonth, 1);
        break;
      }
    }
    return groupedDate;
  }

  formatNumber(value: number): string {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(value);
  }
}
