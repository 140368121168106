import {HttpInterceptorFn} from "@angular/common/http";
import {environment} from "../../../environments/environment";

export const jwtInterceptor: HttpInterceptorFn = (request, next) => {
  // Check if there's a JWT token in the localStorage
  const backendUrl = environment.apiURL;

  if (request.url.startsWith('/assets') || request.url.startsWith('/public')) return next(request);

  if (request.url.includes('/auth') && request.method === 'POST') {
    request = request.clone({
      url: `${backendUrl}/${request.url}`, // Prepend backend URL
    });
    return next(request);
  }

  if (request.url.includes('/auth') && request.method === 'GET') {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      request = request.clone({
        setHeaders: {Authorization: `Bearer ${refreshToken}`},
      });
    }
    request = request.clone({
      url: `${backendUrl}/${request.url}`, // Prepend backend URL
    });
    return next(request);
  }

  // If a token is found, add it to the request headers
  const token = localStorage.getItem('token');
  if (token) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  request = request.clone({
    url: `${backendUrl}/${request.url}`, // Prepend backend URL
  });
  return next(request);
};
