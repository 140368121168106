import {Injectable} from "@angular/core";
import {JwtToken} from "../models/jwt.model";
import {StorageService} from "./storage.service";
import {MenuItem} from "../models/menu-item.model";

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  private token: JwtToken | null = null;

  constructor(private storageService: StorageService) {
    this.token = this.storageService.getToken();
  }

  hasPermission(permission: string): boolean {
    return this.token?.authorities?.includes(permission) ?? false;
  }

  hasModuleAccess(moduleName: string): boolean {
    return this.token?.permissions?.some(module => module.module === moduleName) ?? false;
  }

  hasProductAccess(productCode: string): boolean {
    return this.token?.permissions?.some(module =>
      module.products.some(product => product.product === productCode)
    ) ?? false;
  }

  hasProductPermission(productCode: string, permission: string): boolean {
    return this.token?.permissions?.some(module =>
      module.products.some(product =>
        product.product === productCode &&
        product.permissions.includes(permission)
      )
    ) ?? false;
  }

  checkAccess(item: MenuItem): boolean {
    // If no access controls are specified, allow access
    if (!item.requiredRole && !item.requiredModule && !item.requiredProduct) return true;

    // Check role-based access
    if (item.requiredRole && !this.hasPermission(item.requiredRole)) return false;

    // Check module-based access
    if (item.requiredModule && !this.hasModuleAccess(item.requiredModule)) return false;

    // Check product-based access
    if (item.requiredProduct && !this.hasProductAccess(item.requiredProduct)) return false;

    // If all checks pass, allow access
    return true;
  }
}
