export const countriesUemoa = [
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ef.svg",
    "country": "Bénin",
    "code": "BJ"
  },
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ee.svg",
    "country": "Côte d'Ivoire",
    "code": "CI"
  },
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1e7-1f1eb.svg",
    "country": "Burkina Faso",
    "code": "BF"
  },
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fc.svg",
    "country": "Guinée-Bissau",
    "code": "GW"
  },
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f1.svg",
    "country": "Mali",
    "code": "ML"
  },
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ea.svg",
    "country": "Niger",
    "code": "NE"
  },
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f3.svg",
    "country": "Sénégal",
    "code": "SN"
  },
  {
    "flag": "https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ec.svg",
    "country": "Togo",
    "code": "TG"
  },
]
