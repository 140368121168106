import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IRepaymentEntry} from "../../core/models/repayment-entry.model";
import {ILoanBorrowing} from "../../core/models/loan-borrowing.model";
import {DynamicTableComponent} from "../components/dynamic-table/dynamic-table.component";
import {CurrencyPipe, DatePipe, DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {PrimeTemplate} from "primeng/api";
import {TableModule} from "primeng/table";
import {ChartModule} from "primeng/chart";

@Component({
  selector: 'app-repayment-list',
  standalone: true,
  imports: [
    // Modules
    TableModule, ChartModule,
    // Component
    DynamicTableComponent,
    // Pipes & Directives
    NgForOf, NgIf, PrimeTemplate, DatePipe, DecimalPipe, CurrencyPipe
  ],
  template:  `
    <div class="flex flex-column gap-8 w-full py-4">
      <div *ngIf="showHeader" class="flex justify-content-between text-xl font-semibold">
        <span>{{ loanBorrowing.libelle }}</span>
        <div class="flex gap-2">
          <span>{{ loanBorrowing.amount | number:'1.0-0':'fr' }}</span>
          <span>{{ loanBorrowing.currency.code }}</span>
        </div>
      </div>
      <div class="px-4">
        <p-chart [responsive]="true" type="bar" [data]="chartData" [options]="chartOptions" />
      </div>
      <p-table
        [value]="repaymentEntries" [columns]="headers"
        [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10]"
        styleClass="p-datatable-sm" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr class="header font-bold">
            <th class="text-center" scope="col" *ngFor="let header of headers">{{ header }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="text-center">{{ item.paymentNumber | number:'1.0-0':'fr' }}</td>
            <td class="text-center">{{ item.paymentDate | date : 'dd/MM/yyyy' }}</td>
            <td class="text-center">
              {{ item.paymentAmount | currency : loanBorrowing.currency.code : 'symbol' : '1.0-0' : 'fr-FR' }}
            </td>
            <td class="text-center">
              {{ item.principalPayment | currency : loanBorrowing.currency.code : 'symbol' : '1.0-0' : 'fr-FR' }}
            </td>
            <td class="text-center">
              {{ item.interestPayment | currency : loanBorrowing.currency.code : 'symbol' : '1.0-0' : 'fr-FR' }}
            </td>
            <td class="text-center">
              {{ item.remainingPrincipal | currency : loanBorrowing.currency.code : 'symbol' : '1.0-0' : 'fr-FR' }}
            </td>
            <td class="text-center">{{ item.state }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
})
export class RepaymentListComponent implements OnInit, OnChanges {

  @Input({required: true}) repaymentEntries: IRepaymentEntry[] = [];
  @Input({required: true}) loanBorrowing!: ILoanBorrowing;
  @Input() showHeader: boolean = true;
  headers: string[] = [
    'Numéro',
    'Date de remboursement',
    'Montant total',
    'Montant remboursé',
    'Montant d\'intérêts',
    'Capital restant',
    'Statut'
  ];
  chartData: any;
  chartOptions: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['repaymentEntries'] || changes['loanBorrowing']) this.initializeComponent();
  }

  ngOnInit() {
    this.initializeComponent();
  }

  initializeComponent() {
    this.prepareChartData();
    this.setupChartOptions();
  }

  prepareChartData() {
    const labels = this.repaymentEntries.map(entry => new Date(entry.paymentDate));
    const remainingPrincipal = this.repaymentEntries.map(entry => entry.remainingPrincipal);

    this.chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Capital restant dû',
          data: remainingPrincipal,
          fill: false,
          borderColor: '#42A5F5',
          tension: .4
        }
      ]
    };
  }

  setupChartOptions() {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (context: any) => {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: this.loanBorrowing.currency.code
                }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          },
          type: 'time',
          time: {
            unit: this.getTimeUnit(),
            displayFormats: {
              'month': 'MM/yyyy',
              'quarter': 'QQQ yyyy',
              'year': 'yyyy'
            }
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          },
          title: {
            display: true,
            text: `Capital restant dû`
          }
        }
      }
    };
  }

  getTimeUnit(): 'month' | 'quarter' | 'year' {
    switch (this.loanBorrowing.frequency) {
      case 'Mensuel':
        return 'month';
      case 'Trimestriel':
        return 'quarter';
      case 'Semestriel':
        return 'quarter'; // We'll use quarter for semi-annual as it's the closest option
      case 'Annuel':
        return 'year';
      case 'In Fine':
        return 'year'; // For bullet loans, we'll use yearly view
      default:
        return 'month'; // Default to month if frequency is not recognized
    }
  }
}
