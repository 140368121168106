import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricerFormComponent} from '../components/pricer-form/pricer-form.component';
import {PricerResultComponent} from '../components/pricer-result/pricer-result.component';
import {ISimulation} from '../../../../core/models/simulation.model';
import {PricerHistoryComponent} from '../components/pricer-history/pricer-history.component';

@Component({
  selector: 'app-pricer-bat',
  standalone: true,
  imports: [CommonModule, PricerFormComponent, PricerResultComponent, PricerHistoryComponent],
  templateUrl: './pricer-bat.component.html',
  styleUrl: './pricer-bat.component.scss'
})
export class PricerBatComponent {

  simulationData: ISimulation | undefined;
  resetForm: boolean = false;
  doReload: boolean = false;
  simulations: any[] = [];

  constructor() {
  }


  handleSimulationData(data: any): void {
    console.log('Received Simulation Data from Child:', data);
    this.simulationData = data
  }

  handleReset(event: any) {
    this.resetForm = !this.resetForm;
    setTimeout(() => {
      this.resetForm = event;
      });
  }


  reload() {
    this.doReload = false;  // Set to false first to ensure change is detected
    // Force change detection after a small delay to ensure the changes are noted
    setTimeout(() => {
      this.doReload = true;
    }, 0);
  }

}
