// services/module.service.ts

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IModule} from "../models/module.model";

@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  private baseUrl = 'core/v1/modules';

  constructor(private http: HttpClient) {}

  createModule(productDto: IModule): Observable<IModule> {
    return this.http.post<IModule>(this.baseUrl, productDto).pipe(take(1));
  }

  updateModule(id: string, productDto: IModule): Observable<IModule> {
    return this.http.put<IModule>(`${this.baseUrl}/${id}`, productDto).pipe(take(1));
  }

  deleteModule(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getModuleById(id: string): Observable<IModule> {
    return this.http.get<IModule>(`${this.baseUrl}/${id}`).pipe(take(1));
  }

  getModuleByCode(code: string): Observable<IModule> {
    return this.http.get<IModule>(`${this.baseUrl}/code/${code}`).pipe(take(1));
  }

  getAllModules(pageable: Pageable): Observable<Page<IModule>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<IModule>>(this.baseUrl, { params }).pipe(take(1));
  }
}
