import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../../core/services/toast.service";
import {StorageService} from "../../../../core/services/storage.service";
import {
  customConfirmationDialog,
  customDynamicDialog,
  DEFAULT_ROWS_PER_PAGE_OPTIONS
} from "../../../../core/utils/utils";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {take} from "rxjs";
import {PaginatorState} from "primeng/paginator";
import {IVirementResponse} from "../../../../core/models/virement.model";
import {VirementService} from "../../../../core/services/virement.service";
import {EditVirementComponent} from "./edit-virement/edit-virement.component";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../../../shared/components/toolbar/toolbar.component";
import {AuditComponent} from "../../../../shared/components/audit/audit.component";
import {UploadVirementComponent} from "./upload-virement/upload-virement.component";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-virement',
  standalone: true,
  imports: [
    DynamicTableComponent,
    ToolbarComponent
  ],
  templateUrl: './virement.component.html',
  styleUrl: './virement.component.scss'
})
export class VirementComponent implements OnInit, OnDestroy {

  virements: IVirementResponse[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'state',
      column: 'Statut',
      type: 'text'
    },
    {
      key: 'operationDate',
      column: 'Date opération',
      type: 'date'
    },
    {
      key: 'valueDate',
      column: 'Date valeur',
      type: 'date'
    },
    {
      key: 'libelle',
      column: 'Libelle',
      type: 'text'
    },
    {
      key: 'amount',
      column: 'Montant',
      type: 'currency',
      currencyKey: 'currency.code'
    },
    {
      key: 'account.libelle',
      column: 'Compte',
      type: 'text'
    },
    {
      key: 'beneficiary.name',
      column: 'Bénéficiaire',
      type: 'text'
    }
  ]
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];
  toolbarActions: CustomAction[] = [];

  constructor(
    private virementService: VirementService,
    private toastService: ToastService,
    public dialogService: DialogService,
    private tokenManager: StorageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.loadVirements();
    this.actions = this.getActions();
    this.toolbarActions = this.getToolbarActions();
  }

  ngOnDestroy() {
    this.ref?.close();
  }

  getToolbarActions(): CustomAction[] {
    return [
      {
        role: 'CREATE_VIREMENT',
        icon: 'pi pi-plus',
        label: 'Créer',
        severity: 'primary',
        emit: () => this.createVirement(),
        visible: true
      },
      {
        role: 'UPLOAD_VIREMENT',
        icon: 'pi pi-download',
        label: 'Charger',
        severity: 'secondary',
        emit: () => this.uploadVirement(),
        visible: true
      },
      {
        role: 'EXPORT_VIREMENT',
        icon: 'pi pi-upload',
        label: 'Exporter',
        severity: 'info',
        emit: () => this.loadVirements(),
        visible: true
      }
    ]
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'UPDATE_VIREMENT',
        icon: 'pi pi-check-circle',
        label: 'Valider',
        severity: 'primary',
        emit: (item: IVirementResponse) => this.validateVirement(item),
        visible: (item) => item?.canValidate ?? false
      },
      {
        role: 'UPDATE_VIREMENT',
        icon: 'pi pi-times-circle',
        label: 'Rejeter',
        severity: 'primary',
        emit: (item: IVirementResponse) => this.rejectVirement(item),
        visible: (item) => item?.canReject ?? false
      },
      {
        role: 'UPDATE_VIREMENT',
        icon: 'pi pi-ban',
        label: 'Annuler',
        severity: 'primary',
        emit: (item: IVirementResponse) => this.cancelVirement(item),
        visible: (item) => item?.canCancel ?? false
      },
      {
        role: 'UPDATE_VIREMENT',
        icon: 'pi pi-send',
        label: 'Exécuter',
        severity: 'primary',
        emit: (item: IVirementResponse) => this.executeVirement(item),
        visible: (item: IVirementResponse) => item?.canExecute ?? false
      },
      {
        role: 'READ_VIREMENT',
        icon: 'pi pi-info-circle',
        label: 'Consulter',
        severity: 'info',
        emit: (item: IVirementResponse) => this.viewVirement(item),
        visible: true
      },
      {
        role: 'UPDATE_VIREMENT',
        icon: 'pi pi-pen-to-square',
        label: 'Edit',
        severity: 'primary',
        emit: (item) => this.editVirement(item),
        visible: (item: IVirementResponse) => item.canEdit ?? false
      },
      {
        role: 'READ_VIREMENT',
        icon: 'pi pi-history',
        label: 'Audits',
        severity: 'info',
        emit: (item: IVirementResponse) => this.viewAudits(item),
        visible: true
      },
      {
        role: 'DELETE_VIREMENT',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item: IVirementResponse) => this.confirmDeletion(item),
        visible: (item: IVirementResponse) => item.canDelete ?? false
      },
    ];
  }

  loadVirements(pageable: Pageable = getDefaultPageable()) {
    const jwt = this.tokenManager.getToken();
    this.virementService.getVirementsByInstitution(jwt.institution_id, pageable).subscribe({
      next: page => {
        this.virements = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: err => this.toastService.showToast('Chargement des virements', err.error, 'error')
    })
  }

  viewVirement(virement: IVirementResponse, ) {
    const data = {isView: true, data: virement} as CustomDialogData;
    this.ref = this.dialogService.open(EditVirementComponent, customDynamicDialog('Détails virement', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadVirements());
  }

  viewAudits(virement: IVirementResponse, ) {
    const data = {isView: true, data: virement} as CustomDialogData;
    this.ref = this.dialogService.open(AuditComponent, customDynamicDialog('Audit virement', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadVirements());
  }

  editVirement(virement: IVirementResponse) {
    const data = {isEdit: true, data: virement} as CustomDialogData;
    this.ref = this.dialogService.open(EditVirementComponent, customDynamicDialog('Modification virement', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadVirements());
  }

  createVirement() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(EditVirementComponent, customDynamicDialog('Création virement', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadVirements());
  }

  confirmDeletion(virement: IVirementResponse) {
    const message = `Voulez-vous supprimer le virement ${virement.libelle} ?`;
    const header = 'Suppression de virement';
    const options = customConfirmationDialog(header, message, { accept: () => this.deleteVirement(virement) });
    this.confirmationService.confirm(options);
  }

  deleteVirement(virement: IVirementResponse) {
    this.virementService.deleteVirement(virement.id).subscribe({
      next: () => {
        this.toastService.showToast('Virement supprimée', `Le virement ${virement.libelle} a été supprimé avec succès.`);
        this.loadVirements();
      },
      error: err => this.toastService.showToast('Suppression du virement', err, 'error')
    });
  }

  validateVirement(virement: IVirementResponse) {
    this.virementService.validateVirement(virement.id).subscribe({
      next: () => {
        this.toastService.showToast('Virement validé', `Le virement ${virement.libelle} a été validé avec succès.`);
        this.loadVirements();
      },
      error: err => this.toastService.showToast('Validation du virement', err, 'error')
    });
  }

  rejectVirement(virement: IVirementResponse) {
    this.virementService.rejectVirement(virement.id).subscribe({
      next: () => {
        this.toastService.showToast('Virement rejeté', `Le virement ${virement.libelle} a été rejeté avec succès.`);
        this.loadVirements();
      },
      error: err => this.toastService.showToast('Rejet du virement', err, 'error')
    });
  }

  uploadVirement() {
    const options = { position: 'center', width: '75vw', height: '75vh' };
    this.ref = this.dialogService.open(UploadVirementComponent, customDynamicDialog('Chargement de virements', {}, options));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadVirements());
  }

  cancelVirement(virement: IVirementResponse) {
    this.virementService.cancelVirement(virement.id).subscribe({
      next: () => {
        this.toastService.showToast('Virement annulé', `Le virement ${virement.libelle} a été annulé avec succès.`);
        this.loadVirements();
      },
      error: err => this.toastService.showToast('Erreur lors de l\'annulation du virement', err, 'error')
    });
  }

  executeVirement(virement: IVirementResponse) {
    this.virementService.executeVirement(virement.id).subscribe({
      next: () => {
        this.toastService.showToast('Virement exécuté', `Le virement ${virement.libelle} a été exécuté avec succès.`);
        this.loadVirements();
      },
      error: err => this.toastService.showToast('Exécution du virement', err, 'error')
    });
  }

  onPageChange = (event: PaginatorState) => this.loadVirements(getDefaultPageable(event.page, event.rows));

}
