import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Button} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {NgForOf, NgIf} from "@angular/common";
import {Ripple} from "primeng/ripple";
import {InputTextareaModule} from "primeng/inputtextarea";
import {IFlowLaw, IFlowRate} from "../../../../../core/models/flow-law.model";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputNumberModule} from "primeng/inputnumber";
import {FlowLawService} from "../../../../../core/services/flow-law.service";
import {ToastService} from "../../../../../core/services/toast.service";

@Component({
  selector: 'app-flow-law-details',
  standalone: true,
  imports: [
    // Modules
    InputTextModule, ReactiveFormsModule, InputTextareaModule, FloatLabelModule, InputNumberModule,
    // Components
    Button, NgIf, Ripple, NgForOf
  ],
  templateUrl: './flow-law-details.component.html',
  styleUrl: './flow-law-details.component.scss'
})
export class FlowLawDetailsComponent implements OnInit {

  data: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  formGroup: FormGroup = new FormGroup({});
  isLoading: boolean = false;
  flowLaw: IFlowLaw | undefined;

  constructor(
    private flowLawService: FlowLawService,
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private readonly dialogService: DialogService,
    private fb: FormBuilder,
    private toastService: ToastService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
    this.data = this.instance.data;
    this.flowLaw = this.data?.data;
    this.initForm();
  }

  ngOnInit() {
    if ((this.data?.isView || this.data?.isEdit) && this.flowLaw) {
      const { flowRates, ...rest } = this.flowLaw;
      this.formGroup.patchValue(rest);

      // Iterate over each key-value pair in flowRates if it's an object
      this.flowLaw.flowRates.forEach((flowRate, index) => {
        if (index > 0) this.addFlowRate();
        const rateControl = this.flowRates.at(index);
        rateControl.patchValue({
          month: flowRate.month,  // Convert key to number if necessary
          percentage: flowRate.rate
        });
      });
      if (this.data?.isView) this.formGroup.disable();
    }
  }

  get flowRates() {
    return this.formGroup.get('flowRates') as FormArray;
  }

  private initForm() {
    this.formGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      flowRates: this.fb.array([])
    });
    this.addFlowRate();
  }

  addFlowRate() {
    const flowRateGroup = this.fb.group({
      month: ['', [Validators.required, Validators.min(0)]],
      percentage: ['', [Validators.required, Validators.min(0), Validators.max(100)]]
    });

    this.flowRates.push(flowRateGroup);
  }

  getFormValue(): IFlowLaw {
    const formValue = this.formGroup.value;
    const flowRates: IFlowRate[] = [];

    formValue.flowRates.forEach((rate: { month: number, percentage: number }) => {
      flowRates.push({ month: rate.month, rate: rate.percentage});
    });

    return {
      ...formValue,
      flowRates,
      id: '',
      institutionId: '',
      canUpdate: false,
      canDelete: false
    };
  }

  save() {
    const summary = 'Création loi d\'écoulement';
    this.flowLaw = this.getFormValue();
    this.flowLawService.createFlowLaw(this.flowLaw).subscribe({
      next: () => this.toastService.showToast(summary, 'La loi d\'écoulement a été créée avec succès'),
      error: (err) => this.toastService.showToast(summary, err.error, 'error'),
      complete: () => this.ref.close()
    });
  }

  update() {
    if (this.formGroup.valid) {
      console.log('Update flow law', this.getFormValue());
    }
  }

  removeFlowRate(index: number) {
    this.flowRates.removeAt(index);
  }
}
