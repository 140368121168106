import {Component, OnInit} from '@angular/core';
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../core/services/toast.service";
import {StorageService} from "../../../core/services/storage.service";
import {StepperModule} from "primeng/stepper";
import {Button} from "primeng/button";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {IProduct} from "../../../core/models/product.model";
import {ValidationService} from "../../../core/services/validation.service";
import {ProductService} from "../../../core/services/product.service";
import {Direction, getUnpagedPageable, Sort} from "../../../core/models/page.model";
import {SelectItemGroup} from "primeng/api";
import {AccordionModule} from "primeng/accordion";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {GroupService} from "../../../core/services/group.service";
import {PickListModule} from "primeng/picklist";
import {IGroup} from "../../../core/models/group.model";
import {DragDropModule} from 'primeng/dragdrop';
import {IValidation, IValidationLevel} from "../../../core/models/validation.model";
import {CheckboxModule} from "primeng/checkbox";

@Component({
  selector: 'edit-validation',
  standalone: true,
  imports: [
    // Modules
    StepperModule, AccordionModule, InputTextModule, InputTextareaModule, PaginatorModule, ReactiveFormsModule,
    PickListModule, DragDropModule,
    // Pipes & Directives
    Button, NgIf, CheckboxModule,
  ],
  templateUrl: './edit-validation.component.html',
  styleUrl: './edit-validation.component.scss'
})
export class EditValidationComponent implements OnInit{

  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  formGroup = new FormGroup({
    product: new FormControl<IProduct | null>( null,{nonNullable: true, validators: [Validators.required]}),
    numberOfValidation: new FormControl<number>(0, {nonNullable: true, validators: [Validators.required]}),
    isRequired: new FormControl<boolean>(true, {nonNullable: true, validators: [Validators.required]}),
    orderAbsolute: new FormControl<boolean>(true, {nonNullable: true, validators: [Validators.required]}),
  });
  sort: Sort = { direction: Direction.DESC, orders: [{ property: 'code', ignoreCase: false }] };
  groupedProducts: SelectItemGroup[] = [];
  selectedGroups: IGroup[] = [];
  groups: IGroup[] = [];

  constructor(
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef,
    private readonly groupService: GroupService,
    private readonly validationService: ValidationService,
    private readonly productService: ProductService,
    private readonly tokenManager: StorageService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    const jwt = this.tokenManager.getToken();
    this.productService.getProductsForInstitution(jwt.institution_id, getUnpagedPageable(this.sort)).subscribe({
      next: (page) => {
        const groupedProductsMap = new Map<string, SelectItemGroup>();
        page.content.forEach(product => {
          if (!groupedProductsMap.has(product.module.code)) {
            groupedProductsMap.set(product.module.code, {
              label: product.module.description,
              value: product.module.code,
              items: []
            });
          }

          const group = groupedProductsMap.get(product.module.code);
          if (group) group.items.push({ label: product.description, value: product });
        });

        this.groupedProducts = Array.from(groupedProductsMap.values());
      },
      error: (err) => this.toastService.showToast('Erreur lors de la capture des produits', err, 'error')
    });

    this.boundData = this.instance?.data;
    this.loadGroups()
  }

  loadGroups() {
    const jwt = this.tokenManager.getToken();
    this.groupService.getGroupsByInstitutionId(jwt.institution_id, getUnpagedPageable()).subscribe({
      next: (page) => this.groups = page.content,
      error: (err) => this.toastService.showToast('Erreur lors de la capture des groupes', err, 'error')
    });
  }

  saveValidation() {
    const validation = this.formGroup.value as IValidation;
    validation.validationLevels = this.selectedGroups.map((group, index) => {
      return { level: index++, group: group } as IValidationLevel;
    });
    this.validationService.createValidation(validation).subscribe({
      next: () => {
        this.toastService.showToast('Validation créée', 'La validation a été créée avec succès');
        this.ref.close();
      },
      error: (err) => this.toastService.showToast('Création la validation', err.error, 'error')
    });
  }
}
