import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";
import {WicketOperationRequestModel} from "../models/WicketOperationRequest.model";
import {Page} from "../models/page.model";
import {LimitsModel} from "../models/limits.model";


@Injectable({
  providedIn: 'root',
})
export class WicketOperationService {
  private baseUrl = 'income/v1/wicket';

  constructor(private http: HttpClient) {}

  createWicketOperation(emissionDto: WicketOperationRequestModel): Observable<any> {
    return this.http.post<any>(this.baseUrl, emissionDto).pipe(take(1));
  }

  getAllWicketOperations(page: number, size: number, isPaged: boolean = true): Observable<Page<WicketOperationRequestModel>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isPaged', isPaged.toString());

    return this.http.get<Page<WicketOperationRequestModel>>(this.baseUrl, {params});
  }
}
