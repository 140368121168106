import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {FileUploadModule} from "primeng/fileupload";
import {LimitsModel} from "../../../../core/models/limits.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {PaginatorState} from "primeng/paginator";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {customDynamicDialog} from "../../../../core/utils/utils";
import {take} from "rxjs";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../../core/services/toast.service";
import {FormBuilder} from "@angular/forms";
import {OperationsTitresService} from "../../../../core/services/operationsTitres.service";
import {DatePipe} from "@angular/common";
import {AddLimitComponent} from "./add-limit/add-limit.component";
import {LimitsService} from "../../../../core/services/limits.service";
import {countriesUemoa} from "../../../../../assets/countries.uemoa";

@Component({
  selector: 'app-limite',
  standalone: true,
    imports: [
        ButtonDirective,
        DynamicTableComponent,
        FileUploadModule
    ],
  templateUrl: './limiteTab.component.html',
  styleUrl: './limiteTab.component.scss'
})
export class LimiteTabComponent implements OnInit {

  actions: CustomAction[] = [];
  countries = countriesUemoa;
  ref?: DynamicDialogRef;
  limits: LimitsModel[] = [];
  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  columns: CustomTableHeader[] = [
    { key: 'region', column: 'Emetteur', type: 'custom' },
    { key: 'startDate', column: 'Date de début', type: 'text' },
    { key: 'endDate', column: 'Date de fin', type: 'text' },
    { key: 'maxAmount', column: 'Montant maximal', type: 'text' },
  ];
  constructor(public dialogService: DialogService,
              private toastService :ToastService,
              private limitsService : LimitsService,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private operationsTitresService: OperationsTitresService,
              private datePipe: DatePipe) {


  }
  onPageChange = (event: PaginatorState) => {
    this.loadLimits(getDefaultPageable(event.page, event.rows));
  }
  ngOnInit(): void {
    this.loadLimits(getDefaultPageable());
    this.actions = this.getActions();

  }
  private loadLimits(pageable: Pageable) {
    this.limitsService.getAllLimits(pageable.page, pageable.limit, true)
      .subscribe({
        next: (response) => {
          this.limits = response.content.map(item => ({
            ...item,
            region: this.getCountryName(item.region),
            startDate: this.formatDate(item.startDate),
            endDate: this.formatDate(item.endDate),
          }));
          this.pageable.totalRecords = response.totalElements;
        },
        error: (error) => {
          this.toastService.showToast('Erreur','Erreur lors du chargement des limites', error);
        }
      });
  }
  getActions(): CustomAction[] {
    return [
      {
        role: 'UPDATE_EMISSION',
        icon: 'pi pi-pen-to-square',
        label: 'Editer',
        severity: 'primary',
        emit: (item) => this.updateLimit(item, true),
        visible: true
      },
      {
        role: 'DELETE_EMISSION',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteLimit(item),
        visible: true
      },
    ];
  }

  addLimit() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(AddLimitComponent, customDynamicDialog('Enregistrement de limite', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadLimits(getDefaultPageable()));
  }
  private formatDate(date: Date | string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }
  private getCountryName(region: string): string {
    const country = this.countries.find(c => c.code === region);
    return country ? country.country : region;
  }

  private deleteLimit(limit: LimitsModel) {
    this.limitsService.deleteLimit(limit.id).subscribe({
      next: () => {
        this.toastService.showToast('Limite supprimée', `La limite a été supprimée avec succès.`);
        this.loadLimits(getDefaultPageable());
      },
      error: (err) => {
        this.toastService.showToast('Erreur lors de la suppression du titre de la limite', err, 'error');
      }
    });
  }

  private updateLimit(limit: LimitsModel, isEdit: boolean = false) {
    const data = {isView: !isEdit, isEdit, data: limit} as CustomDialogData;
    this.ref = this.dialogService.open(AddLimitComponent, customDynamicDialog('Détails de la limite', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadLimits(getDefaultPageable()));  }
}
