import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {IPermission} from "../../../core/models/permission.model";
import {GroupService} from "../../../core/services/group.service";
import {ToastService} from "../../../core/services/toast.service";
import {AccordionModule} from "primeng/accordion";
import {UserService} from "../../../core/services/user.service";
import {getDefaultPageable, Pageable, PageParams} from "../../../core/models/page.model";
import {IUser} from "../../../core/models/user.model";
import {DynamicTableComponent} from "../../../shared/components/dynamic-table/dynamic-table.component";
import {PaginatorState} from "primeng/paginator";
import {DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../../core/utils/utils";
import {CheckboxModule} from "primeng/checkbox";
import {NgForOf, NgIf} from "@angular/common";
import {Button} from "primeng/button";
import {ViewPermissionsComponent} from "../view-permissions/view-permissions.component";
import {CustomTableHeader} from "../../../core/models/custom-table.model";
import {IGroup} from "../../../core/models/group.model";

@Component({
  selector: 'app-group-details',
  standalone: true,
  imports: [
    // Modules
    ReactiveFormsModule, InputTextModule, InputTextareaModule, AccordionModule, CheckboxModule, Button,
    // Components
    DynamicTableComponent, ViewPermissionsComponent,
    // Pipes & Directives
    NgForOf, NgIf
  ],
  templateUrl: './group-details.component.html',
  styleUrl: './group-details.component.scss'
})
export class GroupDetailsComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
  });
  group: IGroup = {} as IGroup;
  users: IUser[] = [];
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  userCols: CustomTableHeader[] = [
    {
      key: 'lastname',
      column: 'Nom',
      type: 'text'
    },
    {
      key: 'firstname',
      column: 'Prénom',
      type: 'text'
    },
    {
      key: 'enabled',
      column: 'Status',
      type: 'boolean'
    }
  ];
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  permissions: IPermission[] = [];


  constructor(
    private readonly groupService: GroupService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.boundData = this.instance?.data;
    this.group = this.boundData?.data || {} as IGroup;
    this.permissions = this.boundData?.data?.permissions || [];
    this.loadUsersForGroup();
    if (!this.boundData?.isCreate) this.formGroup.patchValue(this.boundData?.data);
    if (this.boundData?.isView) this.formGroup.disable();
  }

  loadUsersForGroup(pageable: Pageable = getDefaultPageable(), ids: string[] = [this.boundData?.data?.id]) {
    this.userService.getUsersByGroup(ids, pageable).subscribe({
      next: page => {
        this.users = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: () => {
        const summary = 'Erreur lors du chargement des utilisateurs';
        const detail = 'Une erreur est survenue lors du chargement des utilisateurs.';
        this.toastService.showToast(summary, detail, 'error');
      }
    })

  }

  toggleGroup() {
    const enabled = this.boundData?.data?.enabled;
    this.groupService.toggleGroup(this.boundData?.data.id).subscribe({
      next: () => {
        const summary = enabled ? 'Groupe désactivé' : 'Groupe activé';
        const detail = `Le groupe a été ${enabled ? 'désactivé' : 'activé'} avec succès.`;
        this.toastService.showToast(summary, detail);
        this.ref.close();
      },
      error: () => {
        const summary = 'Erreur lors de la modification du groupe';
        const detail = 'Une erreur est survenue lors de la modification du groupe. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary,detail, 'error');
      }
    })
  }

  onPageChange = (event: PaginatorState) => this.loadUsersForGroup(getDefaultPageable(event.page, event.rows));

  permissionChange($event: IPermission[]) {
    this.group.permissions = $event;
  }

  create() {
    this.group.name = this.formGroup.get('name')?.value ?? this.group.name;
    this.group.description = this.formGroup.get('description')?.value ?? this.group.description;
    this.groupService.createGroup(this.group).subscribe({
      next: () => {
        this.toastService.showToast('Groupe créé', `Le groupe ${this.group.name} a été créé avec succès.`);
        this.ref.close();
      },
      error: () => {
        const summary = 'Erreur lors de la création du groupe';
        const detail = 'Une erreur est survenue lors de la création du groupe. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    });
  }

  update() {
    this.group.name = this.formGroup.get('name')?.value ?? this.group.name;
    this.group.description = this.formGroup.get('description')?.value ?? this.group.description;
    this.groupService.updateGroup(this.group.id, this.group).subscribe({
      next: () => {
        this.toastService.showToast('Groupe modifié', `Le groupe ${this.group.name} a été modifié avec succès.`);
        this.ref.close();
      },
      error: () => {
        const summary = 'Erreur lors de la modification du groupe';
        const detail = 'Une erreur est survenue lors de la modification du groupe. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    });
  }
}
