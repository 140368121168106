<div class="flex flex-column gap-4">
  <div class="flex flex-column">
    <span class="font-bold text-2xl">Récapitulatif en {{ currency }}</span>
  </div>
  <div class="flex align-items-center gap-2 w-full">
    <app-balance-history-chart [showReserves]="isLocalCurrency" [currency]="currency" class="w-full" />
  </div>
  <div class="flex flex-column my-2 gap-2">
    <div class="flex justify-content-between align-items-center">
      <span class="font-semibold text-xl">Comptes en {{ currency }}</span>
      <p-splitButton *ngIf="accounts.length > 0" [outlined]="true" icon="pi pi-upload" label="Exporter"
        (onClick)="exportAccounts()" [model]="exportItems" severity="secondary" />
    </div>
    <div *ngIf="accounts.length > 0">
      <dynamic-table [headers]="accountColumns" [data]="accounts" [actions]="accountTableActions" />
      <div class="flex p-2 text-xl justify-content-between font-bold bg-blue-100">
        <span>Solde total</span>
        <span>
        {{ soldeTotal | currency : accounts[0].currency.code : 'symbol' : '1.2-2' : 'fr-FR' }}
      </span>
      </div>
    </div>
    <span *ngIf="accounts.length === 0" class="py-2 text-gray-400 font-semibold font-italic">Aucun compte pour la devise suivante</span>
  </div>
  <div class="flex flex-column my-2">
    <div class="flex justify-content-between align-items-center">
      <span class="font-semibold text-xl">Opérations à venir</span>
      <p-button *ngIf="futureOperations.length > 0" (onClick)="showUpcomingOperations()" styleClass="text-color-secondary" iconPos="right"
                icon="pi pi-expand" severity="secondary" label="Consulter tout" [link]="true" />
    </div>
    <app-operation-list *ngIf="futureOperations.length > 0" class="mb-4" [data]="futureOperations" (paginate)="onFutureOperationPageChange($event)"/>
    <span *ngIf="futureOperations.length <= 0" class=" py-2 text-gray-400 font-semibold font-italic">Aucune opération à venir pour la devise suivante</span>
  </div>
</div>
