import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { CalendarModule } from "primeng/calendar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { NgIf } from "@angular/common";
import { RadioButtonModule } from "primeng/radiobutton";
import { MessageModule } from 'primeng/message';

export interface ExportData {
  dates: Date[] | null;
  extension: 'excel' | 'pdf';
}

@Component({
  selector: 'export',
  standalone: true,
  imports: [
    ButtonModule, DialogModule, CalendarModule, FormsModule, InputTextModule,
    ReactiveFormsModule, RadioButtonModule, NgIf, MessageModule
  ],
  template: `
    <div class="flex w-full">
      <p-button severity="secondary" [outlined]="true" [label]="iconOnly ? '' : 'Exporter' " icon="pi pi-upload" class="w-full" (onClick)="visible = true" />
      <p-dialog [header]="'Export ' + label" [modal]="true" [(visible)]="visible" class="w-full h-full" [style]="{ width: '40rem', height: '65rem' }">
        <div class="flex flex-column h-full gap-4">
          <div *ngIf="withExtensions" class="flex flex-column gap-2">
            <label for="dates" class="font-medium">Format d'export</label>
            <div class="flex flex-wrap gap-3">
              <div class="flex align-items-center">
                <p-radioButton id="excel" name="Excel" value="excel" [(ngModel)]="extension" />
                <label for="excel" class="ml-2">Excel</label>
              </div>
              <div class="flex align-items-center">
                <p-radioButton name="pizza" value="pdf" [(ngModel)]="extension" id="pdf" />
                <label for="pdf" class="ml-2">PDF</label>
              </div>
            </div>
          </div>
          <div class="flex flex-column gap-2" [style]="{ 'max-width': '512px' }">
            <label for="dates" class="font-medium">Période d'export</label>
            <p-calendar id="dates" [(ngModel)]="rangeDates" selectionMode="range" [autofocus]="false"
                        [readonlyInput]="true" (onSelect)="validateDateRange()" />
            <small id="dates-help">Renseigner ici la période de l'export (une année au maximum).</small>
            <p-message *ngIf="isInvalidRange" severity="error" text="La période sélectionnée ne doit pas dépasser un an." />
          </div>
          <div class="flex justify-content-end align-items-end flex-grow-1 gap-2">
            <p-button label="Annuler" severity="secondary" (onClick)="closeDialog()" />
            <p-button label="Exporter" (onClick)="proceedExport()"
                      [disabled]="!rangeDates || rangeDates.length !== 2 || isInvalidRange" />
          </div>
        </div>
      </p-dialog>
    </div>
  `
})
export class ExportComponent {

  @Input({required: true}) label: string = '';
  @Input() iconOnly: boolean = false;
  @Input() withExtensions: boolean = true;
  @Output() handler = new EventEmitter<ExportData>();
  visible: boolean = false;
  rangeDates: Date[] | null = null;
  extension: 'excel' | 'pdf' = 'excel';
  isInvalidRange: boolean = false;

  validateDateRange() {
    if (this.rangeDates && this.rangeDates.length === 2) {
      const [startDate, endDate] = this.rangeDates;
      const oneYearFromStart = new Date(startDate);
      oneYearFromStart.setFullYear(oneYearFromStart.getFullYear() + 1);

      this.isInvalidRange = endDate > oneYearFromStart;
    } else {
      this.isInvalidRange = false;
    }
  }

  proceedExport() {
    if (this.rangeDates && this.rangeDates.length === 2 && !this.isInvalidRange) {
      this.handler.emit({dates: this.rangeDates, extension: this.extension});
      this.closeDialog();
    }
  }

  closeDialog() {
    this.visible = false;
    this.rangeDates = null;
    this.isInvalidRange = false;
    this.extension = 'excel';
  }
}
