<div class="flex flex-column gap-4 py-2">
  <div class="flex justify-content-between align-items-center">
    <div class="flex gap-2 align-items-baseline">
      <span class="text-xl text-color-secondary font-semibold">Prêts et emprunts</span>
      <span class="text-xl text-color-secondary font-semibold">/</span>
      <span class="text-xl font-bold">{{ page }}</span>
    </div>
    <div class="flex gap-2">
      <div class="card flex justify-content-center gap-2">
        <p-selectButton [multiple]="true" [options]="viewOptions" [(ngModel)]="activeViews" optionLabel="icon" optionValue="value">
          <ng-template let-item pTemplate>
            <div class="flex gap-2">
              <i [class]="item.icon"></i>
              <span>{{item.name}}</span>
            </div>
          </ng-template>
        </p-selectButton>
        <p-button label="Nouveau" icon="pi pi-plus" class="p-button-rounded p-button-success" (onClick)="createLoanBorrowing()" />
      </div>
    </div>
  </div>
  <div class="flex flex-column gap-4">
    <div *ngIf="activeViews.includes(2)" class="flex w-full">
      <repayment-volume-bar-chart [types]="types" class="w-full" />
    </div>
    <div *ngIf="activeViews.includes(1)" class="flex flex-column gap-4">
      <div class="flex justify-content-between align-items-center">
        <search (search)="onSearch($event)" [placeholder]="'Recherche'" [debounceTime]="500"></search>
        <div class="filter">
          <p-button severity="secondary" label="Filtrer" icon="pi pi-filter" (onClick)="displayFilterDialog()"/>
        </div>
      </div>
      <dynamic-table [headers]="tableColumns" [data]="loanBorrowings" [pageParams]="tablePageable" [actions]="tableActions" />
    </div>
  </div>
</div>
