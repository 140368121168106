import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../../../core/services/account.service";
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {ToastService} from "../../../../core/services/toast.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {ToolbarComponent} from "../../../../shared/components/toolbar/toolbar.component";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {IAccount} from "../../../../core/models/account.model";
import {DEFAULT_ROWS_PER_PAGE_OPTIONS} from "../../../../core/utils/utils";
import {PaginatorState} from "primeng/paginator";

@Component({
  selector: 'app-suivi',
  standalone: true,
  imports: [ DynamicTableComponent, ToolbarComponent ],
  templateUrl: './suivi.component.html',
  styleUrl: './suivi.component.scss'
})
export class SuiviComponent implements OnInit {

  accounts: IAccount[] = [];
  columns: CustomTableHeader[] = [
    {
      key: 'libelle',
      column: 'Libelle',
      type: 'text'
    },
    {
      key: 'bankName',
      column: 'Banque',
      type: 'text'
    },
    {
      key: 'number',
      column: 'Numéro de compte',
      type: 'text'
    },
    {
      key: 'lastBalance.balance',
      column: 'Solde',
      type: 'currency',
      currencyKey: 'devise.code'
    },
    {
      key: 'lastBalance.date',
      column: 'Date de solde',
      type: 'date'
    }
  ]
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  ref: DynamicDialogRef | undefined;
  actions: CustomAction[] = [];

  constructor(private accountService: AccountService, private toastService: ToastService, public dialogService: DialogService) { }

  ngOnInit() {
    this.getAccounts();
    this.actions = this.getActions();
  }

  getActions(): CustomAction[] {
    return [
      {
        role: 'READ_VIREMENT',
        icon: 'pi pi-info-circle',
        label: 'Opérations',
        severity: 'info',
        emit: (item) => this.viewOperations(item),
        visible: true
      },
      {
        role: 'READ_VIREMENT',
        icon: 'pi pi-chart-line',
        label: 'Soldes',
        severity: 'primary',
        emit: (item) => this.listBalances(item),
        visible: true
      }
    ];
  }

  // TODO: Implement the following methods
  viewOperations(account: IAccount) {
    this.ref = this.dialogService.open(DynamicTableComponent, {
      header: 'Opérations',
      width: '70%',
      data: {
        columns: [
          {
            key: 'operation.operationDate',
            column: 'Date opération',
            type: 'date'
          },
          {
            key: 'operation.valueDate',
            column: 'Date valeur',
            type: 'date'
          },
          {
            key: 'libelle',
            column: 'Libelle',
            type: 'text'
          },
          {
            key: 'operation.amount',
            column: 'Montant',
            type: 'currency'
          },
          {
            key: 'operation.account.libelle',
            column: 'Compte',
            type: 'text'
          },
          {
            key: 'beneficiary.name',
            column: 'Bénéficiaire',
            type: 'text'
          }
        ],
        pageable: {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS},
        actions: []
      }
    });
  }

  // TODO: Implement the following methods
  listBalances(account: IAccount) {

  }

  getAccounts(pageable: Pageable = getDefaultPageable()) {
    return this.accountService.getForeignAccountsForInstitution(pageable).subscribe({
      next: page => {
        this.accounts = page.content;
        this.pageable = {
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
          rowPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS
        }
      },
      error: (err) => {
        this.toastService.showToast('Chargement des comptes NOSTRO', err.error, 'error');
      }
    });
  }

  onPageChange = (event: PaginatorState) => this.getAccounts(getDefaultPageable(event.page, event.rows));


}
