import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {PaginatorModule, PaginatorState} from "primeng/paginator";
import {InputTextModule} from "primeng/inputtext";
import {NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../../../../shared/components/dynamic-table/dynamic-table.component";
import {CustomAction, CustomTableHeader} from "../../../../../core/models/custom-table.model";
import {DetailsWithSubmissionModel, SubmissionModel} from "../../../../../core/models/details-submissionn.model";
import {getDefaultPageable, Pageable, PageParams} from "../../../../../core/models/page.model";
import {finalize} from "rxjs";
import {SubmissionService} from "../../../../../core/services/submission.service";
import {ToastService} from "../../../../../core/services/toast.service";

@Component({
  selector: 'app-new-soumission',
  standalone: true,
  imports: [
    PaginatorModule,
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
    ButtonDirective,
    DynamicTableComponent
  ],
  templateUrl: './new-soumission.component.html',
  styleUrl: './new-soumission.component.scss'
})
export class NewSoumissionComponent implements OnInit{

  readonly SubmissionState = {
    PENDING: 'PENDING',
    VALIDATED: 'VALIDATED',
    REJECTED: 'REJECTED'
  };

  formGroup: FormGroup;
  isLoading: boolean = false;
  primaire: DetailsWithSubmissionModel[] = [];
  pageable: PageParams = {page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50]};
  actions: CustomAction[] = [];
  columns: CustomTableHeader[] = [
    { key: 'isin', column: 'ISIN', type: 'text' },
    { key: 'typeInstrument', column: 'Instrument', type: 'text' },
    { key: 'submissionRequestDTO.amountSubmission', column: 'Montant Soumis', type: 'text' },
    { key: 'submissionRequestDTO.rateSubmission', column: 'Taux Soumis', type: 'text' },
    { key: 'submissionRequestDTO.state', column: 'Statut', type: 'state' }
  ];
  constructor(
    private readonly fb: FormBuilder,
    public readonly ref: DynamicDialogRef,
    public readonly config: DynamicDialogConfig,
    private readonly submissionService: SubmissionService,
    private readonly toastService :ToastService
  ) {
    this.formGroup = this.fb.group({
      typeInstrument: [{ value: '', disabled: true }],
      amountSubmission: ['', [Validators.required, Validators.min(0), Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      rateSubmission: ['', [Validators.required, Validators.min(0), Validators.pattern(/^\d+(\.\d{1,2})?$/)]]
    });
  }
  ngOnInit(): void {
    const emissionData = this.config.data?.data;
    if (emissionData) {
      this.formGroup.patchValue({
        typeInstrument: emissionData.typeInstrument,
        amountSubmission: emissionData.amountSubmission
      });
    }
    this.actions = this.getActions();
    this.loadSubmission(getDefaultPageable(0, 10));
  }
  onPageChange = (event: PaginatorState) => {
    this.loadSubmission(getDefaultPageable(event.page, event.rows));
  }
  addSubmission() {
    if (this.formGroup.valid) {
      const formData = this.formGroup.value;
      const submissionData: SubmissionModel = {
        id: this.config.data?.data?.id,
        amountSubmission: formData.amountSubmission,
        rateSubmission: formData.rateSubmission,
        state: this.SubmissionState.PENDING
      };
      this.isLoading = true;
      this.submissionService.registerSubmission(this.config.data?.data?.id, submissionData)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: () => {
            this.toastService.showToast('Succès', 'Soumission créée avec succès.', 'success');
            this.loadSubmission(getDefaultPageable());
          },
          error: (err) => {
            this.toastService.showToast('Erreur', 'Echec de la soumission', err);
          }
        });
    }
  }


  private loadSubmission(defaultPageable: Pageable) {
    const detailsEmissionId = this.config.data?.data?.id;

    if (detailsEmissionId) {
      this.isLoading = true;
      this.submissionService.getSubmissionByDetailsEmission(detailsEmissionId, defaultPageable.page, defaultPageable.limit)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: (response) => {
            this.primaire = response.content;
            this.pageable.totalRecords = response.totalElements;
          },
          error: (err) => {
            this.toastService.showToast('Erreur', 'Echec de chargement des soumissions', err);
          }
        });
    }
  }
  getActions(): CustomAction[] {
    return [
      {
        role: 'DELETE_SUBMISSION',
        icon: 'pi pi-trash',
        label: 'Supprimer',
        severity: 'danger',
        emit: (item) => this.deleteSubmission(item),
        visible: true
      },
    ];
  }
  deleteSubmission(submissionModel: DetailsWithSubmissionModel) {
    this.submissionService.deleteSubmission(submissionModel.submissionRequestDTO.id).subscribe({
      next: () => {
        this.toastService.showToast('Soumission supprimée', `Soumission a été supprimée avec succès.`);
        this.loadSubmission(getDefaultPageable());
      },
      error: err => {
        this.toastService.showToast('Erreur lors de la suppression de la soumission', err, 'error');
      }
    });
  }
}
