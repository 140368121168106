import {Component} from '@angular/core';
import {IGroup} from "../../../core/models/group.model";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {Direction, getUnpagedPageable, PageParams, Sort} from "../../../core/models/page.model";
import {IPermission} from "../../../core/models/permission.model";
import {GroupService} from "../../../core/services/group.service";
import {ToastService} from "../../../core/services/toast.service";
import {getInstitutionTypeByValue, InstitutionType} from "../../../core/models/institution.model";
import {PermissionService} from "../../../core/services/permission.service";
import {AccordionModule} from "primeng/accordion";
import {CheckboxModule} from "primeng/checkbox";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {Button} from "primeng/button";

@Component({
  selector: 'app-manage-permissions',
  standalone: true,
  imports: [
    AccordionModule,
    CheckboxModule,
    NgForOf,
    FormsModule,
    Button,
    NgIf
  ],
  templateUrl: './manage-permissions.component.html',
  styleUrl: './manage-permissions.component.scss'
})
export class ManagePermissionsComponent {

  group: IGroup = {} as IGroup;
  boundData: CustomDialogData | undefined;
  instance: DynamicDialogComponent | undefined;
  pageable: PageParams = {page: 0, first: 0, rows: 0, totalRecords: 0, rowPerPageOptions: []};
  sort: Sort = { direction: Direction.ASC, orders: [{ property: 'code', ignoreCase: false }] };
  permissions: IPermission[] = [];
  tabs: { title: string, permissions: IPermission[] }[] = [];
  selectedPermissions: IPermission[] = [];

  constructor(private groupService: GroupService, private dialogService: DialogService, private permissionService: PermissionService,
              private toastService: ToastService, private ref: DynamicDialogRef) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.permissionService.getPermissionsByInstitutionType(getInstitutionTypeByValue(InstitutionType.BANK)!, getUnpagedPageable(this.sort))
      .subscribe({
        next: (page) => {
          this.permissions = page.content;
          this.tabs = this.groupByProduct(this.permissions);
        },
        error: (err) => this.toastService.showToast('Erreur lors de la capture des permissions', err, 'error')
      });
    this.boundData = this.instance?.data;
    this.group = this.boundData?.data || {} as IGroup;
    this.selectedPermissions = this.group.permissions || [];
  }

  private groupByProduct(permissions: IPermission[]): { title: string, permissions: IPermission[] }[] {
    const groupedPermissions = permissions.reduce((acc, permission) => {
      const productDesc = permission.product.description;
      if (!acc[productDesc]) acc[productDesc] = [];
      acc[productDesc].push(permission);
      return acc;
    }, {} as { [key: string]: IPermission[] });

    // Convert grouped permissions to tabs
    return Object.entries(groupedPermissions).map(([productDesc, permissions]) => ({
      title: productDesc,
      permissions
    }));
  }

  savePermissions() {
    const group = { ... this.group, permissions: this.selectedPermissions };
    this.groupService.updateGroup(this.group.id, group).subscribe({
      next: () => {
        this.toastService.showToast('Permissions mises à jour avec succès', 'Les permissions ont été mises à jour avec succès');
        this.ref.close();
      },
      error: (err) => this.toastService.showToast('Erreur lors de la mise à jour des permissions', err.error, 'error')
    });
  }
}
