import {Confirmation} from "primeng/api";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {HttpResponse} from "@angular/common/http";

export function getFilenameFromHeader(response: HttpResponse<Blob>): string {
  const contentDisposition = response.headers.get('Content-Disposition');
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches?.[1]) return matches[1].replace(/['"]/g, '');
  }
  return '';
}

export const DEFAULT_ROWS_PER_PAGE_OPTIONS: number[] = [10,25,50];

export function customDynamicDialog(
  header: string,
  data: any = {},
  options: DynamicDialogConfig = {} as DynamicDialogConfig
) {
  return {
    dismissableMask: true,
    modal: true,
    position: 'top-right',
    height: '100vh',
    style: {'max-height': '100%', 'margin': 0, 'min-width': '25%'},
    ...options,
    data,
    header
  } as DynamicDialogConfig;
}

/**
 * Create a custom confirmation dialog
 *
 * @param header        Header of the dialog
 * @param message       Message of the dialog
 * @param options       Custom options of the dialog
 * @param acceptLabel   Label of the accept button
 * @param rejectLabel   Label of the reject button
 */
export function customConfirmationDialog(
  header: string,
  message: string,
  options: Confirmation = {} as Confirmation,
  acceptLabel: string = 'Oui',
  rejectLabel: string = 'Non'
) {
  return {
    icon: 'pi pi-info-circle',
    acceptButtonStyleClass:"p-button-danger p-button-text",
    rejectButtonStyleClass:"p-button-text p-button-text",
    acceptIcon:"none",
    rejectIcon:"none",
    closeOnEscape: true,
    closeOnOutsideClick: true,
    ...options,
    header,
    message,
    acceptLabel,
    rejectLabel,
  } as Confirmation;
}
