<div class="flex flex-column w-full h-full align-content-between">

<div class="flex flex-column h-full">
  <hr style="border: 1px solid #000; margin-bottom: 1rem;">
  <form [formGroup]="formGroup" class="flex flex-row gap-4">
    <!-- Premier champ -->
    <!--<div class="flex flex-column gap-2" style="flex: 1;">
      <label class="font-medium">Type du titre</label>
      <input [attr.disabled]="true" pInputText id="typeInstrument" aria-describedby="typeInstrument-help" formControlName="typeInstrument" />
    </div>-->
    <!-- Deuxième champ -->
    <div class="flex flex-column gap-2" style="flex: 1;">
      <label class="font-medium">Montant (F CFA)</label>
      <input
        type="number"
        [attr.disabled]="true"
        pInputText
        id="amountSubmission"
        aria-describedby="amountSubmission-help"
        formControlName="amountSubmission"
        min="0"
        step="0.01" />
      <small id="amountSubmission-help">Renseigner ici le montant.</small>
    </div>
    <!-- Ajouter un autre champ si nécessaire -->
    <div class="flex flex-column gap-2" style="flex: 1;">
      <label class="font-medium">Taux (%)</label>
      <input
        type="number"
        pInputText
        id="rateSubmission"
        aria-describedby="rateSubmission-help"
        formControlName="rateSubmission"
        min="0"
        step="0.01" />
      <small id="rateSubmission-help">Renseigner ici le taux.</small>
    </div>
    <div class="flex align-items-center" style="flex: 0;">
      <button pButton type="button" icon="pi pi-plus" (click)="addSubmission()" ></button>
    </div>
  </form>
  <hr>
</div>
  <div class="mb-2">
    <h3 class="text-gray-500">LISTE DES SOUMISSIONS</h3>
  </div>
  <dynamic-table [headers]="columns" [data]="primaire" [pageParams]="pageable"
                 (paginate)="onPageChange($event)"></dynamic-table>
</div>
