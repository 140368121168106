import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {getDefaultPageable, Pageable, PageParams} from "../../../../core/models/page.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {PaginatorState} from "primeng/paginator";
import {ButtonDirective} from "primeng/button";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {NewOperationWicketComponent} from "../new-operation-wicket/new-operation-wicket.component";
import {customDynamicDialog} from "../../../../core/utils/utils";
import {take} from "rxjs";
import {ToastService} from "../../../../core/services/toast.service";
import {FormBuilder} from "@angular/forms";
import {WicketOperationService} from "../../../../core/services/wicketOperation.service";
import {DatePipe} from "@angular/common";
import {WicketOperationRequestModel} from "../../../../core/models/WicketOperationRequest.model";
import {differenceInDays} from "date-fns";
import {NewResultWicketComponent} from "../new-result-wicket/new-result-wicket.component";
import {ResultWicketOperationService} from "../../../../core/services/resultWicketOperation.service";
import {RegisterResultWicketOperationModel} from "../../../../core/models/registerResultWicketOperation.model";

@Component({
  selector: 'app-marginal-wicket',
  standalone: true,
  imports: [
    ButtonDirective,
    DynamicTableComponent
  ],
  templateUrl: './marginal-wicket.component.html',
  styleUrl: './marginal-wicket.component.scss'
})
export class MarginalWicketComponent implements OnInit{

  pageableMarginalWicket: PageParams = { page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50] };
  pageableResult: PageParams = { page: 0, first: 0, rows: 10, totalRecords: 0, rowPerPageOptions: [10, 20, 50] };  ref?: DynamicDialogRef;
  actions: CustomAction[] = [];
  columnsEmission: CustomTableHeader[] = [
    { key: 'number', column: 'Numéro', type: 'text' },
    { key: 'nature', column: 'Nature', type: 'text' },
    { key: 'valueDate', column: 'Date de valeur', type: 'text' },
    { key: 'dueDate', column: 'Date échéance', type: 'text' },
    { key: 'rate', column: 'Taux intérêts(%)', type: 'text' },
    { key: 'maxAmount', column: 'Montant maximum', type: 'text' },
    { key: 'duration', column: 'Durée(jours)', type: 'text' }
  ];
  columnsResultat: CustomTableHeader[] = [
    { key: 'wicketOperation.number', column: 'Numéro', type: 'text' },
    { key: 'retainedAmount', column: 'Montant retenu', type: 'text' },
    { key: 'proposedAmount', column: 'Montant proposé', type: 'text' },
  ];
  marginalWicket: WicketOperationRequestModel[] = [];
  result: RegisterResultWicketOperationModel[] = [];

  constructor(public dialogService: DialogService,
              private toastService :ToastService,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private wicketOperationService: WicketOperationService,
              private resultWicketOperationService: ResultWicketOperationService,
              private datePipe: DatePipe) {

  }

  addNewMarginalWicket() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(NewOperationWicketComponent, customDynamicDialog('Nouveau', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadMarginalWicket(getDefaultPageable(0,10)));

  }

  onPageChangeMarginalWicket = (event: PaginatorState) => {
    this.loadMarginalWicket(getDefaultPageable(event.page, event.rows));
  }

  onPageChangeResult = (event: PaginatorState) => {
    this.loadResult(getDefaultPageable(event.page, event.rows));
  }

  private loadMarginalWicket(pageable: Pageable) {
    this.wicketOperationService.getAllWicketOperations(pageable.page, pageable.limit, true)
      .subscribe({
        next: (response) => {
          this.marginalWicket = response.content
            .map(item => {
              const duration = this.calculateDuration(item.valueDate, item.dueDate);
              return {
                ...item,
                valueDate: this.formatDate(item.valueDate),
                dueDate: this.formatDate(item.dueDate),
                duration: duration
              };
            })
            .filter(item => item.duration <= 7);

          this.pageableMarginalWicket.totalRecords = this.marginalWicket.length;
        },
        error: (error) => {
          this.toastService.showToast('Erreur','Erreur lors du chargement des limites', error);
        }
      });
  }

  private loadResult(pageable: Pageable) {
    this.resultWicketOperationService.getAllResultWicketOperations(pageable.page, pageable.limit, true)
      .subscribe({
        next: (response) => {
          this.result = response.content
            .map(item => {
              return {
                ...item,
              };
            })
          this.pageableResult.totalRecords = this.result.length;
        },
        error: (error) => {
          this.toastService.showToast('Erreur','Erreur lors du chargement des resultats', error);
        }
      });
  }


  addNewResult() {
    const data = {isCreate: true} as CustomDialogData;
    this.ref = this.dialogService.open(NewResultWicketComponent, customDynamicDialog('Nouveau', data));
    this.ref.onClose.pipe(take(1)).subscribe(() => this.loadResult(getDefaultPageable(0,10)));
  }

  ngOnInit(): void {
    this.loadMarginalWicket(getDefaultPageable(0,10))
    this.loadResult(getDefaultPageable(0,10))

  }

  private formatDate(date: Date | string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
  }

  private calculateDuration(startDate: string | Date, endDate: string | Date): number {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return differenceInDays(end, start);
  }
}
