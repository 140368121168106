<div class="flex flex-column w-full h-full align-content-between">

    <div class="flex justify-content-start mb-4">
      <div class="flex justify-content-start mt-4 pl-4 gap-2">
        <p-calendar
          [(ngModel)]="dateRange"
          selectionMode="range"
          [readonlyInput]="true"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
          placeholder="Sélectionnez une plage de dates">
        </p-calendar>
        <button (click)="applyDateFilter()" pButton label="Filtrer"></button>
      </div>
    </div>


  <div class="chart-container">
    <div class="card flex flex-column">
      <h2 class="text-center mb-0">Résumé du spread</h2>
      <hr class="w-full mb-4">
      <div class="flex flex-column flex-grow-1 justify-content-between">
        <div class="flex justify-content-between">
          <span>Dernier taux</span>
          <span class="font-semibold text-xl">{{ dernierTaux }}%</span>
        </div>
        <div class="flex justify-content-between">
          <span>Moyenne</span>
          <span class="font-semibold text-xl">{{ moyenne }}%</span>
        </div>
        <div class="flex justify-content-between">
          <span>Écart type</span>
          <span class="font-semibold text-xl">{{ ecartType }}</span>
        </div>
        <div class="flex justify-content-between">
          <span>Écart type à la moyenne</span>
          <span class="font-semibold text-xl">{{ etm }}</span>
        </div>
        <div class="flex justify-content-between">
          <span>Centile</span>
          <span class="font-semibold text-xl">{{ centile }}%</span>
        </div>
        <div class="flex justify-content-between">
          <span>Date du plus haut taux</span>
          <span class="font-semibold text-xl">{{ dateHaut }}</span>
        </div>
        <div class="flex justify-content-between">
          <span>Date du plus bas taux</span>
          <span class="font-semibold text-xl">{{ dateBas }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="dataONE" class="card">
      <p-chart [data]="dataONE" [options]="optionsONE" type="bar"></p-chart>
    </div>
  </div>
  <div class="chart-container">
    <div class="card">
      <p-chart [data]="data" [options]="options" type="line"></p-chart>
    </div>
    <div class="card">
      <p-chart [data]="dataTWO" [options]="optionsTWO" type="bar"></p-chart>
    </div>
  </div>
  <div class="flex align-items-start justify-content-between mb-2 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">Tableau de titres</h3>
    </div>
    <div class="flex-grow-1 flex gap-2 justify-content-end">

      <p-fileUpload
          chooseIcon="pi pi-download"
          mode="basic"
          (onSelect)="onUpload($event)"
          [multiple]="false"
          class="custom-file-upload"
          accept=".xlsx,.xls"
          maxFileSize="5000000"
          chooseLabel="Charger opérations">
        </p-fileUpload>
      <button (click)="createTitleSecondary()" icon="pi pi-plus" label="Nouveau" pButton type="button"></button>
    </div>
  </div>
  <dynamic-table (paginate)="onPageChange($event)" [data]="operationsTitres" [headers]="columns"
                 [pageParams]="pageable"></dynamic-table>
</div>
