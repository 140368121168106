// has-module.directive.ts
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { StorageService } from '../services/storage.service';
import {modules} from "../models/module.model";
import {JwtToken} from "../models/jwt.model";

@Directive({
  selector: '[hasModule]',
  standalone: true
})
export class HasModuleDirective implements OnInit {

  @Input('hasModule') module!: string;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly tokenManager: StorageService
  ) {}

  ngOnInit() {
    const token = this.tokenManager.getToken();
    if (token != null) this.checkForModule(this.module, token);
    else this.viewContainer.clear();
  }

  checkForModule(module: string, token: JwtToken) {
    const moduleData = modules.find(m => m.module === this.module);
    const hasPermission = moduleData ? moduleData.products.some(product =>
      product.permissions.some(permission => token.authorities.includes(permission))
    ) : false;

    hasPermission ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
