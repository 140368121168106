import { Component } from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {IAccount} from "../../../../../core/models/account.model";
import {CustomDialogData} from "../../../../../core/models/custom-dialog.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {VirementService} from "../../../../../core/services/virement.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {AccountService} from "../../../../../core/services/account.service";
import {getUnpagedPageable} from "../../../../../core/models/page.model";
import {IVirementRequest} from "../../../../../core/models/virement.model";
import {DropdownModule} from "primeng/dropdown";
import {Button} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {NgIf} from "@angular/common";
import {InputTextModule} from "primeng/inputtext";
import {CalendarModule} from "primeng/calendar";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ICurrency} from "../../../../../core/models/devise.model";
import {CurrencyService} from "../../../../../core/services/currency.service";
import {BeneficiaryService} from "../../../../../core/services/beneficiary.service";
import {StorageService} from "../../../../../core/services/storage.service";
import {IBeneficiary} from "../../../../../core/models/beneficiary.model";

@Component({
  selector: 'app-edit-virement',
  standalone: true,
  imports: [
    // Modules
    ReactiveFormsModule, DropdownModule, InputTextModule, CalendarModule, InputNumberModule, InputTextareaModule,
    // Pipes & Directives
    Button, Ripple, NgIf
  ],
  templateUrl: './edit-virement.component.html',
  styleUrl: './edit-virement.component.scss'
})
export class EditVirementComponent  {

  formGroup = new FormGroup({
    libelle: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    devise: new FormControl<ICurrency | null>(null, {nonNullable: true, validators: [Validators.required]}),
    description: new FormControl<string>(''),
    amount: new FormControl<number>(1, { nonNullable: true, validators: [Validators.required] }),
    operationDate: new FormControl<Date>(new Date(), { nonNullable: true, validators: [Validators.required] }),
    valueDate: new FormControl<Date>(new Date(), { nonNullable: true, validators: [Validators.required] }),
    account: new FormControl<IAccount>({} as IAccount, { nonNullable: true, validators: [Validators.required] }),
    beneficiary: new FormControl<IAccount>({} as IAccount, { nonNullable: true, validators: [Validators.required] }),
  });
  devises: ICurrency[] = [];
  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  accounts: IAccount[] = [];
  beneficiaries: IBeneficiary[] = [];

  constructor(
    private readonly virementService: VirementService,
    private readonly dialogService: DialogService,
    private readonly deviseService: CurrencyService,
    private readonly beneficiaryService: BeneficiaryService,
    private readonly toastService: ToastService,
    private readonly ref: DynamicDialogRef,
    private readonly accountService: AccountService,
    private readonly tokenManager: StorageService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    this.getDevises();
    this.getAccounts();
    this.getBeneficiaries();
    this.boundData = this.instance?.data;
    if (!this.boundData?.isCreate){
      this.formGroup.patchValue(this.boundData?.data);
      this.formGroup.patchValue({
        account: this.boundData?.data.account,
        beneficiary: this.boundData?.data.beneficiary,
      });
    }
  }

  getBeneficiaries() {
    const jwt = this.tokenManager.getToken();
    this.beneficiaryService.getBeneficiaryByInstitutionId(jwt.institution_id, getUnpagedPageable())
      .subscribe({
        next: (page) => this.beneficiaries = page.content,
        error: (err) => this.toastService.showToast('Récupération des bénéficiaires', err.error, 'error')
      });
  }

  getDevises() {
    this.deviseService.getAllDevises(getUnpagedPageable()).subscribe({
      next: (page) => {
        this.devises = page.content;
        this.formGroup.patchValue({ devise: this.devises[0] });
      },
      error: () => {
        const summary = 'Récupération des devises';
        const detail = 'Une erreur est survenue lors de la récupération des devises. Veuillez réessayer plus tard.';
        this.toastService.showToast(summary, detail, 'error');
      }
    });
  }

  getAccounts() {
    this.accountService.getForeignAccountsForInstitution(getUnpagedPageable()).subscribe({
      next: (page) => this.accounts = page.content,
      error: (err) => {
        const summary = 'Récupération des comptes NOSTRO';
        this.toastService.showToast(summary, err.error, 'error');
      }
    });
  }

  create() {
    this.isLoading = true;
    const virement = this.formGroup.value as IVirementRequest;
    this.virementService.createVirement(virement).subscribe({
      next: (virement) => {
        this.toastService.showToast('Virement créé', `Le virement ${virement.libelle} a été créé avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création du virement', error, 'error');
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

  update() {
    this.isLoading = true;
    const virement = this.formGroup.value as IVirementRequest;
    this.virementService.updateVirement(this.boundData?.data.id, virement).subscribe({
      next: (virement) => {
        this.toastService.showToast('Virement modifié', `Le virement ${virement.libelle} a été modifié avec succès.`);
        this.isLoading = false;
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la modification du virement', error);
        this.isLoading = false;
        this.ref.close();
      }
    });
  }

}
