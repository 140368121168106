import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CustomDialogData} from "../../../../core/models/custom-dialog.model";
import {Button} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {DatePipe, NgIf, UpperCasePipe} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {PrimeTemplate} from "primeng/api";
import {Ripple} from "primeng/ripple";
import {WicketOperationRequestModel} from "../../../../core/models/WicketOperationRequest.model";
import {ToastService} from "../../../../core/services/toast.service";
import {DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {WicketOperationService} from "../../../../core/services/wicketOperation.service";

@Component({
  selector: 'app-new-operation-wicket',
  standalone: true,
  imports: [
    Button,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    NgIf,
    PaginatorModule,
    PrimeTemplate,
    ReactiveFormsModule,
    Ripple,
    UpperCasePipe
  ],
  templateUrl: './new-operation-wicket.component.html',
  styleUrl: './new-operation-wicket.component.scss'
})
export class NewOperationWicketComponent implements OnInit{

  boundData: CustomDialogData | undefined;
  isLoading: boolean = false;
  instance: DynamicDialogComponent | undefined;
  isCreate: boolean = false;
  natureOptions = [
    { code: 'FIXE', name: 'FIXE' },
    { code: 'VARIABLE', name: 'VARIABLE' }
  ];

  formGroup = new FormGroup({
    number: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    nature: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    valueDate: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    dueDate: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    rate: new FormControl(),
    maxAmount: new FormControl(),

  });


  constructor(private wicketOperationService : WicketOperationService,
              private toastService: ToastService,
              public config: DynamicDialogConfig,
              private datePipe: DatePipe,
              private ref: DynamicDialogRef,
              private dialogService: DialogService) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
  }

  createWicketOperation() {
    console.log('FormGroup Values:', this.formGroup.value);

    const wicketOperationRequestModel: WicketOperationRequestModel = {
      id: this.config.data?.data?.id,
      number: this.formGroup.get('number')?.value || '',
      nature: this.formGroup.get('nature')?.value || '',
      valueDate: this.formatDate(this.formGroup.get('valueDate')?.value || ''),
      dueDate: this.formatDate(this.formGroup.get('dueDate')?.value || ''),
      rate: this.formGroup.get('rate')?.value,
      maxAmount: this.formGroup.get('maxAmount')?.value,
      duration: '' || 0
    };
    this.wicketOperationService.createWicketOperation(wicketOperationRequestModel).subscribe({
      next: () => {
        this.toastService.showToast('Opération créée', `L'Opération a été créée avec succès.`);
        this.ref.close();
      },
      error: (error) => {
        this.toastService.showToast('Erreur lors de la création de l\'opération', error, 'error');
        this.ref.close();
      }
    });
  }

  update() {
  }

  private formatDate(date: Date | string): string {
    if (date instanceof Date) {
      return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss') || '';
    }
    return date;
  }
}
