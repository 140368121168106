<div class="flex flex-column w-full h-full align-content-between">

  <div class="flex align-items-start justify-content-between mb-2 align-items-center">

    <div class="mb-2">
      <h3 class="text-gray-500">LISTE DES SOUMISSIONS</h3>
    </div>

    <div class="flex-grow-1 flex gap-2 justify-content-end">
      <button type="button" pButton icon="pi pi-upload" (click)="exportSoumission()"></button>
      <p-fileUpload
        chooseIcon="pi pi-download"
        mode="basic"
        (onSelect)="onUpload($event)"
        [multiple]="false"
        class="custom-file-upload"
        accept=".xlsx,.xls"
        maxFileSize="5000000"
        chooseLabel="Charger soumissions">
      </p-fileUpload>
    </div>
  </div>


  <dynamic-table [headers]="columns" [data]="primary" [pageParams]="pageable"
                 [actions]="actions" (paginate)="onPageChange($event)"></dynamic-table>



</div>
