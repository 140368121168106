<div class="container">
  <p-timeline [value]="audits" class="w-full md:w-20rem">
    <ng-template pTemplate="content" let-event>
      <small class="p-text-secondary">{{ event.date | date : 'dd/MM/yyyy, hh:mm' }}</small>
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
      {{ event.libelle }}
    </ng-template>
  </p-timeline>
</div>
