import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../../../core/services/account.service";
import {OperationService} from "../../../../core/services/operation.service";
import {
  DEFAULT_PAGE_PARAMS,
  getDefaultPageable,
  getUnpagedPageable,
  Pageable,
  PageParams,
  VALUE_DATE_SORT_ASC
} from "../../../../core/models/page.model";
import {IAccount} from "../../../../core/models/account.model";
import {getFilenameFromHeader} from "../../../../core/utils/utils";
import {Button} from "primeng/button";
import {CustomAction, CustomTableHeader} from "../../../../core/models/custom-table.model";
import {DynamicTableComponent} from "../../../../shared/components/dynamic-table/dynamic-table.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {PaginatorState} from "primeng/paginator";
import {CurrencyPipe, NgIf} from "@angular/common";
import {ToastService} from "../../../../core/services/toast.service";
import {IOperation} from "../../../../core/models/operation.model";
import {OperationListComponent} from "../../../../shared/components/operations/operation-list.component";
import {AccountOperationListComponent} from "../../../../shared/components/operations/account-operation-list.component";
import {SpeedDialModule} from "primeng/speeddial";
import {MenuItem} from "primeng/api";
import {SplitButtonModule} from "primeng/splitbutton";
import {BalanceHistoryChartComponent} from "../../../../shared/charts/balance-history.component";
import {UpcomingOperationComponent} from "./upcoming-operation/upcoming-operation.component";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-releve',
  standalone: true,
  imports: [
    // Modules
    SpeedDialModule, SplitButtonModule,
    // Components
    DynamicTableComponent, OperationListComponent, BalanceHistoryChartComponent,
    // Pipes & Directives
    Button, CurrencyPipe, NgIf,
  ],
  templateUrl: './releve.component.html',
  styleUrl: './releve.component.scss'
})
export class ReleveComponent implements OnInit {

  accounts: IAccount[] = [];
  accountColumns: CustomTableHeader[] = [
    {
      key: 'bank.name',
      column: 'Banque',
      type: 'text'
    },
    {
      key: 'libelle',
      column: 'Libelle',
      type: 'text'
    },
    {
      key: 'number',
      column: 'Numéro de compte',
      type: 'text'
    },
    {
      key: 'currency.code',
      column: 'Devise',
      type: 'text'
    },
    {
      key: 'lastBalance.balance',
      column: 'Solde',
      type: 'currency',
      currencyKey: 'currency.code'
    }
  ]
  accountTableActions: CustomAction[] = [];
  ref: DynamicDialogRef | undefined;
  soldeTotal: number = 0;
  futureOperations: IOperation[] = [];
  futureOperationsPageable: PageParams = DEFAULT_PAGE_PARAMS;
  exportItems: MenuItem[] = [
    {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      command: () => this.exportAccounts(),
    },
    {
      label: 'PDF',
      icon: 'pi pi-file-pdf',
      command: () => this.exportAccounts(false)
    }
  ];
  currency: string = 'XOF';
  isLocalCurrency: boolean = true;

  constructor(
    private readonly accountService: AccountService,
    private readonly operationService: OperationService,
    public dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Subscribe to route params and load data accordingly
    this.route.params.subscribe(params => {
      this.currency = params['currency'];
      this.isLocalCurrency = this.route.parent?.parent?.routeConfig?.path === 'locale';
      this.loadData();
    });
  }

  private loadData() {
    this.loadFutureOperations();
    this.loadAccounts();
    this.accountTableActions = this.getAccountActions();
  }

  private loadAccounts() {
    const apiCall = this.isLocalCurrency ?
      this.accountService.getLocalAccountsForInstitution(getUnpagedPageable()) :
      this.accountService.getAccountsByCurrency(this.currency, getUnpagedPageable());

    apiCall.subscribe({
      next: page => {
        this.accounts = page.content;
        this.soldeTotal = this.accounts
          .filter(account => account.currency.code === this.currency)
          .map(account => account.lastBalance.balance)
          .reduce((acc, curr) => acc + curr, 0);
      },
      error: err => this.toastService.showToast('Chargement des comptes', err.error, 'error')
    });
  }

  getAccountActions(): CustomAction[] {
    return [
      {
        role: 'READ_OPERATION',
        icon: 'pi pi-info-circle',
        label: 'Opérations',
        severity: 'info',
        emit: (item) => this.viewOperations(item),
        visible: true
      },
      {
        role: 'UPDATE_OPERATION',
        icon: 'pi pi-chart-line',
        label: 'Soldes',
        severity: 'primary',
        emit: (item) => console.log('Item: ', item),
        visible: true
      }
    ];
  }

  viewOperations(account: IAccount) {
    this.ref = this.dialogService.open(AccountOperationListComponent, {
      header: `Opérations - ${account.libelle}`,
      width: '80%',
      height: '80%',
      data: {
        accountId: account.id
      },
      contentStyle: { overflow: 'auto' }
    });
  }

  loadFutureOperations(pageable: Pageable = getDefaultPageable(0, 5, VALUE_DATE_SORT_ASC))  {
    this.operationService.searchFutureOperations('', this.currency, pageable).subscribe({
      next: page => {
        this.futureOperations = page.content;
        this.futureOperationsPageable = {
          ... this.futureOperationsPageable,
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
        }
      },
      error: err => this.toastService.showToast('Chargement des opérations à venir', err.error, 'error')
    });
  }

  onFutureOperationPageChange($event: PaginatorState) {
    this.loadFutureOperations(getDefaultPageable($event.page, $event.rows, VALUE_DATE_SORT_ASC));
  }

  onPageChange($event: PaginatorState) {
    console.log($event);
  }

  exportAccounts(isExcel: boolean = true) {
    this.accountService.exportAccountBalancesToExcel(true, isExcel).subscribe({
      next: response => {
        const blob = response.body;
        const filename = getFilenameFromHeader(response);

        if (blob) {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename || `releve_comptes_local.xlsx`;
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          this.toastService.showToast('Export des soldes', 'Une erreur est survenue lors de l\'export des soldes', 'error');
        }
      },
      error: err => this.toastService.showToast('Export des soldes', err.error, 'error')
    })
  }

  showUpcomingOperations() {
    console.log('Component currency: ', this.currency);
    this.ref = this.dialogService.open(UpcomingOperationComponent, {
      header: 'Opérations à venir',
      width: '80%',
      height: '80%',
      contentStyle: {
        overflow: 'auto'
      },
      data: {
        currency: this.currency
      }
    });
    this.ref.onClose.subscribe(() => this.ngOnInit());

  }
}
