<div class="p-2">
  <p-accordion [activeIndex]="0">
    <p-accordionTab [header]="tab.title" *ngFor="let tab of tabs">
      <div class="flex flex-column gap-2">
        <div *ngFor="let permission of tab.permissions" class="field-checkbox">
          <p-checkbox [(ngModel)]="selectedPermissions" [label]="permission.description" name="group" [value]="permission" />
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
  <div class="flex flex-grow-1 mt-4 align-items-end justify-content-end">
    <p-button *ngIf="!boundData?.isView" [label]="'Enregistrer'" (onClick)="savePermissions()" />
  </div>
</div>
