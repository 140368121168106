<div class="charts-container">
  <div class="card">
    <p-chart type="line" [data]="data2" [options]="options2"></p-chart>
  </div>
  <div class="card">
    <p-chart type="bar" [data]="data4" [options]="options4"></p-chart>
  </div>
</div>

<div class="charts-container">
  <div class="left-charts">
    <div class="card">
      <p-chart type="line" [data]="data3" [options]="options3"></p-chart>
    </div>
    <div class="card">
      <p-chart type="line" [data]="data" [options]="options"></p-chart>
    </div>
  </div>
  <div class="card">
    <p-chart type="line" [data]="data5" [options]="options5"></p-chart>
  </div>
</div>

<p-tabView [(activeIndex)]="activeTabIndex">
  <p-tabPanel header="Suivi des avances">
      <dynamic-table [headers]="columnsAdvance" [data]="intraday" [pageParams]="pageable"
                     [actions]="actions" (paginate)="onPageChange($event)">
      </dynamic-table>
  </p-tabPanel>
  <p-tabPanel header="Historique">
      <dynamic-table [headers]="columnsHistory" [data]="intraday" [pageParams]="pageable"
                     [actions]="actions" (paginate)="onPageChange($event)">
      </dynamic-table>
  </p-tabPanel>
</p-tabView>
