import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, take} from "rxjs";
import {AuthResponse} from "../models/auth.model";
import {EncryptionService} from "./encryption.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly url: string = 'auth/v1/auth';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly encryptionService: EncryptionService
  ) { }

  signIn(username: string, password: string): Observable<AuthResponse> {
    username = this.encryptionService.encryptData(username)
    password = this.encryptionService.encryptData(password);
    return this.httpClient.post<AuthResponse>(`${this.url}`, { username, password }).pipe(take(1));
  }

  refreshToken(): Observable<AuthResponse> {
    return this.httpClient.get<AuthResponse>(`${this.url}`).pipe(take(1));
  }

  verifyTwoFactor(username: string, code: string): Observable<AuthResponse> {
    return this.httpClient.post<any>(`${this.url}/verify-2fa`, { username, code }).pipe(take(1));
  }

  initPasswordReset(email: string): Observable<void> {
    const params = new HttpParams().set('email', email);
    return this.httpClient.get<void>(`${this.url}/reset-password`, { params }).pipe(take(1));
  }

  resetPassword(code: string, email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/reset-password`, { code, email }).pipe(take(1));
  }

  getPublicKey(): Observable<AuthResponse> {
    return this.httpClient.get<AuthResponse>(`${this.url}/public-key`).pipe(take(1));
  }
}
