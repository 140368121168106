import {Component, Input, OnInit, Optional} from '@angular/core';
import {IOperation} from "../../../../../core/models/operation.model";
import {
  DEFAULT_PAGE_PARAMS,
  getDefaultPageable,
  Pageable,
  PageParams,
  VALUE_DATE_SORT_ASC
} from "../../../../../core/models/page.model";
import {DialogService, DynamicDialogComponent, DynamicDialogRef} from "primeng/dynamicdialog";
import {OperationService} from "../../../../../core/services/operation.service";
import {ToastService} from "../../../../../core/services/toast.service";
import {ExportComponent, ExportData} from "../../../../../shared/components/export.component";
import {OperationListComponent} from "../../../../../shared/components/operations/operation-list.component";
import {SearchComponent} from "../../../../../shared/components/search.component";
import {PaginatorState} from "primeng/paginator";

@Component({
  selector: 'app-upcoming-operation',
  standalone: true,
  imports: [
    ExportComponent,
    OperationListComponent,
    SearchComponent
  ],
  template: `
    <div class="flex flex-column gap-4 w-full">
      <div class="flex w-full justify-content-between mt-2">
        <search (search)="onSearch($event)" [placeholder]="'Recherche'" [debounceTime]="500"></search>
        <export (handler)="exportOperation($event)" label="opérations"/>
      </div>
      <app-operation-list class="w-full" [data]="operations" [pageParams]="pageParams" [loading]="loading"
                          (paginate)="onPageChange($event)"/>
    </div>
  `,
})
export class UpcomingOperationComponent implements OnInit {

  @Input()
  set currency(value: string) {
    if (value) {
      this._currency = value;
      // Only reload if already initialized
      if (this.initialized) {
        this.loadOperations();
      }
    }
  }
  get currency(): string {
    return this._currency;
  }

  operations: IOperation[] = [];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  query: string = '';
  loading: boolean = true;
  private initialized = false;
  private _currency: string = 'XOF';
  instance: DynamicDialogComponent | undefined;

  constructor(
    @Optional() private readonly dialogService: DialogService,
    @Optional() private readonly ref: DynamicDialogRef,
    private readonly operationService: OperationService,
    private readonly toastService: ToastService
  ) {
    // Set initial currency from dialog data if available
    if (this.ref) this.instance = this.dialogService.getInstance(this.ref);
    this._currency = this.instance ? this.instance.data.currency : this.currency;
  }

  ngOnInit(): void {
    this.query = '';
    // Set initialized to true before loading operations
    this.initialized = true;
    this.loadOperations();
  }

  loadOperations(pageable: Pageable = getDefaultPageable(0, 10)): void {
    this.loading = true;
    this.operationService.searchFutureOperations(this.query, this._currency, pageable).subscribe({
      next: page => {
        this.operations = page.content;
        this.pageParams = {
          ...this.pageParams,
          page: page.number,
          first: page.number * page.size,
          rows: page.size,
          totalRecords: page.totalElements,
        };
        this.loading = false;
      },
      error: err => {
        this.toastService.showToast('Chargement des opérations à venir', err.error, 'error');
        this.loading = false;
      }
    });
  }

  onSearch($event: string): void {
    this.query = $event;
    this.loadOperations(getDefaultPageable(this.pageParams.page, this.pageParams.rows, VALUE_DATE_SORT_ASC));
  }

  onPageChange($event: PaginatorState): void {
    this.loadOperations(getDefaultPageable($event.page, $event.rows, VALUE_DATE_SORT_ASC));
  }

  exportOperation($event: ExportData): void {
    console.log('Exporting data: ', $event);
  }
}
