// product-dto.model.ts

import {ModulesEnum, PermissionsEnum, ProductsEnum } from "../enums/access-controls.enums";
import {IProduct} from "./product.model";

export interface IModule {
  id: string; // UUID can be represented as a string
  code: string;
  description: string;
  enabled: boolean;
  deleted: boolean;
  products: IProduct[];
}

export interface Modules {
  module: string;
  products: Products[];
}

export interface Products {
  product: string;
  permissions: string[];
}

export const modules: Modules[] = [
  {
    module: ModulesEnum.ADMINISTRATION,
    products: [
      {
        product: ProductsEnum.ADMINISTRATION,
        permissions: [
          PermissionsEnum.CREATE_USER,
          PermissionsEnum.UPDATE_USER,
          PermissionsEnum.DELETE_USER,
          PermissionsEnum.READ_USER,
          PermissionsEnum.CREATE_INSTITUTION,
          PermissionsEnum.UPDATE_INSTITUTION,
          PermissionsEnum.DELETE_INSTITUTION,
          PermissionsEnum.READ_INSTITUTION,
          PermissionsEnum.CREATE_GROUP,
          PermissionsEnum.UPDATE_GROUP,
          PermissionsEnum.DELETE_GROUP,
          PermissionsEnum.READ_GROUP,
          PermissionsEnum.CREATE_VALIDATION,
          PermissionsEnum.UPDATE_VALIDATION,
          PermissionsEnum.DELETE_VALIDATION,
          PermissionsEnum.READ_VALIDATION
        ]
      }
    ]
  },
  {
    module: ModulesEnum.TRESORERIE,
    products: [
      {
        product: ProductsEnum.VIREMENT,
        permissions: [
          PermissionsEnum.CREATE_VIREMENT,
          PermissionsEnum.UPDATE_VIREMENT,
          PermissionsEnum.DELETE_VIREMENT,
          PermissionsEnum.READ_VIREMENT
        ]
      },
      {
        product: ProductsEnum.LOCALE,
        permissions: [
          PermissionsEnum.CREATE_LOCALE,
          PermissionsEnum.UPDATE_LOCALE,
          PermissionsEnum.DELETE_LOCALE,
          PermissionsEnum.READ_LOCALE
        ]
      },
      {
        product: ProductsEnum.DEVISE,
        permissions: [
          PermissionsEnum.CREATE_DEVISE,
          PermissionsEnum.UPDATE_DEVISE,
          PermissionsEnum.DELETE_DEVISE,
          PermissionsEnum.READ_DEVISE
        ]
      }
    ]
  },
  {
    module: ModulesEnum.FOREX,
    products: [] // No products for FOREX
  },
  {
    module: ModulesEnum.RISK_MANAGEMENT,
    products: [
      {
        product: ProductsEnum.RISK_MANAGEMENT,
        permissions: [
          PermissionsEnum.CREATE_SIMULATION,
          PermissionsEnum.UPDATE_SIMULATION,
          PermissionsEnum.DELETE_SIMULATION,
          PermissionsEnum.READ_SIMULATION,
          PermissionsEnum.READ_AGGREGATIONS
        ]
      }
    ]
  },
  {
    module: "FOREX",
    products: [] // Empty array since there are no products
  },
  {
    module: "RISK_MANAGEMENT",
    products: [] // Empty array since there are no products
  },
  {
    module: "FIXED_INCOME",
    products: [
      {
        product: "FIXED_INCOME",
        permissions: [
          "CREATE_EMISSION",
          "UPDATE_EMISSION",
          "DELETE_EMISSION",
          "READ_EMISSION",
          "DELETE_SUBMISSION"
        ]
      }
    ] // Empty array since there are no products
  }
];

