import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getPageableAsParams, Page, Pageable} from '../models/page.model';
import {IAccount} from "../models/account.model";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private readonly apiUrl = `operation/v1/account`;

  constructor(private readonly http: HttpClient) { }

  /**
   * Create a new account.
   * @param account The account DTO to create.
   * @returns The created IAccount.
   */
  createAccount(account: IAccount): Observable<IAccount> {
    return this.http.post<IAccount>(this.apiUrl, account);
  }

  /**
   * Create a new account for the current user's institution.
   * @param account The account DTO to create.
   * @returns The created IAccount.
   */
  createAccountForMyInstitution(account: IAccount): Observable<IAccount> {
    return this.http.post<IAccount>(`${this.apiUrl}/institution`, account);
  }

  /**
   * Update an existing account by its id.
   * @param id The id of the account to update.
   * @param account The updated IAccount.
   * @returns The updated IAccount.
   */
  updateAccount(id: string, account: IAccount): Observable<IAccount> {
    return this.http.put<IAccount>(`${this.apiUrl}/${id}`, account);
  }

  /**
   * Get a specific account by its id.
   * @param id The id of the account.
   * @returns The IAccount.
   */
  getAccountById(id: string): Observable<IAccount> {
    return this.http.get<IAccount>(`${this.apiUrl}/${id}`);
  }

  /**
   * Get all accounts with optional pagination and sorting.
   * @param pageable The parameter of the requested page.
   * @returns A page of IAccount.
   */
  getAllAccounts(pageable: Pageable): Observable<Page<IAccount>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccount>>(this.apiUrl, { params });
  }

  /**
   * Get all accounts by institution id with optional pagination and sorting.
   * @param institutionId The id of the institution.
   * @param pageable The page params.
   * @returns A page of IAccount.
   */
  getAccountsByInstitutionId(institutionId: string, pageable: Pageable): Observable<Page<IAccount>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccount>>(`${this.apiUrl}/institution/${institutionId}`, { params });
  }

  /**
   * Get all accounts of the current user's institution with optional pagination and sorting.
   * @param pageable The page params.
   * @returns A page of IAccount.
   */
  getCurrentUserInstitutionAccounts(pageable: Pageable): Observable<Page<IAccount>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccount>>(`${this.apiUrl}/institution`, { params });
  }

  /**
   * Delete (soft delete) an account by its id.
   * @param id The id of the account to delete.
   * @returns An Observable for the deletion.
   */
  deleteAccount(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getAccountsByCurrency(currencyCode: string, pageable: Pageable): Observable<Page<IAccount>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccount>>(`${this.apiUrl}/devise/${currencyCode}`, { params });
  }

  getForeignAccountsForInstitution(pageable: Pageable): Observable<Page<IAccount>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccount>>(`${this.apiUrl}/foreign`, { params });
  }

  getLocalAccountsForInstitution(pageable: Pageable): Observable<Page<IAccount>> {
    let params = getPageableAsParams(pageable);
    return this.http.get<Page<IAccount>>(`${this.apiUrl}/local`, { params });
  }

  exportAccountBalancesToExcel(isLocalCurrency: boolean = true, isExcel: boolean = true): Observable<HttpResponse<Blob>> {
    let params = new HttpParams()
      .set('isLocalCurrency', isLocalCurrency.toString())
      .set('isExcel', isExcel.toString());
    return this.http.get<Blob>(`${this.apiUrl}/export`, { params, observe: 'response', responseType: 'blob' as 'json' });
  }

}
