import {CommonModule, UpperCasePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ToolbarComponent} from '../../../shared/components/toolbar/toolbar.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {MenuItem} from 'primeng/api';
import {Router} from '@angular/router';

@Component({
  selector: 'app-simulations',
  standalone: true,
  imports: [CommonModule, ToolbarComponent, UpperCasePipe, TabMenuModule],
  templateUrl: './simulations.component.html',
  styleUrl: './simulations.component.scss'
})
export class SimulationsComponent implements OnInit {

  pageTitle = 'risques alm';
  items: MenuItem[] | undefined;
  activeItem: MenuItem | undefined;


  constructor(private router: Router) {
  }


  ngOnInit() {
    this.items = [
      {label: 'BAT', icon: '', route: 'pricer-bat'},
      {label: 'OAT', icon: '', route: 'pricer-oat'}
    ];

    const currentRoute = this.router.url;
    if (currentRoute.split('/').pop() === 'pricer-bat') {
      this.activeItem = this.items[0]
    } else {
      this.activeItem = this.items[1]
    }
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
  }

}
