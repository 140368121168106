<toolbar [title]="pageTitle"></toolbar>
<div class="passifs-actifs-visualization-container">
  <app-suivi-custom-chart [data]="data" [options]="options" legendContainerId="legend-container"></app-suivi-custom-chart>

  <div class="tables-container">
    <ng-container *ngFor="let group of [{name: 'Actifs', results: actifsResults, totals: totalsActifs}, {name: 'Passifs', results: passifsResults, totals: totalsPassifs}]">

      <p-table class="p-datatable custom" [value]="group.results">
        <ng-template pTemplate="header">
          <tr>
            <th >{{ group.name }}</th>
            <th *ngFor="let date of displayedDates">{{ group.name ==='Actifs' ? formatDate(date) : ''}}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="categoryColumn">{{ getDisplayName(rowData.categoryName) }}</td>
            <td *ngFor="let date of displayedDates">
              {{ rowData.calculations[date] || 0 | number:'1.0-0':'fr' }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr>
            <td>{{ 'Total ' + group.name + ' (XOF)' | uppercase }}</td>
            <td *ngFor="let date of displayedDates">
              {{ (group.totals[date] || 0) | number:'1.0-0':'fr' }}</td>

          </tr>
        </ng-template>
      </p-table>

<!--
      <div class="sep"></div>
-->
    </ng-container>

    <p-table [value]="ratesTableData" class="p-datatable custom">
      <ng-template pTemplate="header">
        <tr>
          <th>Taux d'intérêts (%)</th>
          <th *ngFor="let date of displayedDates"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <ng-container *ngIf="rowData.type !== 'GAP'">
          <tr>
            <td class="categoryColumn">{{ rowData.type }}</td>
            <td *ngFor="let value of rowData.values">{{ value.toFixed(2) }}</td>
          </tr>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td>{{ 'GAP (%)' }}</td>
          <td *ngFor="let value of getGapValues()">{{ value.toFixed(2) }}</td>
        </tr>
      </ng-template>

    </p-table>

  </div>


</div>



<div class="gaps-liquidite-visualization-contianer">

</div>


