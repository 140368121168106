<div class="flex flex-column h-full">
  <form [formGroup]="formGroup" class="flex flex-column gap-4 mt-4">
    <div class="flex flex-column gap-2">
      <label for="firstname" class="font-medium">Prénom</label>
      <input pInputText id="firstname" aria-describedby="firstname-help" formControlName="firstname" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="lastname" class="font-medium">Nom</label>
      <input pInputText id="lastname" aria-describedby="lastname-help" formControlName="lastname" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="username" class="font-medium">Nom d'utilisateur</label>
      <input pInputText id="username" aria-describedby="username-help" formControlName="username" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="email" class="font-medium">Email</label>
      <input pInputText id="email" aria-describedby="email-help" formControlName="email" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="phoneNumber" class="font-medium">Téléphone</label>
      <input pInputText id="phoneNumber" aria-describedby="phone-help" formControlName="phoneNumber" />
    </div>
  </form>
  <div class="flex flex-grow-1 gap-2 align-items-end mt-4" [ngClass]="isEditing ? 'justify-content-end' : 'justify-content-start'">
    <p-button *ngIf="!isEditing" label="Éditer" severity="secondary" [outlined]="true" (onClick)="toggleEditing()"/>
    <p-button pRipple *ngIf="!formGroup.disabled" label="Modifier"
              [disabled]="!formGroup.valid" [loading]="isLoading" (onClick)="update()" />
  </div>
</div>
