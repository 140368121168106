import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Button} from "primeng/button";
import {DynamicTableComponent} from "../dynamic-table/dynamic-table.component";
import {ConfirmationService, PrimeTemplate} from "primeng/api";
import {RepaymentVolumeChartComponent} from "./repayment-chart/repayment-chart.component";
import {SearchComponent} from "../search.component";
import {SelectButtonModule} from "primeng/selectbutton";
import {ILoanBorrowing, LoanBorrowingType} from "../../../core/models/loan-borrowing.model";
import {DEFAULT_PAGE_PARAMS, getDefaultPageable, PageParams} from "../../../core/models/page.model";
import {CustomAction, CustomTableHeader} from "../../../core/models/custom-table.model";
import {
  LoanBorrowingFilterComponent,
  LoanBorrowingFilterData
} from "./loan-borrowing-filter/loan-borrowing-filter.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../../../core/services/toast.service";
import {LoanBorrowingService} from "../../../core/services/loan-borrowing.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {Subscription, take} from "rxjs";
import {CustomDialogData} from "../../../core/models/custom-dialog.model";
import {AuditComponent} from "../audit/audit.component";
import {customConfirmationDialog, customDynamicDialog} from "../../../core/utils/utils";
import {NgIf} from "@angular/common";

@Component({
  selector: 'loan-borrowing',
  standalone: true,
  imports: [
    // Modules
    SelectButtonModule, FormsModule,
    // Components
    DynamicTableComponent, RepaymentVolumeChartComponent, SearchComponent,
    // Pipes & Directives
    Button, PrimeTemplate, NgIf
  ],
  templateUrl: './shared-loan-borrowing.component.html',
  styleUrl: './shared-loan-borrowing.component.scss'
})
export class SharedLoanBorrowingComponent implements OnInit, OnChanges, OnDestroy {

  @Input() page: string = 'Résumé';
  @Input() types: LoanBorrowingType[] = [LoanBorrowingType.LOAN, LoanBorrowingType.BORROWING];
  activeViews: number[] = [1, 2];
  viewOptions: any[] = [
    { name: 'Tableau', icon: 'pi pi-table', value: 1 },
    { name: 'Graphes', icon: 'pi pi-chart-bar', value: 2 },
  ];
  loanBorrowings: ILoanBorrowing[] = [];
  tablePageable: PageParams = DEFAULT_PAGE_PARAMS;
  tableActions: CustomAction[] = [
    {
      role: 'READ_LOAN_BORROWING',
      icon: 'pi pi-info-circle',
      label: 'Consulter',
      severity: 'info',
      emit: (item: ILoanBorrowing) => this.updateLoanBorrowing(item),
      visible: true
    },
    {
      role: 'UPDATE_LOAN_BORROWING',
      icon: 'pi pi-pencil',
      label: 'Modifier',
      severity: 'primary',
      emit: (loanBorrowing: ILoanBorrowing) => this.updateLoanBorrowing(loanBorrowing),
      visible: (item: ILoanBorrowing) => item?.canEdit ?? false
    },
    {
      role: 'UPDATE_LOAN_BORROWING',
      icon: 'pi pi-check-circle',
      label: 'Valider',
      severity: 'primary',
      emit: (item: ILoanBorrowing) => this.validateLoanBorrowing(item),
      visible: (item: ILoanBorrowing) => item?.canValidate ?? false
    },
    {
      role: 'UPDATE_LOAN_BORROWING',
      icon: 'pi pi-times-circle',
      label: 'Rejeter',
      severity: 'primary',
      emit: (item: ILoanBorrowing) => this.rejectLoanBorrowing(item),
      visible: (item: ILoanBorrowing) => item?.canReject ?? false
    },
    {
      role: 'UPDATE_LOAN_BORROWING',
      icon: 'pi pi-ban',
      label: 'Annuler',
      severity: 'primary',
      emit: (item: ILoanBorrowing) => this.cancelLoanBorrowing(item),
      visible: (item: ILoanBorrowing) => item?.canCancel ?? false
    },
    {
      role: 'UPDATE_LOAN_BORROWING',
      icon: 'pi pi-send',
      label: 'Exécuter',
      severity: 'primary',
      emit: (item: ILoanBorrowing) => this.executeLoanBorrowing(item),
      visible: (item: ILoanBorrowing) => item?.canExecute ?? false
    },
    {
      role: 'READ_LOAN_BORROWING',
      icon: 'pi pi-history',
      label: 'Audits',
      severity: 'info',
      emit: (item: ILoanBorrowing) => this.viewAudits(item),
      visible: true
    },
    {
      role: 'DELETE_LOAN_BORROWING',
      icon: 'pi pi-trash',
      label: 'Supprimer',
      severity: 'danger',
      emit: (item: ILoanBorrowing) => this.confirmDeletion(item),
      visible: (item: ILoanBorrowing) => item?.canDelete ?? false
    },
  ];
  tableColumns: CustomTableHeader[] = [
    {
      key: 'valueDate',
      column: 'Date de valeur',
      type: 'date'
    },
    {
      key: 'maturityDate',
      column: 'Date de maturité',
      type: 'date'
    },
    {
      key: 'counterparty',
      column: 'Contrepartie',
      type: 'text'
    },
    {
      key: 'currency.code',
      column: 'Devise',
      type: 'text'
    },
    {
      key: 'interestRate',
      column: 'Taux d\'intérêt (%)',
      type: 'number'
    },
    {
      key: 'amount',
      column: 'Montant',
      type: 'currency',
      currencyKey: 'currency.code'
    },
    {
      key: 'state',
      column: 'État',
      type: 'text'
    },
  ];
  searchOptions: LoanBorrowingFilterData = { query: '', types: [], states: [], dates: null, isLocal: true };
  private routeDataSubscription: Subscription = new Subscription();

  constructor(
    private readonly toastService: ToastService,
    private readonly loanBorrowingService: LoanBorrowingService,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly confirmationService: ConfirmationService
  ) {
    this.searchOptions = {
      ... this.searchOptions,
      isLocal: this.router.url.includes('/tresorerie/locale/')
    };
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.data.subscribe(data => this.updateComponentData(data));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['types']) {
      this.updateSearchOptions();
      this.loadLoanBorrowings();
    }
  }

  ngOnDestroy() {
    if (this.routeDataSubscription) {
      this.routeDataSubscription.unsubscribe();
    }
  }

  private updateComponentData(data: any) {
    this.page = data['page'] || this.page;
    if (data['types']) {
      this.types = Array.isArray(data['types']) ? data['types'] : [data['types']];
    }
    this.updateSearchOptions();
    this.loadLoanBorrowings();
  }

  private updateSearchOptions() {
    this.searchOptions.types = this.types;
  }

  createLoanBorrowing() {
    this.router.navigate(['new'], {
      relativeTo: this.route.parent,
      queryParams: { types: this.types.join(',') }
    }).then();
  }

  updateLoanBorrowing(loanBorrowing: ILoanBorrowing) {
    this.router.navigate(['details',loanBorrowing.id], { relativeTo: this.route.parent }).then();
  }

  loadLoanBorrowings() {
    this.loanBorrowingService.advancedSearch(this.searchOptions, getDefaultPageable(this.tablePageable.page, this.tablePageable.rows))
      .subscribe({
        next: (response) => {
          this.loanBorrowings = response.content;
          this.tablePageable = {
            ...this.tablePageable,
            page: response.number,
            rows: response.size,
            totalRecords: response.totalElements
          };
        },
        error: (err) => this.toastService.showToast('Chargement des prêts/emprunts', err.error, 'error')
      });
  }

  validateLoanBorrowing(item: ILoanBorrowing) {
    this.loanBorrowingService.validateLoanBorrowing(item.id).subscribe({
      next: () => this.showToastAndReload('Prêt/emprunt validé', 'Le prêt/emprunt a été validé avec succès', 'success'),
      error: (err) => this.showToastAndReload('Validation du prêt/emprunt', err.error, 'error')
    });
  }

  confirmDeletion(item: ILoanBorrowing) {
    const message = `Voulez-vous supprimer le prêt/emprunt ${item.counterparty} ?`;
    const header = 'Suppression de prêt/emprunt';
    const options = customConfirmationDialog(header, message, { accept: () => this.deleteLoanBorrowing(item) });
    this.confirmationService.confirm(options);
  }

  deleteLoanBorrowing(item: ILoanBorrowing) {
    this.loanBorrowingService.deleteLoanBorrowing(item.id).subscribe({
      next: () => this.showToastAndReload('Prêt/emprunt supprimé', 'Le prêt/emprunt a été supprimé avec succès', 'success'),
      error: (err) => this.showToastAndReload('Suppression du prêt/emprunt', err.error, 'error')
    });
  }

  executeLoanBorrowing(item: ILoanBorrowing) {
    this.loanBorrowingService.executeLoanBorrowing(item.id).subscribe({
      next: () => this.showToastAndReload('Prêt/emprunt exécuté', 'Le prêt/emprunt a été exécuté avec succès', 'success'),
      error: (err) => this.showToastAndReload('Exécution du prêt/emprunt', err.error, 'error')
    });
  }

  cancelLoanBorrowing(item: ILoanBorrowing) {
    this.loanBorrowingService.cancelLoanBorrowing(item.id).subscribe({
      next: () => this.showToastAndReload('Prêt/emprunt annulé', 'Le prêt/emprunt a été annulé avec succès', 'success'),
      error: (err) => this.showToastAndReload('Annulation du prêt/emprunt', err.error, 'error')
    });
  }

  viewAudits(item: ILoanBorrowing) {
    const data = {isView: true, data: item} as CustomDialogData;
    const ref = this.dialogService.open(AuditComponent, customDynamicDialog('Audit prêt/emprunt', data));
    ref.onClose.pipe(take(1)).subscribe(() => this.loadLoanBorrowings());
  }

  rejectLoanBorrowing(item: ILoanBorrowing) {
    this.loanBorrowingService.rejectLoanBorrowing(item.id).subscribe({
      next: () => this.showToastAndReload('Prêt/emprunt rejeté', 'Le prêt/emprunt a été rejeté avec succès', 'success'),
      error: (err) => this.showToastAndReload('Rejet du prêt/emprunt', err.error, 'error')
    });
  }

  showToastAndReload(message: string, detail: string, severity: string) {
    this.toastService.showToast(message, detail, severity);
    this.loadLoanBorrowings();
  }

  onSearch($event: string) {
    this.searchOptions.query = $event;
    this.loadLoanBorrowings();
  }

  displayFilterDialog() {
    const ref: DynamicDialogRef = this.dialogService.open(LoanBorrowingFilterComponent, {
      header: 'Filtre',
      width: '40rem',
      height: '30rem',
      data: {
        showTypeFilter: this.types.length > 1,
        showStateFilter: true,
        showDateFilter: true,
        initialData: this.searchOptions
      }
    });

    ref.onClose.subscribe((result: LoanBorrowingFilterData) => {
      if (result) {
        this.searchOptions = { ...result, types: this.types };
        this.loadLoanBorrowings();
      }
    });
  }

}
