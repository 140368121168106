<div class="flex flex-column w-full h-full align-content-between">
  <toolbar class="w-full" title="Comptes NOSTRO"></toolbar>
  <div class="flex gap-2 flex-wrap mb-4">
    <div *ngFor="let account of accounts">
      <p-menu #menu [popup]="true" [model]="getMenuItems(account)"></p-menu>
      <div class="flex flex-column p-2 gap-4 border-round border-1 border-200">
        <div class="flex flex-column p-4 gap-4 bg-primary border-round">
          <div class="flex justify-content-between align-items-center">
            <span class="font-semibold text-xl text-color-gray-200">{{ account.lastBalance.date | date : 'dd/MM/yyyy' }}</span>
            <p-button
              rounded="true"
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-rounded"
              (click)="menu.toggle($event)"
            ></p-button>
          </div>
          <div class="w-full bg-white p-2 border-round justify-content-center flex">
            <img [src]="account.bank.logoUrl" [alt]="account.bank.name">
          </div>
          <div class="w-full flex justify-content-center">
            <span class="font-semibold text-xl">{{ account.libelle }}</span>
          </div>
        </div>
        <div class="flex flex-column gap-2 mb-2">
          <div class="flex justify-content-between">
            <span class="font-semibold text-400">Solde</span>
            <span class="font-bold text-500">
              {{ account.lastBalance.balance | currency : account.currency.code : 'symbol' : '1.0-0' : 'fr-FR'  }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-paginator *ngIf="pageable" (onPageChange)="onPageChange($event)" [first]="pageable.first" [rows]="pageable.rows"
               [totalRecords]="pageable.totalRecords" [rowsPerPageOptions]="pageable.rowPerPageOptions" />
</div>
