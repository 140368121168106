export interface IBalanceSheetCategory {
  id: string;
  institutionId: string;
  code: string;
  name: string;
  common: boolean;
  description?: string;
  parent?: IBalanceSheetCategory;
  type: string; //actif ou passif
  itemType: ItemType;
  isLeaf: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

export enum ItemType {
  CONVENTIONAL = "Conventionnel",
  CONTRACTUAL = "Contractuel"
}
