import {CommonModule, DatePipe, DecimalPipe, UpperCasePipe} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToolbarComponent} from '../../../shared/components/toolbar/toolbar.component';
import {SuiviRisqueService} from '../../../core/services/risque-alm/suivi-risque.service';
import {getUnpagedPageable} from "../../../core/models/page.model";
import {TableModule} from 'primeng/table';
import {ChartModule} from 'primeng/chart';
import {SuiviChartComponent} from './suivi-chart/suivi-chart.component';
import {chartTauxData} from '../../../../assets/json';


@Component({
  selector: 'app-suivi-risque',
  standalone: true,
  imports: [CommonModule, ToolbarComponent, UpperCasePipe, DatePipe, TableModule, DecimalPipe, ChartModule, SuiviChartComponent],
  templateUrl: './suivi-risque.component.html',
  styleUrls: ['./suivi-risque.component.scss'],
  providers: [DecimalPipe]
})
export class SuiviRisqueComponent implements OnInit {
  @ViewChild('chart') chartRef!: ElementRef;
  chartInstance: any;
  data: any;
  options: any;
  showView = false;
  pageTitle = 'Risques ALM';
  currentDate = new Date();
  year = 0;
  categoryResults: any[] = [];
  displayedDates: string[] = [];
  actifsResults: any[] = [];
  passifsResults: any[] = [];
  totalsActifs: { [key: string]: number } = {};
  totalsPassifs: { [key: string]: number } = {};

  actifsCategories = ['PRET_INTERBANCAIRE', 'TITRE', 'CREDIT_CLIENTELE', 'IMMOBILISATION'];
  passifsCategories = ['EMPRUNT_INTERBANCAIRE', 'FOND_PROPRE', 'DEPOT_CLIENTELE', 'AUTRE'];

  categoryDisplayNames: { [key: string]: string } = {
    'PRET_INTERBANCAIRE': 'Prêts interbancaires',
    'TITRE': 'Titres',
    'CREDIT_CLIENTELE': 'Crédits clientèle',
    'IMMOBILISATION': 'Immobilisations',
    'EMPRUNT_INTERBANCAIRE': 'Emprunts interbancaires',
    'FOND_PROPRE': 'Fonds Propres',
    'DEPOT_CLIENTELE': 'Dépôts clientèle',
    'AUTRE': 'Autres'
  };

  categoryColors: { [key: string]: string } = {
    'PRET_INTERBANCAIRE': '#004A6A',
    'TITRE': '#0E7B9D',
    'CREDIT_CLIENTELE': '#59B0D4',
    'IMMOBILISATION': '#93E7FF',
    'EMPRUNT_INTERBANCAIRE': '#842C00',
    'FOND_PROPRE': '#9E4200',
    'DEPOT_CLIENTELE': '#B85605',
    'AUTRE': '#FA8C00',
    'Gap': '#21C164',
  };

  lineChartColors: { [key: string]: string } = {
    'Actifs': '#9DF4C2',
    'Passifs': '#4B0707'
  }

  fetchedRatesData = chartTauxData;
  ratesTableData: any;


  constructor(private suiviRisqueService: SuiviRisqueService, private datePipe: DatePipe, public decimalPipe: DecimalPipe) {
  }

  //TODO: optimize code

  prepareRatesTableData() {
    const actifs = this.fetchedRatesData.actifs;
    const passifs = this.fetchedRatesData.passifs;
    this.ratesTableData = [
      {type: 'Actifs', values: actifs},
      {type: 'Passifs', values: passifs}
    ];
  }

  ngOnInit() {
    this.year = this.currentDate.getFullYear();
    this.fetchSuiviALMResults();
  }

  getGapValues(): number[] {
    const actifs = this.fetchedRatesData.actifs;
    const passifs = this.fetchedRatesData.passifs;

    return actifs.map((actifValue: number, index: number) => {
      const passifValue = passifs[index];
      const gapValue = Math.abs(actifValue) - Math.abs(passifValue);
      return gapValue;
    });
  }

  fetchSuiviALMResults() {
    this.suiviRisqueService.getResultsForSuiviALM(getUnpagedPageable(), 2024).subscribe({
      next: (categoryCalculations) => {
        this.categoryResults = categoryCalculations.map(result => {
          const updatedCalculations = Object.fromEntries(
            Object.entries(result.calculations).map(([date, amount]) => [date, Math.abs(Number(amount))])
          );
          return {...result, calculations: updatedCalculations};
        });
        this.updateDisplayedDates();
        this.filterAndCalculateTotals();
        this.prepareChartData();  // Prepare chart data after filtering

      },
      error: (error) => {
        console.log("Error fetching the information", error);
      }
    });
  }

  updateDisplayedDates(): void {
    const datesSet = new Set<string>();
    this.categoryResults.forEach(result => {
      Object.keys(result.calculations).forEach(date => datesSet.add(date));
    });
    this.displayedDates = Array.from(datesSet).sort();
  }

  filterAndCalculateTotals(): void {
    this.actifsResults = this.categoryResults.filter(result =>
      this.actifsCategories.includes(result.categoryName)
    );
    this.passifsResults = this.categoryResults.filter(result =>
      this.passifsCategories.includes(result.categoryName)
    );

    this.totalsActifs = this.calculateGroupTotals(this.actifsResults);
    this.totalsPassifs = this.calculateGroupTotals(this.passifsResults);
  }

  calculateGroupTotals(results: any[]): { [key: string]: number } {
    const totals: { [key: string]: number } = {};
    results.forEach(result => {
      Object.entries(result.calculations).forEach(([date, amount]) => {
        totals[date] = (totals[date] || 0) + Number(amount);
      });
    });
    return totals;
  }

  prepareChartData(): void {
    this.prepareRatesTableData();

    const months = Array.from(new Set(this.displayedDates.map(date => this.formatMonth(date))));
    const categoryTotals: { [key: string]: number[] } = {};

    this.actifsCategories.forEach(category => {
      categoryTotals[category] = new Array(months.length).fill(0);
    });

    this.passifsCategories.forEach(category => {
      categoryTotals[category] = new Array(months.length).fill(0);
    });

    // Add an entry for the actifs and passifs gap
    categoryTotals['Gap'] = new Array(months.length).fill(0);

    this.displayedDates.forEach((date, index) => {
      const month = this.formatMonth(date);
      const monthIndex = months.indexOf(month);

      let totalActifs = 0;
      let totalPassifs = 0;

      this.categoryResults.forEach(result => {
        const amount = Math.abs(result.calculations[date]);

        if (this.actifsCategories.includes(result.categoryName)) {
          totalActifs += amount;
          categoryTotals[result.categoryName][monthIndex] = amount;
        } else if (this.passifsCategories.includes(result.categoryName)) {
          totalPassifs += amount;
          categoryTotals[result.categoryName][monthIndex] = -amount;  // Passifs are negative
        }
      });

      // Calculate the gap
      let gap = 0;
      if (totalActifs > totalPassifs) {
        gap = -(totalActifs - totalPassifs);
      } else {
        gap = totalPassifs - totalActifs;
      }
      console.log("TOTAL ACTI, passif , gap", totalActifs, totalPassifs, gap)
      categoryTotals['Gap'][monthIndex] = gap;
    });

    this.data = this.setChartData(months, categoryTotals);
    this.options = this.setChartOptions();

    this.showView = true;
  }

  getDisplayName(categoryName: string): string {
    return this.categoryDisplayNames[categoryName] || categoryName;
  }

  formatDate(date: string): string {
    const dateStr = new Date(date);
    const formattedDate = this.datePipe.transform(dateStr, 'MMM yy', 'fr') || '';
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }

  formatMonth(date: string): string {
    return new Date(date).toLocaleDateString('fr-FR', {year: 'numeric', month: 'short'});
  }

  getRatesValues(type: 'Actifs' | 'Passifs'): number[] {
    const rateData = this.ratesTableData.find((row: { type: string }) => row.type === type);
    if (rateData) {
      // Negate values for 'Passifs'
      if (type === 'Passifs') {
        return rateData.values.map((value: number) => -value);
      }
      // Return values as is for 'Actifs'
      return rateData.values;
    }
    // Return an empty array if no data is found
    return [];
  }

  setChartData(months: string[], categoryTotals: { [p: string]: number[] }) {
    return {
      labels: months,
      datasets: [
        ...Object.keys(categoryTotals).map(category => ({
          label: this.categoryDisplayNames[category] || category,
          data: categoryTotals[category],
          backgroundColor: this.categoryColors[category] || '#CCCCCC',// Default color if not found,
          barThickness: 55,
          borderColor: category === 'Gap' ? 'black' : 'transparent', // Black border
          borderWidth: category === 'Gap' ? 1.8 : 0,
          borderDash: category === 'Gap' ? [15, 5] : [0, 0],
          pointStyle: category === 'Gap' ? 'dash' : 'rect',
          yAxisID: 'y',
          type: 'bar', order: 2
        })),
        {
          label: 'Taux Actifs',
          type: 'line',
          data: this.getRatesValues("Actifs"), // Use Actifs values from ratesTableData
          backgroundColor: this.lineChartColors['Actifs'], // Light green
          borderColor: this.lineChartColors['Actifs'],
          borderWidth: 2,
          fill: false,
          yAxisID: 'y1',
          order: 1
        },
        {
          label: 'Taux Passifs',
          type: 'line',
          data: this.getRatesValues("Passifs"), // Use Passifs values from ratesTableData
          backgroundColor: this.lineChartColors['Passifs'], // Light red
          borderColor: this.lineChartColors['Passifs'],
          borderWidth: 2,
          fill: false,
          yAxisID: 'y1',
          order: 1
        },
        // Line chart for Passifs percentages

      ]
    }
  }

  setChartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 0.5,
      plugins: {
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: 'legend-container',
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += `${context.parsed.y.toLocaleString('fr-FR')}`;  // Format number with dots
              }
              return label;
            }
          }
        },

      },
      scales: {
        x: {
          ticks: {
            font: {
              color: 'black',
              size: 12,  // Font size for the label
              family: 'Spline Sans',  // Font family
              weight: 'bold',  // Font weight
            },
          },
          stacked: true,
          title: {
            display: true,
            text: '(Période)',  // The unit label for the Y-axis
            color: '#000',  // Color of the label
            font: {
              size: 14,  // Font size for the label
              family: 'Spline Sans',  // Font family
              weight: 'bold',  // Font weight
            },
            padding: {
              bottom: 0  // Space between the label and the axis
            }
          }
        },
        y: {
          stacked: true,
          ticks: {
            callback: (value: number) => (value / 1000000000).toLocaleString('fr-FR'), // Format number with spaces for thousands
            font: {
              color: 'black',
              size: 12,  // Font size for the label
              family: 'Spline Sans',  // Font family
              weight: 'bold',  // Font weight
            },
          },
          font: {
            size: 14,  // Font size for the label
            family: 'Spline Sans',  // Font family
            weight: 'bold',  // Font weight
          },
          title: {
            display: true,
            text: '(Milliards XOF)',  // The unit label for the Y-axis
            color: '#000',  // Color of the label
            font: {
              size: 14,  // Font size for the label
              family: 'Spline Sans',  // Font family
              weight: 'bold',  // Font weight
            },
            padding: {
              bottom: 0  // Space between the label and the axis
            }
          }
        },
        y1: {
          title: {
            display: true,
            text: '(Taux %)',  // The unit label for the Y-axis
            color: '#000',  // Color of the label
            font: {
              size: 14,  // Font size for the label
              family: 'Spline Sans',  // Font family
              weight: 'bold',  // Font weight
            },
          },
          type: 'linear',
          position: 'right',
          grid: {
            drawOnChartArea: false,
          },
          alignToPixels: true,
          ticks: {
            stepSize: 2,
            callback: (value: number) => `${value}%`,
            dashOffset: 0.0,
            font: {
              size: 12,  // Font size for the label
              family: 'Spline Sans',  // Font family
              weight: 'bold',  // Font weight
            },
          }
        }
      }
    }
  }

}
